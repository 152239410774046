export const determineUserAccess = permission => {
  const userPermission = {
    hasInsights: false,
    isAdmin: false,
    priceVisible: false,
    providersVisible: false,
    isTrafficker: false,
    isPartner: false,
    isSelfService: false,
    isDemo: false,
    exportData: false,
    isProcessor: false,
    canResetPasswords: false,
    campaignSummary: false,
    isPacing: false,
    pastData: false,
    segment: false,
    technicalSupport: false,
    horizontalNavigation: false,
    articles: false,
    amplify: false,
    configurator: false,
    billing: false,
    sitesAppsTransactions: false,
  };

  if (!permission) {
    return userPermission;
  }

  userPermission.hasInsights =
    permission && permission.hasInsights ? permission.hasInsights : false;

  userPermission.isAdmin =
    permission && permission.isAdmin ? permission.isAdmin : false;

  userPermission.priceVisible =
    permission && permission.priceVisible ? permission.priceVisible : false;

  userPermission.providersVisible =
    permission && permission.providersVisible
      ? permission.providersVisible
      : false;

  userPermission.isTrafficker =
    permission && permission.isTrafficker ? permission.isTrafficker : false;

  userPermission.isPartner =
    permission && permission.isPartner ? permission.isPartner : false;

  userPermission.isSelfService =
    permission && permission.isSelfService ? permission.isSelfService : false;

  userPermission.isDemo =
    permission && permission.isDemo ? permission.isDemo : false;

  userPermission.exportData =
    permission && permission.exportData ? permission.exportData : false;

  userPermission.isProcessor =
    permission && permission.isProcessor ? permission.isProcessor : false;

  userPermission.canResetPasswords =
    permission && permission.canResetPasswords
      ? permission.canResetPasswords
      : false;

  userPermission.isPacing =
    permission && permission.isPacing ? permission.isPacing : false;

  userPermission.campaignSummary =
    permission && permission.campaignSummary
      ? permission.campaignSummary
      : false;

  userPermission.pastData =
    permission && permission.pastData ? permission.pastData : false;

  userPermission.segment =
    permission && permission.segment ? permission.segment : false;

  userPermission.technicalSupport =
    permission && permission.technicalSupport
      ? permission.technicalSupport
      : false;

  userPermission.horizontalNavigation =
    permission && permission.horizontalNavigation
      ? permission.horizontalNavigation
      : false;

  userPermission.articles =
    permission && permission.articles ? permission.articles : false;

  userPermission.amplify =
    permission && permission.amplify ? permission.amplify : false;

  userPermission.configurator =
    permission && permission.configurator ? permission.configurator : false;

  userPermission.billing =
    permission && permission.billing ? permission.billing : false;

  userPermission.sitesAppsTransactions =
    permission && permission.sitesAppsTransactions
      ? permission.sitesAppsTransactions
      : false;

  return userPermission;
};

export const determineWalkIns = () => {
  return false;
};

export const determineTransactions = () => {
  return false;
};
