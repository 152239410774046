import gql from "graphql-tag";

const IMPRESSION_TAG_CREATE = gql`
  mutation createImpressionsTag(
    $name: String!
    $dsp: DemandSidePlatform!
    $orgId: String!
  ) {
    createImpressionsTag(
      data: { advertiserName: $name, environment: $dsp, orgId: $orgId }
    ) {
      id
      name
      eventTags {
        id
        data
      }
      isAdvertiser
      dsp
    }
  }
`;

export default IMPRESSION_TAG_CREATE;
