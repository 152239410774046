import React from "react";
import { Card, Row } from "antd";
import { Chart, Axis, Geom, Tooltip } from "bizcharts";
import {
  CardText,
  CardTitleText,
} from "../../../../../../shared/globalStyling/styledText";

import { ExtraInfo } from "./";
import {
  formatWholeNumber,
  formatWholeNumberNaN,
} from "../../../../../../../core/utils/campaigns";

const VisitByDayOfWeekCardV2 = ({
  data,
  geoDates: { start, end },
  customLabel,
  showTotalVisits = true,
  explanation = "",
}) => {
  const geoDatesDiff = end.diff(start, "days");
  const startDate = start.format("MMMM DD, YYYY");
  const endDate = end.format("MMMM DD, YYYY");

  const cols = {
    count: {
      min: 0,
    },
  };

  // Map original hour to human readable
  const hourMapping = {
    0: "12 am",
    1: "1 am",
    2: "2 am",
    3: "3 am",
    4: "4 am",
    5: "5 am",
    6: "6 am",
    7: "7 am",
    8: "8 am",
    9: "9 am",
    10: "10 am",
    11: "11 am",
    12: "12 nn",
    13: "1 pm",
    14: "2 pm",
    15: "3 pm",
    16: "4 pm",
    17: "5 pm",
    18: "6 pm",
    19: "7 pm",
    20: "8 pm",
    21: "9 pm",
    22: "10 pm",
    23: "11 pm",
  };

  const hourOfDay = data.map(item => ({
    Hour: item.hour,
    "Hour of the Day": hourMapping[item.hour],
    Visits: item.count,
  }));

  const totalVisits = hourOfDay.reduce(
    (accumulator, currentValue) => accumulator + currentValue["Visits"],
    0
  );

  return (
    <Card
      title={
        <CardTitleText
          text={`${customLabel ? customLabel : "Walk Ins"}: By Hour Of The Day`}
        />
      }
      style={{
        height: "34.6em",
      }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2%",
      }}
      headStyle={{ borderWidth: "2px" }}
      bordered
      extra={
        explanation ? (
          <ExtraInfo placement={"top"} title={explanation} />
        ) : (
          false
        )
      }
    >
      <Row type="flex" justify="center">
        <CardText
          text={`${startDate} - ${endDate} (${geoDatesDiff} days) 
          ${
            showTotalVisits
              ? `- ${formatWholeNumberNaN(totalVisits)} Visits`
              : ""
          }`}
        />
      </Row>
      <Chart
        padding={["auto", "auto", "auto", "auto"]}
        height={315}
        scale={cols}
        data={hourOfDay}
        forceFit
      >
        <Axis name="Hour of the Day" title />
        <Axis
          name="Visits"
          title
          label={{
            formatter(visits) {
              return formatWholeNumber(visits);
            },
          }}
        />
        <Tooltip
          crosshairs={{
            type: "y",
          }}
        />

        <Geom
          type="lineStack"
          position="Hour of the Day*Visits"
          color="#b2d5ff"
          size={0}
        />
        <Geom
          type="areaStack"
          position="Hour of the Day*Visits"
          color="#b2d5ff"
          size={0}
        />
      </Chart>
    </Card>
  );
};

export default VisitByDayOfWeekCardV2;
