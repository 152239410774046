//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Timeline, Row, Col } from "antd";

const { Item } = Timeline;

const ClicksSubTable = ({ clicks, attributionMethod }) => {
  const [clicksData, setClicksData] = useState(clicks);

  useEffect(() => {
    const sortedClicks = clicks.sort((a, b) => {
      if (moment(a.viewTime).isBefore(moment(b.viewTime))) return -1;
      return 1;
    });

    setClicksData(sortedClicks);
  }, [clicks]);

  const makeTimeLine = clicksData.map((click, i) => {
    const { viewTime, pageURL } = click;

    //For debugging purposes only. Don't delete
    // let value;
    // switch (attributionMethod) {
    //   case "COOKIES":
    //     value = click.domainUserId;
    //     break;
    //   case "FINGERPRINT":
    //     value = click.fingerprint;
    //     break;
    //   case "IP":
    //     value = click.ipAddress;
    //     break;
    //   case "DEVICE_FINGERPRINT":
    //     value = click.useragent;
    //     break;
    // }
    return (
      <Item key={i}>
        <Row>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Page Viewed at - ${moment(viewTime).format(
                "YYYY-MM-DD - HH:mm:ss"
              )}`}
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Page - ${pageURL}`}
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <a href={`${pageURL}`} target="_blank" rel="noopener noreferrer">
                Visit Link
              </a>
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Timeline mode={"left"} reverse={true}>
        {makeTimeLine}
      </Timeline>
    </div>
  );
};

export default ClicksSubTable;
