import React from "react";

const SignalTagsMainController = props => {
  const publishersTags = [];
  const measurementsTags = [];
  const impressionsTags = [];

  const { data, createImpressionTag, currentOrgId, refetch } = props;

  data.editorials.forEach(publisher => {
    const { clicksTag, transactionsTag } = publisher;
    const publisherExistings = publishersTags.find(p => {
      return p.id === clicksTag.id;
    });
    const measurementExistings = measurementsTags.findIndex(p => {
      return p.id === transactionsTag.id;
    });

    if (!publisherExistings) {
      publishersTags.push({
        id: clicksTag.id,
        name: clicksTag.name,
        advertiser: clicksTag.orgs[0].name,
        domain: clicksTag.orgs[0].website,
        pageViews: clicksTag.pageViews,
        tagId: clicksTag.eventTags[0].appId[0],
      });
    }

    if (measurementExistings === -1) {
      measurementsTags.push({
        id: transactionsTag.id,
        name: transactionsTag.name,
        advertiser: transactionsTag.orgs[0].name,
        funnels: 1,
        events: [
          {
            event: "Transactions",
            value: transactionsTag.transactions,
            date: transactionsTag.tagUpdateDate,
          },
        ],
        tagId: transactionsTag.eventTags[0].appId[0],
      });
    } else {
      measurementsTags[measurementExistings].funnels += 1;
    }
  });

  data.impressionTags.forEach(impressionsTag => {
    const impressionExistings = impressionsTags.findIndex(p => {
      return p.id === impressionsTag.id;
    });

    if (impressionExistings === -1) {
      impressionsTags.push({
        id: impressionsTag.id,
        name: impressionsTag.name,
        dsp: impressionsTag.dsp,
        tagId: impressionsTag.eventTags[0].appId[0],
      });
    }
  });

  data.measurementsTags.forEach(measurementsTag => {
    const measurementExistings = measurementsTags.findIndex(p => {
      return p.id === measurementsTag.id;
    });

    if (measurementExistings === -1) {
      measurementsTags.push({
        id: measurementsTag.id,
        name: measurementsTag.name,
        advertiser: measurementsTag.orgs[0].name,
        funnels: 0,
        events: [
          {
            event: "Transactions",
            value: measurementsTag.transactions,
          },
        ],
        tagId: measurementsTag.eventTags[0].appId[0],
      });
    }
  });

  data.advertisers.forEach(advertiser => {
    const { transactionsTag } = advertiser;
    const measurementExistings = measurementsTags.findIndex(p => {
      return p.id === transactionsTag.id;
    });

    if (measurementExistings === -1) {
      measurementsTags.push({
        id: transactionsTag.id,
        name: transactionsTag.name,
        advertiser: advertiser.orgs[0].name,
        funnels: 1,
        events: [
          {
            event: "Transactions",
            value: transactionsTag.transactions,
          },
        ],
        tagId: transactionsTag.eventTags[0].appId[0],
      });
    } else {
      measurementsTags[measurementExistings].funnels += 1;
    }
  });

  data.publisherDomainTags.forEach(publisherDomainTag => {
    const publisherExisiting = publishersTags.findIndex(p => {
      return p.id === publisherDomainTag.id;
    });

    if (publisherExisiting === -1) {
      publishersTags.push({
        id: publisherDomainTag.id,
        advertiser: publisherDomainTag.orgs[0].name,
        name: publisherDomainTag.name,
        domain: publisherDomainTag.orgs[0].website,
        pageViews: publisherDomainTag.pageViews,
        tagId: publisherDomainTag.eventTags[0].appId[0],
      });
    }
  });

  const onImressionsTagCreate = async input => {
    const { name, dsp } = input;

    await createImpressionTag({
      variables: {
        name,
        dsp,
        orgId: currentOrgId,
      },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    publishersTags,
    measurementsTags,
    impressionsTags,
    onImressionsTagCreate,
    refetch,
  });
};

export default SignalTagsMainController;
