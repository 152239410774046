import React from "react";

// Ant Design
import { Button, Grid, Modal } from "antd";
import SelectedMetricList from "../SelectedMetricList";
import AudienceDetails from "../AudienceDetails";

import { isMobileScreen } from "../../utils/mobileUtils";

const AudienceDetailsModal = props => {
  const { audienceDetails, metrics, visible, handleCancel } = props;

  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();

  return (
    <Modal
      visible={visible}
      title="Audience Detail"
      style={{ width: isMobileScreen(breakPoint) ? "80%" : undefined }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <SelectedMetricList list={metrics}></SelectedMetricList>
      <AudienceDetails audienceDetails={audienceDetails}></AudienceDetails>
    </Modal>
  );
};

export default AudienceDetailsModal;
