export const getExistingMetricValue = (dataMapping, selectedMetrics) => {
  return selectedMetrics.find(data => data.key === dataMapping.statara);
};

export const extractPrimaryMetric = metrics => {
  // Pop the first element of the array since the backend only sends one metric
  const flattenedArray = metrics.mappings.flatMap(obj => Object.values(obj));
  return {
    name: metrics.name,
    mappings: metrics.mappings[0],
    key: flattenedArray[0],
  };
};

export const findCategoryAndSubCategory = (
  dimensions,
  categories,
  metricKey
) => {
  const matchedDimension = dimensions.find(item => {
    return (
      Array.isArray(item.mappings) &&
      item.mappings.some(mapping => mapping.providerSubCategory === metricKey)
    );
  });
  return {
    matchedDimension: matchedDimension,
    category: categories.find(
      category => category.name === matchedDimension.category
    ),
  };
};

export const findSelectedSubCategory = (categories, metricKey) => {
  const selectedSubCategory = categories
    .flatMap(item => item.datapoints)
    .find(
      datapoint =>
        Array.isArray(datapoint.mappings) &&
        datapoint.mappings.some(
          mapping => mapping.providerSubCategory === metricKey
        )
    );

  return selectedSubCategory;
};
