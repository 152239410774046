import gql from "graphql-tag";

const LIST_ORGS = gql`
  query listOrgs($parentid: ID!, $skip: Int, $first: Int) {
    orgs(
      first: $first
      skip: $skip
      where: { OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }] }
      orderBy: name_ASC
    ) {
      id
      name
      domain
      config {
        providersVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        priceVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
      }
      dataConfig {
        reTargeting
        appIds
        roasFormat
        currency {
          currency
          conversionValue
        }
        tradeDeskIdentifier
        storageBucket
        googleCustomerId
        attributionWindow
        menuId
        dspAdvertiserId
        defaultDisplayAttributionWindow
        defaultPaidSearchAttributionWindow
        defaultSEOAttributionWindow
        seo
        semrushEnable
        semrushConfig {
          projectId
          rootDomainUrl
          rootKeyword
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        googleAnalytics {
          viewId
          isGA4
        }
        bing {
          accountId
          customerId
        }
        tagProcessingConfig {
          processTag
        }
        paidSearchDataConfig {
          isEnabled
        }
      }
      paymentConfig {
        id
        providerId
        paymentProvider
      }
      brands(first: 2) {
        name
        logo {
          key
        }
      }
      name
      description
      locations(first: 5) {
        street
        city
        state
      }
      logo {
        id
        key
        url
      }
      signInLogo {
        id
        key
        url
      }
      roles(first: 50, where: { isMaster: true }) {
        id
        name
        roleItems(first: 15) {
          id
          feature
          actions
        }
        users(first: 30) {
          id
        }
        isMaster
      }
      level
      chatWootId
      cpm
      website
      platformDomain
      tagDomain
      parentOrg {
        name
        id
      }
      isAllTimeFiltered
      zone
      #Used by Paid Search Module to check if the org has paid search processed
      paidSearchSummary {
        id
      }
      dealIds {
        org {
          id
          name
        }
      }
    }
  }
`;

export default LIST_ORGS;
