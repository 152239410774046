import React from "react";
import { Dropdown, Menu } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";

import { saveAs } from "file-saver";
import Papa from "papaparse";
import JSZip from "jszip";
import moment from "moment";

const ExportCSVButton = ({ orgName, generateReport }) => {
  let reports = [
    {
      name: `Pacing Report`,
      reportName: `${orgName.replace(/[\W_]/g, "_")}-Pacing Report ${moment()
        .utc()
        .format("MMMM D")}`,
      key: `pacing`,
    },
  ];

  const menu = (
    <Menu>
      {reports.map(report => {
        return (
          <Menu.Item
            key={report.key}
            onClick={() => {
              try {
                const name = report.reportName;
                const zip = new JSZip();
                const folder = zip.folder(name);
                const fileData = generateReport();
                folder.file(`${name}.csv`, Papa.unparse(fileData));
                zip.generateAsync({ type: "blob" }).then(
                  blob => {
                    saveAs(blob, name);
                    return true;
                  },
                  function(e) {
                    console.log("error", e);
                    return false;
                  }
                );
              } catch (err) {
                console.log(`CSV error: ${err}`);
              }
            }}
          >
            {report.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
      <Button
        style={{ width: "100%" }}
        icon={<DownloadOutlined />}
        type="primary"
      >
        <ButtonText text={"Export CSV"} />
      </Button>
    </Dropdown>
  );
};

export default ExportCSVButton;
