import React, { useState, useEffect } from "react";

import moment from "moment";
import { formatCurrency2SigFig } from "../../../core/utils/campaigns";

const Controller = ({
  data,
  currentuser,
  listInvoice,
  eventTags,
  totalDomains,
  invoiceData,
  invoiceList,
  ...props
}) => {
  const [filteredInfo, setFilteredInfo] = useState("UNPAID");
  const [invoiceFilterList, setInvoiceFilterList] = useState([]);
  const [invoiceListData, setInvoiceListData] = useState([]);
  const [overDueAmount, setOverDueAmount] = useState(0.0);

  useEffect(() => {
    if (invoiceList) {
      let paid = 0.0,
        unpaid = 0.0,
        overdueAmount = 0.0;

      let dataRaw = invoiceList.getInvoiceList.map(invoice => {
        const {
          invoice_number,
          title,
          public_url,
          status,
          created_at,
          payment_requests,
        } = invoice;

        const {
          due_date,
          computed_amount_money,
          total_completed_amount_money,
        } = payment_requests[0];

        let amount = computed_amount_money.amount / 100;
        let partialPay = total_completed_amount_money.amount / 100;
        let balance = amount - partialPay;
        let duration = moment.duration(
          moment(new Date()).diff(new Date(due_date))
        );
        let overdue = Math.ceil(duration.asDays());

        switch (status) {
          case "PAID":
            paid = paid + parseFloat(amount);
            break;
          case "UNPAID":
            unpaid = unpaid + parseFloat(amount);
            if (overdue > 0) {
              overdueAmount = overdueAmount + parseFloat(amount);
            }
            break;

          default:
            break;
        }

        return {
          key: invoice_number,
          issuedDate: moment(created_at).format("MM/DD/Y"),
          dueDate: moment(due_date).format("MM/DD/Y"),
          invoice_number,
          title,
          amount: formatCurrency2SigFig(amount),
          balance: balance !== 0 ? formatCurrency2SigFig(balance) : "-",
          overdue:
            status === "PAID" ||
            status === "REFUNDED" ||
            status === "CANCELED" ||
            overdue <= 0 ||
            overdue <= 0
              ? "-"
              : overdue + " day",
          status,
          public_url,
        };
      });

      let invoiceFilterListData = dataRaw.filter(invoice => {
        return invoice.status.includes(filteredInfo);
      });
      setOverDueAmount(overdueAmount);
      setInvoiceFilterList(invoiceFilterListData);
      setInvoiceListData(dataRaw);
    }
  }, [filteredInfo, invoiceList]);

  useEffect(() => {
    if (invoiceListData.length) {
      if (filteredInfo === "All") {
        setInvoiceFilterList(invoiceListData);
      } else {
        let filterInvoice = invoiceListData.filter(
          invoice => invoice.status === filteredInfo
        );
        setInvoiceFilterList(filterInvoice);
      }
    }
  }, [filteredInfo, invoiceListData]);

  let publisherTags = [];
  if (eventTags) {
    let orgId = currentuser.role.org.id;
    eventTags.eventsTargets.forEach(eventsTargets => {
      if (eventsTargets.orgs[0].id === orgId) {
        publisherTags.push(eventsTargets);
      }
    });
  }

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        totalDomains,
        publisherTags,
        invoiceFilterList,
        invoiceList: invoiceListData,
        filteredInfo,
        setFilteredInfo,
        totalOverdue: formatCurrency2SigFig(overDueAmount),
      })}
    </React.Fragment>
  );
};

export default Controller;
