/* eslint-disable prettier/prettier */
import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import PropTypes from "prop-types";
import { TagsOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select } from "antd";

const { Option } = Select;

const TagsPublisherDomainManagerView = ({
  onSubmit,
  advertisers,
  headerSubmit,
  currentOrgId,
}) => {
  const [form] = Form.useForm();
  return (
    <React.Fragment>
      {headerSubmit ? (
        <PageHeaderLayout
          title={"Create New Tag"}
          titleIcon={<TagsOutlined />}
          rightContent={
            <Button
              shape="round"
              type="primary"
              onClick={() => {
                form.validateFields().then(async values => {
                  message.success("Tag Created");
                  await onSubmit(values);
                });
              }}
            >
              Create Tag
            </Button>
          }
        />
      ) : null}

      <PageContentLayout>
        <Form
          form={form}
          layout="vertical"
          name={"createPublisherTag"}
          initialValues={{
            advertiser: currentOrgId,
            domainProtocol: "https://",
            domainExtension: ".com",
          }}
        >
          <Form.Item name="domainProtocol" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="domainExtension" noStyle hidden>
            <Input type="hidden" />
          </Form.Item>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                label="Publisher Tag Name"
                name="name"
                rules={[{ required: true, message: "Name Required" }]}
              >
                <Input placeholder="Enter tag name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"advertiser"}
                rules={[{ required: true, message: "Advertiser Required" }]}
                label={"Advertiser"}
              >
                <Select
                  placeholder="Select Advertiser"
                  options={advertisers}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Domain"
                name="domainUrl"
                rules={[
                  {
                    required: true,
                    message: "Domain Required",
                  },
                ]}
              >
                <Input
                  addonBefore={
                    <Select
                    defaultValue={"https://"}
                      onChange={val => {
                        form.setFieldsValue({ domainProtocol: val });
                      }}
                    >
                      <Option value="http://">http://</Option>
                      <Option value="https://">https://</Option>
                    </Select>
                  }
                  addonAfter={
                    <Select defaultValue=".com" onChange={val => {
                        form.setFieldsValue({ domainExtension: val });
                      }}>
                      <Option value=".com">.com</Option>
                      <Option value=".net">.net</Option>
                      <Option value=".edu">.edu</Option>
                      <Option value=".org">.org</Option>
                      <Option value=".gov">.gov</Option>
                      <Option value=".mil">.mil</Option>
                    </Select>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {!headerSubmit ? (
          <Col span={24}>
            <Row align="end">
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  form.validateFields().then(async values => {
                    message.loading("Creating Tag");
                    await onSubmit(values);
                    message.destroy();
                    message.success("Tag Created");
                    form.resetFields();
                  });
                }}
              >
                Create Tag
              </Button>
            </Row>
          </Col>
        ) : null}
      </PageContentLayout>
    </React.Fragment>
  );
};

TagsPublisherDomainManagerView.propTypes = {
  onSubmit: PropTypes.func,
  advertisers: PropTypes.array,
  currentOrgId: PropTypes.string,
  headerSubmit: PropTypes.bool,
};

export default TagsPublisherDomainManagerView;
