import React from "react";
import EmailTab from "../../OrgConfig/components/EmailTab";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";

const TagsMainView = ({
  activateHashedEmailList,
  createHashedEmailList,
  updateHashedEmailList,
  bulkCreateHashedEmailAddress,
  hashedEmailLists,
  deleteHashedEmailList,
  refetchHashedEmailLists,
  loadingHashedEmailList,
  processDeviceIds,
}) => {
  //Title shown on browser tab
  document.title = "First Party Data Main View";

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<UsergroupAddOutlined />}
        title={"Audience"}
      />
      <PageContentLayout>
        <Card>
          <EmailTab
            activateHashedEmailList={activateHashedEmailList}
            createHashedEmailList={createHashedEmailList}
            updateHashedEmailList={updateHashedEmailList}
            bulkCreateHashedEmailAddress={bulkCreateHashedEmailAddress}
            hashedEmailLists={
              !isEmpty(hashedEmailLists) ? hashedEmailLists : []
            }
            deleteHashedEmailList={deleteHashedEmailList}
            refetchHashedEmailLists={refetchHashedEmailLists}
            loadingHashedEmailList={loadingHashedEmailList}
            processDeviceIds={processDeviceIds}
          />
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default TagsMainView;
