import { Chart, Coord, Axis, Geom, Tooltip, Legend, Guide } from "bizcharts";
import React from "react";
import {
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../../core/utils/campaigns";

const DonutChart = ({
  axisName = "value",
  position = "counts",
  showTooltip = false,
  chartData = [{ value: "Placeholder", counts: 10, color: "Blue" }],
  height = 300,
  totalCount,
  showLegend = false,
  isDecimal = false,
  showCenterText = false,
  centerTextTitle = "",
  centerTextValue = null,
}) => {
  const cols = {
    persent: {
      formatter: val => {
        val = (val / totalCount) * 100;
        return `${val.toFixed(2)}%`;
      },
    },
    counts: {
      formatter: val => {
        const percentage = totalCount
          ? `(${((val / totalCount) * 100).toFixed(2)}%)`
          : "";
        return `${val.toLocaleString()} ${percentage}`;
      },
    },
  };

  return (
    <Chart
      forceFit
      data={chartData}
      padding={showLegend ? [20, 20, 60, 20] : [20, 20, 20, 20]}
      height={height / 1.1}
      scale={cols}
    >
      <Coord type="theta" radius={0.75} innerRadius={0.65} />
      <Axis name={axisName} />
      <Tooltip showTitle={showTooltip} />
      <Geom
        color={[
          "value",
          value => {
            const index = chartData.findIndex(el => el.value === value);
            if (chartData[index]) return chartData[index].color;
            else return "black";
          },
        ]}
        position={position}
        type="intervalStack"
      />
      {showCenterText && centerTextValue !== null && (
        <Guide>
          <Guide.Html
            position={["50%", "50%"]}
            html={() => `
              <div style="text-align: center; width: 10em;">
                <div style="font-size: 14px; color: #00000; font-weight: 500;">
                  ${centerTextTitle}:<br/>
                  ${centerTextValue.toLocaleString()}
                </div>
              </div>
            `}
            alignX="middle"
            alignY="middle"
          />
        </Guide>
      )}
      {showLegend && (
        <Legend
          position="bottom"
          offsetY={-10}
          itemFormatter={val => {
            const item = chartData.find(el => el.value === val);
            return item
              ? `${val} (${
                  isDecimal
                    ? formatNumber2SigFig(item.counts)
                    : formatWholeNumber(item.counts)
                })`
              : val;
          }}
        />
      )}
    </Chart>
  );
};

export default DonutChart;
