import gql from "graphql-tag";

const ATTRIBUTION_AUDIENCES_ORG_CONFIG = gql`
  query attributionAudienceOrgConfig($TYPE: AudienceType, $ORGID: ID!) {
    audiencesConnection(
      where: { AND: [{ type: $TYPE }, { orgs_some: { id: $ORGID } }] }
      orderBy: createdAt_DESC
    ) {
      edges {
        node {
          id
          name
          type
          isAmplifyDefault
          geoTargets {
            id
            name
            location {
              id
              name
              formattedAddress
              street
              state
              city
              zip
              lat
              lng
              country
              county
            }
            polygons {
              geometry
            }
            circles {
              radius
              coordinate {
                lat
                lng
              }
              location {
                id
                name
                formattedAddress
                zip
                street
                city
                county
                state
                country
                lat
                lng
              }
            }
          }
          eventsTargets {
            id
          }
          createdAt
          updatedAt
          createdBy {
            id
            name
          }
          orgs {
            id
            name
          }
        }
      }
    }
  }
`;

export default ATTRIBUTION_AUDIENCES_ORG_CONFIG;
