import { isNaN, cloneDeep } from "lodash";

export const computeCTR = (clicks, impressions, isPercent = true) => {
  const raw = clicks / impressions;
  if (!isPercent) return !isNaN(raw) ? raw : 0;
  return !isNaN(raw) ? raw * 100 : 0;
};

export const computeCPC = (cost, clicks) => {
  const cpc = cost / clicks;
  return Number.isFinite(cpc) && !isNaN(cpc) ? cpc : 0;
};

export const computeCPM = (cost, impressions) => {
  return (cost / impressions) * 1000;
};

export const computeSpend = (impressions, cpm) => {
  return (impressions / 1000) * cpm;
};

export const computeROAS = (revenue, spend) => {
  const roas = (revenue / spend) * 100;
  return Number.isFinite(roas) && !isNaN(roas) ? roas : 0;
};

export const computeConversionRate = (clicks, transaction) => {
  const conversionRate = (transaction / clicks) * 100;
  return Number.isFinite(conversionRate) && !isNaN(conversionRate)
    ? conversionRate
    : 0;
};

export const calculatePercentageDifference = (currentNumber, lastNumber) => {
  const increase =
    parseFloat(((currentNumber - lastNumber) / lastNumber) * 100).toFixed(1) ||
    0;

  if (increase < 0) {
    return (
      -parseFloat(((lastNumber - currentNumber) / lastNumber) * 100).toFixed(
        1
      ) || 0
    );
  }
  console.log(increase);
  return increase;
};

export const filterNames = paidSearch => {
  //does not alter possibleFields - obfuscates names only
  paidSearch = [...paidSearch].map((c, i) => {
    c.org.name = `Example Paid Search ${i}`;
    let campaign = cloneDeep(c);
    return {
      ...campaign,
    };
  });

  return paidSearch;
};
