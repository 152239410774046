import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber2SigFig,
} from "../../../../core/utils/campaigns";
import { isEmpty } from "lodash";
import { defaultCurrencyFormat } from "../../../../core/utils/constants/constants";

export const summaryScale = (
  data,
  leftY,
  rightY,
  currencyFormat = defaultCurrencyFormat
) => {
  const scale = {
    left:
      leftY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 1000,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : leftY === "conversions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["conversions"] : 0,
            alias: "Conversions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "averageOrder"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["averageOrder"] : 0,
            alias: "Average Order",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : leftY === "econConvRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["econConvRate"] : 0,
            alias: "Econ Conv. Rate",
            formatter: val => {
              return `${formatNumber2SigFig(val)}%`;
            },
          }
        : {},
    right:
      rightY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 1000,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : rightY === "conversions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["conversions"] : 0,
            alias: "Conversions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "averageOrder"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["averageOrder"] : 0,
            alias: "Average Order",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : rightY === "econConvRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["econConvRate"] : 0,
            alias: "Econ Conv. Rate",
            formatter: val => {
              return `${formatNumber2SigFig(val)}%`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};
