import React from "react";
import { withApollo, graphql, useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";

// Queries
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GEOTARGETS_CONNECTION from "../../../GraphQl/Queries/GEOTARGETS_CONNECTION";
import EVENTS_TARGETS_CONNECTION from "../../../GraphQl/Queries/EVENTS_TARGETS_CONNECTION";

// Mutations
import CREATE_GEOTARGET from "../../../GraphQl/Mutations/CREATE_GEOTARGET";
import DELETE_GEOTARGET from "../../../GraphQl/Mutations/DELETE_GEOTARGET";
import UPDATE_SEGMENT from "../../../GraphQl/Mutations/UPDATE_SEGMENT";
import CREATE_AUDIENCE from "../../../GraphQl/Mutations/CREATE_AUDIENCE";
import START_GEOTARGET_OBSERVATIONS from "../../../GraphQl/Mutations/START_GEOTARGET_OBSERVATIONS";
import CREATE_EVENTS_TARGET from "../../../GraphQl/Mutations/CREATE_EVENTS_TARGET";
import UPDATE_EVENTS_TARGET from "../../../GraphQl/Mutations/UPDATE_EVENTS_TARGET";
import DELETE_EVENTS_TARGET from "../../../GraphQl/Mutations/DELETE_EVENTS_TARGET";

//local
import Controller from "./Controller";
import { featurePermissions } from "../../../utils/general/user";

let combineSyncSegments = [];

const getGeoTargetsToRender = data => {
  const { edges } = data;
  const geoTargets = edges.map(item => {
    return item.node;
  });

  return geoTargets;
};

const getEventsTargetsToRender = data => {
  const { edges } = data;
  const eventsTargets =
    edges &&
    edges.map(item => {
      return item.node;
    });

  return eventsTargets;
};

const onChange = (key, value) => {
  this.setState({ [key]: value });
};

const getEventQueryVariables = (
  tableConfig,
  location,
  match,
  customPageSize,
  currentUser
) => {
  const { pageSize } = tableConfig;
  const { pageNumber, orderByFilter, whereFilter } = match.params;
  const pageCustom = customPageSize ? customPageSize : pageSize;

  const isNewPage = location.pathname.includes("new");
  const page = parseInt(pageNumber, 10);
  const skip = isNewPage ? (page - 1) * pageCustom : 0;
  const first = isNewPage ? pageCustom : 100;
  const orderBy = orderByFilter;

  const where = JSON.parse(decodeURIComponent(whereFilter));
  return { first, skip, orderBy, where };
};

const getQueryVariables = (
  tableConfig,
  location,
  match,
  customPageSize,
  currentUser
) => {
  const { pageSize } = tableConfig;
  const { pageNumber, orderByFilter, whereFilter } = match.params;
  const pageCustom = customPageSize ? customPageSize : pageSize;

  const isNewPage = location.pathname.includes("new");
  const page = parseInt(pageNumber, 10);
  const skip = isNewPage ? (page - 1) * pageCustom : 0;
  const first = isNewPage ? pageCustom : 100;
  const orderBy = orderByFilter;

  const search = JSON.parse(decodeURIComponent(whereFilter));
  const where = {
    AND: [
      {
        orgs_some: {
          OR: [
            { name: currentUser ? currentUser.role.org.name : "" },
            {
              parentOrg_some: {
                name: currentUser ? currentUser.role.org.name : "",
              },
            },
          ],
        },
        isBase: true,
      },
      search,
    ].filter(item => {
      return Object && item && Object.keys(item).length > 0;
    }),
  };
  return { first, skip, orderBy, where };
};

const Loader = props => {
  const { tableConfig, client, geotargetSync, eventsTargetSync } = props;
  const { pageNumber, orderByFilter, whereFilter } = props.match.params;

  let getGeotargets = [];
  let getEventsTarget = [];
  let getGeotargetsCount = 0;
  let getEventsTargetCount = 0;
  let pageSizeNumber = 16;

  const { READ, WRITE } = featurePermissions(
    props.currentUser.role,
    "SEGMENTS"
  );

  const { loading: loadingGeoTargets, error, data } = useQuery(
    GEOTARGETS_CONNECTION,
    {
      variables: getQueryVariables(
        props.tableConfig,
        props.location,
        props.match
      ),
    }
  );

  const {
    loading: loadingEventsTargets,
    error: errorEventsTargets,
    data: eventsTargetsData,
  } = useQuery(EVENTS_TARGETS_CONNECTION, {
    variables: getEventQueryVariables(
      props.tableConfig,
      props.location,
      props.match
    ),
  });

  if (
    geotargetSync &&
    geotargetSync.geoTargetsConnection &&
    geotargetSync.geoTargetsConnection.edges
  ) {
    getGeotargets = getGeoTargetsToRender(geotargetSync.geoTargetsConnection);
    getGeotargetsCount = geotargetSync.full.aggregate.count;
  }

  if (
    eventsTargetSync &&
    eventsTargetSync.eventsTargetsConnection &&
    eventsTargetSync.eventsTargetsConnection.edges
  ) {
    getEventsTarget = getEventsTargetsToRender(
      eventsTargetSync.eventsTargetsConnection
    );
    getEventsTargetCount = eventsTargetSync.full.aggregate.count;
  }

  const query = new URLSearchParams(props.location.search);
  combineSyncSegments =
    query.get("type") === "RETARGETING"
      ? [...getEventsTarget]
      : [...getGeotargets];
  combineSyncSegments.length === 16
    ? (pageSizeNumber = 16)
    : (pageSizeNumber = combineSyncSegments.length);

  if (error)
    return `Error (Query: EVENTS_TARGETS_CONNECTION)! ${error.message} `;
  if (errorEventsTargets)
    return `Error (Query: EVENTS_TARGETS_CONNECTION)! ${errorEventsTargets.message} `;

  let count = 0;
  let geoTargets = [];
  let countEventsTargets = 0;
  let countCombineSegments =
    query.get("type") === "RETARGETING"
      ? getEventsTargetCount
      : getGeotargetsCount;
  let eventsTargets = [];
  const onPage = parseInt(pageNumber, 10);

  if (!loadingGeoTargets) {
    const { geoTargetsConnection, full } = data;
    count = full.aggregate.count;
    geoTargets = getGeoTargetsToRender(geoTargetsConnection);
  }

  if (!loadingEventsTargets) {
    const { eventsTargetsConnection, full } = eventsTargetsData;
    countEventsTargets = full.aggregate.count;
    eventsTargets = getEventsTargetsToRender(eventsTargetsConnection);
  }

  return (
    <Controller
      {...props}
      client={client}
      loadingGeoTargets={loadingGeoTargets}
      loadingEventsTarget={loadingEventsTargets}
      geoTargets={geoTargets}
      eventsTargets={eventsTargets}
      combineSyncSegments={combineSyncSegments}
      pageSizeNumber={pageSizeNumber}
      tableConfig={tableConfig}
      getQueryVariables={getQueryVariables}
      onPage={onPage}
      count={count}
      countEventsTargets={countEventsTargets}
      countCombineSegments={countCombineSegments}
      onChange={onChange}
      orderByFilter={orderByFilter}
      whereFilter={whereFilter}
      READ={READ}
      WRITE={WRITE}
    />
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser } }) => {
      return { currentUser };
    },
  }),
  graphql(CREATE_AUDIENCE, {
    name: "createAudience",
  }),
  graphql(CREATE_GEOTARGET, {
    name: "createGeoTarget",
    options: props => ({
      refetchQueries: [
        {
          query: GEOTARGETS_CONNECTION,
          variables: getQueryVariables(
            props.tableConfig,
            props.location,
            props.match
          ),
        },
      ],
    }),
  }),
  graphql(DELETE_GEOTARGET, {
    name: "deleteGeoTarget",
    options: props => ({
      refetchQueries: [
        {
          query: GEOTARGETS_CONNECTION,
          variables: getQueryVariables(
            props.tableConfig,
            props.location,
            props.match
          ),
        },
      ],
    }),
  }),
  graphql(UPDATE_SEGMENT, {
    name: "updateSegment",
    options: props => ({
      refetchQueries: [
        {
          query: GEOTARGETS_CONNECTION,
          variables: getQueryVariables(
            props.tableConfig,
            props.location,
            props.match
          ),
        },
      ],
    }),
  }),
  graphql(START_GEOTARGET_OBSERVATIONS, {
    name: "startGeoTargetObservations",
  }),
  graphql(CREATE_EVENTS_TARGET, {
    name: "createEventsTarget",
    options: props => ({
      refetchQueries: [
        {
          query: EVENTS_TARGETS_CONNECTION,
          variables: getEventQueryVariables(
            props.tableConfig,
            props.location,
            props.match
          ),
        },
      ],
    }),
  }),
  graphql(UPDATE_EVENTS_TARGET, {
    name: "updateEventsTarget",
    options: props => ({
      refetchQueries: [
        {
          query: EVENTS_TARGETS_CONNECTION,
          variables: getEventQueryVariables(
            props.tableConfig,
            props.location,
            props.match
          ),
        },
      ],
    }),
  }),
  graphql(DELETE_EVENTS_TARGET, {
    name: "deleteEventsTarget",
    options: props => ({
      refetchQueries: [
        {
          query: EVENTS_TARGETS_CONNECTION,
          variables: getEventQueryVariables(
            props.tableConfig,
            props.location,
            props.match
          ),
        },
      ],
    }),
  }),
  graphql(GEOTARGETS_CONNECTION, {
    name: "geotargetSync",
    options: props => ({
      variables: getQueryVariables(
        props.tableConfig,
        props.location,
        props.match,
        8,
        props.currentUser
      ),
    }),
  }),
  graphql(EVENTS_TARGETS_CONNECTION, {
    name: "eventsTargetSync",
    options: props => ({
      variables: getEventQueryVariables(
        props.tableConfig,
        props.location,
        props.match,
        8,
        props.currentUser
      ),
    }),
  })
)(withApollo(withRouter(Loader)));
