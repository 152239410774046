/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Ant Design
import {
  Tag,
  Row,
  Col,
  Card,
  Spin,
  Input,
  Button,
  Divider,
  message,
  Typography,
  Grid,
  Popconfirm,
} from "antd";

import {
  TeamOutlined,
  FormOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Gauge } from "@ant-design/plots";

// Custom Components
import { PageHeaderLayout } from "../../../shared/layout";
import { ButtonText } from "../../../shared/globalStyling/styledText";

// Demographic Components
import CategoryList from "./CategoryList";
import SubCategoryList from "./SubCategoryList";
import SelectedMetricList from "./SelectedMetricList";
import SearchResultsView from "./SearchResultsView";
import MobileViewCategoryList from "./MobileViewCategoryList";

// Modals
import AudienceBuilderSaveModal from "./Modals/AudienceBuilderSaveModal";
import GenericMetric from "./Metrics/GenericMetric";

// Constants
import { graphConfiguration } from "../../../../core/utils/constants/demographicConstants";

// Utils
import { getGaugeColor, getColorTag } from "../utils/getColorRange";
import { filterAndMapCategories } from "../utils/searchUtils";
import { transformAudienceSchema } from "../utils/transformAudienceSchema";
import { isMobileScreen } from "../utils/mobileUtils";
import {
  getExistingMetricValue,
  extractPrimaryMetric,
  findSelectedSubCategory,
  findCategoryAndSubCategory,
} from "../utils/dataMapper";
import {
  cardBodyStyle,
  categoryList,
  searchResultList,
  subCategoryList,
  gaugeStyle,
  sideBarCardStyle,
  sideBarTitleStyle,
} from "../styling/inline-style";
import "../styling/styles.css";
import { useAudienceBuilderContext } from "../context/AudienceBuilderContext";

const { Text, Title } = Typography;

const AudienceBuilderPage = props => {
  const {
    history,
    audienceSchema,
    fetchAudienceCount,
    handleCreateAudience,
    refetchAudienceSchema,
    loadingAudienceSchema,
    handleFetchAudienceDemographs,
  } = props;

  const {
    setInputValue,
    setMatchCharacteristics,
    setMetricValue,
    setActiveTabKey,
    setUserSelections,
    setSelectedCheckboxes,
  } = useAudienceBuilderContext();

  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [graphStateConfig, setGraphStateConfig] = useState(graphConfiguration);
  const [reachCount, setReachCount] = useState(0);
  const [searchValues, setSearchValues] = useState(null);
  const [groupedCategories, setGroupedCategories] = useState([]);

  const onCategoryClick = category => {
    if (category === selectedCategory) {
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setSubCategories(null);
      return;
    }

    setSelectedCategory(category);
    setSubCategories(category.datapoints);

    if (isMobileScreen(breakPoint)) {
      setActiveTabKey("2");
    }
  };

  const onSubCategoryClick = subCategory => {
    setSelectedSubCategory(subCategory);
    setMetrics(extractPrimaryMetric(subCategory));
    setSelectedCheckboxes([]);

    // Map all the user selections from the mappings
    const userSelections =
      subCategory.mappings.length > 1
        ? subCategory.mappings.map(item => item.userSelections[0])
        : subCategory.mappings[0].userSelections;

    setUserSelections(userSelections);

    const metricValue = getExistingMetricValue(
      subCategory.mappings[0],
      selectedMetrics
    );

    if (metricValue) {
      setInputValue(metricValue.value);
      setMetricValue(metricValue);
      setMatchCharacteristics(metricValue.operator);
    } else {
      setInputValue(undefined);
      setMetricValue(null);
    }

    if (isMobileScreen(breakPoint)) {
      setActiveTabKey("3");
    }
  };

  const handleSelectedMetric = metric => {
    try {
      const { key, value, uniqueId } = metric;

      const dimensions = audienceSchema.internalMicroserviceAudienceSchema;

      const selectedSubCategory = findSelectedSubCategory(
        categories,
        metric.key
      );
      setSelectedSubCategory(selectedSubCategory);

      const { matchedDimension, category } = findCategoryAndSubCategory(
        dimensions,
        categories,
        key
      );
      setInputValue(value);
      setMetricValue(metric);
      setSubCategories(category.datapoints);
      setSelectedCategory(category);
      setMetrics({
        name: matchedDimension.subCategory,
        type: matchedDimension.type,
        key: key,
        uniqueId: uniqueId,
        mappings: matchedDimension.mappings[0],
      });

      if (isMobileScreen(breakPoint)) {
        setActiveTabKey("3");
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to select metric. Please try again.");
    }
  };

  const processDeviceCount = async metrics => {
    try {
      let response = { data: { getAudienceCount: { count: 0 } } };

      if (metrics.length > 0) {
        response = await fetchAudienceCount(metrics);
      }

      const audienceCountData = response.data.getAudienceCount;
      const totalDeviceCount = audienceCountData.count;
      const percentage = (totalDeviceCount * 100) / 100000;
      setReachCount(totalDeviceCount);
      setGraphStateConfig(prevState => ({
        ...prevState,
        percent: percentage / 100,
        range: {
          color: getGaugeColor(totalDeviceCount),
        },
        statistic: {
          ...prevState.statistic,
          title: {
            ...prevState.statistic.title,
            formatter: () =>
              totalDeviceCount ? totalDeviceCount.toLocaleString() : "0",
          },
        },
      }));
    } catch (error) {
      message.error("Failed to fetch audience count. Please try again.");
    }
  };

  const removeAudienceMetric = async metric => {
    try {
      const filteredMetrics = selectedMetrics.filter(
        item => item.uniqueId !== metric.uniqueId
      );

      setInputValue(undefined);
      setMetricValue(null);
      setSelectedMetrics(filteredMetrics);
      setLoading(true);
      await processDeviceCount(filteredMetrics);
      setLoading(false);
    } catch (error) {
      message.error("Failed to remove metric. Please try again.");
    }
  };

  const removeAllAudienceMetric = async () => {
    try {
      setSelectedMetrics([]);
      setLoading(true);
      await processDeviceCount([]);
      setLoading(false);
    } catch (error) {
      message.error("Failed to remove metric. Please try again.");
    }
  };

  const updateAudienceMetrics = async metrics => {
    let success = false;
    try {
      setLoading(true);

      const { matchCharacteristics, metricValue } = metrics;

      var newMetrics = [];

      const existing = selectedMetrics.find(
        item => item.key === metricValue.key
      );

      if (existing && existing.dataType !== "userSelections") {
        return message.error("You cannot add duplicate values for this metric");
      } else if (existing && existing.dataType === "userSelections") {
        const duplicate = selectedMetrics.find(
          item =>
            item.key === metricValue.key &&
            metricValue.value.some(metric => metric.value === item.value)
        );

        if (duplicate) {
          return message.error(
            "You cannot add duplicate values for this metric"
          );
        }
      }

      const existingMetricIndex = selectedMetrics.findIndex(
        metric =>
          metric.uniqueId !== undefined &&
          metrics.metricValue !== undefined &&
          metrics.metricValue.uniqueId !== undefined &&
          metric.uniqueId === metrics.metricValue.uniqueId
      );

      if (existingMetricIndex !== -1) {
        const metric = selectedMetrics[existingMetricIndex];
        const value =
          typeof metrics.metricValue.value === "object"
            ? metrics.metricValue.value
            : {
                label: metrics.metricValue.value,
                value: metrics.metricValue.value,
              };

        metric.displayValue = value.label;
        metric.value = value.value;
        metric.operator = matchCharacteristics;
        metric.match = matchCharacteristics === "=";
      } else {
        let values = [];

        if (Array.isArray(metricValue.value)) {
          values = metricValue.value.map(item => {
            return {
              operator: matchCharacteristics,
              value: item.value,
              label: metricValue.name,
              key: metricValue.key,
              dataType: metricValue.dataType,
              displayValue: item.label,
              uniqueId: `${metricValue.name}-${Date.now()}-${Math.random()
                .toString(36)
                .substring(2, 15)}`,
            };
          });
        } else {
          values = [
            {
              operator: matchCharacteristics,
              value: metricValue.value,
              label: metricValue.name,
              key: metricValue.key,
              dataType: metricValue.dataType,
              displayValue: metricValue.value,
              uniqueId: `${metricValue.name}-${Date.now()}-${Math.random()
                .toString(36)
                .substring(2, 15)}`,
            },
          ];
        }

        newMetrics.push(...values);
      }

      const combineMetrics = [...newMetrics, ...selectedMetrics];

      await processDeviceCount(combineMetrics);
      setSelectedMetrics(combineMetrics);
      success = true;
    } catch (err) {
      console.log(err);
      message.error("Failed to update metrics. Please try again.");
    } finally {
      setLoading(false);
      return success;
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const validateSubmission = data => {
    if (reachCount < 10000) {
      message.error("Minimum of 10,000 devices required to save audience");
      return false;
    }
    // Place future validation logic here
    return true;
  };

  // Submit to Save Audience
  const handleSubmit = async data => {
    try {
      if (validateSubmission(data) === true) {
        const audienceData = {
          ...data,
          metrics: selectedMetrics,
          reachCount: reachCount,
        };

        await handleCreateAudience(audienceData);
        await handleFetchAudienceDemographs();

        message.success("Successfully Created an Audience");
        history.push("/demograph");
      }
    } catch (error) {
      message.error(
        `Failed to create an Audience. Please try again later.: ${error}`
      );
    }
  };

  const resetList = () => {
    setCategories(Object.values(groupedCategories));
    setSubCategories([]);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  };

  useEffect(() => {
    refetchAudienceSchema();
  }, []);

  useEffect(() => {
    const {
      filteredCategories,
      filteredSubCategories,
    } = filterAndMapCategories(groupedCategories, searchValues);

    if (!filteredCategories.length) {
      resetList();
      return;
    }

    setCategories(filteredCategories);
    setSelectedCategory(filteredCategories[0]);
    setSubCategories(filteredSubCategories);
  }, [searchValues]);

  useEffect(() => {
    const { groupedCategories } = transformAudienceSchema(audienceSchema);

    setGroupedCategories(groupedCategories);
    setCategories(Object.values(groupedCategories));
  }, [audienceSchema]);

  return (
    <>
      <AudienceBuilderSaveModal
        visible={open}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        history={history}
      />

      {!isMobileScreen(breakPoint) && (
        <PageHeaderLayout
          title={"Demograph"}
          titleIcon={<TeamOutlined className="page-header-bar-icon" />}
          rightContent={
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  type="primary"
                  ghost
                  icon={<FormOutlined />}
                >
                  <ButtonText text={"Save this Audience"} />
                </Button>
              </Col>
            </Row>
          }
        />
      )}

      <Row>
        {isMobileScreen(breakPoint) && (
          <Col xs={24} sm={24}>
            <Card
              title={
                <div>
                  <Col xs={24} sm={24}>
                    <Title level={4} style={{ textAlign: "center" }}>
                      {reachCount ? reachCount.toLocaleString() : 0}
                    </Title>
                  </Col>
                  <Col xs={24} sm={24}>
                    <div className="horizontal-scroll-container">
                      {selectedMetrics.map((item, index) => {
                        const { operator, dataType, value, label } = item;

                        let displayValue = value;

                        if (dataType === "Boolean") {
                          displayValue = value === 1 ? "Yes" : "No";
                        }
                        return (
                          <div
                            id={index}
                            key={index}
                            style={{
                              display: "inline-block",
                              cursor: "pointer",
                              border: "1px solid black",
                              marginBottom: "0.7em",
                              marginRight: "0.5em",
                              borderRadius: "6px",
                              padding: "5px 22px",
                              borderLeft: `5px solid ${getColorTag(operator)}`,
                            }}
                          >
                            <span>{label}</span>
                            <p>{displayValue}</p>
                          </div>
                        );
                      })}
                    </div>
                    <Divider />
                  </Col>
                  <Col xs={24} sm={24}>
                    <span style={{ marginBottom: "0.5em" }}>
                      EXPLORE AUDIENCE CATEGORIES
                    </span>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Input
                      allowClear
                      disabled={loadingAudienceSchema}
                      placeholder="Search..."
                      value={searchValues}
                      onChange={e => setSearchValues(e.target.value)}
                      className="search-input"
                    />
                  </Col>
                  <Col xs={24} sm={24}>
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="primary"
                      style={{ width: "100%" }}
                      icon={<FormOutlined />}
                    >
                      <ButtonText text={"Save this Audience"} />
                    </Button>
                  </Col>
                </div>
              }
              bordered={false}
              bodyStyle={{
                padding: "1em",
                height: "100%",
                marginBottom: "1em",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
            >
              <MobileViewCategoryList
                categories={categories}
                subCategories={subCategories}
                selectedCategory={selectedCategory}
                onCategoryClick={onCategoryClick}
                onSubCategoryClick={onSubCategoryClick}
                titleFontStyle={categoryList.titleFontStyle}
                listItemStyle={categoryList.listItemStyle}
                selectedStyle={categoryList.selectedStyle}
                selectedFontStyle={categoryList.selectedFontStyle}
                updateAudienceMetrics={updateAudienceMetrics}
                fetchAudienceCount={fetchAudienceCount}
                removeAudienceMetric={removeAudienceMetric}
                loading={loading}
                metric={metrics}
              />
            </Card>
          </Col>
        )}
        {!isMobileScreen(breakPoint) && (
          <Col
            xs={24}
            xl={19}
            xxl={19}
            span={19}
            className="col-category-parent desktop-view"
          >
            <Card
              title={
                <div className="card-header-title-layout">
                  EXPLORE AUDIENCE CATEGORIES
                  <Input
                    allowClear
                    disabled={loadingAudienceSchema}
                    placeholder="Search..."
                    value={searchValues}
                    onChange={e => setSearchValues(e.target.value)}
                    className="search-input"
                  />
                </div>
              }
              bordered={false}
              bodyStyle={cardBodyStyle}
            >
              <Row className="card-body-row-style">
                {!searchValues && (
                  <>
                    <Col
                      xs={24}
                      sm={24}
                      md={7}
                      xl={7}
                      xxl={7}
                      span={7}
                      className="col-category-list"
                    >
                      <Spin spinning={loadingAudienceSchema}>
                        <CategoryList
                          list={categories}
                          selectedCategory={selectedCategory}
                          onCategoryClick={onCategoryClick}
                          titleFontStyle={categoryList.titleFontStyle}
                          listItemStyle={categoryList.listItemStyle}
                          selectedStyle={categoryList.selectedStyle}
                          selectedFontStyle={categoryList.selectedFontStyle}
                        ></CategoryList>
                      </Spin>
                    </Col>
                    {!selectedCategory && (
                      <>
                        <Col
                          xs={24}
                          xl={17}
                          xxl={17}
                          span={17}
                          className="col-empty-view"
                        >
                          <Text className="empty-view-text">
                            Select categories on the left to browser targeting
                            metrics
                          </Text>
                          <br />
                          <Text className="empty-view-text-description">
                            When you are satisfied, name your audience to save
                            it for use
                          </Text>
                        </Col>
                      </>
                    )}
                    {selectedCategory && (
                      <>
                        <Col
                          xs={24}
                          sm={24}
                          md={7}
                          lg={7}
                          xl={7}
                          xxl={7}
                          span={7}
                          className="col-sub-category-list"
                        >
                          <SubCategoryList
                            list={subCategories}
                            selectedCategory={selectedSubCategory}
                            loading={false}
                            listItemStyle={subCategoryList.listItemStyle}
                            titleFontStyle={subCategoryList.titleFontStyle}
                            selectedStyle={subCategoryList.selectedStyle}
                            selectedFontStyle={
                              subCategoryList.selectedFontStyle
                            }
                            onCategoryClick={onSubCategoryClick}
                            setSelectedSubCategory={setSelectedSubCategory}
                          ></SubCategoryList>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {searchValues && (
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                    span={14}
                    className="search-results-view-style"
                  >
                    <SearchResultsView
                      searchValues={searchValues}
                      subCategories={subCategories}
                      selectedCategory={selectedSubCategory}
                      selectedStyle={searchResultList.selectedStyle}
                      selectedFontStyle={searchResultList.selectedFontStyle}
                      titleFontStyle={searchResultList.titleFontStyle}
                      listItemStyle={searchResultList.listItemStyle}
                      onCategoryClick={onSubCategoryClick}
                      setSearchValues={setSearchValues}
                    />
                  </Col>
                )}
                {selectedCategory && selectedSubCategory && (
                  <Col sm={4} md={10} lg={10} xl={10} xxl={10} span={10}>
                    <GenericMetric
                      updateAudienceMetrics={updateAudienceMetrics}
                      fetchAudienceCount={fetchAudienceCount}
                      removeAudienceMetric={removeAudienceMetric}
                      loading={loading}
                      metric={metrics}
                    ></GenericMetric>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        )}

        {!isMobileScreen(breakPoint) && (
          <Col xs={24} xl={5} xxl={5} span={5}>
            <Card bordered={false} style={sideBarCardStyle}>
              <Gauge {...graphStateConfig} style={gaugeStyle} />
              {selectedMetrics.length > 0 && reachCount < 10000 && (
                <center>
                  <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Minimum of 10,0000
                  </Tag>
                </center>
              )}
              <Divider></Divider>
              <Typography style={sideBarTitleStyle}>ANALYTIC</Typography>
              <SelectedMetricList
                list={selectedMetrics}
                handleSelectedMetric={handleSelectedMetric}
                removeAudienceMetric={removeAudienceMetric}
                loading={loading}
              ></SelectedMetricList>
              {selectedMetrics.length > 0 && (
                <Popconfirm
                  title="Are you sure you want to clear all items?"
                  onConfirm={removeAllAudienceMetric}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" style={{ marginBottom: "16px" }}>
                    Clear All
                  </Button>
                </Popconfirm>
              )}
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AudienceBuilderPage;
