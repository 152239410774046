import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "antd";
import DetailBar from "../../components/DetailBar";
import DualAxisChart from "../../../../../shared/charts/DualAxisChart";
import DonutChart from "../../components/DonutChart";
import TrafficByChannelLineChart from "../../components/TrafficByChannelLineChart";
import SessionsChart from "../../components/TrafficSessionsChart";
import { KpiSubText } from "../../../../../shared/globalStyling/styledText";
import { useSEOTrafficContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOTrafficContext";
import { LoadingPage } from "../../../../../shared/LoadingPage";
import {
  formatCurrency2SigFig,
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../../../core/utils/campaigns";
import { summaryScale } from "../../../utils/summaryScale";
import moment from "moment";

const TrafficView = ({
  domain,
  selectedDate,
  setSelectedDate,
  setReportData,
}) => {
  const {
    reportLoading,
    topLandingPages,
    topCities,
    newVsReturning,
    trafficByChannelSummary,
    trafficByChannelByDate,
    mediums,
    sessions,
    sessionsByDate,
    bounceRate,
    revenueByTrafficSummary,
    revenueByTraffic,
    revenueByDateSummary,
    revenueByDate,
    startDateEnDate,
    onDateChange,
  } = useSEOTrafficContext();
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (
      selectedDate &&
      selectedDate.length > 0 &&
      startDateEnDate.length > 0 &&
      (!selectedDate[0].isSame(startDateEnDate[0]) ||
        !selectedDate[1].isSame(startDateEnDate[1])) &&
      !reportLoading
    ) {
      onDateChange(selectedDate);
    }
  }, [onDateChange, reportLoading, selectedDate, startDateEnDate]);

  useEffect(() => {
    if (
      !reportLoading &&
      revenueByDate &&
      revenueByDate.length > 0 &&
      initialLoad
    ) {
      setSelectedDate([
        moment(revenueByDate[0].date),
        moment(revenueByDate[revenueByDate.length - 1].date),
      ]);
      if (revenueByTraffic && revenueByTraffic.length > 0) {
        setReportData(
          revenueByTraffic.map(r => ({
            "Traffic Source": r.medium,
            Revenue: formatCurrency2SigFig(r.revenue),
            Conversions: formatWholeNumber(r.conversions),
            "Average Order": formatCurrency2SigFig(r.averageOrder),
            "Ecom. Conv. Rate": formatNumber2SigFig(r.econConvRate) + "%",
          }))
        );
      }
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    reportLoading,
    revenueByDate,
    revenueByTraffic,
    setReportData,
    setSelectedDate,
  ]);

  useEffect(() => {
    if (revenueByTraffic && revenueByTraffic.length > 0 && !initialLoad) {
      setReportData(
        revenueByTraffic.map(r => ({
          "Traffic Source": r.medium,
          Revenue: formatCurrency2SigFig(r.revenue),
          Conversions: formatWholeNumber(r.conversions),
          "Average Order": formatCurrency2SigFig(r.averageOrder),
          "Ecom. Conv. Rate": formatNumber2SigFig(r.econConvRate) + "%",
        }))
      );
    }
  }, [initialLoad, revenueByTraffic, setReportData]);

  const topLandingPagesColumns = [
    {
      title: "Page URL",
      dataIndex: "landingPage",
      key: "landingPage",
      width: "70%",
      sorter: (a, b) => a.landingPage.localeCompare(b.landingPage),
      render: text => (domain ? domain + text : text),
    },
    {
      title: "Page Views",
      dataIndex: "screenPageViews",
      key: "screenPageViews",
      sorter: (a, b) => a.screenPageViews - b.screenPageViews,
      render: text => {
        const formatNumber = num => {
          if (num >= 1000) {
            return (num / 1000).toFixed(1) + "K";
          }
          return num.toString();
        };

        return formatNumber(text);
      },
    },
  ];

  const topCitiesColumns = [
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "70%",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      sorter: (a, b) => a.sessions - b.sessions,
      render: text => {
        const formatNumber = num => {
          if (num >= 1000) {
            return (num / 1000).toFixed(1) + "K";
          }
          return num.toString();
        };

        return formatNumber(text);
      },
    },
  ];

  const revenueColumns = [
    {
      title: "Traffic Source",
      dataIndex: "medium",
      key: "medium",
      sorter: (a, b) => a.medium.localeCompare(b.medium),
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: text => `${formatCurrency2SigFig(text)}`,
      sorter: (a, b) => a.revenue - b.revenue,
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: text => `${formatWholeNumber(text)}`,
      sorter: (a, b) => a.conversions - b.conversions,
    },
    {
      title: "Average Order",
      dataIndex: "averageOrder",
      key: "averageOrder",
      render: text => `${formatCurrency2SigFig(text)}`,
      sorter: (a, b) => a.averageOrder - b.averageOrder,
    },
    {
      title: "Ecom. Conv. Rate",
      dataIndex: "econConvRate",
      key: "econConvRate",
      render: text => formatNumber2SigFig(text) + "%",
      sorter: (a, b) => a.econConvRate - b.econConvRate,
    },
  ];

  const dropDownOptions = [
    { key: "revenue", display: "Revenue" },
    { key: "conversions", display: "Conversions" },
    { key: "averageOrder", display: "Average Order" },
    { key: "econConvRate", display: "Econ Conv. Rate" },
  ];

  if (reportLoading) return <LoadingPage />;
  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DetailBar dataToDisplay={trafficByChannelSummary} />
        </Col>
        <Col xs={24}>
          <Card
            title={"TRAFFIC BY CHANNEL"}
            // TODO: Add toggle button
            // extra={<Button>Toggle Channels</Button>}
          >
            <TrafficByChannelLineChart
              data={trafficByChannelByDate}
              mediums={mediums}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card title={"REVENUE"}>
            <Row gutter={[12, 12]}>
              <Col xs={24}>
                <DetailBar dataToDisplay={revenueByDateSummary} />
              </Col>
              <Col xs={24}>
                <DualAxisChart
                  id={"SeoSummary"}
                  title={null}
                  byDate={revenueByDate}
                  xAxis="date"
                  options={dropDownOptions}
                  defaultLeftYValue={{ key: "revenue", display: "Revenue" }}
                  defaultRightYValue={{
                    key: "conversions",
                    display: "Conversions",
                  }}
                  showDatePicker={false}
                  customScale={summaryScale}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={8}>
              <Card title={"NEW VS RETURNING VISITORS"}>
                <DonutChart
                  chartData={[
                    {
                      value: "New",
                      counts: newVsReturning.new,
                    },
                    {
                      value: "Returning",
                      counts: newVsReturning.returning,
                    },
                  ]}
                  showLegend={true}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card title={"SESSIONS"}>
                <div style={{ marginBottom: "20px" }}>
                  <KpiSubText text={formatWholeNumber(sessions)} />
                </div>
                <SessionsChart data={sessionsByDate} />
              </Card>
            </Col>
            <Col span={8}>
              <Card title={"BOUNCE RATE"}>
                <DonutChart
                  chartData={[
                    {
                      value: "Bounce Rate",
                      counts: bounceRate,
                    },
                  ]}
                  showLegend={true}
                  isDecimal={true}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={12}>
              <Card title={"TOP LANDING PAGES"}>
                <Table
                  style={{ width: "100%" }}
                  columns={topLandingPagesColumns}
                  dataSource={topLandingPages}
                  size={"default"}
                  scroll={{ x: 300 }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card title={"TOP CITIES"}>
                <Table
                  style={{ width: "100%" }}
                  columns={topCitiesColumns}
                  dataSource={topCities}
                  size={"default"}
                  scroll={{ x: 300 }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card title={"REVENUE BY TRAFFIC SOURCE"}>
            <DetailBar dataToDisplay={revenueByTrafficSummary} />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={"REVENUE BY TRAFFIC SOURCE"}>
            <Table
              style={{ width: "100%" }}
              columns={revenueColumns}
              dataSource={revenueByTraffic}
              size={"default"}
              scroll={{ x: 300 }}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TrafficView;
