import gql from "graphql-tag";

const EVENTS_TARGET = gql`
  query eventsTarget($where: EventsTargetWhereUniqueInput!) {
    eventsTarget(where: $where) {
      id
      name
      eventTags {
        appId
      }
      cart
      tag
      pageViews
      signups
      transactions
      basketItems
      tagUpdateDate
      testLogs {
        id
        testDate
        pageViews
        signups
        transactions
        basketItems
      }
      orgs {
        id
        name
        tagDomain
        level
        parentOrg {
          id
          level
          tagDomain
        }
      }
      isDisabled
      isSignUps
      isCommerce
    }
  }
`;

export default EVENTS_TARGET;
