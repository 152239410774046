import {
  Row,
  Typography,
  Modal,
  Select,
  Form,
  Card,
  Input,
  Col,
  Table,
  Tag,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../shared/globalStyling/styledText";

const { Text, Title } = Typography;
const { CheckableTag } = Tag;
const LaunchEditorial = ({ tags, open, onCreate, onCancel, currentOrgId }) => {
  const formattedTags = tags.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
      website: tag.orgs[0].website,
      isOwned: tag.orgs[0].id === currentOrgId,
      transactions: tag.transactions,
      pageViews: tag.pageViews,
      basketItems: tag.basketItems,
      signups: tag.signups,
    };
  });

  const [form] = Form.useForm();
  const [clickName, setClicksName] = useState();
  const [transactionName, setTransactionName] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableTags, setTableTags] = useState(
    formattedTags.filter(tag => !tag.isOwned)
  );
  const initialRowSelectedTags = formattedTags.reduce((result, tag) => {
    result[tag.value] = [];
    if (tag.transactions > 0) {
      result[tag.value].push("Transactions");
    }
    if (tag.pageViews > 0) {
      result[tag.value].push("Page Views");
    }
    if (tag.signups > 0) {
      result[tag.value].push("Sign Ups");
    }
    if (tag.basketItems > 0) {
      result[tag.value].push("Basket Items");
    }
    return result;
  }, {});
  const [rowSelectedTags, setRowSelectedTags] = useState(
    initialRowSelectedTags
  );

  // Handle change of measurement tags
  const handleChange = (tag, checked, rowValue) => {
    setRowSelectedTags(prevRowSelectedTags => {
      const updatedRowSelectedTags = { ...prevRowSelectedTags };
      updatedRowSelectedTags[rowValue] = checked
        ? [...updatedRowSelectedTags[rowValue], tag]
        : updatedRowSelectedTags[rowValue].filter(t => t !== tag);
      return updatedRowSelectedTags;
    });
  };

  // Mapping of measurement tags to enum values
  const measurementMapping = {
    Transactions: "TRANSACTIONS",
    "Page Views": "PAGE_VIEWS",
    "Basket Items": "BASKET_ITEMS",
    "Sign Ups": "SIGN_UPS",
  };

  const tagsColumn = [
    {
      title: <TableColumnTitle text={"Measurement Tag Name"} />,
      dataIndex: "label",
      key: "label",
      align: "left",

      render: text => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <PrimaryTableRowText text={text} />
            </Col>
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Measured Events"} />,
      dataIndex: "measured",
      key: "measured",
      align: "left",

      render: (text, record) => {
        return (
          <Row type="flex" justify="start">
            {record.transactions > 0 ||
            record.pageViews > 0 ||
            record.signups > 0 ||
            record.basketItems > 0 ? (
              <>
                {record.transactions > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes(
                      "Transactions"
                    )}
                    onChange={checked =>
                      handleChange("Transactions", checked, record.value)
                    }
                  >
                    Transactions
                  </CheckableTag>
                )}
                {record.pageViews > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes(
                      "Page Views"
                    )}
                    onChange={checked =>
                      handleChange("Page Views", checked, record.value)
                    }
                  >
                    Page Views
                  </CheckableTag>
                )}
                {record.signups > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes("Sign Ups")}
                    onChange={checked =>
                      handleChange("Sign Ups", checked, record.value)
                    }
                  >
                    Sign Ups
                  </CheckableTag>
                )}
                {record.basketItems > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes(
                      "Basket Items"
                    )}
                    onChange={checked =>
                      handleChange("Basket Items", checked, record.value)
                    }
                  >
                    Basket Items
                  </CheckableTag>
                )}
              </>
            ) : (
              <Tag color="default">No Measured Events</Tag>
            )}
          </Row>
        );
      },
    },
  ];

  const onChangeClicks = (value, option) => {
    setClicksName(option.label);
    form.setFieldsValue({
      domain: option.website,
    });
  };

  // Format values to match enums
  const formatTrackMeasurements = rowValue => {
    const trackMeasurementsValue = rowSelectedTags[rowValue];
    const formattedValues = trackMeasurementsValue.map(
      measurement => measurementMapping[measurement]
    );
    return formattedValues;
  };

  // Real-time update of measurement values
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const rowValue = selectedRowKeys[0];
      const formatted = formatTrackMeasurements(rowValue);
      form.setFieldsValue({
        transactionsTag: rowValue,
        trackMesurements: formatted,
      });
    } else {
      form.setFieldsValue({
        transactionsTag: undefined,
        trackMesurements: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelectedTags, selectedRowKeys, form]);

  useEffect(() => {
    if (clickName || transactionName)
      form.setFieldsValue({
        name: `${clickName || "-"} to ${transactionName || "-"}`,
      });
  }, [clickName, form, transactionName]);

  return (
    <Modal
      title="Add Publisher Funnel"
      visible={open}
      okText="Launch"
      cancelText="Cancel"
      width={"75%"}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            setClicksName();
            setTransactionName();
            setSelectedRowKeys([]);
            onCreate(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={() => {
        form.resetFields();
        setClicksName();
        setTransactionName();
        setSelectedRowKeys([]);
        onCancel();
      }}
    >
      <Card title={"Funnel Details"}>
        <Form form={form} layout="vertical" name={"launchEditorialForm"}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                required={false}
                name={"name"}
                label={<Text strong>Funnel Name</Text>}
                rules={[{ required: true, message: `Funnel Name Required` }]}
              >
                <Input placeholder="Enter Funnel Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"startDate"}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Start Date </Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Empty start date will default to today
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                required={false}
                name={"clicksTag"}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Publisher Domain </Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Select Publisher Domain Tag for this funnel
                      </Text>
                    </Col>
                  </Row>
                }
                rules={[
                  { required: true, message: `Please select a tag` },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && getFieldValue("transactionsTag") === value) {
                        return Promise.reject(
                          new Error(
                            `The Clicks Tag cannot be the same as the Transactions Tag!`
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Clicks Tag"
                  showSearch={true}
                  filterOption={(input, option) => {
                    if (
                      option.label.toLowerCase().includes(input.toLowerCase())
                    )
                      return true;
                  }}
                  options={formattedTags.filter(tag => tag.isOwned)}
                  filterSort={(optionA, optionB) =>
                    optionA.label
                      .toLowerCase()
                      .localeCompare(optionB.label.toLowerCase())
                  }
                  onChange={onChangeClicks}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"url"}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Funnel URL (Optional)</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Enter a specific URL to measure. Leave blank to measure
                        the domain
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <Row>
                  <Col span={8}>
                    <Form.Item noStyle name={"domain"}>
                      <Input placeholder="Tag Domain" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item noStyle name={"extension"}>
                      <Input placeholder="Enter Optional URL" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={5}>Measurement Tags</Title>
            </Col>
            <Col span={24}>
              <Text type="secondary">
                Select a Measurement Tag for this funnel
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={13}>
              <Form.Item
                name={"transactionsTag"}
                rules={[
                  { required: true, message: "Measurement Tag Required" },
                ]}
              >
                <Input type="hidden" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name={"trackMesurements"}
                rules={[
                  { required: true, message: "Measured Events Required" },
                ]}
              >
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
          <Card
            title="Measurement Tags"
            extra={
              <Input.Search
                placeholder="Search Tags"
                allowClear
                onSearch={value => {
                  if (value && value !== "") {
                    const filteredTags = formattedTags.filter(
                      tag =>
                        tag.label.toLowerCase().includes(value.toLowerCase()) &&
                        !tag.isOwned
                    );

                    setTableTags(filteredTags);
                  } else {
                    setTableTags(formattedTags.filter(tag => !tag.isOwned));
                  }
                }}
              />
            }
          >
            <Table
              columns={tagsColumn}
              dataSource={tableTags}
              rowKey={record => record.value}
              rowSelection={{
                type: "radio",
                selectedRowKeys,
                onChange: (newSelectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(newSelectedRowKeys);
                  if (selectedRows.length > 0) {
                    setTransactionName(selectedRows[0].label);
                  } else {
                    setTransactionName(undefined);
                  }
                },
              }}
            />
          </Card>
        </Form>
      </Card>
    </Modal>
  );
};

export default LaunchEditorial;
