import React from "react";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash/flowRight";
import { useQuery } from "@apollo/react-hooks";
// Queries
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import LIST_INVOICE from "../../GraphQl/Mutations/LIST_INVOICE";
import TAG_ORGS from "../../GraphQl/Queries/TAG_ORGS";
import EDITORIAL_CONNECTION from "../../GraphQl/Queries/EDITORIAL_CONNECTION";
import GET_INVOICE_LIST from "../../GraphQl/Queries/GET_INVOICE_LIST";
import OverlaySpinner from "../../../platform/shared/OverlaySpinner";

// Locals
import Controller from "./Controller";

const Loader = props => {
  const { children, currentUser } = props;
  const currentOrgId = currentUser.role.org.id;

  const currentProviderId = currentUser.role.org.paymentConfig.length
    ? currentUser.role.org.paymentConfig[0].providerId
    : "null";

  const where = {
    AND: [
      {
        clicksTag: {
          id_not: null,
        },
      },
    ],
    OR: [
      {
        sharedOrgs_some: {
          org: {
            id: currentOrgId,
          },
        },
      },
      {
        sharedOrgs_some: {
          org: {
            parentOrg_some: { id: currentOrgId },
          },
        },
      },
    ],
  };

  const { data, loading } = useQuery(EDITORIAL_CONNECTION, {
    variables: {
      //first: pageSize,
      //skip: (Number(pageNumber) - 1) * pageSize,
      where: where,
      orderBy: "name_ASC",
    },
    fetchPolicy: "no-cache",
  });

  const { data: eventTags, loading: eventTagsLoading } = useQuery(TAG_ORGS, {
    variables: {
      orgId: currentOrgId,
    },
  });

  const { data: invoiceList, loading: invoiceLoading } = useQuery(
    GET_INVOICE_LIST,
    {
      variables: {
        customerId: currentProviderId.toString(),
      },
    }
  );

  // const { data: listInvoice, loading: invoiceLoading } = useQuery(
  //   EDITORIAL_CONNECTION,
  //   {
  //     variables: { customerId: currentProviderId.toString() },
  //     fetchPolicy: "no-cache",
  //   }
  // );
  //const [listInvoice, { data: invoiceData }] = useMutation(LIST_INVOICE);

  // const listInvoice = async providerId => {
  //   console.log("providerId", providerId);
  //   try {
  //     const response = await props.listInvoice({
  //       variables: { customerId: providerId.toString() },
  //     });
  //     if (response) {
  //       return response;
  //     }
  //   } catch (err) {
  //     return {
  //       status: "failure",
  //       error: err,
  //     };
  //   }
  // };

  if (eventTagsLoading || !data || loading || invoiceLoading)
    return <OverlaySpinner />;

  const totalDomains = data.full.aggregate.count;

  return (
    <Controller
      {...props}
      currentuser={props.currentUser}
      data={data}
      eventTags={eventTags}
      totalDomains={totalDomains}
      invoiceList={invoiceList}
    >
      {children}
    </Controller>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(LIST_INVOICE, { name: "listInvoice" })
)(withApollo(Loader));
