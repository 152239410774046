import React, { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Card,
  Button,
  Popconfirm,
  message,
  notification,
  Tag,
} from "antd";
import { GeoTargetObservationButton } from "../../../../shared/GeoTargetObservationButton";
import {
  DeleteOutlined,
  ReloadOutlined,
  RedoOutlined,
  LoadingOutlined,
  FileSyncOutlined,
  CheckCircleTwoTone,
  WarningOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const AdminModal = ({
  modalOpen,
  toggleModal,
  campaignOrder,
  startObservationJob,
  isLoadingRefreshObservation,
  setIsLoadingRefreshObservation,
  reprocessCurrentCampaign,
  campaignId,
  onDeleteCampaignOrder,
  scrapeDSPCampaigns,
  reprocessStatus,
  pauseOrStartLiquidMCampaign,
  loadingPauseOrStartLiquidMCampaign,
}) => {
  const [processDeleteClick, setProcessDeleteClick] = useState(false);

  const reprocessProps = () => {
    if (reprocessStatus === "QUEUED") {
      return {
        color: "warning",
        text: "Queued",
        icon: <ClockCircleOutlined />,
      };
    } else if (reprocessStatus === "PROCESSING") {
      return {
        color: "processing",
        text: "Processing",
        icon: <SyncOutlined spin />,
      };
    } else if (reprocessStatus === "FINISHED") {
      return {
        color: "success",
        text: "Processed",
        icon: <CheckCircleOutlined />,
      };
    } else if (reprocessStatus === "FAILED") {
      return {
        color: "error",
        text: "Failed",
        icon: <CloseCircleOutlined />,
      };
    }
  };

  // const selectedAdProviderId =
  //   campaignOrder &&
  //   campaignOrder.campaigns &&
  //   campaignOrder.campaigns.map(val => {
  //     let getAdProviderId = [];

  //     if (val.demandSidePlatform === "LIQUIDM") {
  //       getAdProviderId = val.adProviderId;
  //     }

  //     return getAdProviderId;
  //   });

  const confirmDeleteCampaignOrder = async () => {
    try {
      setProcessDeleteClick(true);
      message.success("Successfully deleted the campaign");
      await onDeleteCampaignOrder();
    } catch (err) {
      message.error(err);
      setProcessDeleteClick(false);
    }
  };

  const cancelDeleteCampaignOrder = () => {
    setProcessDeleteClick(false);
  };

  return (
    <Modal
      title={"Campaign Admin Tools"}
      visible={modalOpen === 1}
      okText="Export PDF"
      onCancel={() => toggleModal(0)}
      width={"900px"}
      footer={null}
    >
      <Row type="flex" style={{ justifyContent: "center" }}>
        <Col xs={24} sm={12} md={11}>
          <Card title="Refresh Observations">
            <Row type="flex" justify="center" align="middle">
              <ReloadOutlined
                style={{ fontSize: "80px", marginBottom: "20px" }}
              />
              <div style={{ marginBottom: "20px" }}>
                This will refresh observation data for both targeting locations
                and walk in locations
              </div>
              <GeoTargetObservationButton
                isLoadingRefreshObservation={isLoadingRefreshObservation}
                setIsLoadingRefreshObservation={setIsLoadingRefreshObservation}
                id={campaignOrder && campaignOrder.id}
                startObservationJob={startObservationJob}
              />
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={11} offset={2}>
          <Card title="Reprocess Campaign">
            <Row type="flex" justify="center" align="middle">
              {reprocessStatus && (
                <Tag
                  color={reprocessProps().color}
                  icon={reprocessProps().icon}
                  style={{ marginBottom: "25px" }}
                >
                  {reprocessProps().text}
                </Tag>
              )}
            </Row>

            <Row type="flex" justify="center" align="middle">
              <Col md="24" type="flex" align="middle" justify="center">
                <ReloadOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  This will reprocess the campaign
                </div>
              </Col>
              <Col md="24">
                <Button
                  type="primary"
                  ghost
                  icon={<RedoOutlined />}
                  onClick={() => {
                    notification.open({
                      message: "Reprocessing Campaign...",
                      description:
                        "Please wait for a while to Reprocess the Campaign. It may take some time.",
                      icon: <FileSyncOutlined />,
                      duration: 20,
                    });

                    reprocessCurrentCampaign(campaignId);
                  }}
                >
                  Reprocess Campaign
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={11}>
          <Card title="Campaign Rediscovery" style={{ marginTop: "25px" }}>
            <Row type="flex" justify="center" align="middle">
              <Col md="24" type="flex" align="middle" justify="center">
                <ReloadOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  This will rediscover campaigns on DSP
                </div>
              </Col>
              <Col md="24">
                <Button
                  type="primary"
                  ghost
                  icon={<RedoOutlined />}
                  onClick={() => {
                    scrapeDSPCampaigns();
                    notification.open({
                      message: "Campaign Rediscovery on Progress...",
                      description:
                        "Sucessfully Rediscover Campaigns in DSP. Please wait for a while for the rediscovery and try to reload the page in a few minutes",
                      icon: <FileSyncOutlined />,
                      duration: 20,
                    });
                  }}
                >
                  Campaign Rediscovery
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={11} offset={2}>
          <Card title="Delete Campaign" style={{ marginTop: "25px" }}>
            <Row type="flex" justify="center" align="middle">
              <Col md="24" type="flex" align="middle" justify="center">
                <DeleteOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  This action cannot be undone.
                </div>
              </Col>
              <Col md="24">
                <Popconfirm
                  title="Are you sure you want to delete the campaign?"
                  onConfirm={confirmDeleteCampaignOrder}
                  onCancel={cancelDeleteCampaignOrder}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    ghost
                    loading={processDeleteClick}
                    icon={
                      !processDeleteClick ? (
                        <DeleteOutlined />
                      ) : (
                        <LoadingOutlined />
                      )
                    }
                  >
                    Delete this Campaign
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={11}>
          <Card title="Start Liquid M Campaign" style={{ marginTop: "25px" }}>
            <Row type="flex" justify="center" align="middle">
              <Col md="24" type="flex" align="middle" justify="center">
                <PlayCircleOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  Start a Liquid M Campaign
                </div>
              </Col>
              <Col md="24">
                <Button
                  loading={
                    loadingPauseOrStartLiquidMCampaign &&
                    campaignOrder.status === "PAUSED"
                  }
                  type="primary"
                  style={{ marginTop: "10px", marginLeft: "15px" }}
                  disabled={
                    campaignOrder.status === "LIVE" ||
                    campaignOrder.status === "LIVE_PENDING"
                  }
                  onClick={async () => {
                    try {
                      if (campaignOrder.status === "PAUSED") {
                        await pauseOrStartLiquidMCampaign({
                          variables: {
                            id: campaignOrder.id,
                          },
                        });

                        notification.open({
                          message: "Successfully Started Campaign",
                          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
                        });
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }
                    } catch (err) {
                      console.log(err);
                      notification.open({
                        message: `Failed to Start Campaign: ${err}`,
                        icon: <WarningOutlined style={{ color: "yellow" }} />,
                      });
                    }
                  }}
                  icon={<PlayCircleOutlined />}
                >
                  Start Campaign Ads
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={11} offset={2}>
          <Card title="Pause Liquid M Campaign" style={{ marginTop: "25px" }}>
            <Row type="flex" justify="center" align="middle">
              <Col md="24" type="flex" align="middle" justify="center">
                <PauseCircleOutlined
                  style={{ fontSize: "80px", marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "20px" }}>
                  Pause Liquid M Campaign
                </div>
              </Col>
              <Col md="24">
                <Button
                  loading={
                    loadingPauseOrStartLiquidMCampaign &&
                    campaignOrder.status === "LIVE"
                  }
                  type="primary"
                  style={{ marginTop: "10px", marginLeft: "15px" }}
                  disabled={campaignOrder.status === "PAUSED"}
                  onClick={async () => {
                    try {
                      if (
                        ["LIVE", "LIVE_PENDING"].includes(campaignOrder.status)
                      ) {
                        await pauseOrStartLiquidMCampaign({
                          variables: {
                            id: campaignOrder.id,
                          },
                        });
                        notification.open({
                          message: "Successfully Paused Campaign",
                          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
                        });
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }
                    } catch (err) {
                      console.log(err);
                      notification.open({
                        message: `Failed to Pause Campaign: ${err}`,
                        icon: <WarningOutlined style={{ color: "yellow" }} />,
                      });
                    }
                  }}
                  icon={<PauseCircleOutlined />}
                >
                  Pause Campaign Ads
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export { AdminModal };
