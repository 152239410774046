import React from "react";

const TagsManagerController = ({
  orgs,
  currentOrgId,
  createEventTag,
  ...props
}) => {
  const providers = [
    { value: "bigcommerce", label: "Bigcommerce" },
    { value: "buddi", label: "Buddi" },
    { value: "dispense", label: "Dispense" },
    { value: "dutchie-iframe", label: "Dutchie Iframe" },
    { value: "dutchieplus", label: "Dutchie Plus" },
    { value: "dutchie-subdomain", label: "Dutchie Subdomain" },
    { value: "ecwid", label: "Ecwid" },
    { value: "grassdoor", label: "Grassdoor" },
    { value: "greenrush", label: "Greenrush" },
    { value: "jane", label: "Jane" },
    { value: "lightspeed", label: "Lightspeed" },
    { value: "magento", label: "Magento" },
    { value: "meadow", label: "Meadow" },
    { value: "olla", label: "Olla" },
    { value: "shopify", label: "Shopify" },
    { value: "sticky-leaf", label: "Sticky Leaf" },
    { value: "square", label: "Square" },
    { value: "thirdparty", label: "Thirdparty" },
    { value: "tymber", label: "Tymber" },
    { value: "wefunder", label: "Wefunder" },
    { value: "webjoint", label: "Webjoint" },
    { value: "woocommerce", label: "Woocommerce" },
    { value: "yotpo", label: "Yotpo" },
  ];
  const advertisers = orgs.orgs.map(org => {
    return { value: org.id, label: org.name };
  });

  const onSubmit = values => {
    const advertiser = orgs.orgs.find(org => values.advertiser === org.id);
    let domain = null;
    const parentChannelPartner = advertiser.parentOrg.find(
      org => org.level === "CHANNEL_PARTNER" && org.tagDomain !== null
    );
    if (advertiser.level === "CHANNEL_PARTNER") {
      domain = advertiser.tagDomain;
    } else if (parentChannelPartner) {
      domain = parentChannelPartner.tagDomain;
    }
    createEventTag({
      variables: {
        data: {
          name: values.name,
          orgId: values.advertiser,
          isEcommerce: values.ecommerce,
          cart: values.platform,
          isSignUps: values.signups,
          domain,
        },
      },
    });
  };
  return React.cloneElement(props.children, {
    ...props.children.props,
    providers,
    advertisers,
    currentOrgId,
    onSubmit,
  });
};

export default TagsManagerController;
