import React, { useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Divider,
  Input,
  Spin,
  Select,
  Table,
  Switch,
  Space,
  Typography,
  message,
} from "antd";
import { cardMargin } from "../../../shared/globalStyling/style";
import {
  attributionWindowSelections,
  defaultAttributionWindow,
} from "../../../../core/utils/constants/constants";
import { MenuItemTextColor } from "../../../shared/globalStyling/styledText";
import GlobalButton from "../../../shared/globalStyling/Button";

const { Option } = Select;

//Common Form Input Component.
//Since the form input is used multiple times, this component is created to avoid code duplication
const FormInput = ({ name, placeholder }) => {
  return (
    <Form.Item name={name} noStyle>
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

//Common Form Switch Component.
//Since the form switch is used multiple times, this component is created to avoid code duplication
// const FormSwitch = ({ name }) => {
//   return (
//     <Form.Item name={name} valuePropName="checked" noStyle>
//       <Switch />
//     </Form.Item>
//   );
// };

const OrgDataSettings = ({
  dataConfig, //Org data config to be displayed and modified on input
  onSubmit = () => {}, //Function to be called when the form is submitted
}) => {
  const {
    dspAdvertiserId,
    menuId,
    googleCustomerId,
    defaultDisplayAttributionWindow,
    defaultPaidSearchAttributionWindow,
    defaultSEOAttributionWindow,
    tradeDeskIdentifier,
    storageBucket,
    seo,
    paidSearchDataConfig,
    googleAnalytics,
    bing,
    semrushEnable,
    semrushConfig,
    seoDomainRankings,
  } = dataConfig;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [semRushEnabled, setSemRushEnabled] = useState(semrushEnable);
  const [seoEnabled, setSeoEnabled] = useState(seo);

  //List of data config values to be displayed on the table. If new config is needed add it here.
  const dataTable = dataConfig
    ? [
        {
          key: "tradeDeskIdentifier", //Unique key for the table.
          name: "Trade Desk Identifier", //Display name on the table. See `columnsTable` key `name` for reference
          permissions: () => {
            //Permission UI to be displayed on the table. See `columnsTable` key `permissions` for reference
            return (
              <FormInput
                name={"tradeDeskIdentifier"}
                placeholder="Trade Desk Identifier"
              />
            );
          },
        },
        {
          key: "storageBucket",
          name: "Storage Bucket",
          permissions: () => {
            return (
              <FormInput name={"storageBucket"} placeholder="Storage Bucket" />
            );
          },
        },
        {
          key: "seo",
          name: "SEO",
          permissions: () => {
            return (
              <Form.Item name={"seo"} valuePropName="checked" noStyle>
                <Switch onChange={value => setSeoEnabled(value)} />
              </Form.Item>
            );
          },
        },
        {
          key: "seoDomainRankings",
          name: "Website Domain Rankings",
          permissions: () => {
            const validateUrl = (_, value) => {
              const pattern = /^(https?:\/\/|\/)/i;
              if (value) {
                if (!pattern.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error(
                      "Please enter a domain without 'https://', 'http://', or '/'."
                    )
                  );
                }
              } else {
                return Promise.resolve();
              }
            };
            return (
              <Form.Item
                name={"seoDomainRankings"}
                style={{ marginTop: "24px" }}
                rules={[{ validator: validateUrl }]}
              >
                <Input placeholder={"Website Domain"} disabled={!seoEnabled} />
              </Form.Item>
            );
          },
        },
        {
          key: "googleAnalyticsViewId",
          name: "Google Analytics View ID",
          permissions: () => {
            return (
              <Row gutter={[0, 16]}>
                <FormInput
                  name={"googleAnalyticsViewId"}
                  placeholder="Google Analytics View ID"
                />
                <Space size="small" direction="horizontal">
                  <Typography.Text>UA</Typography.Text>
                  <Form.Item
                    name={"googleAnalyticsViewIdIsGA4"}
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch />
                  </Form.Item>
                  <Typography.Text>GA4</Typography.Text>
                </Space>
              </Row>
            );
          },
        },
        {
          key: "semrush",
          name: "Semrush",
          permissions: () => {
            return (
              <Row gutter={[0, 16]}>
                <Form.Item name={"semrushProjectID"} noStyle>
                  <Input placeholder="Project ID" disabled={!semRushEnabled} />
                </Form.Item>
                <Form.Item name={"semrushRootDomainUrl"} noStyle>
                  <Input
                    placeholder="Root Domain URL (Optional)"
                    disabled={!semRushEnabled}
                  />
                </Form.Item>
                <Form.Item name={"semrushRootKeyword"} noStyle>
                  <Input
                    placeholder="Keyword (Optional)"
                    disabled={!semRushEnabled}
                  />
                </Form.Item>
                <Form.Item name={"semrushCompetitor1stRootDomainUrl"} noStyle>
                  <Input
                    placeholder="Competitor Root Domain URL"
                    disabled={!semRushEnabled}
                  />
                </Form.Item>
                <Form.Item name={"semrushCompetitor2ndRootDomainUrl"} noStyle>
                  <Input
                    placeholder="Competitor Root Domain URL"
                    disabled={!semRushEnabled}
                  />
                </Form.Item>
                <Form.Item
                  name={"semrushEnable"}
                  valuePropName="checked"
                  noStyle
                >
                  <Switch
                    onChange={checked => {
                      setSemRushEnabled(checked);
                    }}
                  />
                </Form.Item>
              </Row>
            );
          },
        },
      ]
    : [];

  const columnsTable = [
    {
      title: "Data Configs",
      width: "25%",
      dataIndex: "name",
      key: "name",
      render: text => <div>{text}</div>,
    },
    {
      title: "Permissions",
      width: "75%",
      dataIndex: "permissions",
      key: "permissions",
      render: permission => permission(),
    },
  ];

  return (
    <Card style={{ ...cardMargin }} title={"Data Settings"}>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          name="orgDataSettingsForm"
          initialValues={{
            dspAdvertiserId,
            menuId,
            googleCustomerId,
            defaultDisplayAttributionWindow: defaultDisplayAttributionWindow
              ? defaultDisplayAttributionWindow
              : defaultAttributionWindow.key,
            defaultPaidSearchAttributionWindow: defaultPaidSearchAttributionWindow
              ? defaultPaidSearchAttributionWindow
              : defaultAttributionWindow.key,
            defaultSEOAttributionWindow: defaultSEOAttributionWindow
              ? defaultSEOAttributionWindow
              : defaultAttributionWindow.key,
            tradeDeskIdentifier,
            storageBucket,
            seo,
            seoDomainRankings,
            paidSearch: paidSearchDataConfig
              ? paidSearchDataConfig.isEnabled
              : false,
            googleAnalyticsViewId: googleAnalytics
              ? googleAnalytics.viewId
              : null,
            googleAnalyticsViewIdIsGA4: googleAnalytics
              ? googleAnalytics.isGA4
              : false,
            bingCustomerId: bing ? bing.customerId : null,
            bingAccountId: bing ? bing.accountId : null,
            semrushEnable: semrushEnable,
            semrushProjectID: semrushConfig ? semrushConfig.projectId : null,
            semrushRootDomainUrl: semrushConfig
              ? semrushConfig.rootDomainUrl
              : null,
            semrushRootKeyword: semrushConfig
              ? semrushConfig.rootKeyword
              : null,
            semrushCompetitor1stRootDomainUrl: semrushConfig
              ? semrushConfig.competitor1stRootDomainUrl
              : null,
            semrushCompetitor2ndRootDomainUrl: semrushConfig
              ? semrushConfig.competitor2ndRootDomainUrl
              : null,
          }}
          onFinish={async values => {
            form.validateFields().then(async values => {
              setIsLoading(true);
              try {
                await onSubmit(values);
                message.success("Organization Data updated successfully");
              } catch (err) {
                message.error("Error updating organization data");
              }
              setIsLoading(false);
            });
          }}
        >
          <Divider orientation="left" plain>
            Advertiser Configuration
          </Divider>
          <Row justify="space-between">
            <Col xs={24} xl={11}>
              <Form.Item label="Menu ID" name="menuId">
                <Input placeholder="Menu ID" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={11}>
              <Form.Item label="DSP Advertiser ID" name="dspAdvertiserId">
                <Input placeholder="DSP Advertiser ID" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            Default Attribution Window
          </Divider>
          <Row justify="space-between">
            <Col xs={24} xl={11}>
              <Form.Item label="Display" name="defaultDisplayAttributionWindow">
                <Select>
                  {attributionWindowSelections.map(option => {
                    return (
                      <Option key={option.key} value={option.key}>
                        <MenuItemTextColor text={option.name} />
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={11}>
              <Form.Item
                label="Paid Search"
                name="defaultPaidSearchAttributionWindow"
              >
                <Select>
                  {attributionWindowSelections.map(option => {
                    return (
                      <Option key={option.key} value={option.key}>
                        <MenuItemTextColor text={option.name} />
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={11}>
              <Form.Item label="SEO" name="defaultSEOAttributionWindow">
                <Select>
                  {attributionWindowSelections.map(option => {
                    return (
                      <Option key={option.key} value={option.key}>
                        <MenuItemTextColor text={option.name} />
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            Data Configuration
          </Divider>
          <Row justify="space-between">
            <Col xs={24} xl={12}>
              <Table
                columns={columnsTable}
                dataSource={dataTable}
                bordered
                pagination={false}
              />
            </Col>
          </Row>
          <Col style={{ textAlign: "right" }} xs={24}>
            <GlobalButton htmlType="submit" type="primary">
              Update
            </GlobalButton>
          </Col>
        </Form>
      </Spin>
    </Card>
  );
};

export default OrgDataSettings;
