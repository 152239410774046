import React, { useEffect, useState } from "react";
import { Card, Col, message, Modal, Row } from "antd";
import { cardMargin, cardBody } from "../../../../shared/globalStyling/style";
import Button from "../../../../shared/globalStyling/Button";
import {
  PlusOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { flatten } from "lodash";

// CONFIGS
import { OrganizationConfig } from "../../configs/organizationConfig";

// COMPONENTS
import OrganizationCsvLocationUpload from "../OrganizationCsvLocationUpload";
import TemplateCSVDownloader from "../../../../shared/TemplateCSVDownloader";
import TargetingLocationListView from "./TargetingLocationListView";
import TargetingLocationForm from "./TargetingLocationForm";
import CreateAudienceForm from "../CreateAudienceForm";
import TargetingLocationPreviewImport from "./TargetingLocationPreviewImport";

export default function TargetingLocations({
  attributionAudienceName,
  createTargetingLocation,
  deleteLocation,
  google,
  locationList,
  onChangeStateValue,
  onCsvUploadComplete,
  onSubmitAudience,
  selectedLocations,
  updateTargetingLocation,
  isTargetingPreviewModalOpen,
  targetingLocationsPreviewData,
  closeTargetingPreviewModal,
  bulkImportTargetingLocations,
  selectTargetingLocationPreview,
  deleteBulkLocation,
  targetingAudiences,
  selectedOrg,
}) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteBulk, setIsDeleteBulk] = useState(false);
  const [isAudienceFormOpen, setIsAudienceFormOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    if (locationList && selectedOrg) {
      const locations = locationList.filter(
        location => location.org && location.org.id === selectedOrg.id
      );
      setFilteredLocations(locations);
    }
  }, [locationList, selectedOrg]);

  const toggleForm = val => {
    setIsFormOpen(val);
  };

  const onCloseForm = () => {
    setSelectedLocation(null);
    toggleForm(false);
  };

  const onDeleteTargetingLocation = Id => {
    toggleForm(false);

    message.loading("Action in progress..", 0);

    deleteLocation(Id)
      .then(() => {
        message.destroy();
        message.success(`Successfully deleted retail Location`);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: Cannot delete retail Location`);
      });
  };

  const onEditLocation = location => {
    setSelectedLocation(location);
    toggleForm(true);
  };

  const onSubmitForm = location => {
    if (selectedLocation) {
      updateTargetingLocation({
        ...selectedLocation,
        ...location,
      });
    } else {
      createTargetingLocation(location);
    }
    toggleForm(false);
  };

  // CREATE AUDIECES
  const onCloseAudienceForm = () => {
    setIsAudienceFormOpen(false);
  };

  const onSubmitAudienceForm = () => {
    onCloseAudienceForm();
    onSubmitAudience("TARGETING");
  };

  const handleCancelDeleteBulk = () => {
    setIsDeleteBulk(false);
  };

  const handleOkDeleteBulk = () => {
    deleteBulkLocation();
    setIsDeleteBulk(false);
  };

  return (
    <div>
      {isFormOpen && (
        <TargetingLocationForm
          selectedLocation={selectedLocation}
          isFormOpen={isFormOpen}
          google={google}
          targetingLocations={locationList}
          closeForm={onCloseForm}
          submitForm={onSubmitForm}
        />
      )}

      {isAudienceFormOpen && (
        <CreateAudienceForm
          attributionAudienceName={attributionAudienceName}
          isFormOpen={isAudienceFormOpen}
          closeForm={onCloseAudienceForm}
          onChangeStateValue={onChangeStateValue}
          submitForm={onSubmitAudienceForm}
        />
      )}

      {isDeleteBulk && selectedLocations.length && (
        <Modal
          title="Confirm Action"
          visible={isDeleteBulk}
          onOk={handleOkDeleteBulk}
          onCancel={handleCancelDeleteBulk}
        >
          <p>
            Are you sure you want to delete{" "}
            {`${selectedLocations.length} ${
              selectedLocations.length === 1 ? "location" : "locations"
            }`}
            ?
          </p>
        </Modal>
      )}

      {isTargetingPreviewModalOpen && (
        <TargetingLocationPreviewImport
          isOpen={isTargetingPreviewModalOpen}
          targetingLocationsPreviewData={targetingLocationsPreviewData}
          closeTargetingPreviewModal={closeTargetingPreviewModal}
          bulkImportTargetingLocations={bulkImportTargetingLocations}
          selectTargetingLocationPreview={selectTargetingLocationPreview}
        />
      )}

      <Row gutter={[18, 18]}>
        <Col xs={24} lg={24}>
          <Card
            style={cardMargin}
            bodyStyle={cardBody}
            title="Targeting Locations"
            extra={
              <>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => toggleForm(true)}
                >
                  Add Targeting Location
                </Button>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={selectedLocations && selectedLocations.length === 0}
                  onClick={() => {
                    setIsAudienceFormOpen(true);
                  }}
                >
                  Create Targeting Group
                </Button>

                <OrganizationCsvLocationUpload
                  onComplete={onCsvUploadComplete}
                />

                <TemplateCSVDownloader
                  tooltipMessage="CSV Template for Organization Locations"
                  rows={OrganizationConfig.csvTemplate.rows}
                  templateName={OrganizationConfig.csvTemplate.templateName}
                  title="Sample Template"
                />

                <Button
                  disabled={
                    !filteredLocations || filteredLocations.length === 0
                  }
                >
                  <CSVLink
                    filename={`TargetingLocations.csv`}
                    style={{ textDecoration: "none" }}
                    data={flatten(filteredLocations)}
                  >
                    <DownloadOutlined /> Download CSV
                  </CSVLink>
                </Button>

                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  disabled={selectedLocations && selectedLocations.length === 0}
                  onClick={() => setIsDeleteBulk(true)}
                >
                  Delete
                </Button>
              </>
            }
          >
            <Row
              align="right"
              className="ant-row-justify-center ant-row-justify-end"
            ></Row>
            <TargetingLocationListView
              deleteLocation={onDeleteTargetingLocation}
              editLocation={onEditLocation}
              onChangeStateValue={onChangeStateValue}
              targetingLocationList={filteredLocations}
              targetingAudiences={targetingAudiences}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
