import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { BarChartOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ApproveCampaignButton = ({ checkStatus, ...props }) => {
  const history = useHistory();
  return (
    <>
      <Button
        style={{ marginRight: "30px" }}
        type="primary"
        onClick={() => {
          history.push(`/campaigns/manager/${props.id}`);
        }}
        // // disabled={
        // //   (checkStatus === "PENDING" &&
        // //     (checkIsEditCampaign === false ||
        // //       checkIsEditCampaign === null))
        // //  || getIsRequestChange === "Campaign Changed for Review"
        // // }
        icon={<EditOutlined />}
      >
        Edit Campaign
      </Button>
      {checkStatus === "LIVE" && (
        <Button
          type="primary"
          icon={<BarChartOutlined />}
          onClick={() => {
            history.push(`/campaigns/details/${props.id}`);
          }}
        >
          See Live Campaign
        </Button>
      )}
      {/* <Button
        type="primary"
        onClick={async () => {
          setProcessApprovedClick(true);
          await props.updateCampaignOrderStatus({
            variables: {
              id: props.id,
              status: "APPROVED",
              message: `We have received a new campaign order ${props.name.toUpperCase()}`,
            },
          });
          setProcessApprovedClick(false);
        }}
        disabled={false}
        icon={!processApprovedClick ? <LikeOutlined /> : <LoadingOutlined />}
      >
        Approve Campaign
      </Button> */}
    </>
  );
};

ApproveCampaignButton.propTypes = {
  updateCampaignOrderStatus: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ApproveCampaignButton;
