import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Progress, Tooltip } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import PaginationTable from "../../../../shared/PaginationTable";
import {
  formatWholeNumber,
  formatCurrency2SigFig,
  sessionTimer,
} from "../../../../../core/utils/campaigns";

// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
  StandardTableRowText,
} from "../../../../shared/globalStyling/styledText";

const MainTable = ({
  seoCampaigns,
  match,
  history,
  location,
  onRowChange,
  selectedRowKeys,
  currencyFormat,
}) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      key: "org.name",
      align: "left",
      width: "20%",
      render: record => {
        const { name } = record.org;
        return (
          <Row type="flex" justify="start">
            <Col>
              <PrimaryTableRowText
                text={
                  name.length > 35 ? (
                    <Tooltip title={name}>
                      {name.slice(0, 36).concat("...")}
                    </Tooltip>
                  ) : (
                    name
                  )
                }
              />
            </Col>
          </Row>
        );
      },
      footerContent: "Totals + Averages",
    },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      key: "daterange",
      width: "20%",
      render: record => {
        if (!record) {
          return <div />;
        }
        const currentDate = moment.utc();
        const startDate = moment.utc(record.startDate);
        const endDate = moment.utc(record.endDate);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <React.Fragment>
            <Row type="flex" justify="start">
              {record.startDate && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.endDate && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </React.Fragment>
        );
      },
    },
    {
      title: "Users",
      key: "deviceIdCount",
      width: "20%",
      render: record => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <Row type="flex">
                <PrimaryTableRowText text={`New Users: ‎`} />
                <StandardTableRowText
                  text={
                    record.seoSummaryData
                      ? `${formatWholeNumber(record.seoSummaryData.newUsers)}`
                      : "0"
                  }
                />
              </Row>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "Sessions",
      key: "sessions",
      width: "20%",
      render: record => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <Row type="flex">
                <PrimaryTableRowText text={`Sessions: ‎`} />
                <StandardTableRowText
                  text={
                    record.seoSummaryData
                      ? `${formatWholeNumber(record.seoSummaryData.sessions)}`
                      : "0"
                  }
                />
              </Row>

              <Row type="flex">
                <PrimaryTableRowText text={`Pages / Sessions: ‎`} />
                <StandardTableRowText
                  text={
                    record.seoSummaryData && record.seoSummaryData.rowSize !== 0
                      ? `${formatWholeNumber(
                          record.seoSummaryData.pageView /
                            record.seoSummaryData.rowSize
                        )}`
                      : "0"
                  }
                />
              </Row>
              <Row type="flex">
                <PrimaryTableRowText text={`Average Sessions: ‎`} />
                <StandardTableRowText
                  text={
                    record.seoSummaryData && record.seoSummaryData.rowSize !== 0
                      ? `${sessionTimer(
                          record.seoSummaryData.avgSessionDuration /
                            record.seoSummaryData.rowSize
                        ).toLocaleString()}`
                      : "0"
                  }
                />
              </Row>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "ROI",
      key: "revenue",
      width: "20%",
      render: record => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <Row type="flex">
                <PrimaryTableRowText text={`Revenue:‏‏‎ ‎`} />
                <StandardTableRowText
                  text={` ${formatCurrency2SigFig(
                    record.seoSummaryData
                      ? record.seoSummaryData.revenue || 0
                      : 0,
                    currencyFormat
                  )}`}
                />
              </Row>

              <Row type="flex">
                <PrimaryTableRowText text={`Online Orders: ‎`} />
                <StandardTableRowText
                  text={
                    record.seoSummaryData
                      ? `${formatWholeNumber(
                          record.seoSummaryData.transactions
                        )}`
                      : "0"
                  }
                />
              </Row>
              <Row type="flex">
                <PrimaryTableRowText text={`Average Orders: ‎`} />
                <StandardTableRowText
                  text={
                    record.seoSummaryData && record.seoSummaryData.rowSize !== 0
                      ? `${formatWholeNumber(
                          record.seoSummaryData.avgOrderValue /
                            record.seoSummaryData.rowSize
                        )}`
                      : "0"
                  }
                />
              </Row>
            </Col>
          </Row>
        );
      },
    },
  ];

  const params = new URLSearchParams(location.search);
  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;
  const { pageNumber } = match.params;
  const searchTerm = params.get("searchTerm");
  let filteredArr = [];

  if (searchTerm) {
    filteredArr = seoCampaigns.filter(obj =>
      obj.org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const count = searchTerm ? filteredArr.length : seoCampaigns.length;
  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        data={searchTerm ? filteredArr : seoCampaigns}
        columns={columns}
        scroll={{ x: 1200, y: 700 }}
        pageSize={pageSize}
        onRow={data => {
          return {
            onClick: () => {
              const mainPath = location.pathname.split("/")[1];
              history.push(`/${mainPath}/details/${data.id}`);
            },
          };
        }}
        rowSelection={{
          onChange: onRowChange,
          selectedRowKeys,
          columnWidth: "5%",
        }}
        onChange={(paginationObject, sorterObject) => {
          const nextPage = location.pathname.split("/");
          nextPage[3] = paginationObject.current;
          const searchParams = new URLSearchParams(window.location.search);

          if (searchParams.get("pageSize")) searchParams.delete("pageSize");
          if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
            searchParams.append("pageSize", paginationObject.pageSize);
          }

          history.push({
            pathname: nextPage.join("/"),
            search: `?${searchParams.toString()}`,
          });
        }}
        total={count}
        current={parseInt(pageNumber)}
        bordered={false}
      />
    </div>
  );
};

export default withRouter(MainTable);
