import gql from "graphql-tag";

const GET_AUDIENCE_DEMOGRAPHS = gql`
  query audienceDemographs($whereParameters: AudienceDemographWhereInput!) {
    audienceDemographs(
      first: 1000
      orderBy: updatedAt_DESC
      where: $whereParameters
    ) {
      id
      name
      description
      tags
      metrics
      reachCount
      createdAt
      isPublished
      publishedDate
      createdBy {
        id
        name
      }
    }
  }
`;

export default GET_AUDIENCE_DEMOGRAPHS;
