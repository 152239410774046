import { Col, Row } from "antd";
import React from "react";
import OrgDetailSettings from "./components/OrgDetailSettings";
import OrgUISettings from "./components/OrgUISettings";

import OrgDataSettings from "./components/OrgDataSettings";
import ErrorPage from "../../shared/ErrorPage";
import { LoadingPage } from "../../shared/LoadingPage";
import { PageContentLayout } from "../../shared/layout";

const SettingTab = ({
  selectedOrg,
  updateOrgInfo,
  updateOrgUI,
  updateOrgData,
  orgTableConfig,
  themeConfig,
  orgTableConfigLoading,
  themeConfigLoading,
  orgTableConfigError,
  themeConfigError,
}) => {
  if (orgTableConfigLoading || themeConfigLoading) return <LoadingPage />;
  if (orgTableConfigError || themeConfigError) return <ErrorPage />;

  const {
    name,
    description,
    website,
    zone,
    roasFormat,
    logo,
    dataConfig,
  } = selectedOrg;
  return (
    <React.Fragment>
      <PageContentLayout>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <OrgDetailSettings
              name={name}
              description={description}
              website={website}
              timezone={zone}
              roasFormat={roasFormat}
              logo={logo}
              onSubmit={updateOrgInfo}
            />
          </Col>
          <Col span={24}>
            <OrgUISettings
              tableConfig={orgTableConfig}
              themeConfig={themeConfig}
              onSubmit={updateOrgUI}
            />
          </Col>
          <Col span={24}>
            <OrgDataSettings dataConfig={dataConfig} onSubmit={updateOrgData} />
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SettingTab;
