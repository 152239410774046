import React, { useState } from "react";
import GlobalButton from "../../../../shared/globalStyling/Button";
import { Modal, Row, Col, Card, message, Space, Popconfirm } from "antd";
import {
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";

const PausePlay = ({ status, pausePlayEditorial }) => {
  if (status && status === "PAUSED") {
    return (
      <Col span={12}>
        <Card title="Resume Publisher">
          <Row align="center">
            <Space direction="vertical" size="middle" align="center">
              <PlayCircleOutlined
                style={{ fontSize: "80px", marginBottom: "20px" }}
              />
              <div style={{ marginBottom: "20px" }}>
                This will resume the processing of this publisher
              </div>
              <Popconfirm
                title="Resume this Publisher?"
                onConfirm={async () => {
                  try {
                    await pausePlayEditorial("ACTIVE");
                    message.success("Publisher Resumed");
                  } catch (err) {
                    message.error("Failed Resuming Publisher");
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <GlobalButton icon={<PlayCircleOutlined />} type="primary">
                  Resume Publisher
                </GlobalButton>
              </Popconfirm>
            </Space>
          </Row>
        </Card>
      </Col>
    );
  } else {
    return (
      <Col span={12}>
        <Card title="Pause Publisher">
          <Row align="center">
            <Space direction="vertical" size="middle" align="center">
              <PauseCircleOutlined
                style={{ fontSize: "80px", marginBottom: "20px" }}
              />
              <div style={{ marginBottom: "20px" }}>
                This will stop the processing of this publisher
              </div>
              <Popconfirm
                title="Pause this Publisher?"
                onConfirm={async () => {
                  try {
                    await pausePlayEditorial("PAUSED");
                    message.success("Publisher Paused");
                  } catch (err) {
                    console.log(err);
                    message.error("Failed Pausing Publisher");
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <GlobalButton icon={<PauseCircleOutlined />} type="primary">
                  Pause Publisher
                </GlobalButton>
              </Popconfirm>
            </Space>
          </Row>
        </Card>
      </Col>
    );
  }
};

const AdminModal = ({
  deleteEditorial,
  pausePlayEditorial,
  status,
  history,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <React.Fragment>
      <GlobalButton
        type="primary"
        shape="round"
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      >
        Admin Tools
      </GlobalButton>
      <Modal
        title={"Admin Tools"}
        visible={modalOpen}
        style={{ width: "60%" }}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Card title="Delete Publisher">
              <Row align="center">
                <Space direction="vertical" size="middle" align="center">
                  <DeleteOutlined
                    style={{ fontSize: "80px", marginBottom: "20px" }}
                  />
                  <div style={{ marginBottom: "20px" }}>
                    This will permanently delete the current Publisher
                  </div>
                  <Popconfirm
                    title="Delete this Publisher?"
                    onConfirm={async () => {
                      try {
                        await deleteEditorial();
                        message.success("Publisher Deleted");
                        history.push("/editorial/main/1");
                      } catch (err) {
                        message.err("Failed Deleting Publisher");
                      }
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <GlobalButton icon={<DeleteOutlined />} type="primary">
                      Delete Publisher
                    </GlobalButton>
                  </Popconfirm>
                </Space>
              </Row>
            </Card>
          </Col>
          <PausePlay status={status} pausePlayEditorial={pausePlayEditorial} />
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(AdminModal);
