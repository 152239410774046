import React, { useState } from "react";

// Ant Design
import {
  Table,
  Row,
  Col,
  Button,
  Layout,
  Popconfirm,
  Grid,
  message,
} from "antd";
import SelectedMetrics from "../components/SelectedMetricList";
import AudienceDetails from "../components/AudienceDetails";
import AudienceDetailsModal from "../components/Modals/AudienceDetailsModal.js";

import {
  demographicsAudienceTableColumn,
  demographicsAudienceTableColumnMobile,
} from "../utils/tableColumns";

import { isMobileScreen } from "../utils/mobileUtils";
import { useAudienceBuilderContext } from "../context/AudienceBuilderContext.js";
const { Footer } = Layout;

const DemographicsAudienceTable = props => {
  const {
    audienceDemographs,
    handleUpdateAudience,
    handleFetchAudienceDemographs,
  } = props;

  const { setSetSelectedAudiences } = useAudienceBuilderContext();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { useBreakpoint } = Grid;

  const breakPoint = useBreakpoint();

  const [loading, setLoading] = useState(false);
  const [visbile, setVisible] = useState(false);
  const [metrics, setMetrics] = useState([]);

  const [audienceDetails, setAudienceDetails] = useState({});

  const [audienceState, setAudienceState] = useState({});

  const handleArchive = async id => {
    try {
      setLoading(true);
      await handleUpdateAudience({ id, isArchived: true });
      await handleFetchAudienceDemographs();
      message.success("Audience has been archived", 2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Failed to delete audience", 2);
    }
  };

  // Function to handle update of Audience records
  const handleUpdateAudienceRecord = async data => {
    try {
      setLoading(true);

      const cleanedData = {
        id: data.id,
        description: data.description,
        tags: data.tags,
      };

      await handleUpdateAudience({ ...cleanedData });
      await handleFetchAudienceDemographs();

      setAudienceState(undefined);
      message.success("Audience has been updated", 2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Failed to update audience", 2);
    }
  };

  const defaultExpandable = {
    expandedRowRender: record => {
      const { metrics } = record;
      return (
        <>
          <Row style={{ minHeight: "700px" }} gutter={16}>
            <Col span={6}>
              <SelectedMetrics list={metrics}></SelectedMetrics>
            </Col>
            <Col span={18} style={{ height: "700px" }}>
              <AudienceDetails
                audienceDetails={record}
                audienceState={audienceState}
                setAudienceState={setAudienceState}
              />
              <Footer>
                <Button
                  loading={loading}
                  style={{ marginRight: "0.2em" }}
                  type="primary"
                  onClick={() => {
                    handleUpdateAudienceRecord(audienceState);
                  }}
                >
                  Update
                </Button>
                <Popconfirm
                  title="Are you sure you want to archive this record?"
                  onConfirm={() => {
                    handleArchive(record.id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger loading={loading}>
                    Archive
                  </Button>
                </Popconfirm>
              </Footer>
            </Col>
          </Row>
        </>
      );
    },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (record, selected, selectedRows) => {
      setSelectedRowKeys(record);
    },
    onSelect: (record, selected, selectedRows) => {
      setSetSelectedAudiences(selectedRows);
    },
  };

  const tableProps = isMobileScreen(breakPoint)
    ? {}
    : {
        rowSelection,
        expandable: defaultExpandable,
      };

  const tableColumns = isMobileScreen(breakPoint)
    ? demographicsAudienceTableColumnMobile
    : demographicsAudienceTableColumn;

  const handleRowClick = record => {
    setAudienceDetails(record);
    setMetrics(record.metrics);
    setVisible(true);
  };

  const rowProps = record => ({
    onClick: () => handleRowClick(record),
  });

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <AudienceDetailsModal
        visible={visbile}
        audienceDetails={audienceDetails}
        metrics={metrics}
        handleCancel={handleCancel}
      />
      <Table
        {...tableProps}
        columns={tableColumns}
        styles={{ minHeight: "600px", height: "600px" }}
        dataSource={audienceDemographs.map(i => ({ ...i, key: i.id }))}
        onRow={isMobileScreen(breakPoint) ? rowProps : null}
      />
    </>
  );
};

export default DemographicsAudienceTable;
