import React from "react";
import moment from "moment";
import { Row, Col, Result, Tooltip, Card, Statistic, Typography } from "antd";
import {
  AreaChartOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  DollarCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { notification } from "antd";
import { FileSyncOutlined } from "@ant-design/icons";

import Button from "../../shared/globalStyling/Button";
import { ButtonText } from "../../shared/globalStyling/styledText";

import { DataTabsCard } from "./components/OrgSummary";

import { useHistory } from "react-router-dom";
import {
  formatCurrency2SigFig,
  formatNumber,
} from "../../../core/utils/campaigns";
import AdvertiserFilter from "../../shared/AdvertiserFilter";
import { orgLevelCustomization } from "../../../core/permissions/orgLevelCustomization";

const { Text } = Typography;

const DisplaySummaryView = ({
  totalOrgSummaries,
  loadingDeviceClicks,
  deviceClicks,
  advertiserIds,
  orgId,
  loading,
  errorOrgSummary,
  userPermission,
  campaignOrders,
  selectedCOIds,
  setSelectedCOIds,
  disableRefresh,
  currentUser,
  reprocessOrg,
  reprocessOrgLoading,
}) => {
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;
  // Set Document Title
  document.title = "Display Summary";

  // user permission
  const history = useHistory();
  const refreshPage = () => {
    history.push("/");
    history.goBack();
  };

  if (!userPermission) {
    return (
      <>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button onClick={() => refreshPage()} type="primary">
              Reload
            </Button>
          }
        />
      </>
    );
  }

  const onClickRefresh = () => {
    reprocessOrg({
      variables: {
        where: {
          dag_id: "process-org",
          body: {
            conf: {
              id: `id=${orgId}`,
            },
          },
        },
      },
    });

    notification.open({
      message: "Reprocessing Org...",
      description:
        "Please wait for a while to reprocess the current org. It's currently on queue and may take some time.",
      icon: <FileSyncOutlined />,
      duration: 20,
    });
  };

  if (errorOrgSummary) {
    return (
      <>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </>
    );
  }

  const updatedAt = () => {
    if (totalOrgSummaries && totalOrgSummaries.updatedAt) {
      return `Updated ${moment(
        totalOrgSummaries.updatedAt,
        moment.DATETIME_LOCAL_MS
      ).calendar()}`;
    }
    return "";
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Display Performance"}
        subtitle={updatedAt()}
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Col style={{ display: "flex" }} span={24}>
            {!disableRefresh && advertiserIds.includes(orgId) && (
              <>
                <Tooltip
                  placement="left"
                  title="Request the latest summary data for your own organization."
                >
                  <Button
                    loading={reprocessOrgLoading}
                    onClick={onClickRefresh}
                    disabled={!advertiserIds.includes(orgId)}
                  >
                    <ButtonText text="Update" />
                  </Button>
                </Tooltip>
              </>
            )}
          </Col>
        }
      />
      <PageContentLayout>
        <Row>
          <Col xs={24} md={8} xl={5}>
            <Text>Filter by Advertiser</Text>
            <AdvertiserFilter
              isSinglePage={true}
              isDemo={userPermission.isDemo}
            />
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          <Col flex={4}>
            <Card loading={loading} bordered={false}>
              <Statistic
                title="Live Campaigns"
                value={
                  totalOrgSummaries
                    ? formatNumber(totalOrgSummaries.liveCampaigns)
                    : 0
                }
              />
            </Card>
          </Col>
          <Col flex={4}>
            <Card loading={loading} bordered={false}>
              <Statistic
                title="Attribution Campaigns"
                value={
                  totalOrgSummaries
                    ? formatNumber(totalOrgSummaries.inAttributionCampaigns)
                    : 0
                }
              />
            </Card>
          </Col>
          <Col flex={4}>
            <Card loading={loading} bordered={false}>
              <Statistic
                title="Total Campaigns"
                value={
                  totalOrgSummaries && totalOrgSummaries.totalCampaigns
                    ? formatNumber(totalOrgSummaries.totalCampaigns)
                    : 0
                }
              />
            </Card>
          </Col>
          {userPermission.priceVisible ? (
            <Col flex={4}>
              <Card loading={loading}>
                <Statistic
                  title="ROAS"
                  prefix={
                    (totalOrgSummaries && totalOrgSummaries.totalRevenue
                      ? totalOrgSummaries.totalRevenue
                      : 0) >
                    (totalOrgSummaries &&
                    totalOrgSummaries.mediaSpendBudgetSpent
                      ? totalOrgSummaries.mediaSpendBudgetSpent
                      : 0) ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                  value={
                    totalOrgSummaries && totalOrgSummaries.roas
                      ? totalOrgSummaries.roas
                      : 0
                  }
                  precision={2}
                  suffix="%"
                />
              </Card>
            </Col>
          ) : null}
          {userPermission.priceVisible ? (
            <Col flex={4}>
              <Card loading={loading}>
                <Statistic
                  title="Impressions Goal"
                  prefix={<UsergroupAddOutlined />}
                  value={
                    totalOrgSummaries && totalOrgSummaries.goal
                      ? formatNumber(totalOrgSummaries.goal)
                      : "--"
                  }
                />
              </Card>
            </Col>
          ) : null}
          {userPermission.priceVisible ? (
            <Col flex={4}>
              <Card loading={loading}>
                <Statistic
                  title="Budget"
                  prefix={<DollarCircleOutlined />}
                  value={formatCurrency2SigFig(
                    totalOrgSummaries ? totalOrgSummaries.budget : 0,
                    currencyFormat
                  )}
                />
              </Card>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col xs={24}>
            <DataTabsCard
              userPermission={userPermission}
              isLoading={loading}
              orgSummary={totalOrgSummaries}
              loadingDeviceClicks={loadingDeviceClicks}
              deviceClicks={deviceClicks}
              advertiserIds={advertiserIds}
              campaignDataOptions={campaignOrders}
              selectedCIDs={selectedCOIds}
              setSelectedCIDs={setSelectedCOIds}
              currencyFormat={currencyFormat}
            />
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default DisplaySummaryView;
