import React from "react";
import PropTypes from "prop-types";
import { Button, Spin } from "antd";
import { isEmpty } from "lodash";
export const GeoTargetObservationButton = ({
  isLoadingRefreshObservation,
  setIsLoadingRefreshObservation,
  id,
  startObservationJob,
}) => {
  return (
    <>
      {isLoadingRefreshObservation ? (
        <Spin />
      ) : (
        <Button
          type="primary"
          ghost
          disabled={isEmpty(id)}
          onClick={() => {
            setIsLoadingRefreshObservation(true);
            startObservationJob({ variables: { id } });
          }}
        >
          Refresh Observations
        </Button>
      )}
    </>
  );
};

GeoTargetObservationButton.propTypes = {
  id: PropTypes.string.isRequired,
};
