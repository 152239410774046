import { Button, Modal } from "antd";
import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { providers } from "../../../../../core/utils/constants/constants";
import {
  BigCommerceInstructions,
  BigCommerceInstructionsPDF,
} from "./instructions/BigCommerceInstructions";
import {
  WoocommerceInstructions,
  WoocommerceInstructionsPDF,
} from "./instructions/WoocommerceInstructions";
import {
  ShopifyInstructions,
  ShopifyInstructionsPDF,
} from "./instructions/ShopifyInstructions";
import {
  WeFunderInstructions,
  WeFunderInstructionsPDF,
} from "./instructions/WeFunderInstructions";
import {
  DispenseInstructions,
  DispenseInstructionsPDF,
} from "./instructions/DispenseInstructions";
import {
  JaneInstructions,
  JaneInstructionsPDF,
} from "./instructions/JaneInstructions";
import {
  TymberInstructions,
  TymberInstructionsPDF,
} from "./instructions/TymberInstructions";
import {
  DutchieSubdomainInstructions,
  DutchieSubdomainInstructionsPDF,
} from "./instructions/DutchieSubdomainInstructions";
import {
  DutchiePlusInstructions,
  DutchiePlusInstructionsPDF,
} from "./instructions/DutchiePlusInstructions";
import {
  DutchieIframeInstructions,
  DutchieIframeInstructionsPDF,
} from "./instructions/DutchieIframeInstructions";
import {
  GrassdoorInstructions,
  GrassdoorInstructionsPDF,
} from "./instructions/GrassdoorInstructions";
import {
  GreenrushInstructions,
  GreenrushInstructionsPDF,
} from "./instructions/GreenrushInstructions";
import {
  LightspeedInstructions,
  LightspeedInstructionsPDF,
} from "./instructions/LightSpeedInstructions";
import {
  MeadowInstructions,
  MeadowInstructionsPDF,
} from "./instructions/MeadowInstructions";
import {
  BuddiInstructions,
  BuddiInstructionsPDF,
} from "./instructions/BuddiInstructions";
import {
  OllaInstructions,
  OllaInstructionsPDF,
} from "./instructions/OllaInstructions";

const GetCartPlatform = cart => {
  const hasNoInstruction = [
    "no-cart",
    "yotpo",
    "webjoint",
    "thirdparty",
    "square",
    "sticky-leaf",
    "magento",
    "ecwid",
  ];
  //Newly created tag
  const selectedCart = providers.find(provider => provider.value === cart);

  //if no cart is found check legacy carts
  if (!selectedCart) {
    const legacyCart = providers.find(provider => provider.legacyCtr === cart);
    if (!legacyCart) {
      return null;
    }
    if (hasNoInstruction.includes(legacyCart.value)) {
      return null;
    }
    return legacyCart;
  } else {
    if (hasNoInstruction.includes(selectedCart.value)) {
      return null;
    }
    return selectedCart;
  }
};

const GetInstructions = ({ cart, component, generatedTag }) => {
  if (cart === "bigcommerce") {
    if (component === "PDF") {
      return <BigCommerceInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <BigCommerceInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "woocommerce") {
    if (component === "PDF") {
      return <WoocommerceInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <WoocommerceInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "shopify") {
    if (component === "PDF") {
      return <ShopifyInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <ShopifyInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "wefunder") {
    if (component === "PDF") {
      return <WeFunderInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <WeFunderInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "dispense") {
    if (component === "PDF") {
      return <DispenseInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <DispenseInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "jane") {
    if (component === "PDF") {
      return <JaneInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <JaneInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "tymber") {
    if (component === "PDF") {
      return <TymberInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <TymberInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "dutchie-subdomain") {
    if (component === "PDF") {
      return <DutchieSubdomainInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <DutchieSubdomainInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "dutchieplus") {
    if (component === "PDF") {
      return <DutchiePlusInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <DutchiePlusInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "dutchie-iframe") {
    if (component === "PDF") {
      return <DutchieIframeInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <DutchieIframeInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "grassdoor") {
    if (component === "PDF") {
      return <GrassdoorInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <GrassdoorInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "greenrush") {
    if (component === "PDF") {
      return <GreenrushInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <GreenrushInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "lightspeed") {
    if (component === "PDF") {
      return <LightspeedInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <LightspeedInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "meadow") {
    if (component === "PDF") {
      return <MeadowInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <MeadowInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "buddi") {
    if (component === "PDF") {
      return <BuddiInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <BuddiInstructions generatedTag={generatedTag} />;
    }
  } else if (cart === "olla") {
    if (component === "PDF") {
      return <OllaInstructionsPDF generatedTag={generatedTag} />;
    } else if (component === "Instructions") {
      return <OllaInstructions generatedTag={generatedTag} />;
    }
  }
};

const GenTagInstructionsModal = ({ cart, generatedTag }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const platform = GetCartPlatform(cart);
  if (!platform) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Modal
        title={"Cart Instructions for " + platform.label + "."}
        width={"40%"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        centered
        footer={
          <PDFDownloadLink
            document={
              <GetInstructions
                cart={platform.value}
                component={"PDF"}
                generatedTag={generatedTag}
              />
            }
            fileName={`installation instruction.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button type="primary" shape="round" loading={loading}>
                Download PDF
              </Button>
            )}
          </PDFDownloadLink>
        }
      >
        <GetInstructions
          cart={platform.value}
          component={"Instructions"}
          generatedTag={generatedTag}
        />
      </Modal>
      <Button
        shape="round"
        type="primary"
        onClick={() => setIsModalVisible(true)}
      >
        View Instructions
      </Button>
    </React.Fragment>
  );
};

export default GenTagInstructionsModal;
