import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// Ant Design
import {
  Button,
  Col,
  Grid,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";

// Utils
import { isMobileScreen } from "../utils/mobileUtils";
import PublishStatusDisplayModal from "./Modals/PublishStatusDisplayModal.js";
import { useAudienceBuilderContext } from "../context/AudienceBuilderContext";

const { Option } = Select;
const { Title } = Typography;

const FilterBar = props => {
  const {
    refetchAudienceDemographs,
    loadingAudienceDemographs,
    handleSaveAudiencesToApi,
    loadingPublishing,
  } = props;

  const { useBreakpoint } = Grid;

  const {
    selectedAudiences,
    selectedDSP,
    setSelectedDSP,
  } = useAudienceBuilderContext();

  const breakPoint = useBreakpoint();

  const handlePublish = () => {
    if (!selectedDSP) {
      message.warning("Please select a DSP to publish");
      return;
    }
    if (!selectedAudiences || selectedAudiences.length === 0) {
      message.warning("Please select an audience to publish");
      return;
    }
    if (selectedAudiences.some(obj => obj["isPublished"] === true)) {
      message.error("One or more audience is already published");
      return;
    }
    showModal();
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handlePublishStatusDisplayCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <PublishStatusDisplayModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handlePublishStatusDisplayCancel}
        handleSaveAudiencesToApi={handleSaveAudiencesToApi}
        loadingPublishing={loadingPublishing}
        refetchAudienceDemographs={refetchAudienceDemographs}
      />
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <Title level={5}>Audience Library</Title>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={20}
          lg={20}
          xl={17}
          xxl={17}
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Row gutter={14}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} span={8}>
              <Select
                allowClear
                style={{
                  border: "none",
                  width: isMobileScreen(breakPoint) ? "100%" : "100%",
                  marginBottom: isMobileScreen(breakPoint) ? "0.8em" : "0px",
                }}
                onChange={value => {
                  refetchAudienceDemographs({ isArchived: undefined });
                }}
                onSelect={value => {
                  const isArchived = value === "ARCHIVED" ? true : undefined;
                  const isPublished = value === "PUBLISHED" ? true : undefined;

                  refetchAudienceDemographs({ isArchived, isPublished });
                }}
              >
                <Option value="ARCHIVED">Archived</Option>
                <Option value="PUBLISHED">Published</Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} span={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Select
                  style={{
                    width: isMobileScreen(breakPoint) ? "100%" : "100%",
                    marginRight: 0,
                    marginBottom: isMobileScreen(breakPoint) ? "0.8em" : "0px",
                  }} // Adjusted width
                  placeholder="Select an option"
                  onSelect={value => {
                    setSelectedDSP(value);
                  }}
                >
                  <Option value="LIQUIDM">Liquid M</Option>
                </Select>
                <Button
                  type="primary"
                  style={{
                    marginBottom: isMobileScreen(breakPoint) ? "0.8em" : "0px",
                  }}
                  onClick={handlePublish}
                >
                  Publish
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} span={8}>
              <Input.Search
                placeholder={"Search Audience Library"}
                loading={loadingAudienceDemographs}
                style={{ width: isMobileScreen(breakPoint) ? "100%" : "100%" }}
                onSearch={search => {
                  refetchAudienceDemographs({ nameSearch: search });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(FilterBar);
