/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Typography,
  Col,
  Row,
  message,
} from "antd";
import { useAudienceBuilderContext } from "../../context/AudienceBuilderContext";

const { Text } = Typography;

const TypeBasedInput = props => {
  const {
    inputValue,
    setInputValue,
    setMetricValue,
    metric,
    options,
    dataType,
  } = props;

  let minNumber = 0;
  let maxNumber = 0;
  let rangeNumber = null;

  const {
    selectedCheckboxes,
    setSelectedCheckboxes,
    columnCount,
  } = useAudienceBuilderContext();

  const handleInputChange = props => {
    try {
      const { value, providerSubCategory, uniqueId } = props;
      setMetricValue({
        value: value,
        key: providerSubCategory,
        name: metric.name,
        dataType: dataType,
        uniqueId: uniqueId,
      });
    } catch (error) {
      message.error("Error setting metric value");
    }
  };

  const handleChange = (values, providerSubCategory, uniqueId) => {
    setSelectedCheckboxes(values);

    handleInputChange({
      value: values.map(label => ({
        label: label.label,
        value: label.providerDataPoint,
      })),
      providerSubCategory,
      uniqueId,
    });
  };

  const handleCheckboxChange = (
    checked,
    item,
    providerSubCategory,
    uniqueId
  ) => {
    const newValues = checked
      ? [...selectedCheckboxes, item]
      : selectedCheckboxes.filter(
          selectedItem => selectedItem.label !== item.label
        );

    handleChange(newValues, providerSubCategory, uniqueId);
  };

  const renderUI = () => {
    try {
      const { inputCriteria, providerSubCategory } = metric.mappings;
      const uniqueId = metric.uniqueId ? metric.uniqueId : null;

      if (inputCriteria) {
        const { range = null } = inputCriteria;
        rangeNumber = range;
      }

      if (rangeNumber) {
        const { min = 0, max = 100 } = rangeNumber;
        minNumber = min;
        maxNumber = max;
      }

      switch (dataType) {
        case "string":
          return (
            <Input
              placeholder="Enter a value"
              allowClear
              value={inputValue}
              onChange={e => {
                const value = e.target.value;
                setInputValue(value);
                handleInputChange({ value, providerSubCategory, uniqueId });
              }}
            />
          );
        case "date":
          return (
            <DatePicker
              value={inputValue}
              format="YYYY/MM/DD"
              style={{ width: "100%" }}
              onChange={e => {
                const value = e ? e.format("YYYY/MM/DD") : null;
                handleInputChange({
                  value,
                  providerSubCategory,
                  uniqueId,
                });
                if (e) {
                  setInputValue(e);
                }
              }}
            />
          );
        case "boolean":
          return (
            <Checkbox
              checked={inputValue}
              defaultChecked={false}
              onChange={v => {
                const value = v.target.checked;
                setInputValue(value);
                handleInputChange({
                  value: value ? 1 : 0,
                  providerSubCategory,
                  uniqueId,
                });
              }}
            />
          );
        case "number":
          return (
            <>
              <InputNumber
                placeholder={`Enter a value between ${minNumber} and ${maxNumber}`}
                min={minNumber}
                max={maxNumber}
                style={{ width: "100%" }}
                value={inputValue}
                onChange={value => {
                  if (value > maxNumber) {
                    message.error(`Value cannot be greater than ${maxNumber}`);
                  } else if (value < minNumber) {
                    message.error(`Value cannot be less than ${minNumber}`);
                  } else {
                    setInputValue(value);
                    handleInputChange({ value, providerSubCategory, uniqueId });
                  }
                }}
              />
              {(minNumber !== undefined || maxNumber !== undefined) && (
                <div style={{ marginTop: 4 }}>
                  {minNumber !== undefined && (
                    <Text type="secondary" style={{ marginRight: 8 }}>
                      Min: {minNumber}
                    </Text>
                  )}
                  {maxNumber !== undefined && (
                    <Text type="secondary">Max: {maxNumber}</Text>
                  )}
                </div>
              )}
            </>
          );
        case "checkbox":
          return (
            <div>
              {options.reduce((result, item, index) => {
                const columnSpan = columnCount === 2 ? 12 : 24;
                const column = (
                  <Col
                    span={columnSpan}
                    key={item.label}
                    styles={{ marginRight: "0.1em" }}
                  >
                    <Checkbox
                      checked={selectedCheckboxes.some(
                        selectedItem => selectedItem.label === item.label
                      )}
                      onChange={e =>
                        handleCheckboxChange(
                          e.target.checked,
                          item,
                          providerSubCategory,
                          uniqueId
                        )
                      }
                    >
                      {item.label}
                    </Checkbox>
                  </Col>
                );

                if (columnCount === 2) {
                  if (index % 2 === 0) {
                    result.push(<Row key={index}>{[column]}</Row>);
                  } else {
                    result[result.length - 1].props.children.push(column);
                  }
                } else {
                  result.push(<Row key={index}>{[column]}</Row>);
                }

                return result;
              }, [])}
            </div>
          );
        default:
          let formattedValue = [];

          if (inputValue && !Array.isArray(inputValue)) {
            formattedValue = [inputValue];
          } else if (inputValue === undefined || inputValue === null) {
            formattedValue = [];
          } else {
            formattedValue = inputValue;
          }

          return (
            <Select
              mode={uniqueId ? "single" : "multiple"}
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              value={formattedValue}
              placeholder="Please select"
              onChange={(value, option) => {
                setInputValue(value);
                handleInputChange({
                  value: option,
                  providerSubCategory,
                  uniqueId,
                });
              }}
              options={options.map(option => {
                return { label: option.label, value: option.providerDataPoint };
              })}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          );
      }
    } catch (err) {
      console.log(err);
      return <></>;
    }
  };

  return renderUI();
};

export default TypeBasedInput;
