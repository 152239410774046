import React from "react";
import { useState } from "react";
import { Row, Col, Space, Select } from "antd";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import {
  FormOutlined,
  ShareAltOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import EditorialTable from "./components/EditorialTable";
import { ButtonText } from "../../shared/globalStyling/styledText";
import Button from "../../shared/globalStyling/Button";
import LaunchEditorial from "./components/LaunchEditorial";
import ShareEditorial from "./components/ShareEditorial";
import FilterBar from "./components/FilterBar";

const EditorialMainView = ({
  history,
  data,
  location,
  current,
  total,
  pageSize,
  tags,
  tag,
  isSelfService,
  onCreateEditorial,
  onShareEditorial,
  orgsSharing,
  publishers,
  selectedPublisher,
  currentOrgId,
  getFilteredOrgsRow,
}) => {
  const [openLaunch, setOpenLaunch] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const onRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  return (
    <React.Fragment>
      <LaunchEditorial
        tags={tags}
        open={openLaunch}
        onCreate={values => {
          setOpenLaunch(false);
          onCreateEditorial(values);
        }}
        onCancel={() => {
          setOpenLaunch(false);
        }}
        currentOrgId={currentOrgId}
      />
      <ShareEditorial
        open={openShare}
        orgs={orgsSharing}
        selectedRows={selectedRows}
        getFilteredOrgsRow={getFilteredOrgsRow}
        onCancel={() => {
          setOpenShare(false);
        }}
        onCreate={values => {
          setOpenShare(false);
          onShareEditorial(values);
          setSelectedRows([]);
          setSelectedRowKeys([]);
        }}
      />
      <PageHeaderLayout
        title={"Publisher"}
        titleIcon={
          <ReadOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        titleExtra={
          <Select
            defaultValue={"All Publisher"}
            style={{ width: "200px" }}
            onChange={val => {
              const params = new URLSearchParams(location.search);
              if (params.get("publisher")) params.delete("publisher");
              if (val !== "All Publisher") params.append("publisher", val);

              history.push(`/editorial/main/1?${params.toString()}`);
            }}
            value={selectedPublisher ? selectedPublisher : "All Publisher"}
          >
            <Select.Option key="all" value="All Publisher">
              All Publisher
            </Select.Option>
            {publishers.map(publisher => {
              return (
                <Select.Option key={publisher.id} value={publisher.id}>
                  {publisher.name}
                </Select.Option>
              );
            })}
          </Select>
        }
        rightContent={
          <Row>
            <Col className="ant-row-flex-column ant-row-align-items-center">
              {isSelfService && (
                <Button
                  onClick={() => {
                    setOpenLaunch(!openLaunch);
                  }}
                  type="primary"
                  ghost
                  icon={<FormOutlined />}
                >
                  <ButtonText text={"Add Funnel"} />
                </Button>
              )}
            </Col>
          </Row>
        }
      />
      <PageContentLayout>
        <PageHeaderLayout
          title={"Publisher Funnels"}
          rightContent={
            <Row align="middle" justify="end">
              <Col flex="auto">
                <Space>
                  <Button
                    icon={<ShareAltOutlined />}
                    style={{
                      fontSize: "14px",
                      width: "175px",
                      marginRight: "10px",
                      height: "36px",
                      margin: "12px 10px 12px 0",
                    }}
                    shape="round"
                    type="primary"
                    disabled={selectedRowKeys.length === 0}
                    ghost
                    onClick={() => {
                      setOpenShare(!openShare);
                    }}
                  >
                    Share Editorial
                  </Button>
                </Space>
              </Col>
            </Row>
          }
        />
        <FilterBar />
        <EditorialTable
          data={data}
          history={history}
          selectedRowKeys={selectedRowKeys}
          onRowChange={onRowChange}
          current={current}
          location={location}
          pageSize={pageSize}
          total={total}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(EditorialMainView);
