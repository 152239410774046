import { InfoCircleOutlined } from "@ant-design/icons";
import { Collapse, Divider, Tag, Typography, Modal, Image } from "antd";
import React from "react";
import seo1 from "../../../../../../admin/assets/docs/seo/1-seo.png";
import seo2 from "../../../../../../admin/assets/docs/seo/2-seo.png";
import seo3 from "../../../../../../admin/assets/docs/seo/3-seo.png";
import seo4 from "../../../../../../admin/assets/docs/seo/4-seo.png";
import seo5 from "../../../../../../admin/assets/docs/seo/5-seo.png";
import seo6 from "../../../../../../admin/assets/docs/seo/6-seo.png";
import seo7 from "../../../../../../admin/assets/docs/seo/7-seo.png";
import seo8 from "../../../../../../admin/assets/docs/seo/8-seo.png";
import seo9 from "../../../../../../admin/assets/docs/seo/9-seo.png";
import seo10 from "../../../../../../admin/assets/docs/seo/10-seo.png";
import seo11 from "../../../../../../admin/assets/docs/seo/11-seo.png";
import seo12 from "../../../../../../admin/assets/docs/seo/12-seo.png";
import seo13 from "../../../../../../admin/assets/docs/seo/13-seo.png";
import seo15 from "../../../../../../admin/assets/docs/seo/14-seo.png";
import seo16 from "../../../../../../admin/assets/docs/seo/16-seo.png";
import seo17 from "../../../../../../admin/assets/docs/seo/17-seo.png";
import seo18 from "../../../../../../admin/assets/docs/seo/18-seo.png";

const SeoOnboardingDocs = () => {
  const [isOpen, setIsDocOpen] = React.useState(false);

  const toggleDocs = () => setIsDocOpen(prev => !prev);
  return (
    <>
      <Tag
        style={{ cursor: "pointer", borderRadius: "100px" }}
        color="blue"
        onClick={toggleDocs}
      >
        <InfoCircleOutlined /> onboarding
      </Tag>

      <Modal
        width={1000}
        title={"What is Google Search Console?"}
        handleClick={toggleDocs}
        open={isOpen}
        onCancel={toggleDocs}
        visible={isOpen}
        onOk={toggleDocs}
        centered
        footer={null}
      >
        <p>
          Google Search Console measures how searchable your site is in a
          numerical and a tabular format.
        </p>

        <Divider orientation="left">Step 1</Divider>
        <Collapse size="small">
          <Collapse.Panel header="Step 1 (A): Onboarding SEO via Google Analytics">
            <ol>
              <li>
                Set up Google Analytics GA4 access{" "}
                <Typography.Link
                  href="https://analytics.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://analytics.google.com/
                </Typography.Link>
                <ol>
                  <li>
                    Navigate to the “Settings” page and you should see something
                    like this
                    <Image
                      src={seo1}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                  <li>
                    Click on “Account access management” and add the
                    “seosemadministrator@mediajel.com” email account and provide
                    the “Administrator” account role.
                    <Image
                      src={seo2}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Set up GA4 on the Mediajel Dashboard.
                <ol>
                  <li>
                    Log in to https://mediajel.io/ and navigate to the Org
                    Config section{" "}
                    <Typography.Link href="/settings/main/1">
                      settings page
                    </Typography.Link>
                  </li>
                  <li>
                    Then provide your GA4 ID in the “Google Anlaytics” section
                    then click on the “Update” button below.
                    <Image
                      src={seo3}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                  <li>
                    Note: — Your GA4 ID is visible in Google Analytics Admin
                    section under “Property Details”. It’s called PROPERTY ID.
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo4}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel header="Step 1 (B): Onboarding Google Search Console (For existing clients)">
            <p>
              If domain verification is already completed for the client you are
              onboarding to Google Search Console. Then you’ll need to
              accomplish the following steps:
            </p>
            <ol>
              <li>Navigate to the Google Search Console</li>
              <li>
                Navigate to the site you are onboarding to the dashboard for SEO
              </li>
              <li>Click on “Settings” on the bottom left</li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo5}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>Tap on “Users and permissions”</li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo6}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>Click on “Add User”</li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo7}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>
                Then add “seosemadministrator@mediajel.com” and set “Permission”
                to “Owner” and click on “Add
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo8}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel header="Step 1 (C): Onboarding Google Search Console (For new clients)">
            <p>
              If domain verification has not yet been implemented, these are the
              steps to onboard Google Search SEO.
            </p>
            <ol>
              <li>
                Go to Google Search Console.{" "}
                <Typography.Link
                  href="https://search.google.com/search-console/welcome"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  https://search.google.com/search-console/welcome{" "}
                </Typography.Link>
              </li>
              <li>Login to admin@integrations.thesmservices.com</li>
              <li>
                On the URL Prefix option, input the URL of the site you want to
                add in a format similar to "https://www.yourwebsite.com"
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo9}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>Click on Continue.</li>
              <li>Find the “HTML tag” Verification option</li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo10}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>
                Copy the “meta tag” which would look something like:{" "}
                <pre>
                  {" "}
                  &lt;meta name="google-site-verification"
                  content="vHX_XXXXXXXXXXXXXX" /&gt;{" "}
                </pre>
              </li>
              <li>
                Paste it within the <pre>&lt;head&gt;&lt;/head&gt;</pre> section
                of the site you are onboarding to verify the domain. Ensure that
                the tag applies to all pages of the domain.
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel header="Steps to add the meta tag to “Wordpress”">
            <ol>
              <li>
                Find the “Add a meta tag to your site's home page”, copy the
                meta tag.
              </li>
              <li>Login to the Website Manager. (e.g. Wordpress/Wix)</li>
              <li>
                Go to Snippets → Add New (Make sure that the "Snippets" Plugin
                is installed)
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo11}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>Paste the meta tag.</li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo12}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>
                Scroll down to the bottom of the screen. Choose the Scope for
                your snippet. Click Save Changes and Activate.
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo13}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>Go back to Google Search Console and click Verify.</li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Divider orientation="left">Step 2</Divider>
        <Collapse size="small">
          <Collapse.Panel header="Step 2: Onboarding to the dashboard">
            <ol>
              <li>
                Log in to Mediajel with the account that you want to set it up
                with.
              </li>
              <li>
                On the sidebar menu go to {">"} Admin {">"} Settings
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo15}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>
                Scroll down to the Data Settings section on the bottom of the
                page. Then provide your GA4 ID in the “Google Analytics” section
                then click on the “Update” button below. Make sure to toggle GA4
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo16}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>
                Note: — Your GA4 ID is visible in Google Analytics Admin section
                under “Property Details”. It’s called PROPERTY ID.
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo17}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
              <li>
                Make sure the siteUrl value in the image above is the same as
                the Website domain rankings on the Org Config section.
              </li>
              <li style={{ listStyleType: "none" }}>
                <Image
                  src={seo18}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                />
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
      </Modal>
    </>
  );
};

export default SeoOnboardingDocs;
