import { Card, Form, Row, Col, Input, Select, Spin, message } from "antd";
import { cardMargin, colPadding } from "../../../shared/globalStyling/style";
import React, { useState } from "react";
import SingleImageUploader from "../../../shared/images/SingleImageUploader";
import { MenuItemTextColor } from "../../../shared/globalStyling/styledText";
import {
  defaultRoasFormat,
  roasFormat as roasFormatOptions,
} from "../../../../core/utils/constants/constants";
import GlobalButton from "../../../shared/globalStyling/Button";

const { Option } = Select;
const OrgDetailSettings = ({
  name = "", //Org name to be displayed and modified on input
  description = "", //Org description to be displayed and modified on input
  website = "", //Org website to be displayed and modified on input
  timezone = "UTC", //Org timezone to be displayed and modified on input
  roasFormat = defaultRoasFormat.key, //Org roas format to be displayed and modified on input
  logo, //Org logo to be displayed and modified on input
  onSubmit = () => {}, //Function to be called when the form is submitted
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false); //Set true to show spinner for this card

  return (
    <Card style={{ ...cardMargin }} title={"Details Settings"}>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          name={"orgDetailSettingsForm"}
          onFinish={async values => {
            setIsLoading(true);
            try {
              await onSubmit(values);
              message.success("Organization Details Updated Successfully");
            } catch (err) {
              message.error("Error updating organization data");
            }
            setIsLoading(false);
          }}
          initialValues={{
            name,
            description,
            website,
            zone: timezone,
            roasFormat,
          }}
        >
          <Form.Item name="imageKey" hidden={true} />
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col xs={24} lg={4}>
              <Row align="middle" justify="center">
                <Form.Item name="logo">
                  <SingleImageUploader
                    statekey="logo"
                    image={logo} //Due to the nature of the SingleImageUploader, we need to pass the image as a prop
                    onChange={(key, value) => {
                      //Single Image Uploader on change value is different than the forms expected output so we need to manually set the value
                      form.setFieldsValue({
                        [key]: value.id,
                      });
                      //Hidden value for image key. This is used to update the logo on the sidebar
                      form.setFieldsValue({
                        [`imageKey`]: value.key,
                      });
                    }}
                    isSubmitted={true}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col xs={24} lg={20} style={colPadding}>
              <Row type="flex" style={{ paddingBottom: "25px" }}>
                <Col xs={24} xl={20}>
                  <Form.Item label="Org Name" name="name">
                    <Input placeholder="Org name" />
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <Input.TextArea placeholder="Description about the organization" />
                  </Form.Item>
                  <Row
                    type="flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Col xs={24} xl={11}>
                      <Form.Item label="Website" name="website">
                        <Input placeholder="Name of Website" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={11}>
                      <Form.Item label="Timezone" name="zone">
                        <Input placeholder="UTC" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    type="flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Col xs={24} xl={11}>
                      <Form.Item label="Roas Format" name="roasFormat">
                        <Select>
                          {roasFormatOptions.map(option => {
                            return (
                              <Option key={option.key} value={option.key}>
                                <MenuItemTextColor text={option.name} />
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col style={{ textAlign: "right" }} xs={24}>
            <GlobalButton htmlType="submit" type="primary">
              Update
            </GlobalButton>
          </Col>
        </Form>
      </Spin>
    </Card>
  );
};

export default OrgDetailSettings;
