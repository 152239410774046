import React from "react";
import { withRouter } from "react-router-dom";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card, Row, Space, Table, Tabs, Tag, Typography, message } from "antd";
import {
  formatNumber,
  formatWholeNumber,
} from "../../../../core/utils/campaigns";
import moment from "moment";
import { CodeOutlined, CopyOutlined } from "@ant-design/icons";
import CreateImpressionsTag from "./components/CreateImpressionsTag";
import CreateMeasurementTags from "./components/CreateMeasurementTags";
import CreatePublisherDomainTag from "./components/CreatePublsiherDomainTag";
const { Text } = Typography;

const SignalTagsMainView = ({
  publishersTags,
  measurementsTags,
  impressionsTags,
  onImressionsTagCreate,
  history,
  refetch,
}) => {
  const publisherColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
      render: text => {
        return formatWholeNumber(text);
      },
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => {
        return (
          <Space direction="horizontal">
            {text}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(text);
                message.info("Tag ID copied to clipboard");
              }}
            />
          </Space>
        );
      },
    },
  ];

  const measurementColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
    },
    {
      title: "Funnels",
      dataIndex: "funnels",
      key: "funnels",
    },
    {
      title: "Measured Events",
      dataIndex: "events",
      key: "events",
      render: value => {
        const events = value.map((e, index) => {
          const { event, value, date } = e;
          return (
            <Tag key={index} color="success">
              <Space direction="vertical" size={0}>
                <Text type="success" strong>
                  {formatNumber(value)}
                </Text>
                <Text type="success" strong>
                  {event}
                </Text>
                <Text type="success" disabled>
                  {moment(date).format("YYYY/MM/DD HH:mm:ss")}
                </Text>
              </Space>
            </Tag>
          );
        });

        return <Row>{events}</Row>;
      },
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => {
        return (
          <Space direction="horizontal">
            {text}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(text);
                message.info("Tag ID copied to clipboard");
              }}
            />
          </Space>
        );
      },
    },
  ];

  const impressionColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Platform",
      dataIndex: "dsp",
      key: "dsp",
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => {
        return (
          <Space direction="horizontal">
            {text}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(text);
                message.info("Tag ID copied to clipboard");
              }}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Signal Tags"}
        titleIcon={
          <CodeOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        <Tabs>
          <Tabs.TabPane tab="Publisher Domain Tags" key="domain">
            <Card
              title={"Publisher Domain Tags"}
              extra={
                <Space direction="horizontal">
                  <CreatePublisherDomainTag />
                </Space>
              }
            >
              <Table columns={publisherColumn} dataSource={publishersTags} />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Impression Tags" key="impressions">
            <Card
              title={"Impressions Tags"}
              extra={
                <Space direction="horizontal">
                  <CreateImpressionsTag
                    onSubmit={onImressionsTagCreate}
                    refetch={refetch}
                  />
                </Space>
              }
            >
              <Table columns={impressionColumn} dataSource={impressionsTags} />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Measurement Tags" key="measurement">
            <Card
              title="Measurement Tags"
              extra={
                <Space direction="horizontal">
                  <CreateMeasurementTags />
                </Space>
              }
            >
              <Table
                columns={measurementColumn}
                dataSource={measurementsTags}
                onRow={data => ({
                  onClick: () => {
                    const { id } = data;
                    history.push(`/tags/details/${id}`);
                  },
                })}
              />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(SignalTagsMainView);
