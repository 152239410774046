import React, { useEffect, useMemo, useState } from "react";
import {
  columnsObj,
  defaultColumnKeys,
} from "../../../../platform/ux/Home/components/PacingTab/utils";
import { PacingDataObjectOrderByInput } from "../../../GraphQl/Queries/GET_PACING_DATA_CONNECTION";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import {
  TABLE_ORDER_ASC,
  TABLE_ORDER_DESC,
} from "../../../utils/constants/constants";

function toTableData(edges) {
  return edges.map(i => {
    const p = i.node;
    const campaignOrder = i.node.campaignOrder;
    const campaignOrderOverallData = campaignOrder.aggregateData.overallData;
    return {
      key: i.cursor,
      id: campaignOrder.id,
      actualImpressions: campaignOrderOverallData.aggImpressions,
      daysIntoFlight: p.daysIntoFlight,
      impressionsLeft: p.impressionsLeft,
      pace: campaignOrder.cpm > 0 ? p.pace : null,
      paceYesterday: campaignOrder.cpm > 0 ? p.paceYesterday : null,
      paceTwoDaysAgo: campaignOrder.cpm > 0 ? p.paceTwoDaysAgo : null,
      paceThreeDaysAgo: campaignOrder.cpm > 0 ? p.paceThreeDaysAgo : null,
      paceFourDaysAgo: campaignOrder.cpm > 0 ? p.paceFourDaysAgo : null,
      paceFiveDaysAgo: campaignOrder.cpm > 0 ? p.paceFiveDaysAgo : null,
      paceSixDaysAgo: campaignOrder.cpm > 0 ? p.paceSixDaysAgo : null,
      paceSevenDaysAgo: campaignOrder.cpm > 0 ? p.paceSevenDaysAgo : null,
      dailyAvgImpressions: p.dailyAvgImpressions,
      dailyAvgImpressionsLeft: p.dailyAvgImpressionsLeft,
      daysLeft: p.daysLeft,
      name: campaignOrder.name,
      cpm: campaignOrder.cpm,
      dsp: campaignOrder.demandSidePlatform,
      budget: campaignOrder.budget,
      impressionsYesterday: p.impressionsYesterday,
      expectedImpressions: p.expectedImpressions,
      roas: p.roas,
      ctr: p.ctr,
      status: campaignOrder.status,
      attributionWindow: campaignOrder.attributionWindow,
    };
  });
}

const PacingController = props => {
  //TODO transform data to have the computation

  const {
    skip,
    setSkip,
    first,
    setFirst,
    getExport,
    loadingExport,
    exportData,
    setOrderBy,
  } = props;

  let tableData = [];
  const [selectedCIDs, setSelectedCIDs] = useState([]);
  const [selectedDSP, setSelectedDSP] = useState();
  const [activeColumns, setActiveColumns] = useState(defaultColumnKeys);
  const { data, isLoading, filterOptions, refetch } = props;
  const [pagination, setPagination] = useState({
    current: skip / first < 1 ? 1 : skip / first,
    pageSize: first,
  });

  const userPermission = determineUserAccess(props.currentUser.permission);

  if (data && data.pacingDataObjectsConnection.edges) {
    const edges = data.pacingDataObjectsConnection.edges;
    tableData = toTableData(edges);
  }

  const total = useMemo(() => {
    return data && data.total ? data.total.aggregate.count : 0;
  }, [data]);

  const campaignDataOptions = useMemo(() => {
    if (
      filterOptions &&
      filterOptions.campaignOrdersConnection &&
      filterOptions.campaignOrdersConnection.edges
    ) {
      const edges = filterOptions.campaignOrdersConnection.edges;
      const options = edges.map(e => ({
        id: e.node.id,
        name: e.node.name,
      }));

      // Filter options to only include those present in tableData
      return options.filter(option =>
        tableData.some(data => data.name === option.name)
      );
    }
    return [];
  }, [filterOptions, tableData]);

  const dspDataOptions = useMemo(() => {
    if (filterOptions && filterOptions.getPacingReportFilterOptions) {
      return filterOptions.getPacingReportFilterOptions.dsps;
    }
    return [];
  }, [filterOptions]);

  const onExport = () => {
    getExport();
  };

  const exportTableData = useMemo(() => {
    if (exportData && exportData.pacingDataObjectsConnection) {
      return toTableData(exportData.pacingDataObjectsConnection.edges);
    }
  }, [exportData]);

  const handleSorterChange = (key, order) => {
    if (!order) {
      // default
      setOrderBy(PacingDataObjectOrderByInput.daysLeft_DESC);
    }

    if (key === columnsObj.name.key) {
      if (order === TABLE_ORDER_ASC) {
        setOrderBy(PacingDataObjectOrderByInput.campaignName_ASC);
      } else if (order === TABLE_ORDER_DESC) {
        setOrderBy(PacingDataObjectOrderByInput.campaignName_DESC);
      }
    }

    if (key === columnsObj.daysLeft.key) {
      if (order === TABLE_ORDER_ASC) {
        setOrderBy(PacingDataObjectOrderByInput.daysLeft_ASC);
      } else if (order === TABLE_ORDER_DESC) {
        setOrderBy(PacingDataObjectOrderByInput.daysLeft_DESC);
      }
    }
    if (key === columnsObj.pace.key) {
      if (order === TABLE_ORDER_ASC) {
        setOrderBy(PacingDataObjectOrderByInput.pace_ASC);
      } else if (order === TABLE_ORDER_DESC) {
        setOrderBy(PacingDataObjectOrderByInput.pace_DESC);
      }
    }
  };

  useEffect(() => {
    setFirst(pagination.pageSize);
    setSkip(pagination.current * pagination.pageSize - pagination.pageSize);
  }, [pagination, setSkip, setFirst]);

  useEffect(() => {
    refetch({
      first: pagination.pageSize,
      skip: pagination.current * pagination.pageSize - pagination.pageSize,
      campaignOrderIds: selectedCIDs.length > 0 ? selectedCIDs : undefined,
    });
  }, [pagination, refetch, selectedCIDs]);

  return React.cloneElement(props.children, {
    tableData,
    isLoading,
    currentPagination: pagination,
    total,
    setPagination,
    onExport,
    dspDataOptions,
    campaignDataOptions,
    selectedCIDs,
    setSelectedCIDs,
    selectedDSP,
    setSelectedDSP,
    activeColumns,
    loadingExport,
    exportTableData,
    setActiveColumns,
    userPermission,
    handleSorterChange,
    refetch,
  });
};

export default PacingController;
