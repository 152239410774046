import React, { useState } from "react";
import {
  Card,
  Table,
  DatePicker,
  Row,
  Tabs,
  Spin,
  Col,
  Menu,
  Dropdown,
  Checkbox,
  Modal,
  Space,
} from "antd";
import Button from "../../../../shared/globalStyling/Button";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import {
  MenuItemText,
  ButtonText,
} from "../../../../shared/globalStyling/styledText";
import { withRouter } from "react-router-dom";
import ExportCSVButton from "../../components/PaidSearchAnalytics/ExportCSVButton";
import {
  formatWholeNumber,
  formatCurrency2SigFig,
  formatNumber2SigFig,
  computeCTR,
  computeCPC,
  computePacing,
  computeDailyRemainingSpend,
} from "../../../../../core/utils/campaigns";
import moment from "moment";
import ExportExcelButton from "../../components/PaidSearchAnalytics/ExportExcelButton";

const DropDownStatusToggle = withRouter(({ location, history }) => {
  const statuses = [
    { text: "Active", key: "ACTIVE" },
    { text: "Inactive", key: "INACTIVE" },
    { text: "No Access", key: "NO_ACCESS" },
  ];

  const params = new URLSearchParams(location.search);
  const selectedStatus = params.get("status");
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu selectedKeys={[selectedStatus]}>
          {statuses.map((status, i) => {
            const isSelected = selectedStatus === status.key;
            return (
              <Menu.Item
                key={`${status.text}-${i}`}
                onClick={() => {
                  if (selectedStatus) params.delete("status");
                  if (!isSelected) params.append("status", status.key);
                  history.push(
                    `/home/analytics/paid-search?${params.toString()}`
                  );
                }}
              >
                <Checkbox
                  checked={isSelected}
                  style={{ visibility: isSelected ? "visible" : "hidden" }}
                />
                <MenuItemText text={status.text} />
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <Button
        style={{
          width: "100%",
        }}
        shape="round"
        type={!!selectedStatus ? "primary" : "default"}
        ghost={!!selectedStatus ? true : false}
      >
        <ButtonText text={"Status"} />
        <DownOutlined color="black" />
      </Button>
    </Dropdown>
  );
});

const CampaignsModal = ({ pacingCampaignsData, currencyFormat }) => {
  const data =
    pacingCampaignsData && pacingCampaignsData.getPaidSearchPacingCampaigns
      ? pacingCampaignsData.getPaidSearchPacingCampaigns
      : null;
  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
      width: "12%",
      render: text => {
        return text;
      },
    },
    {
      title: "Day of Month",
      dataIndex: "dayOfMonth",
      key: "dayOfMonth",
      width: "8%",
      render: text => {
        return formatWholeNumber(
          text
            ? text
            : moment()
                .utc()
                .date()
        );
      },
    },
    {
      title: "Day in Month",
      dataIndex: "dayInMonth",
      key: "dayInMonth",
      width: "8%",
      render: text => {
        return formatWholeNumber(
          text
            ? text
            : moment()
                .utc()
                .daysInMonth()
        );
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: "7%",
      render: text => {
        return formatWholeNumber(text ? text : 0);
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: "7%",
      render: text => {
        return formatWholeNumber(text ? text : 0);
      },
    },
    {
      title: "Budget",
      dataIndex: "monthlyBudget",
      key: "monthlyBudget",
      width: "7%",
      render: text => {
        return formatCurrency2SigFig(text ? text : 0, currencyFormat);
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: "7%",
      render: text => {
        return formatCurrency2SigFig(text ? text : 0, currencyFormat);
      },
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
      width: "7%",
      render: text => {
        return `${formatNumber2SigFig(text ? text : 0)}%`;
      },
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      width: "7%",
      render: text => {
        return `${formatNumber2SigFig(text ? text : 0)}%`;
      },
    },
    {
      title: "Target Pacing",
      dataIndex: "targetPacing",
      key: "targetPacing",
      width: "8%",
      render: text => {
        return `${formatNumber2SigFig(text ? text : 0)}%`;
      },
    },
    {
      title: "Pacing",
      dataIndex: "pace",
      key: "pace",
      width: "7%",
      render: text => {
        return `${formatNumber2SigFig(text ? text : 0)}%`;
      },
    },
    {
      title: "Daily Remaining Spend",
      dataIndex: "dailyRemainSpend",
      key: "dailyRemainSpend",
      width: "11%",
      render: text => {
        return formatCurrency2SigFig(text ? text : 0, currencyFormat);
      },
    },
  ];
  return (
    <Tabs>
      {data &&
        data.length >= 1 &&
        data.map(p => {
          const { name, campaigns } = p;
          return (
            <Tabs.TabPane tab={name} key={name}>
              <Table
                scroll={{ x: 1700, y: 400 }}
                dataSource={campaigns}
                columns={columns}
                rowKey={record => record.id}
                summary={pacingData => {
                  const aggregatedPage = pacingData.reduce(
                    (acc, curr) => {
                      const {
                        clicks,
                        impressions,
                        spend,
                        monthlyBudget,
                      } = curr;
                      acc.impressions += impressions;
                      acc.clicks += clicks;
                      acc.budget += monthlyBudget ? monthlyBudget : 0;
                      acc.spend += spend;

                      return acc;
                    },
                    { impressions: 0, clicks: 0, budget: 0, spend: 0 }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          {formatWholeNumber(aggregatedPage.impressions)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          {formatWholeNumber(aggregatedPage.clicks)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          {formatCurrency2SigFig(
                            aggregatedPage.budget,
                            currencyFormat
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          {formatCurrency2SigFig(
                            aggregatedPage.spend,
                            currencyFormat
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          {formatNumber2SigFig(
                            computeCTR(
                              aggregatedPage.clicks,
                              aggregatedPage.impressions
                            )
                          )}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          {formatNumber2SigFig(
                            computeCPC(
                              aggregatedPage.spend,
                              aggregatedPage.clicks
                            )
                          )}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>0%</Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>
                          {formatNumber2SigFig(
                            computePacing(
                              aggregatedPage.spend,
                              aggregatedPage.budget
                            )
                          )}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10}>
                          {formatCurrency2SigFig(
                            computeDailyRemainingSpend(
                              aggregatedPage.spend,
                              aggregatedPage.budget
                            ),
                            currencyFormat
                          )}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Tabs.TabPane>
          );
        })}
    </Tabs>
  );
};

const PacingReport = ({
  pacingReport,
  pacingAllCampaignsData,
  pacingSingleCampaignsData,
  generateReport,
  orgConfig,
  history,
  location,
  pacingGoogleReport,
  pacingBingReport,
  getPacing,
  getPacingGoogle,
  getPacingBing,
  getPacingAllCampaigns,
  getSingleCampaigns,
  pacingLoading,
  pacingGoogleLoading,
  pacingBingLoading,
  pacingAllCampaignsLoading,
  pacingSingleCampaignsLoading,
  currencyFormat,
}) => {
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [isModalOpenSingle, setIsModelOpenSingle] = useState(false);
  const columns = [
    {
      title: "Advertiser",
      dataIndex: "org",
      key: "org.name",
      width: "11%",
      render: text => {
        return text.name;
      },
    },
    {
      title: "Day of Month",
      dataIndex: "overallPacingData",
      key: "overallPacingData.dayOfMonth",
      width: "7%",
      render: text => {
        return formatWholeNumber(
          text
            ? text.dayOfMonth
            : moment()
                .utc()
                .date()
        );
      },
    },
    {
      title: "Day in Month",
      dataIndex: "overallPacingData",
      key: "overallPacingData.dayInMonth",
      width: "7%",
      render: text => {
        return formatWholeNumber(
          text
            ? text.dayInMonth
            : moment()
                .utc()
                .daysInMonth()
        );
      },
    },
    {
      title: "Impressions",
      dataIndex: "overallPacingData",
      key: "overallPacingData.impressions",
      width: "6%",
      render: text => {
        return formatWholeNumber(text ? text.impressions : 0);
      },
    },
    {
      title: "Clicks",
      dataIndex: "overallPacingData",
      key: "overallPacingData.clicks",
      width: "6%",
      render: text => {
        return formatWholeNumber(text ? text.clicks : 0);
      },
    },
    {
      title: "Budget",
      dataIndex: "config",
      key: "config.monthlyBudget",
      width: "6%",
      render: text => {
        return formatCurrency2SigFig(
          text ? text.monthlyBudget : 0,
          currencyFormat
        );
      },
    },
    {
      title: "Spend",
      dataIndex: "overallPacingData",
      key: "overallPacingData.spend",
      width: "6%",
      render: text => {
        return formatCurrency2SigFig(text ? text.spend : 0, currencyFormat);
      },
    },
    {
      title: "CTR",
      dataIndex: "overallPacingData",
      key: "overallPacingData.ctr",
      width: "6%",
      render: text => {
        return `${formatNumber2SigFig(text ? text.ctr : 0)}%`;
      },
    },
    {
      title: "CPC",
      dataIndex: "overallPacingData",
      key: "overallPacingData.cpc",
      width: "6%",
      render: text => {
        return `${formatNumber2SigFig(text ? text.cpc : 0)}%`;
      },
    },
    {
      title: "Target Pacing",
      dataIndex: "overallPacingData",
      key: "overallPacingData.targetPacing",
      width: "7%",
      render: text => {
        return `${formatNumber2SigFig(text ? text.targetPacing : 0)}%`;
      },
    },
    {
      title: "Pacing",
      dataIndex: "overallPacingData",
      key: "overallPacingData.pace",
      width: "6%",
      render: text => {
        return `${formatNumber2SigFig(text ? text.pace : 0)}%`;
      },
    },
    {
      title: "Daily Remaining Spend",
      dataIndex: "overallPacingData",
      key: "overallPacingData.dailyRemainSpend",
      width: "11%",
      render: text => {
        return formatCurrency2SigFig(
          text ? text.dailyRemainSpend : 0,
          currencyFormat
        );
      },
    },
    {
      dataIndex: "org",
      key: "org.id",
      width: "11%",
      render: record => {
        return (
          <Button
            icon={<MenuOutlined />}
            type="primary"
            onClick={() => {
              const searchParams = new URLSearchParams(location.search);
              const selectedPacingDate = searchParams.get("pacingDate");
              const status = searchParams.get("status");
              getSingleCampaigns({
                variables: {
                  orgId: record.id,
                  advertiserIds: [record.id],
                  date: selectedPacingDate,
                  status: status,
                },
              });
              setIsModelOpenSingle(true);
            }}
          >
            <ButtonText text={"View Campaigns"} />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card
        extra={
          <Space className="ant-space-md-horizontal ant-space-xs-vertical">
            <Button
              icon={<MenuOutlined />}
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                getPacingAllCampaigns();
                setIsModelOpen(!isModalOpen);
              }}
            >
              <ButtonText text={"View All Campaigns"} />
            </Button>
            {orgConfig.exportData && (
              <ExportCSVButton orgName={""} generateReport={generateReport} />
            )}
            {orgConfig.exportData && (
              <ExportExcelButton orgName={""} generateReport={generateReport} />
            )}
          </Space>
        }
      >
        <Modal
          title={"Campaigns"}
          visible={isModalOpen}
          onOk={() => {
            setIsModelOpen(false);
          }}
          onCancel={() => {
            setIsModelOpen(false);
          }}
          width={1500}
          footer={null}
          centered
        >
          {pacingAllCampaignsLoading && (
            <Row justify="center">
              <Spin />
            </Row>
          )}
          {!pacingAllCampaignsLoading && (
            <CampaignsModal
              pacingCampaignsData={pacingAllCampaignsData}
              currencyFormat={currencyFormat}
            />
          )}
        </Modal>

        <Modal
          title={"Campaigns"}
          visible={isModalOpenSingle}
          onOk={() => {
            setIsModelOpenSingle(false);
          }}
          onCancel={() => {
            setIsModelOpenSingle(false);
          }}
          footer={null}
          width={1500}
          centered
        >
          {pacingSingleCampaignsLoading && (
            <Row justify="center">
              <Spin />
            </Row>
          )}
          {!pacingSingleCampaignsLoading && (
            <CampaignsModal
              pacingCampaignsData={pacingSingleCampaignsData}
              currencyFormat={currencyFormat}
            />
          )}
        </Modal>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={6} lg={5} xl={4}>
            <DropDownStatusToggle />
          </Col>
          <Col
            xs={24}
            md={{ span: 7, offset: 4 }}
            lg={{ span: 6, offset: 5 }}
            xl={{ span: 4, offset: 7 }}
          >
            <DatePicker
              style={{ width: "100%", marginTop: "16px" }}
              onChange={date => {
                const params = new URLSearchParams(window.location.search);
                if (params.get("pacingDate")) params.delete("pacingDate");

                params.append("pacingDate", date.utc().format());
                const pathname = window.location.pathname.toString();
                if (pathname === "/home/analytics/paid-search") {
                  history.push(`${pathname}?${params.toString()}`);
                }
              }}
            />
          </Col>
        </Row>

        <Tabs
          onChange={key => {
            if (key === "pacingOverall") getPacing();
            if (key === "pacingGoogle") getPacingGoogle();
            if (key === "pacingBing") getPacingBing();
          }}
        >
          <Tabs.TabPane tab="Overall" key="pacingOverall">
            {pacingLoading ? (
              <Row justify="center">
                <Spin />
              </Row>
            ) : (
              <Table
                scroll={{ x: 1700, y: 400 }}
                dataSource={pacingReport}
                columns={columns}
                rowKey={record => record.id}
                summary={pacingData => {
                  if (!pacingData) {
                    return { impressions: 0, clicks: 0, budget: 0, spend: 0 };
                  }

                  const aggregatedPage = pacingData.reduce(
                    (acc, curr) => {
                      const { overallPacingData, config } = curr;

                      if (overallPacingData) {
                        const {
                          clicks,
                          impressions,
                          spend,
                        } = overallPacingData;
                        acc.impressions += impressions || 0;
                        acc.clicks += clicks || 0;
                        acc.spend += spend || 0;
                      }

                      if (config && config.monthlyBudget) {
                        acc.budget += config.monthlyBudget;
                      }

                      return acc;
                    },
                    { impressions: 0, clicks: 0, budget: 0, spend: 0 }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          {formatWholeNumber(aggregatedPage.impressions)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          {formatWholeNumber(aggregatedPage.clicks)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          {formatCurrency2SigFig(
                            aggregatedPage.budget,
                            currencyFormat
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          {formatCurrency2SigFig(
                            aggregatedPage.spend,
                            currencyFormat
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          {formatNumber2SigFig(
                            computeCTR(
                              aggregatedPage.clicks,
                              aggregatedPage.impressions
                            )
                          )}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          {formatNumber2SigFig(
                            computeCPC(
                              aggregatedPage.spend,
                              aggregatedPage.clicks
                            )
                          )}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>0%</Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>
                          {formatNumber2SigFig(
                            computePacing(
                              aggregatedPage.spend,
                              aggregatedPage.budget
                            )
                          )}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10}>
                          {formatCurrency2SigFig(
                            computeDailyRemainingSpend(
                              aggregatedPage.spend,
                              aggregatedPage.budget
                            ),
                            currencyFormat
                          )}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Google" key="pacingGoogle">
            {pacingGoogleLoading ? (
              <Row justify="center">
                <Spin />
              </Row>
            ) : (
              <Table
                scroll={{ x: 1700, y: 400 }}
                dataSource={pacingGoogleReport}
                columns={columns}
                rowKey={record => record.id}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bing" key="pacingBing">
            {pacingBingLoading ? (
              <Row justify="center">
                <Spin />
              </Row>
            ) : (
              <Table
                scroll={{ x: 1700, y: 400 }}
                dataSource={pacingBingReport}
                columns={columns}
                rowKey={record => record.id}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default PacingReport;
