import React from "react";

const AdvertiserMainController = ({
  data,
  eventsTargets,
  createAdvertiser,
  refetch,
  mediajelAdminId,
  mediajelId,
  ...props
}) => {
  const onCreateAdvertiser = async values => {
    try {
      await createAdvertiser({
        variables: {
          data: {
            name: values.name,
            impressionsTag: { connect: { id: values.impressionsTag } },
            transactionsTag: { connect: { id: values.transactionsTag } },
            insertionOrderIds: { set: values.insertionOrderIds },
            startDate: values.startDate,
            endDate: values.endDate,
            trackMesurements: { set: values.trackMesurements },
            transactions: 0,
            revenue: 0,
            customers: 0,
            clicks: 0,
            impressions: 0,
            pageViews: 0,
            signUps: 0,
            sharedOrgs: {
              create: [
                {
                  isAdmin: false,
                  isImpressionsOrg: true,
                  isMeasurementOrg: false,
                  org: {
                    connect: {
                      id: values.selectedImpresionsTagOrgId,
                    },
                  },
                },
                {
                  isAdmin: false,
                  isImpressionsOrg: false,
                  isMeasurementOrg: true,
                  org: {
                    connect: {
                      id: values.transactionsTagOrgId,
                    },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        tags: eventsTargets,
        data,
        onCreateAdvertiser,
      })}
    </React.Fragment>
  );
};

export default AdvertiserMainController;
