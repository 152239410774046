import React from "react";
import DataSet from "@antv/data-set";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";

const TrafficByChannelLineChart = ({ data, mediums }) => {
  const colors = {};
  mediums.forEach(m => {
    colors[m.medium] = m.color;
  });
  const dv = new DataSet.View().source(data);
  dv.transform({
    type: "fold",
    fields: mediums.map(m => m.medium),
    key: "channel",
    value: "traffic",
  });

  const scale = {
    traffic: {
      min: 0,
      alias: "Traffic",
    },
    date: {
      range: [0, 1],
    },
  };

  return (
    <Chart height={400} data={dv} scale={scale} forceFit>
      <Legend />
      <Axis name="date" />
      <Axis name="traffic" />
      <Tooltip crosshairs={{ type: "y" }} />
      <Geom
        type="line"
        position="date*traffic"
        size={0}
        color={["channel", channel => colors[channel]]}
      />
      <Geom
        type="point"
        position="date*traffic"
        size={data.length > 400 ? 1.7 : 3}
        shape={"circle"}
        color={["channel", channel => colors[channel]]}
        style={{ stroke: "#fff", lineWidth: 1 }}
      />
    </Chart>
  );
};

export default TrafficByChannelLineChart;
