import gql from "graphql-tag";

const ADVERTISER_CONNECTION = gql`
  query AdvertiserConnection(
    $where: AdvertiserWhereInput
    $orderBy: AdvertiserOrderByInput
  ) {
    advertisersConnection(where: $where, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          impressions
          clicks
          pageViews
          signUps
          transactions
          revenue
          cpm
          budget
          customers
          roas
          spend
          startDate
          endDate
          createdAt
          updatedAt
          totalImpressions
          impressionsTag {
            id
            name
          }
          insertionOrderIds
          sharedOrgs {
            isAdmin
            isImpressionsOrg
            isMeasurementOrg
            org {
              id
              name
            }
          }
          trackMesurements
          transactionsTag {
            id
            name
          }
        }
      }
      aggregate {
        count
      }
    }
  }
`;

export default ADVERTISER_CONNECTION;
