// transformAudienceSchema.js

export const transformAudienceSchema = audienceSchema => {
  try {
    if (!audienceSchema || !audienceSchema.internalMicroserviceAudienceSchema) {
      return {
        groupedCategories: {},
        categories: [],
      };
    }

    const groupedCategories = audienceSchema.internalMicroserviceAudienceSchema.reduce(
      (acc, obj) => {
        const category = obj.category;
        if (!acc[category]) {
          acc[category] = {
            name: category,
            description: obj.categoryDescription,
            datapoints: [],
          };
        }
        acc[category].datapoints.push({
          name: obj.subCategory,
          description: obj.subCategoryDescription,
          mappings: obj.mappings,
        });
        return acc;
      },
      {}
    );

    return {
      groupedCategories: groupedCategories,
    };
  } catch (error) {
    console.error("Error in transformAudienceSchema", error);
    return {
      groupedCategories: {},
    };
  }
};
