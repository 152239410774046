import React, { useEffect, useState } from "react";
import {
  graphql,
  useQuery,
  useMutation,
  useLazyQuery,
  withApollo,
} from "react-apollo";
import compose from "lodash/flowRight";
import {
  Redirect,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import { message } from "antd";
//locals core
import CAMPAIGN_ORDER_DETAILS_ADMIN from "../../../GraphQl/Queries/CAMPAIGN_ORDER_DETAILS_ADMIN";
import UPDATE_CAMPAIGN_ORDER from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_OLD";
import UPDATE_CAMPAIGN_ORDER_PENDING from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_PENDING";
import UPDATE_CAMPAIGN_ORDER_REQUEST from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_REQUEST";
import DELETE_CAMPAIGN_ORDER from "../../../GraphQl/Mutations/DELETE_CAMPAIGN_ORDER";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import CREATE_MESSAGE from "../../../GraphQl/Mutations/CREATE_MESSAGE";
import UPDATE_CONVERSATION from "../../../GraphQl/Mutations/UPDATE_CONVERSATION";
import CREATE_NOTIFICATION from "../../../GraphQl/Mutations/CREATE_NOTIFICATION";
import GET_NOTIFICATIONS from "../../../GraphQl/Queries/GET_NOTIFICATIONS";
import GET_BASKET_ITEMS from "../../../GraphQl/Queries/GET_BASKET_ITEMS";
import START_CAMPAIGN_ORDER_OBSERVATIONS from "../../../GraphQl/Mutations/START_CAMPAIGN_ORDER_OBSERVATIONS";
import SCRAPE_DSP_CAMPAIGNS from "../../../GraphQl/Queries/SCRAPE_DSP_CAMPAIGNS";
import LIQUID_M_CAMPAIGN_PAUSE from "../../../GraphQl/Queries/LIQUID_M_CAMPAIGN_PAUSE";
import LIQUID_M_CAMPAIGN_START from "../../../GraphQl/Queries/LIQUID_M_CAMPAIGN_START";
import EDIT_CAMPAIGN_STATUS from "../../../GraphQl/Mutations/EDIT_CAMPAIGN_STATUS";
import TRIGGER_AIRFLOW_DAG from "../../../GraphQl/Queries/TRIGGER_AIRFLOW_DAG";
import UPSERT_REPROCESS_STATUS from "../../../GraphQl/Mutations/UPSERT_REPROCESS_STATUS";
// utils
import { handleHideProvider } from "../../../utils/configureOrg";
import { featurePermissions } from "../../../utils/general/user";
import {
  getCostForLineItemsByCampaignOrder,
  isDemoCampaignOrder,
} from "../../../utils/campaigns";
//locals platform
import CampaignDetailsController from "./CampaignDetailsController";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import { determineUserAccess } from "../../../permissions/userLevelAccess";

import { isEmpty, cloneDeep } from "lodash";
import ReprocessHandler from "./hooks/ReprocessHandler";
import START_LIQUIDM_CAMPAIGN from "../../../GraphQl/Mutations/START_LIQUIDM_CAMPAIGN";

const DetailsViewDataLoader = props => {
  const [loadingCreateMessage, setLoadingCreateMessage] = useState(false);
  const [getListMessage, setGetListMessage] = useState([]);
  const [getCurrentConversationId, setGetCurrentConversationId] = useState("");
  const [
    isLoadingRefreshObservation,
    setIsLoadingRefreshObservation,
  ] = useState(false);
  const [embeddableConfig, setEmbeddableConfig] = useState({});
  const { match, currentUser } = props;
  const { params } = match;
  const { id } = params;
  const location = useLocation();
  const history = useHistory();

  const { reprocessStatus, refetchStatus } = ReprocessHandler(id);
  useEffect(() => {
    if (
      currentUser &&
      currentUser.permission &&
      currentUser.permission.embeddableConfig
    ) {
      setEmbeddableConfig(currentUser.permission.embeddableConfig);
    }
  }, [currentUser]);

  const config = currentUser.permission;
  const loggedInRole = currentUser.role;
  const loggedInId = currentUser.id;

  const dataConfig =
    currentUser && !currentUser.isPreviewOrg
      ? !isEmpty(currentUser.defaultRole.org)
        ? currentUser.defaultRole.org.dataConfig
        : !isEmpty(currentUser.defaultRole.defaultRole)
        ? currentUser.defaultRole.defaultRole.org.dataConfig
        : {}
      : (currentUser &&
          currentUser.role &&
          currentUser.role.org &&
          currentUser.role.org.dataConfig) ||
        {};

  const orgConfig = determineUserAccess(config);

  //We pull isDemo here - it is used by the controller to obfuscate campaign details
  const { isDemo, campaignSummary, isProcessor, isAdmin } = orgConfig;

  const getIsProcessor = isProcessor;

  const getIsAdmin = isAdmin;

  const getOrgName = loggedInRole.org.name;
  const [createMessage] = useMutation(CREATE_MESSAGE, {
    variables: {
      personality: props.personality,
      context: props.context,
      message: props.message,
      userID: props.userID,
      currentOrgID: props.currentOrgID,
    },
    onCompleted: data => {
      setLoadingCreateMessage(false);
    },
  });
  const [updateConversation] = useMutation(UPDATE_CONVERSATION, {
    variables: {
      conversationID: props.conversationID,
      message: props.message,
      currentOrgID: props.currentOrgID,
    },
    onCompleted: data => {
      setLoadingCreateMessage(false);
    },
  });

  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    variables: {
      userID: props.userID,
      message: props.message,
    },
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          userId: loggedInId,
        },
      },
    ],
  });

  const [updateCampaignOrderPending] = useMutation(
    UPDATE_CAMPAIGN_ORDER_PENDING,
    {
      variables: {
        campaignID: props.campaignID,
        isEditCampaign: props.isEditCampaign,
      },
    }
  );

  const [updateCampaignOrderRequest] = useMutation(
    UPDATE_CAMPAIGN_ORDER_REQUEST,
    {
      variables: {
        id: props.campaignID,
        isRequestChange: props.isRequestChange,
      },
      awaitRefetchQueries: true,
    }
  );

  const [
    getBasketItems,
    {
      loading: basketItemsLoading,
      error: basketItemsError,
      data: basketItemsData,
    },
  ] = useLazyQuery(GET_BASKET_ITEMS, {
    variables: { transactionId: props.transactionId, appId: props.appId },
  });

  const [startObservationJob] = useMutation(START_CAMPAIGN_ORDER_OBSERVATIONS, {
    onCompleted: data => {
      message.destroy();
      message.success(
        "Getting observations. Please check back in a couple minutes."
      );
      setIsLoadingRefreshObservation(false);
    },
    onError: error => {
      message.destroy();
      message.error("Campaign Order has no associated Geo Targets");
      setIsLoadingRefreshObservation(false);
    },
  });
  const [scrapeDSPCampaigns] = useLazyQuery(SCRAPE_DSP_CAMPAIGNS);

  const [reprocessCampaigns] = useLazyQuery(TRIGGER_AIRFLOW_DAG);
  const [upsertReprocessStatus] = useMutation(UPSERT_REPROCESS_STATUS, {
    onCompleted: data => {
      refetchStatus();
    },
  });

  const [pauseLiquidMCampaign] = useLazyQuery(LIQUID_M_CAMPAIGN_PAUSE, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "no-cache",
  });

  const [startLiquidMCampaign] = useLazyQuery(LIQUID_M_CAMPAIGN_START, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "no-cache",
  });

  const [updateCampaignOrderStatus] = useMutation(EDIT_CAMPAIGN_STATUS, {
    variables: {
      id: props.id,
      status: props.status,
    },
  });

  // PAUSED or START LiquidM Campaign(s)
  const [
    pauseOrStartLiquidMCampaign,
    { loading: loadingPauseOrStartLiquidMCampaign },
  ] = useMutation(START_LIQUIDM_CAMPAIGN, {
    variables: {
      id: props.id,
    },
  });

  const org = currentUser.role.org.name;

  const { loading, error, data } = useQuery(CAMPAIGN_ORDER_DETAILS_ADMIN, {
    variables: { ID: id },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const { READ, WRITE } = featurePermissions(
    props.currentUser.role,
    "CAMPAIGNS"
  );

  if (!id || !READ) {
    return <Redirect to="/campaigns/main/1" />;
  }

  let searchParams = new URLSearchParams(props.location.search);
  let selectedTab = searchParams.get("tab")
    ? searchParams.get("tab")
    : campaignSummary
    ? "summary"
    : "";

  if (embeddableConfig.type && embeddableConfig.type === "WIDGET") {
    selectedTab = "widgets";
  }

  // handling when summary is manually added to URL
  if (campaignSummary === false && selectedTab === "summary") {
    searchParams.delete("tab");
    searchParams.append("tab", "overview");
    history.push(`${location.pathname}?${searchParams.toString()}`);
  }

  const selectedProvider =
    props.currentUser &&
    props.currentUser.role &&
    props.currentUser.role.org.config &&
    props.currentUser.role.org.config.providersVisible
      ? searchParams.get("provider") || "All"
      : "All";

  if (loading) {
    return <OverlaySpinner />;
  }

  if (error)
    return `Error (Query: CAMPAIGN_ORDER_DETAILS_ADMIN)! ${error.message}`;

  let {
    campaignOrder,
    attributionReports,
    signUpReports,
    displayReports,
    transactionReports,
    transactionReportsCSV,
    basketItemsReports,
    pageViewReports,
  } = cloneDeep(data);

  if (!campaignOrder) {
    return <Redirect to="/campaigns/main/1" />;
  }

  // Start date kludge
  if (!campaignOrder.startDate && !isEmpty(campaignOrder.data)) {
    if (!isEmpty(campaignOrder.data.byDate))
      campaignOrder.startDate = campaignOrder.data.byDate[0].xAxis;
  }

  // End date kludge
  if (!campaignOrder.endDate && !isEmpty(campaignOrder.data)) {
    if (!isEmpty(campaignOrder.data.byDate))
      campaignOrder.endDate =
        campaignOrder.data.byDate[campaignOrder.data.byDate.length - 1].xAxis ||
        new Date();
  }

  // TODO: use a similar function for all features
  // Needs to handle better using advertiser
  const usersOrgOwnsCampaign =
    campaignOrder.orgs.some(org => org.id === props.currentUser.role.org.id) &&
    props.currentUser.role.roleItems.some(roleItem => {
      return (
        roleItem.feature === "CAMPAIGNS" && roleItem.actions.includes("WRITE")
      );
    });

  // Org Config
  // TODO: better use org config from DB
  if (!props.currentUser.isAdmin) {
    campaignOrder.campaigns = handleHideProvider(campaignOrder.campaigns);
  }

  if (campaignOrder.campaigns.length) {
    campaignOrder.campaigns = getCostForLineItemsByCampaignOrder(
      campaignOrder,
      props.currentUser
    );
  }

  if (config && config.isDemo) {
    campaignOrder = isDemoCampaignOrder(campaignOrder);
  }
  return (
    <CampaignDetailsController
      isDemo={isDemo}
      org={org}
      orgConfig={orgConfig}
      dataConfig={dataConfig}
      campaignOrder={campaignOrder}
      usersOrgOwnsCampaign={usersOrgOwnsCampaign}
      selectedTab={selectedTab || "overview"}
      selectedProvider={selectedProvider}
      attributionReportId={
        attributionReports.reports &&
        attributionReports.reports[0] &&
        attributionReports.reports[0].id
      }
      signUpReportId={
        signUpReports.reports &&
        signUpReports.reports[0] &&
        signUpReports.reports[0].id
      }
      displayReportId={
        displayReports.reports &&
        displayReports.reports[0] &&
        displayReports.reports[0].id
      }
      transactionReportId={
        transactionReports.reports &&
        transactionReports.reports[0] &&
        transactionReports.reports[0].id
      }
      transactionCSVReportId={
        transactionReportsCSV.reports &&
        transactionReportsCSV.reports[0] &&
        transactionReportsCSV.reports[0].id
      }
      basketItemsReportId={
        basketItemsReports.reports &&
        basketItemsReports.reports[0] &&
        basketItemsReports.reports[0].id
      }
      pageViewReports={pageViewReports}
      loadingCreateMessage={loadingCreateMessage}
      setLoadingCreateMessage={setLoadingCreateMessage}
      getOrgName={getOrgName}
      getIsProcessor={getIsProcessor}
      getIsAdmin={getIsAdmin}
      getListMessage={getListMessage}
      getCurrentConversationId={getCurrentConversationId}
      setGetListMessage={setGetListMessage}
      setGetCurrentConversationId={setGetCurrentConversationId}
      createMessage={createMessage}
      updateConversation={updateConversation}
      createNotification={createNotification}
      updateCampaignOrderPending={updateCampaignOrderPending}
      updateCampaignOrderRequest={updateCampaignOrderRequest}
      READ={READ}
      WRITE={WRITE}
      getBasketItems={getBasketItems}
      basketItemsLoading={basketItemsLoading}
      basketItemsError={basketItemsError}
      basketItemsData={basketItemsData}
      startObservationJob={startObservationJob}
      isLoadingRefreshObservation={isLoadingRefreshObservation}
      setIsLoadingRefreshObservation={setIsLoadingRefreshObservation}
      scrapeDSPCampaigns={scrapeDSPCampaigns}
      pauseLiquidMCampaign={pauseLiquidMCampaign}
      startLiquidMCampaign={startLiquidMCampaign}
      currentUser={currentUser}
      updateCampaignOrderStatus={updateCampaignOrderStatus}
      embeddableConfig={embeddableConfig}
      reprocessCampaigns={reprocessCampaigns}
      upsertReprocessStatus={upsertReprocessStatus}
      reprocessStatus={reprocessStatus}
      pauseOrStartLiquidMCampaign={pauseOrStartLiquidMCampaign}
      loadingPauseOrStartLiquidMCampaign={loadingPauseOrStartLiquidMCampaign}
      {...props}
    >
      {props.children}
    </CampaignDetailsController>
  );
};

const DetailsViewWithRoutes = withRouter(DetailsViewDataLoader);

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(UPDATE_CAMPAIGN_ORDER, { name: "updateCampaignOrder" }),
  graphql(DELETE_CAMPAIGN_ORDER, { name: "deleteCampaignOrder" })
)(withApollo(DetailsViewWithRoutes));
