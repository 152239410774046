/** @jsx jsx */
import React from "react";
import { Row, Switch, Tooltip, notification } from "antd";
import { AreaChartOutlined, WarningOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import Basics from "./CampaignLauncherViewBasics";
import Targeting from "./CampaignLauncherViewTargeting";
import Attribution from "./CampaignLauncherViewAttribution";
import Creatives from "./CampaignLauncherViewMedia";
import Review from "./CampaignLauncherViewReview";
import Success from "./CampaignLauncherViewSuccess";
import Menu from "./components/Menu";
import moment from "moment";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { css, jsx } from "@emotion/react";

const responsiveMenu = css`
  .responsive-menu {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .responsive-menu {
      display: block;
    }

    .mobile-button-wrapper {
      display: block !important;
      padding: 0px;
    }
    .mobile-button-wrapper li {
      display: block !important;
      margin: 5px;
      margin-bottom: 25px;
      position: relative;
    }

    .mobile-button-wrapper li div {
      position: absolute;
      top: 6px;
      left: 60px;
    }
  }
`;

const steps = [
  {
    title: "Basics",
    content: Basics,
    visible: () => true,
  },
  {
    title: "Targeting",
    content: Targeting,
    visible: () => true,
  },
  {
    title: "Media",
    content: Creatives,
    visible: ({ campaignOrder, type }) =>
      (campaignOrder && campaignOrder.type !== "OOH") ||
      (!campaignOrder && type !== 2),
  },
  {
    title: "Attribution",
    content: Attribution,
    visible: () => true,
  },
  {
    title: "REVIEW",
    content: Review,
    visible: () => true,
  },
  {
    title: "SUCCESS",
    content: Success,
    visible: () => true,
  },
];

const style = {
  menuWrapper: {
    backgroundColor: "white",
    borderTop: "0.3px solid #ededed",
    borderBottom: "0.3px solid #ededed",
    padding: "20px",
  },
  stepsAction: {
    marginTop: "24px",
    paddingLeft: "3em",
  },
  previousButton: {
    marginRight: "8px",
  },
  navigation: {
    marginTop: "20px",
    width: "210px",
  },
};

const VisibilityWrapper = ({ children, visible, ...rest }) => {
  const isVisible = visible(rest);
  if (isVisible)
    return (
      <React.Fragment>
        {React.cloneElement(children, {
          ...rest,
        })}
      </React.Fragment>
    );

  let searchParams = new URLSearchParams(rest.location.search);
  const indexOfFirstVisible = steps.findIndex(step => step.visible(rest));
  searchParams.delete("step");
  if (indexOfFirstVisible > 0)
    searchParams.append("step", indexOfFirstVisible + 1);
  return (
    <Redirect to={`${rest.location.pathname}?${searchParams.toString()}`} />
  );
};

const CampaignLauncherView = props => {
  const {
    step,
    onChange,
    onStepChange,
    campaignName,
    isLaunchCampaign,
    isSucessfullySubmitted,
    status,
    isTrafficker,
    startDate,
    endDate,
    campaignOrder,
    awarenessCampaign,
    pauseOrStartLiquidMCampaign,
    loadingPauseOrStartLiquidMCampaign,
  } = props;

  // const getCampaigns = campaignOrder && campaignOrder.campaigns;
  // const getArrayAdProviderId = !isEmpty(getCampaigns)
  //   ? getCampaigns.map(val => {
  //       let getLiquidMAdId = [];
  //       if (val.demandSidePlatform === "LIQUIDM") {
  //         getLiquidMAdId = val.adProviderId;
  //       }
  //       return getLiquidMAdId;
  //     })
  //   : [];

  function returnComponent(actualStep) {
    const Component = actualStep.content;
    return (
      <div style={style.stepsContent}>
        <VisibilityWrapper
          {...props}
          visible={actualStep.visible}
          onChange={onChange}
          onStepChange={onStepChange}
          currentStep={step}
        >
          <Component />
        </VisibilityWrapper>
      </div>
    );
  }

  const actualStep = steps[step - 1];

  const currentDateTime = moment()
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
  const getStartDate = startDate
    ? moment(startDate)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
    : null;
  const getEndDate = endDate
    ? moment(endDate)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(0)
    : null;

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={
          isLaunchCampaign === "false"
            ? campaignName
            : "Campaign Manager - " + campaignName
        }
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        centerContent={<div />}
        rightContent={
          currentDateTime.isBetween(getStartDate, getEndDate) &&
          status !== "PENDING" &&
          status !== "DRAFT" &&
          isTrafficker === true ? (
            <Tooltip title="Campaigns running on Liquid M will be automatically paused. Note: Campaigns running on other DSPS will need to be paused manually from within the DSP interface.">
              <Switch
                loading={loadingPauseOrStartLiquidMCampaign}
                disabled={loadingPauseOrStartLiquidMCampaign}
                checkedChildren="LIVE"
                unCheckedChildren="PAUSED"
                defaultChecked={status === "PAUSED" ? false : true}
                onClick={async isChecked => {
                  try {
                    onChange(
                      "status",
                      isChecked === false
                        ? "PAUSED"
                        : currentDateTime.isBetween(getStartDate, getEndDate)
                        ? "LIVE"
                        : currentDateTime.isAfter(endDate)
                        ? "PAST"
                        : "APPROVED"
                    );

                    await pauseOrStartLiquidMCampaign({
                      variables: {
                        id: campaignOrder.id,
                      },
                    });
                  } catch (err) {
                    console.log(err);
                    notification.open({
                      message: `Failed to ${
                        isChecked === false ? "Pause" : "Start"
                      } Campaign: ${err}`,
                      icon: <WarningOutlined style={{ color: "yellow" }} />,
                    });
                  }
                }}
              />
            </Tooltip>
          ) : (
            <div />
          )
        }
      />

      {isSucessfullySubmitted === false && step !== 6 && (
        <Row
          style={style.menuWrapper}
          type="flex"
          justify="center"
          css={responsiveMenu}
        >
          <div className="responsive-menu">
            <Menu {...props} awarenessCampaign={awarenessCampaign} />
          </div>
        </Row>
      )}

      <PageContentLayout>{returnComponent(actualStep)}</PageContentLayout>
    </React.Fragment>
  );
};

export default CampaignLauncherView;
