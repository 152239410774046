import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import PropTypes from "prop-types";
import { TagsOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";

const { Text } = Typography;

const TagsManagerView = ({
  onSubmit,
  providers,
  advertisers,
  currentOrgId,
  headerSubmit,
  tagsToCreate,
}) => {
  const [form] = Form.useForm();
  const [ecommerceSetup, setEcommerceSetup] = useState(false);
  return (
    <React.Fragment>
      {headerSubmit ? (
        <PageHeaderLayout
          title={"Create New Tag"}
          titleIcon={<TagsOutlined />}
          rightContent={
            <Button
              shape="round"
              type="primary"
              onClick={() => {
                form.validateFields().then(async values => {
                  message.success("Tag Created");
                  await onSubmit(values);
                });
              }}
            >
              Create Tag
            </Button>
          }
        />
      ) : null}

      <PageContentLayout>
        {tagsToCreate === "Measurement" && (
          <Form
            form={form}
            layout="vertical"
            name={"createTag"}
            initialValues={{
              ecommerce: false,
              signups: false,
              advertiser: currentOrgId,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card title={<Text strong>BASICS</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"name"}
                        rules={[
                          { required: true, message: "Name is required" },
                        ]}
                        label={"Tag Name"}
                      >
                        <Input placeholder="Input tag name" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={"advertiser"}
                        rules={[
                          { required: true, message: "Advertiser Required" },
                        ]}
                        label={"Advertiser"}
                      >
                        <Select
                          placeholder="Select Advertiser"
                          options={advertisers}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card title={<Text strong>ECOMMERCE</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"ecommerce"}
                        label={"Will the tag measure ecommerce?"}
                      >
                        <Radio.Group
                          onChange={val => setEcommerceSetup(val.target.value)}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={"platform"}
                        rules={[
                          () => ({
                            validator(_, value) {
                              //If process with google ads is enabled then google ads customer id is required
                              if (ecommerceSetup) {
                                if (value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(`Cart Required`);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        label={"Which shopping cart platform is being used?"}
                        hidden={!ecommerceSetup}
                      >
                        <Select
                          placeholder="Selected Advertiser"
                          options={providers}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card title={<Text strong>SIGN-UPS</Text>}>
                  <Row gutter={[18, 18]}>
                    <Col span={8}>
                      <Form.Item
                        name={"signups"}
                        label={"Will this tag measure sign-ups?"}
                      >
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        )}

        {tagsToCreate === "Impression" && (
          <Form form={form} layout="vertical" name={"createImpressionsTag"}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  label="Impression Tag Name"
                  name="name"
                  rules={[{ required: true, message: "Name Required" }]}
                >
                  <Input placeholder="Enter tag name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Advertising Platform"
                  name="dsp"
                  rules={[
                    {
                      required: true,
                      message: "Advertising Platform Required",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Advertising Platform"
                    options={advertisers}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {!headerSubmit ? (
          <Col span={24}>
            <Row align="end">
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  form.validateFields().then(async values => {
                    message.loading("Creating Tag");
                    await onSubmit(values);
                    message.destroy();
                    message.success("Tag Created");
                    form.resetFields();
                  });
                }}
              >
                Create Tag
              </Button>
            </Row>
          </Col>
        ) : null}
      </PageContentLayout>
    </React.Fragment>
  );
};

TagsManagerView.propTypes = {
  onSubmit: PropTypes.func,
  providers: PropTypes.array,
  advertisers: PropTypes.array,
  currentOrgId: PropTypes.string,
  headerSubmit: PropTypes.bool,
  tagsToCreate: PropTypes.oneOf(["Measurement", "Impression"]),
};

export default TagsManagerView;
