import gql from "graphql-tag";

const CREATE_EDITORIAL = gql`
  mutation createEditorialAttribution(
    $clicksTag: String!
    $transactionsTag: String!
    $name: String!
    $domain: String!
    $url: String
    $trackMesurements: [TagMesurements!]!
    $startDate: String
  ) {
    createEditorialAttribution(
      data: {
        clicksTag: $clicksTag
        transactionsTag: $transactionsTag
        name: $name
        domain: $domain
        url: $url
        trackMesurements: $trackMesurements
        startDate: $startDate
      }
    ) {
      id
      name
    }
  }
`;

export default CREATE_EDITORIAL;
