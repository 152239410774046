import React from "react";
import moment from "moment";

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }

  return color;
};

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }

  return color;
};

const TagsMainController = ({ ...props }) => {
  const { dataMultiOrgTags, requestAssistance } = props;
  const formattedTags = dataMultiOrgTags.eventsTargets.map(tag => {
    const {
      transactions,
      pageViews,
      signups,
      name,
      id,
      tagUpdateDate,
      eventTags,
      orgs,
      isSignUps,
    } = tag;
    const appId = eventTags[0].appId[0];
    return {
      id,
      name,
      advertiser: orgs[0].name,
      measuredEvents: {
        pageViews,
        transactions,
        signups,
        isSignUps,
        date: tagUpdateDate
          ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
          : "Signal Unknown",
        colors: {
          transactions: typePicker(tagUpdateDate, transactions),
          pageViews: typePicker(tagUpdateDate, pageViews),
          signups: typePicker(tagUpdateDate, signups),
        },
        typeColors: {
          transactions: colorPicker(tagUpdateDate, transactions),
          pageViews: colorPicker(tagUpdateDate, pageViews),
          signups: colorPicker(tagUpdateDate, signups),
        },
      },
      appId,
    };
  });
  return React.cloneElement(props.children, {
    ...props.children.props,
    formattedTags,
    requestAssistance,
  });
};

export default TagsMainController;
