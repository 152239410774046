import React from "react";
import { Switch, Route } from "react-router-dom";

import Loader from "../../../../core/components/invoice";
import InvoiceMainView from "../InvoiceMainView";

const InvoiceRoutes = props => {
  const { path } = props.match;
  return (
    <div>
      <Switch>
        <Route
          path={`${path}`}
          exact
          render={() => (
            <Loader>
              <InvoiceMainView />
            </Loader>
          )}
        />
      </Switch>
    </div>
  );
};

export default InvoiceRoutes;
