import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { TagsOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import DetailsCard from "./components/DetailsCard";
import GeneratedTagsCard from "./components/GeneratedTagsCard";
import SignUpsCard from "./components/SignUpsCard";
import TestLogsCard from "./components/TestLogsCard";
import RegenerateTag from "./components/RegenarateTag";

const TagsDetailsView = ({
  details,
  generatedTag,
  signUpsTag,
  testTag,
  testLogs,
  tagActivityLoading,
  onDisableEnableTag,
  disableEnableLoading,
  regenerateTag,
  userPermission,
}) => {
  const { isSelfService } = userPermission;
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={details.name}
        titleIcon={<TagsOutlined />}
        rightContent={
          isSelfService ? (
            <RegenerateTag
              cart={details.cart}
              regenerateTag={regenerateTag}
              domain={details.domain}
            />
          ) : null
        }
      />
      <PageContentLayout>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <DetailsCard
                  details={details}
                  onDisableEnableTag={onDisableEnableTag}
                  loading={disableEnableLoading}
                />
              </Col>
              <Col span={24}>
                <TestLogsCard
                  logs={testLogs}
                  hasBasketItems={generatedTag.basketItems > 0}
                  hasSignups={signUpsTag.signups > 0}
                  testTag={testTag}
                  loading={tagActivityLoading}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <GeneratedTagsCard generatedTag={generatedTag} />
              </Col>
              {signUpsTag && (signUpsTag.signups > 0 || signUpsTag.hasSignups) && (
                <Col span={24}>
                  <SignUpsCard signUpsTag={signUpsTag} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default TagsDetailsView;
