import React from "react";

// Ant Design
import { Table, Tag, Tooltip } from "antd";
import moment from "moment";

export const demographicsAudienceTableColumnMobile = [
  {
    title: "Audience Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) => {
      return (
        <div
          style={{
            border: "1px solid black",
            padding: "20px",
            borderRadius: "20px",
            borderLeft: "5px solid #1677FF",
          }}
        >
          <p style={{ marginBottom: "0.1em" }}>{record.name}</p>
          <p style={{ marginBottom: "0.1em" }}>
            <b>Created On</b> {moment(record.createdAt).format("MMM D, YYYY")}
          </p>
          <p style={{ marginBottom: "0.1em" }}>
            <b>Reach</b>{" "}
            {record && record.reachCount
              ? record.reachCount.toLocaleString()
              : 0}
          </p>
        </div>
      );
    },
  },
];

export const demographicsAudienceTableColumn = [
  Table.SELECTION_COLUMN,
  Table.EXPAND_COLUMN,
  {
    title: "Audience Name",
    dataIndex: "name",
    key: "name",
    render: text => <span>{text}</span>,
  },
  {
    title: "Reach",
    dataIndex: "reachCount",
    key: "reachCount",
    align: "right",
    render: reachCount => {
      return <span>{reachCount ? reachCount.toLocaleString() : 0}</span>;
    },
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: createdAt => moment(createdAt).format("M/D/YYYY"),
  },
  {
    title: "Published",
    dataIndex: "isPublished",
    key: "isPublished",
    render: (isPublished, record) => {
      return (
        <Tooltip
          title={
            record.publishedDate
              ? moment(record.publishedDate).format("M/D/YYYY")
              : "No yet published"
          }
        >
          {isPublished ? (
            <Tag color="green">Published</Tag>
          ) : (
            <Tag color="red">Unpublished</Tag>
          )}
        </Tooltip>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: text => <span>{text}</span>,
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags &&
          tags
            .sort((a, b) => a.localeCompare(b))
            .map(tag => {
              return <Tag key={tag}>{tag}</Tag>;
            })}
      </>
    ),
  },
];
