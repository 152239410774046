import React, { createContext, useContext, useEffect, useState } from "react";
import {
  formatCurrency2SigFig,
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../utils/campaigns";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import moment from "moment";
import { cloneDeep } from "lodash";

const SEOTrafficContext = createContext();

function hslToHex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

const randomColors = () => {
  const h = Math.floor(Math.random() * (360 - 0 + 1) + 0);
  const s = 44;
  const l = 70;

  return hslToHex(h, s, l);
};
export const SEOTrafficProvider = ({ reportKey = "-", children }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();
  //Traffic by Channel
  const [trafficByChannelSummary, setTrafficByChannelSummary] = useState();
  const [trafficByChannelByDate, setTrafficByChannelByDate] = useState([]);
  const [mediums, setMediums] = useState([]);
  // New Vs Returning
  const [newVsReturning, setNewVsReturning] = useState({
    total: 0,
    new: 0,
    returning: 0,
  });
  // Sessions Chart
  const [sessions, setSessions] = useState(0);
  const [sessionsByDate, setSessionsByDate] = useState([]);
  // Bounce Rate Chart
  const [bounceRate, setBounceRate] = useState(0);
  // Landing Page Report
  const [topLandingPages, setTopLandingPages] = useState([]);
  // Top Cities Report
  const [topCities, setTopCities] = useState([]);
  // Revenue By Traffic Source Report
  const [revenueByTrafficSummary, setRevenueByTrafficSummary] = useState();
  const [revenueByTraffic, setRevenueByTraffic] = useState([]);

  // Revenue By Date
  const [revenueByDateSummary, setRevenueByDateSummary] = useState();
  const [revenueByDate, setRevenueByDate] = useState([]);
  const [startDateEnDate, setStartDateEnDate] = useState([]);

  const { data: signedURL } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  // Fetch the report from S3 on the signed URL has finished
  useEffect(() => {
    if (signedURL && !reportData) {
      const s3Url = new URL(signedURL.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setReportData(json);
            });
          } catch (err) {
            console.log(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [reportData, signedURL]);

  useEffect(() => {
    if (reportData) {
      const {
        trafficByChannelReport,
        newVsReturningReport,
        revenueByDateReport,
        topLandingPageByDateReport,
        topCitiesByDateReport,
        revenueByTrafficSourceReport,
      } = reportData;
      const endDate =
        revenueByDateReport.byDate.length > 0 &&
        revenueByDateReport.byDate.reduce((acc, curr) => {
          if (!acc) acc = moment(curr.date, "YYYY-MM-DD");
          else if (moment(curr.date, "YYYY-MM-DD").isAfter(acc)) {
            acc = moment(curr.date, "YYYY-MM-DD");
          }
          return acc;
        }, null);

      // Revenue By Date
      setRevenueByDateSummary(
        generateRevenueByDateSummary(revenueByDateReport.total)
      );
      setRevenueByDate(
        revenueByDateReport.byDate.filter(d => {
          const eDate = cloneDeep(endDate);
          return moment(d.date).isAfter(eDate.subtract(30, "days"));
        })
      );

      setTrafficByChannelSummary(
        generateTrafficSummary(trafficByChannelReport.total)
      );
      setTrafficByChannelByDate(
        generateTrafficByDate(
          trafficByChannelReport.byDate.filter(d => {
            const eDate = cloneDeep(endDate);
            return moment(d.date).isAfter(eDate.subtract(30, "days"));
          }),
          trafficByChannelReport.total
        )
      );
      //New Vs Returning Chart
      setNewVsReturning({
        total: newVsReturningReport.new + newVsReturningReport.returning,
        new: newVsReturningReport.new,
        returning: newVsReturningReport.returning,
      });

      //Sessions Chart
      setSessions(revenueByDateReport.total.sessions);
      setSessionsByDate(
        revenueByDateReport.byDate
          .filter(d => {
            const eDate = cloneDeep(endDate);
            return moment(d.date).isAfter(eDate.subtract(30, "days"));
          })
          .map(d => ({
            date: d.date,
            value: d.sessions,
          }))
      );

      //Bounce Rate Chart
      setBounceRate(revenueByDateReport.total.bounceRate);

      //Top Landing Pages
      setTopLandingPages(topLandingPageByDateReport.topLandingPages);

      //Top Cities
      setTopCities(topCitiesByDateReport.topCities);

      //Revenue By Traffic Source
      setRevenueByTrafficSummary(
        generateRevenueByTrafficSummary(revenueByTrafficSourceReport.total)
      );
      setRevenueByTraffic(revenueByTrafficSourceReport.trafficSource);

      setReportLoading(false);
      const eDate = moment(endDate);
      setStartDateEnDate([eDate.subtract(29, "days"), endDate]);
    }
  }, [reportData]);

  const onDateChange = dates => {
    const {
      trafficByChannelReport,
      newVsReturningReport,
      revenueByDateReport,
      topLandingPageByDateReport,
      topCitiesByDateReport,
      revenueByTrafficSourceReport,
    } = reportData;
    // Revenue By Date
    const byDateRevenueFiltered = revenueByDateReport.byDate.filter(d => {
      return (
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
      );
    });
    const revenueByDateSummary = byDateRevenueFiltered.reduce(
      (acc, curr) => {
        acc.revenue += Number(curr.revenue);
        acc.conversions += Number(curr.conversions);
        acc.transactions += Number(curr.transactions);
        acc.sessions += Number(curr.sessions);
        acc.averageOrder = acc.revenue / acc.transactions;
        acc.econConvRate = (acc.transactions / acc.sessions) * 100;
        acc.engagedSessions = Number(curr.engagedSessions);
        //TODO: Bounce rate computation as per ChatGPT needs to clarify
        acc.bounceRate =
          acc.sessions > 0
            ? 100 - (acc.engagedSessions / acc.sessions) * 100
            : 0;
        return acc;
      },
      {
        revenue: 0,
        conversions: 0,
        averageOrder: 0,
        econConvRate: 0,
        transactions: 0,
        sessions: 0,
        engagedSessions: 0,
        bounceRate: 0,
      }
    );
    setRevenueByDate(byDateRevenueFiltered);
    setRevenueByDateSummary(generateRevenueByDateSummary(revenueByDateSummary));

    //Traffic By Channel
    const trafficByChannelByDateFiltered = trafficByChannelReport.byDate.filter(
      d => {
        return (
          moment(d.date).isSameOrAfter(moment(dates[0])) &&
          moment(d.date).isSameOrBefore(moment(dates[1]))
        );
      }
    );
    const trafficByChannelSummary = [];
    trafficByChannelByDateFiltered.forEach(d => {
      d.mediums.forEach(m => {
        const channelExist = trafficByChannelSummary.findIndex(
          c => c.medium === m.medium
        );
        if (channelExist === -1) {
          trafficByChannelSummary.push({
            medium: m.medium,
            sessions: Number(m.sessions),
          });
        } else {
          trafficByChannelSummary[channelExist].sessions += Number(m.sessions);
        }
      });
    });
    setTrafficByChannelSummary(generateTrafficSummary(trafficByChannelSummary));
    setTrafficByChannelByDate(
      generateTrafficByDate(
        trafficByChannelByDateFiltered,
        trafficByChannelSummary
      )
    );
    const newVsReturningByDate = newVsReturningReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    const newTotal = newVsReturningByDate.reduce((acc, curr) => {
      return acc + curr.new;
    }, 0);
    const returningTotal = newVsReturningByDate.reduce((acc, curr) => {
      return acc + curr.returning;
    }, 0);
    //New Vs Returning Chart
    setNewVsReturning({
      total: newTotal + returningTotal,
      new: newTotal,
      returning: returningTotal,
    });

    //Sessions Chart
    setSessions(revenueByDateSummary.sessions);
    setSessionsByDate(
      byDateRevenueFiltered.map(d => ({
        date: d.date,
        value: d.sessions,
      }))
    );

    //Bounce Rate Chart
    setBounceRate(revenueByDateSummary.bounceRate);

    //Top Landing Pages
    const topLandingPagesFiltered = topLandingPageByDateReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    setTopLandingPages(
      topLandingPagesFiltered
        .reduce((acc, curr) => {
          const pageExist = acc.findIndex(
            c => c.landingPage === curr.landingPage
          );
          if (pageExist === -1) {
            acc.push({
              landingPage: curr.landingPage,
              screenPageViews: Number(curr.screenPageViews),
            });
          } else {
            acc[pageExist].screenPageViews += Number(curr.screenPageViews);
          }
          return acc;
        }, [])
        .sort((a, b) => b.screenPageViews - a.screenPageViews)
        .slice(0, 10)
    );

    //Top Cities
    const topCitiesFiltered = topCitiesByDateReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    setTopCities(
      topCitiesFiltered
        .reduce((acc, curr) => {
          const cityExist = acc.findIndex(c => c.city === curr.city);
          if (cityExist === -1) {
            acc.push({
              city: curr.city,
              sessions: Number(curr.sessions),
            });
          } else {
            acc[cityExist].sessions += Number(curr.sessions);
          }
          return acc;
        }, [])
        .sort((a, b) => b.sessions - a.sessions)
        .slice(0, 10)
    );

    //Revenue By Traffic Source
    const revenueByTrafficSourceFiltered = revenueByTrafficSourceReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    setRevenueByTrafficSummary(
      generateRevenueByTrafficSummary(
        revenueByTrafficSourceFiltered.reduce(
          (acc, curr) => {
            acc.revenue += Number(curr.totalRevenue);
            acc.conversions += Number(curr.conversions);
            acc.transactions += Number(curr.transactions);
            acc.averageOrder = acc.revenue / acc.transactions;
            acc.sessions += Number(curr.sessions);
            acc.econConvRate = (acc.transactions / acc.sessions) * 100;
            return acc;
          },
          {
            revenue: 0,
            conversions: 0,
            averageOrder: 0,
            econConvRate: 0,
            transactions: 0,
            sessions: 0,
          }
        )
      )
    );
    setRevenueByTraffic(
      revenueByTrafficSourceFiltered.reduce((acc, curr) => {
        const mediumExists = acc.findIndex(
          c => c.medium === curr.sessionMedium
        );

        if (mediumExists === -1) {
          acc.push({
            medium: curr.sessionMedium,
            revenue: Number(curr.totalRevenue),
            conversions: Number(curr.conversions),
            transactions: Number(curr.transactions),
            averageOrder:
              curr.transactions !== 0
                ? curr.totalRevenue / curr.transactions
                : 0,
            sessions: Number(curr.sessions),
            econConvRate: (curr.transactions / curr.sessions) * 100,
          });
        } else {
          acc[mediumExists].revenue += Number(curr.totalRevenue);
          acc[mediumExists].conversions += Number(curr.conversions);
          acc[mediumExists].transactions += Number(curr.transactions);
          acc[mediumExists].averageOrder =
            acc[mediumExists].transactions !== 0
              ? acc[mediumExists].revenue / acc[mediumExists].transactions
              : 0;
          acc[mediumExists].sessions += Number(curr.sessions);
          acc[mediumExists].econConvRate =
            (acc[mediumExists].transactions / acc[mediumExists].sessions) * 100;
        }
        return acc;
      }, [])
    );

    setReportLoading(false);
    setStartDateEnDate(dates);
  };

  const generateTrafficSummary = totals => {
    const mediums = totals.map(m => ({
      medium: m.medium,
      color: randomColors(),
    }));
    setMediums(mediums);
    return {
      visible: true,
      values: totals.map(channel => ({
        title: channel.medium,
        value: formatWholeNumber(channel.sessions),
        prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
        prevPercentage: 0,
        prevRanges: null,
      })),
    };
  };

  const generateTrafficByDate = (byDates, summary) => {
    const mediums = summary.map(m => m.medium);
    return byDates.map(date => {
      const byDate = {
        date: moment(date.date, "YYYYMMDD").format("YYYY-MM-DD"),
      };
      mediums.forEach(m => {
        const existOnDate = date.mediums.find(d => d.medium === m);
        if (!existOnDate) {
          byDate[m] = 0;
        } else {
          byDate[m] = existOnDate.sessions;
        }
      });
      return byDate;
    });
  };

  const generateRevenueByTrafficSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Revenue",
          value: formatCurrency2SigFig(totals.revenue),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Conversions",
          value: formatWholeNumber(totals.conversions),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Average Order",
          value: formatCurrency2SigFig(totals.averageOrder),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Ecom Conv. Rate",
          value: formatNumber2SigFig(totals.econConvRate) + "%",
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const generateRevenueByDateSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Revenue",
          value: formatCurrency2SigFig(totals.revenue),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Conversions",
          value: formatWholeNumber(totals.conversions),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Average Order",
          value: formatCurrency2SigFig(totals.averageOrder),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Ecom Conv. Rate",
          value: formatNumber2SigFig(totals.econConvRate) + "%",
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  return (
    <SEOTrafficContext.Provider
      value={{
        reportLoading,
        topLandingPages,
        topCities,
        newVsReturning,
        sessions,
        trafficByChannelSummary,
        trafficByChannelByDate,
        mediums,
        sessionsByDate,
        bounceRate,
        revenueByTrafficSummary,
        revenueByTraffic,
        revenueByDateSummary,
        revenueByDate,
        reportError,
        startDateEnDate,
        onDateChange,
      }}
    >
      {children}
    </SEOTrafficContext.Provider>
  );
};

export const useSEOTrafficContext = () => {
  const context = useContext(SEOTrafficContext);
  if (!context) {
    throw new Error(
      "useSEOTrafficContext must be used within a SEOTrafficProvider"
    );
  }
  return context;
};
