import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import KeywordRankingsView from "./reportingtabs/KeywordRankingsView";
import TrafficView from "./reportingtabs/TrafficView";
import LinkAcquisitionsView from "./reportingtabs/LinkAcquisitionsView";
import ConversionsView from "./reportingtabs/ConversionsView";
import { SEOTrafficProvider } from "../../../../../core/components/seoRefactored/seoDetails/context/SEOTrafficContext";
import { SEOLinkAcquisitionsProvider } from "../../../../../core/components/seoRefactored/seoDetails/context/SEOLinkAcquisitionsContext";
import { SEOConversionsProvider } from "../../../../../core/components/seoRefactored/seoDetails/context/SEOConversionsContext";
import { SEOKeywordsProvider } from "../../../../../core/components/seoRefactored/seoDetails/context/SEOKeywordsContext";

const ReportingTab = ({
  hasKeywordRankings,
  keywordsReportKey,
  trafficReportKey,
  conversionsReportKey,
  linkAcquisitionData,
  domain,
  setShowDatePicker,
  setSelectedDate,
  selectedDate,
  setSelectedDropdown,
  setReportData,
}) => {
  const [selectedTab, setSelectedTab] = useState(
    hasKeywordRankings ? "keywords" : "traffic"
  );
  useEffect(() => {
    //Show date picker for traffic and conversions
    if (
      selectedTab === "link acquisitions" ||
      selectedTab === "traffic" ||
      selectedTab === "conversions"
    )
      setShowDatePicker(true);
    else setShowDatePicker(false);

    //Reset selected date when tab changes
    //Link Aquisions does not have load time so no need to temporarily reset
    if (selectedTab !== "link acquisitions") {
      setSelectedDate(null);
      setSelectedDropdown("Custom");
      setReportData([]);
    }
  }, [
    selectedTab,
    setReportData,
    setSelectedDate,
    setSelectedDropdown,
    setShowDatePicker,
  ]);
  return (
    <div>
      <Radio.Group
        value={selectedTab}
        onChange={e => setSelectedTab(e.target.value)}
        style={{ marginBottom: "20px" }}
      >
        <Radio.Button value="keywords">Keywords</Radio.Button>
        <Radio.Button value="traffic">Traffic</Radio.Button>
        <Radio.Button value="link acquisitions">Link Acquisitions</Radio.Button>
        <Radio.Button value="conversions">Conversions</Radio.Button>
      </Radio.Group>

      {selectedTab === "keywords" && hasKeywordRankings ? (
        <SEOKeywordsProvider reportKey={keywordsReportKey}>
          <KeywordRankingsView setReportData={setReportData} />
        </SEOKeywordsProvider>
      ) : (
        <></>
      )}
      {selectedTab === "traffic" && (
        <SEOTrafficProvider reportKey={trafficReportKey}>
          <TrafficView
            domain={domain}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setReportData={setReportData}
          />
        </SEOTrafficProvider>
      )}
      {selectedTab === "link acquisitions" && (
        <SEOLinkAcquisitionsProvider dataRaw={linkAcquisitionData}>
          <LinkAcquisitionsView
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setReportData={setReportData}
          />
        </SEOLinkAcquisitionsProvider>
      )}
      {selectedTab === "conversions" && (
        <SEOConversionsProvider reportKey={conversionsReportKey}>
          <ConversionsView
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setReportData={setReportData}
          />
        </SEOConversionsProvider>
      )}
    </div>
  );
};

export default ReportingTab;
