import {
  Card,
  Col,
  Input,
  Row,
  Radio,
  Space,
  Tag,
  Table,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import DetailBar from "../../components/DetailBar";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DesktopOutlined,
  MobileOutlined,
  MonitorOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { formatNumber2SigFig } from "../../../../../../core/utils/campaigns";
import { PrimaryTableRowText } from "../../../../../shared/globalStyling/styledText";
import { useSEOKeywordContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOKeywordsContext";
import { LoadingPage } from "../../../../../shared/LoadingPage";

const { Text } = Typography;
const RankImproveIcon = (currentRank, compareRank) => {
  if (currentRank === compareRank) {
    return compareRank;
  } else if (currentRank < compareRank) {
    const diff = Math.abs(currentRank - compareRank);
    return (
      <Space>
        {compareRank}
        <Text type="success">
          <ArrowUpOutlined />
          {formatNumber2SigFig(diff)}
        </Text>
      </Space>
    );
  } else {
    const diff = Math.abs(compareRank - currentRank);
    return (
      <Space>
        {compareRank}
        <Text type="danger">
          <ArrowDownOutlined />
          {formatNumber2SigFig(diff)}
        </Text>
      </Space>
    );
  }
};

const KeywordRankingsView = ({ setReportData }) => {
  const {
    reportData,
    reportLoading,
    summaryDetails,
    keywordTableRankings,
    selectedDevice,
    setSelectedDevice,
    onSearchKeywordRankings,
  } = useSEOKeywordContext();

  useEffect(() => {
    if (keywordTableRankings && keywordTableRankings.length > 0)
      setReportData(
        keywordTableRankings.map(k => ({
          Keyword: k.keyword,
          "Current Rank": k.currentRank ? k.currentRank : "-",
          "Previous Week": k.lastWeekRank ? k.lastWeekRank : "-",
          "Previous Month": k.lastMonthRank ? k.lastMonthRank : "-",
          Desktop: k.desktopRank ? k.desktopRank : "-",
          Mobile: k.mobileRank ? k.mobileRank : "-",
          Tablet: k.tabletRank ? k.tabletRank : "-",
        }))
      );
  }, [keywordTableRankings, setReportData]);

  if (reportLoading) return <LoadingPage />;
  if (!reportData) {
    return <div>No rankings data available</div>;
  }

  let columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (text, record) => (
        <div>
          <PrimaryTableRowText text={text} />
          {record.locations.map(location => (
            <Tag>{location}</Tag>
          ))}
        </div>
      ),
      sorter: (a, b) => a.keyword.localeCompare(b.keyword),
    },
    {
      title: "Current Rank",
      dataIndex: "currentRank",
      key: "currentRank",
      render: text => {
        return text ? text : "-";
      },
      sorter: (a, b) => a.currentRank - b.currentRank,
    },
    {
      title: "Previous Week",
      dataIndex: "lastWeekRank",
      key: "lastWeekRank",
      render: (text, record) => {
        const { currentRank } = record;
        return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
      },
      sorter: (a, b) => a.lastWeekRank - b.lastWeekRank,
    },
    {
      title: "Previous Month",
      dataIndex: "lastMonthRank",
      key: "lastMonthRank",
      render: (text, record) => {
        const { currentRank } = record;
        return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
      },
      sorter: (a, b) => a.lastMonthRank - b.lastMonthRank,
    },
  ];

  if (selectedDevice === "all") {
    columns = [
      ...columns,
      {
        title: "Desktop",
        dataIndex: "desktopRank",
        key: "desktopRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
        },
      },
      {
        title: "Mobile",
        dataIndex: "mobileRank",
        key: "mobileRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
        },
      },
      {
        title: "Tablet",
        dataIndex: "tabletRank",
        key: "tabletRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
        },
      },
    ];
  }

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DetailBar dataToDisplay={summaryDetails} />
        </Col>
        <Col xs={24}>
          <Card
            title={"Top Google Keyword Positions"}
            extra={
              <Row gutter={[16, 16]} align="middle">
                <Col>
                  <Radio.Group
                    value={selectedDevice}
                    onChange={e => {
                      const device = e.target.value;
                      setSelectedDevice(device);
                    }}
                  >
                    <Radio.Button value="all">
                      <Space>
                        <MonitorOutlined />
                        All Device
                      </Space>
                    </Radio.Button>
                    <Radio.Button value="desktop">
                      <Space>
                        <DesktopOutlined />
                        Desktop
                      </Space>
                    </Radio.Button>
                    <Radio.Button value="mobile">
                      <Space>
                        <MobileOutlined />
                        Mobile
                      </Space>
                    </Radio.Button>
                    <Radio.Button value="tablet">
                      <Space>
                        <TabletOutlined />
                        Tablet
                      </Space>
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col flex="auto">
                  <Input.Search
                    placeholder="Search keywords"
                    onSearch={onSearchKeywordRankings}
                    style={{ width: 200 }}
                    allowClear={true}
                  />
                </Col>
              </Row>
            }
          >
            <Table
              rowKey={row => row.keyword}
              columns={columns}
              dataSource={keywordTableRankings}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default KeywordRankingsView;
