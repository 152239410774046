import React, { useEffect, useState } from "react";
import { Modal, Select, Row, Typography, Col, Button, Tabs, Space } from "antd";
import { isNil, cloneDeep } from "lodash";
import { InfoCircleTwoTone } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const AudienceShare = ({
  modalToggle,
  selectedRows,
  onCancel,
  onSubmit,
  orgs,
}) => {
  //Generate default to be saved data
  const [toBeSavedData, setToBeSavedData] = useState([]);
  const [batchOrgs, setBatchOrgs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("multipleSelection");

  useEffect(() => {
    setToBeSavedData(
      selectedRows && !isNil(selectedRows)
        ? selectedRows.map(s => {
            console.log(s);
            return {
              displayValues: !isNil(s.orgs) ? s.orgs.map(o => o.id) : [],
              ...s,
            };
          })
        : []
    );
    //Reset batch orgs
    setBatchOrgs([]);
    setSelectedTab("multipleSelection");
  }, [selectedRows]);
  //Create Share Components for each audiences
  const audienceToShare =
    toBeSavedData && !isNil(toBeSavedData)
      ? toBeSavedData.map((data, idx) => {
          const { id, name, displayValues } = data;
          const orgsChoices = [];

          //Generate Orgs Option for sharing
          !isNil(orgs) &&
            orgs &&
            orgs.forEach(org => {
              orgsChoices.push(
                <Option key={org.id} value={org.id}>
                  {org.name}
                </Option>
              );
            });

          const onChange = value => {
            const cloneTobeSaved = cloneDeep(toBeSavedData);
            cloneTobeSaved[idx].displayValues = value;

            setToBeSavedData(cloneTobeSaved);
          };

          return (
            <Row key={id} style={{ marginBottom: `10px` }}>
              <Col xs={24}>
                <Title level={5}>Share {name}'s Audience with:</Title>
                <Select
                  showSearch
                  key={record => record.id}
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please Select Organization"
                  value={displayValues}
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {orgsChoices}
                </Select>
              </Col>
            </Row>
          );
        })
      : [];
  return (
    <Modal
      title={`Share Audiences`}
      visible={modalToggle}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onOk={() => {
        onSubmit({ mode: selectedTab, data: toBeSavedData, batchOrgs });
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          {"Cancel"}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            onSubmit({ mode: selectedTab, data: toBeSavedData, batchOrgs });
          }}
        >
          {"Update"}
        </Button>,
      ]}
    >
      <Tabs
        onChange={activeKey => {
          setSelectedTab(activeKey);
        }}
      >
        <TabPane tab="Multiple Selection" key="multipleSelection">
          {audienceToShare}
        </TabPane>
        <TabPane tab="Batch Selection" key="batchSelection">
          <Row>
            <Space direction="vertical">
              <Text>
                <InfoCircleTwoTone /> Use this to add new organizations to
                multiple campaigns at once. Please note that addition/removal of
                orgs set from the Multiple Selection tab will not reflect/be
                saved here.
              </Text>

              <Title level={5}>
                You've chosen to share the following{" "}
                {toBeSavedData.length > 1 ? "audiences" : "audience"}:
              </Title>
              {toBeSavedData.map(audience => {
                return <Text key={audience.id}>{audience.name}</Text>;
              })}
            </Space>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Title level={5}>
              Below you can select the orgs to share these audience with:
            </Title>
            <Select
              showSearch
              key={record => record.id}
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please Select Organization"
              value={batchOrgs}
              onChange={values => {
                setBatchOrgs(values);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {Array.isArray(orgs) &&
                orgs.map(org => {
                  return (
                    <Option key={org.id} value={org.id}>
                      {org.name}
                    </Option>
                  );
                })}
            </Select>
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default AudienceShare;
