import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
// Queries
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import LIST_ORGS_ADMIN from "../.././GraphQl/Queries/LIST_ORGS_ADMIN";
// Mutations
import CREATE_ORG_ADMIN from "../.././GraphQl/Mutations/CREATE_ORG_ADMIN";
import EDIT_ORG_ADMIN from "../.././GraphQl/Mutations/EDIT_ORG_ADMIN";
import EDIT_ORG_SELF from "../.././GraphQl/Mutations/EDIT_ORG_SELF";
import DELETE_ORG_ADMIN from "../.././GraphQl/Mutations/DELETE_ORG_ADMIN";
import DISABLE_ORG from "../../../core/GraphQl/Mutations/DISABLE_ORG";
import ENABLE_ORG from "../../../core/GraphQl/Mutations/ENABLE_ORG";
// Local Mutations
import UPDATE_USER from "../../../core/GraphQl/Mutations/UPDATE_USER";

// Locals
import Controller from "./Controller";
import OverlaySpinner from "../../.././platform/shared/OverlaySpinner";

import { getRolesFeatures } from "../../utils/dataManipulating/organization";

// Constant
import { errorType } from "../../utils/constants/constants";

import { isEmpty, cloneDeep, isBoolean, get } from "lodash";
import UPDATE_ORG_SEND_EMAIL_CONFIG from "../../GraphQl/Mutations/UPDATE_ORG_SEND_EMAIL_CONFIG";
const Loader = props => {
  const { currentUser } = props;

  const loggedInRole = currentUser.role;
  const [pageSize] = useState(300);
  const [pageNumber, setPageNumber] = useState(1);
  //-----------------------------
  //const currentUsersOrg = currentUser.role.org.name;
  //const currentUsersOrgId = currentUser.role.org.id;
  const config = currentUser.role.org.config;
  const [newOrgs, setNewOrgs] = useState([]);

  //----------------------------

  const createOrg = async data => {
    const {
      name,
      description,
      level,
      chatWootId,
      cpm,
      website,
      domain,
      logo,
      parentOrgId,
      roleitems,
      parentOrg,
      config,
      dataConfig,
      signInLogo,
      platformDomain,
      tagDomain,
    } = data;

    if (!dataConfig.reTargeting) {
      dataConfig.appIds = [];
    }

    try {
      const response = await props.createOrgMutation({
        variables: {
          name,
          description,
          level,
          chatWootId,
          cpm: cpm.toLocaleString(),
          website,
          domain: isEmpty(domain) ? null : domain,
          platformDomain: isEmpty(platformDomain) ? null : platformDomain,
          tagDomain: isEmpty(tagDomain) ? null : tagDomain,
          logoId: isEmpty(logo) ? null : { connect: { id: logo } },
          signInLogoId: isEmpty(signInLogo)
            ? null
            : { connect: { id: signInLogo.id } },
          parentOrgId,
          roleitems,
          parentOrgs: parentOrg.map(org => {
            return { id: org.id };
          }),
          config,
          reTargeting: dataConfig.reTargeting,
          appIds: dataConfig.appIds,
          tradeDeskIdentifier: dataConfig.tradeDeskIdentifier,
          storageBucket: dataConfig.storageBucket,
          googleCustomerId: dataConfig.googleCustomerId,
          seo: dataConfig.seo,
          semrushEnable: dataConfig.semrushEnable,
          projectId: dataConfig.semrushConfig.projectId,
          rootDomainUrl: dataConfig.semrushConfig.rootDomainUrl,
          rootKeyword: dataConfig.semrushConfig.rootKeyword,
          competitor1stRootDomainUrl:
            dataConfig.semrushConfig.competitor1stRootDomainUrl,
          competitor2ndRootDomainUrl:
            dataConfig.semrushConfig.competitor2ndRootDomainUrl,
          viewId: dataConfig.googleAnalytics.viewId,
          isGA4: dataConfig.googleAnalytics.isGA4,
        },
        update: (store, { data: { createOrg } }) => {
          let newOrgsSlice = newOrgs.slice();
          newOrgsSlice.splice(0, 0, createOrg);
          setNewOrgs(newOrgsSlice);
        },
      });
      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createOrg,
        };
      }
      return response;
    } catch (err) {
      const { nameDuplicate, domainDuplicate } = errorType;
      if (err.message === nameDuplicate) {
        return {
          status: "failure",
          error: nameDuplicate,
        };
      } else if (err.message === domainDuplicate) {
        return {
          status: "failure",
          error: domainDuplicate,
        };
      } else {
        return {
          status: "failure",
          error: err,
        };
      }
    }
  };

  const disableOrg = async record => {
    try {
      const response = await props.disableOrg({
        variables: { id: record.id },
        update: (store, { data: { disableOrg } }) => {
          let newOrgsSlice = newOrgs.slice();
          const indexToUpdate = newOrgs.findIndex(
            org => org.id === disableOrg.id
          );
          newOrgsSlice.splice(indexToUpdate, 1, disableOrg);
          setNewOrgs(newOrgsSlice);
        },
      });
      if (response) {
        return {
          data: response.data.disableOrg,
          status: "success",
          error: null,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const enableOrg = async record => {
    try {
      const response = await props.enableOrg({
        variables: { id: record.id },
        update: (store, { data: { enableOrg } }) => {
          let newOrgsSlice = newOrgs.slice();
          const indexToUpdate = newOrgsSlice.findIndex(
            org => org.id === enableOrg.id
          );
          newOrgsSlice.splice(indexToUpdate, 1, enableOrg);
          setNewOrgs(newOrgsSlice);
        },
      });
      if (response) {
        return {
          data: response.data.enableOrg,
          status: "success",
          error: null,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const deleteOrg = async record => {
    try {
      const response = await props.deleteOrgMutation({
        variables: {
          Id: record.id,
        },
        update: (store, { data: { deleteOrg } }) => {
          let newOrgsSlice = newOrgs.slice();
          newOrgsSlice = newOrgsSlice
            .map(org => {
              return {
                ...org,
                parentOrg: org.parentOrg.filter(org => org.id !== deleteOrg.id),
              };
            })
            .filter(org => org.id !== deleteOrg.id);
          setNewOrgs(newOrgsSlice);
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const editOrg = async data => {
    const {
      id,
      name,
      description,
      level,
      chatWootId,
      cpm,
      website,
      domain,
      logo,
      parentOrgId,
      roleitems,
      roles,
      defaultOrg,
      config,
      dataConfig,
      signInLogo,
      platformDomain,
      tagDomain,
    } = data;
    if (name === defaultOrg) {
      try {
        const response = await props.updateOrgBasic({
          variables: {
            id,
            name,
            description,
            level,
            chatWootId,
            cpm: cpm.toLocaleString(),
            website,
            domain: isEmpty(domain) ? null : domain,
            logoId: isEmpty(logo) ? null : { id: logo.id },
            signInLogoId: isEmpty(signInLogo) ? null : { id: signInLogo.id },
            platformDomain: isEmpty(platformDomain) ? null : platformDomain,
            tagDomain: isEmpty(tagDomain) ? null : tagDomain,
          },
          update: (store, { data: { updateOrg } }) => {
            const data = cloneDeep(
              store.readQuery({
                query: LIST_ORGS_ADMIN,
                variables: {
                  parentid: loggedInRole.org.id,
                  skip: (Number(pageNumber) - 1) * pageSize,
                },
              })
            );
            const indexToUpdate = data.orgs.findIndex(
              org => org.id === updateOrg.id
            );
            data.orgs.splice(indexToUpdate, 1, updateOrg);
            store.writeQuery({
              query: LIST_ORGS_ADMIN,
              variables: {
                parentid: loggedInRole.org.id,
                skip: (Number(pageNumber) - 1) * pageSize,
              },
              data,
            });
          },
        });

        if (response) {
          return {
            status: "success",
            error: null,
            data: response.data.updateOrg,
          };
        }
        return response;
      } catch (err) {
        return {
          status: "failure",
          error: err,
        };
      }
    } else {
      if (config) delete config.__typename;
      if (dataConfig) delete dataConfig.__typename;
      // if config is null
      let tempConfig = !config ? { providersVisible: false } : config;

      if (!dataConfig.reTargeting) {
        dataConfig.appIds = [];
      }

      // The Org's master flag. Might be changed to look at the type field
      const roleId = roles.find(role => role.isMaster).id;

      try {
        const response = await props.updateOrgMutation({
          variables: {
            id,
            name,
            description,
            level,
            chatWootId,
            cpm: cpm.toLocaleString(),
            website,
            domain: isEmpty(domain) ? null : domain,
            logoId: isEmpty(logo) ? null : { id: logo.id },
            signInLogoId: isEmpty(signInLogo) ? null : { id: signInLogo.id },
            platformDomain: isEmpty(platformDomain) ? null : platformDomain,
            tagDomain: isEmpty(tagDomain) ? null : tagDomain,
            parentOrgId,
            roleitems,
            roleId,
            createConfig: tempConfig,
            updateConfig: tempConfig,
            reTargeting: dataConfig.reTargeting,
            appIds: dataConfig.appIds,
            tradeDeskIdentifier: dataConfig.tradeDeskIdentifier,
            storageBucket: dataConfig.storageBucket,
            googleCustomerId: dataConfig.googleCustomerId,
            seo: isBoolean(dataConfig.seo) ? dataConfig.seo : false,
            semrushEnable: dataConfig.semrushEnable,
            projectId: dataConfig.semrushConfig
              ? dataConfig.semrushConfig.projectId
              : null,
            rootDomainUrl: dataConfig.semrushConfig
              ? dataConfig.semrushConfig.rootDomainUrl
              : null,
            rootKeyword: dataConfig.semrushConfig
              ? dataConfig.semrushConfig.rootKeyword
              : null,
            competitor1stRootDomainUrl: dataConfig.semrushConfig
              ? dataConfig.semrushConfig.competitor1stRootDomainUrl
              : null,
            competitor2ndRootDomainUrl: dataConfig.semrushConfig
              ? dataConfig.semrushConfig.competitor2ndRootDomainUrl
              : null,
            viewId: get(dataConfig.googleAnalytics, "viewId", null),
            isGA4: dataConfig.googleAnalytics
              ? dataConfig.googleAnalytics.isGA4
              : false,
            bingCustomerId: dataConfig.bing ? dataConfig.bing.customerId : null,
            bingAccountId: dataConfig.bing ? dataConfig.bing.accountId : null,
            processTag: dataConfig.tagProcessingConfig
              ? dataConfig.tagProcessingConfig.processTag
              : false,
            paidSearchIsEnable: dataConfig.paidSearchDataConfig
              ? dataConfig.paidSearchDataConfig.isEnabled
              : false,
          },
          refetchQueries: [
            {
              query: LIST_ORGS_ADMIN,
              variables: {
                parentid: loggedInRole.org.id,
                skip: (Number(pageNumber) - 1) * pageSize,
              },
            },
          ],
        });
        if (response) {
          return {
            status: "success",
            error: null,
            data: response.data.updateParentOrg,
          };
        }
        return response;
      } catch (err) {
        console.log(err);
        return {
          status: "failure",
          error: err,
        };
      }
    }
  };

  // Assume Role
  const assumeRole = async previewRole => {
    const featuresTemp = getRolesFeatures(previewRole.roles);

    const roleTemp = {};

    roleTemp.roleItems = previewRole.roles[0].roleItems;
    roleTemp.features = featuresTemp;
    roleTemp.org = previewRole;

    const isAdmin = previewRole.config ? previewRole.config.isAdmin : false;

    const isDemo = previewRole.config ? previewRole.config.isDemo : false;

    const {
      isAuthenticated,
      id,
      username,
      email,
      cognitoUserId,
      avatar,
      notifications,
      msaStatus,
      ssoToken,
      campaignTableDisplayColumns,
    } = currentUser;

    const currentUserPermissions = currentUser.permission;

    const userData = {
      isAuthenticated,
      id,
      username,
      email,
      cognitoUserId,
      avatar,
      features: featuresTemp,
      notifications,
      defaultRole: currentUser,
      role: roleTemp,
      isAdmin,
      isDemo,
      isPreviewOrg: true,
      permission: {
        ...previewRole.config,
        isSelfService: currentUserPermissions.isSelfService,
      },
      msaStatus,
      ssoToken,
      campaignTableDisplayColumns:
        campaignTableDisplayColumns && campaignTableDisplayColumns.length
          ? campaignTableDisplayColumns
          : [],
    };

    localStorage.setItem("userData", JSON.stringify(userData));
    await props.updateUser({
      variables: { ...userData },
    });
    props.history.push("/");
  };

  const updateOrgSendEmailConfig = async (data, id) => {
    const {
      campaignOrders: campaignOrdersValue,
      email,
      frequency,
      dayOfWeek,
    } = data;

    let campaignOrders = campaignOrdersValue;
    try {
      if (Array.isArray(campaignOrdersValue)) {
        campaignOrders = campaignOrdersValue.map(obj => obj.key).join(",");
      }

      const response = await props.updateOrgSendEmailConfig({
        variables: {
          id,
          campaignOrders,
          email,
          frequency,
          dayOfWeek,
        },
      });

      if (response) {
        return response;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  // Get All list orgs Admin
  const {
    loading: loadingOrgs,
    error: errorOrgs,
    data: dataOrgs,
    refetch: refetchOrganizations,
  } = useQuery(LIST_ORGS_ADMIN, {
    variables: {
      parentid: loggedInRole.org.id,
      first: pageSize,
      skip: (Number(pageNumber) - 1) * pageSize,
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      // Added this function to stop the orgs getting duplicated in the state, weird behaviour TBH
    },
  });

  const { loading: loadingDealIds, data: getDealIds } = useQuery(
    LIST_ORGS_ADMIN,
    {
      variables: {
        parentid: loggedInRole.org.id,
      },
      fetchPolicy: "no-cache",
    }
  );
  const dataDealIds =
    getDealIds && getDealIds.orgs
      ? getDealIds.orgs.flatMap(org => org.dealIds)
      : [];

  if (loadingOrgs || loadingDealIds) return <OverlaySpinner />;

  let recursive = true;

  if (recursive && dataOrgs && dataOrgs.orgs.length > 0) {
    let newOrgsSlice = newOrgs.slice();
    newOrgsSlice.push(...dataOrgs.orgs);
    setNewOrgs(newOrgsSlice);
    setPageNumber(pageNumber + 1);
  } else {
    recursive = false;
  }

  if (errorOrgs) return `Error (Query: LIST_ORGS_ADMIN)! ${errorOrgs.message}`;

  return (
    <Controller
      organizations={newOrgs}
      createOrg={createOrg}
      editOrg={editOrg}
      disableOrg={disableOrg}
      deleteOrg={deleteOrg}
      enableOrg={enableOrg}
      refetchOrganizations={refetchOrganizations}
      currentuser={props.currentUser}
      currentOrgConfig={config}
      assumeRole={assumeRole}
      updateOrgSendEmailConfig={updateOrgSendEmailConfig}
      loadingOrgs={loadingOrgs}
      dataDealIds={dataDealIds}
    />
  );
};

export default compose(
  graphql(UPDATE_USER, { name: "updateUser" }),
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(CREATE_ORG_ADMIN, { name: "createOrgMutation" }),
  graphql(EDIT_ORG_ADMIN, { name: "updateOrgMutation" }),
  graphql(EDIT_ORG_SELF, { name: "updateOrgBasic" }),
  graphql(DISABLE_ORG, { name: "disableOrg" }),
  graphql(ENABLE_ORG, { name: "enableOrg" }),
  graphql(DELETE_ORG_ADMIN, { name: "deleteOrgMutation" }),
  graphql(UPDATE_ORG_SEND_EMAIL_CONFIG, { name: "updateOrgSendEmailConfig" })
)(Loader);
