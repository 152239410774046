import React from "react";
import { Row, Col, Progress, Space, Table } from "antd";
import {
  FlightDateText,
  PrimaryTableRowText,
  StandardTableRowText,
  TableColumnTitle,
} from "../../../shared/globalStyling/styledText";
import moment from "moment";
import {
  formatCurrency2SigFig,
  formatWholeNumber,
} from "../../../../core/utils/campaigns";

const Frag = React.Fragment;
const EditorialTable = ({
  data,
  history,
  selectedRowKeys,
  onRowChange,
  location,
  total,
  current,
  pageSize,
}) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "15%",
      align: "left",

      render: text => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <PrimaryTableRowText text={text} />
            </Col>
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      dataIndex: "startDate",
      key: "startDate",
      align: "left",
      width: "14%",
      footerContent: " ",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment().format("MM/DD/YY");
        const startDate = record.startDate ? moment(record.startDate) : null;
        const endDate = record.endDate ? moment(record.endDate) : null;
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row type="flex" justify="start">
              {record.startDate && (
                <Col>
                  <FlightDateText
                    text={startDate ? startDate.format("MM/DD/YY") : null}
                  />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.endDate && (
                <Col>
                  <FlightDateText
                    text={endDate ? endDate.format("MM/DD/YY") : null}
                  />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Views"} />,
      dataIndex: "customers",
      key: "customers",
      align: "left",
      width: "14%",
      render: (text, record) => {
        return (
          <Col>
            <Row type="flex" justify="start">
              <Space>
                <PrimaryTableRowText text={"Page Views:"} />
                <StandardTableRowText text={formatWholeNumber(record.clicks)} />
              </Space>
            </Row>
            <Row type="flex" justify="start">
              <Space>
                <PrimaryTableRowText text={"Customers:"} />

                <StandardTableRowText
                  text={formatWholeNumber(record.customers)}
                />
              </Space>
            </Row>
          </Col>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Transactions"} />,
      dataIndex: "transactions",
      key: "transactions",
      align: "left",
      width: "14%",
      render: (text, record) => {
        return (
          <Col>
            <Row type="flex" justify="start">
              <PrimaryTableRowText text={"Transactions: ‎"} />
              <StandardTableRowText
                text={formatWholeNumber(record.transactions)}
              />
            </Row>
            <Row type="flex" justify="start">
              <PrimaryTableRowText text={"Revenue: ‎"} />
              <StandardTableRowText
                text={formatCurrency2SigFig(record.revenue)}
              />
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <Table
        onRow={data => ({
          onClick: () => {
            const { id } = data;
            history.push(`/editorial/details/${id}/1`);
          },
        })}
        dataSource={data}
        pagination={{
          hideOnSinglePage: false,
          position: ["none", "bottomCenter"],
        }}
        size="default"
        columns={columns}
        scroll={{ x: 1200 }}
        bordered={false}
        rowKey={record => record.id}
        rowSelection={{
          onChange: onRowChange,
          selectedRowKeys,
          columnWidth: "5%",
        }}
      />
    </div>
  );
};

export default EditorialTable;
