import React from "react";
import { Card, Row } from "antd";
import { Chart, Axis, Geom, Tooltip } from "bizcharts";
import {
  CardTitleText,
  CardText,
} from "../../../../../../shared/globalStyling/styledText";

import { ExtraInfo } from "./";
import {
  formatWholeNumber,
  formatWholeNumberNaN,
} from "../../../../../../../core/utils/campaigns";

const VisitsByDayOfWeekCardV2 = ({
  data,
  geoDates: { start, end },
  fullDayName = false,
  customLabel,
  showTotalVisits = true,
  explanation = "",
}) => {
  const geoDatesDiff = end.diff(start, "days");
  const startDate = start.format("MMMM DD, YYYY");
  const endDate = end.format("MMMM DD, YYYY");

  const cols = {
    count: {
      min: 0,
    },
  };

  // Map original day names to shorter names
  let dayMapping = {
    Sun: "Sun",
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat",
  };

  if (fullDayName === true) {
    dayMapping = {
      SUNDAY: "Sun",
      MONDAY: "Mon",
      TUESDAY: "Tue",
      WEDNESDAY: "Wed",
      THURSDAY: "Thu",
      FRIDAY: "Fri",
      SATURDAY: "Sat",
    };
  }

  const dayOfWeek = data.map(item => ({
    Day: dayMapping[item.day],
    FullDay: item.day,
    Visits: item.count,
  }));

  const totalVisits = dayOfWeek.reduce(
    (accumulator, currentValue) => accumulator + currentValue["Visits"],
    0
  );

  return (
    <Card
      title={
        <CardTitleText
          text={`${customLabel ? customLabel : "Walk Ins"}: By Day Of Week`}
        />
      }
      style={{
        height: "34.6em",
      }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2%",
      }}
      headStyle={{ borderWidth: "2px" }}
      bordered
      extra={
        explanation ? (
          <ExtraInfo placement={"top"} title={explanation} />
        ) : (
          false
        )
      }
    >
      <Row type="flex" justify="center">
        <CardText
          text={`${startDate} - ${endDate} (${geoDatesDiff} days) ${
            showTotalVisits
              ? `- ${formatWholeNumberNaN(totalVisits)} Visits`
              : ""
          }`}
        />
      </Row>
      <Chart
        padding={["auto", "auto", "auto", "auto"]}
        height={315}
        scale={cols}
        data={dayOfWeek}
        forceFit
      >
        <Axis name="Day" title={false} />
        <Axis
          name="Visits"
          title={false}
          label={{
            formatter(visits) {
              return formatWholeNumber(visits);
            },
          }}
        />
        <Tooltip
          crosshairs={{
            type: "y",
          }}
        />
        <Geom
          size={20}
          type="intervalStack"
          position="Day*Visits"
          color="#0077fe"
          style={{
            stroke: "#fff",
            lineWidth: 1,
          }}
        />
      </Chart>
    </Card>
  );
};

export default VisitsByDayOfWeekCardV2;
