import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Modal,
  Col,
  Select,
  Card,
  Form,
  Input,
  Typography,
  Table,
  Tag,
  DatePicker,
  message,
} from "antd";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
const { Title, Text } = Typography;
const { CheckableTag } = Tag;

const LaunchAdvertiser = ({ tags, open, onCancel, currentOrgId, onCreate }) => {
  const formattedTags = tags.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
      website: tag.orgs[0].website,
      isOwned: tag.orgs[0].id === currentOrgId,
      isAdvertiser: tag.isAdvertiser === true,
      transactions: tag.transactions,
      pageViews: tag.pageViews,
      basketItems: tag.basketItems,
      signups: tag.signups,
      selectedImpresionsTagOrgId: tag.orgs[0].id,
    };
  });

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableTags, setTableTags] = useState(formattedTags);
  const [selectedInsertionsOrderId, setSelectedInsertionsOrderId] = useState(
    []
  );

  const initialRowSelectedTags = formattedTags.reduce((result, tag) => {
    result[tag.value] = [];
    if (tag.transactions > 0) {
      result[tag.value].push("Transactions");
    }
    if (tag.pageViews > 0) {
      result[tag.value].push("Page Views");
    }
    if (tag.signups > 0) {
      result[tag.value].push("Sign Ups");
    }
    if (tag.basketItems > 0) {
      result[tag.value].push("Basket Items");
    }
    return result;
  }, {});
  const [rowSelectedTags, setRowSelectedTags] = useState(
    initialRowSelectedTags
  );

  useEffect(() => {
    if (selectedInsertionsOrderId.length > 0) {
      form.setFieldsValue({
        insertionOrderIds: selectedInsertionsOrderId,
      });
    }
  }, [form, selectedInsertionsOrderId]);

  // Handle change of measurement tags
  const handleChange = (tag, checked, rowValue) => {
    setRowSelectedTags(prevRowSelectedTags => {
      const updatedRowSelectedTags = { ...prevRowSelectedTags };
      updatedRowSelectedTags[rowValue] = checked
        ? [...updatedRowSelectedTags[rowValue], tag]
        : updatedRowSelectedTags[rowValue].filter(t => t !== tag);
      return updatedRowSelectedTags;
    });
  };

  // Mapping of measurement tags to enum values
  const measurementMapping = {
    Transactions: "TRANSACTIONS",
    "Page Views": "PAGE_VIEWS",
    "Basket Items": "BASKET_ITEMS",
    "Sign Ups": "SIGN_UPS",
  };

  const tagsColumn = [
    {
      title: <TableColumnTitle text={"Measurement Tag Name"} />,
      dataIndex: "label",
      key: "label",
      align: "left",

      render: text => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <PrimaryTableRowText text={text} />
            </Col>
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Measured Events"} />,
      dataIndex: "measured",
      key: "measured",
      align: "left",

      render: (text, record) => {
        return (
          <Row type="flex" justify="start">
            {record.transactions > 0 ||
            record.pageViews > 0 ||
            record.signups > 0 ||
            record.basketItems > 0 ? (
              <>
                {record.transactions > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes(
                      "Transactions"
                    )}
                    onChange={checked =>
                      handleChange("Transactions", checked, record.value)
                    }
                  >
                    Transactions
                  </CheckableTag>
                )}
                {record.pageViews > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes(
                      "Page Views"
                    )}
                    onChange={checked =>
                      handleChange("Page Views", checked, record.value)
                    }
                  >
                    Page Views
                  </CheckableTag>
                )}
                {record.signups > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes("Sign Ups")}
                    onChange={checked =>
                      handleChange("Sign Ups", checked, record.value)
                    }
                  >
                    Sign Ups
                  </CheckableTag>
                )}
                {record.basketItems > 0 && (
                  <CheckableTag
                    checked={rowSelectedTags[record.value].includes(
                      "Basket Items"
                    )}
                    onChange={checked =>
                      handleChange("Basket Items", checked, record.value)
                    }
                  >
                    Basket Items
                  </CheckableTag>
                )}
              </>
            ) : (
              <Tag color="default">No Measured Events</Tag>
            )}
          </Row>
        );
      },
    },
  ];

  const onChangeClicks = (value, option) => {
    form.setFieldsValue({
      domain: option.website,
      selectedImpresionsTagOrgId: option.selectedImpresionsTagOrgId,
    });
  };

  // Format values to match enums
  const formatTrackMeasurements = rowValue => {
    const trackMeasurementsValue = rowSelectedTags[rowValue];
    const formattedValues = trackMeasurementsValue.map(
      measurement => measurementMapping[measurement]
    );
    return formattedValues;
  };

  // Real-time update of measurement values
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const rowValue = selectedRowKeys[0];
      const formatted = formatTrackMeasurements(rowValue);
      form.setFieldsValue({
        transactionsTag: rowValue,
        trackMesurements: formatted,
      });
    } else {
      form.setFieldsValue({
        transactionsTag: undefined,
        trackMesurements: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelectedTags, selectedRowKeys, form]);

  // get transactionsTagOrgId
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const selectedRowKey = selectedRowKeys[0];
      const selectedTag = tags.find(tag => tag.id === selectedRowKey);
      if (selectedTag && selectedTag.orgs && selectedTag.orgs.length > 0) {
        form.setFieldsValue({ transactionsTagOrgId: selectedTag.orgs[0].id });
      }
    } else {
      form.setFieldsValue({ transactionsTagOrgId: undefined });
    }
  }, [form, selectedRowKeys, tags]);

  return (
    <Modal
      title="Add Advertiser Funnel"
      visible={open}
      okText="Create"
      cancelText="Cancel"
      width={"75%"}
      onOk={() => {
        form
          .validateFields()
          .then(async values => {
            message.loading(`Creating Advertisers`);
            await onCreate(values);
            message.destroy();
            message.success(`Advertisers Created Successfully`);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Card title={"Funnel Details"}>
        <Form form={form} layout="vertical" name={"launchAdvertiserForm"}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                required={false}
                rules={[
                  {
                    required: true,
                    message: "Funnel Name is Required",
                  },
                ]}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Funnel Name</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Enter your desired Funnel Name
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <Input placeholder="Enter Funnel Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"impressionsTag"}
                required={false}
                rules={[
                  {
                    required: true,
                    message: "Impression Tag is Required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && getFieldValue("transactionsTag") === value) {
                        return Promise.reject(
                          new Error(
                            `The Impression Tag cannot be the same as the Transactions Tag!`
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Impression Tag</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Select the Impression Tag for this funnel
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Impression Tag"
                  showSearch={true}
                  filterOption={(input, option) => {
                    if (
                      option.label.toLowerCase().includes(input.toLowerCase())
                    )
                      return true;
                  }}
                  options={formattedTags.filter(
                    tag => tag.isOwned && tag.isAdvertiser
                  )}
                  filterSort={(optionA, optionB) =>
                    optionA.label
                      .toLowerCase()
                      .localeCompare(optionB.label.toLowerCase())
                  }
                  onChange={onChangeClicks}
                />
              </Form.Item>
              <Form.Item name="selectedImpresionsTagOrgId" hidden>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"insertionOrderIds"}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Campaign Order IDs</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Enter the Campaign Order IDs for this funnel (at least
                        1)
                      </Text>
                    </Col>
                  </Row>
                }
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value.length === 0) {
                        return Promise.reject(
                          new Error(
                            "Atleast One (1) Campaign Order ID is Required"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                    type: "array",
                  },
                ]}
              >
                <Col span={24}>
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Enter Campaign Order ID"
                    allowClear={true}
                    notFoundContent={null}
                    onChange={value => {
                      setSelectedInsertionsOrderId(value);
                    }}
                  />
                </Col>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                required={false}
                name={"startDate"}
                rules={[
                  {
                    required: true,
                    message: "Start Date is Required",
                  },
                ]}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Start Date</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Select the Start Date for this funnel
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <DatePicker
                  className="span-mobile"
                  style={{ width: "100%" }}
                  // value={startDate ? moment(startDate) : null}
                  // onChange={date => handleStartDateChange(date)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                required={false}
                name={"endDate"}
                rules={[
                  {
                    required: true,
                    message: "End Date is Required",
                  },
                ]}
                label={
                  <Row>
                    <Col span={24}>
                      <Title level={5}>End Date</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        Select the End Date for this funnel
                      </Text>
                    </Col>
                  </Row>
                }
              >
                <DatePicker
                  className="span-mobile"
                  style={{ width: "100%" }}
                  disabledDate={current => {
                    return current && current < moment().endOf("day");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={5}>Measurement Tags</Title>
            </Col>
            <Col span={24}>
              <Text type="secondary">
                Select a Measurement Tag for this funnel
              </Text>
            </Col>
            <Form.Item
              name={"transactionsTag"}
              rules={[{ required: true, message: "Measurement Tag Required" }]}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="transactionsTagOrgId" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name={"trackMesurements"}
              rules={[{ required: true, message: "Measured Events Required" }]}
            >
              <Input type="hidden" />
            </Form.Item>
          </Row>
          <Card
            title="Measurement Tags"
            extra={
              <Input.Search
                placeholder="Search Tags"
                allowClear
                onSearch={value => {
                  if (value && value !== "") {
                    const filteredTags = formattedTags.filter(
                      tag =>
                        tag.label.toLowerCase().includes(value.toLowerCase()) &&
                        !tag.isOwned
                    );

                    setTableTags(filteredTags);
                  } else {
                    setTableTags(formattedTags.filter(tag => !tag.isOwned));
                  }
                }}
              />
            }
          >
            <Table
              columns={tagsColumn}
              dataSource={tableTags}
              rowKey={record => record.value}
              rowSelection={{
                type: "radio",
                selectedRowKeys,
                onChange: (newSelectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(newSelectedRowKeys);
                },
              }}
            />
          </Card>
        </Form>
      </Card>
    </Modal>
  );
};

export default LaunchAdvertiser;
