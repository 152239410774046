export const cardBodyStyle = { padding: "0px", height: "650px" };
export const categoryList = {
  titleFontStyle: { fontSize: "17px", fontWeight: "bold" },
  listItemStyle: { padding: "21px 18px 21px 18px" },
  selectedFontStyle: { color: "white", fontSize: "17px" },
  selectedStyle: { backgroundColor: "black" },
};
export const subCategoryList = {
  listItemStyle: {
    padding: "20 0px 1px 20px",
    border: "0",
  },
  titleFontStyle: { fontSize: "15px" },
  selectedStyle: { backgroundColor: "black" },
  selectedFontStyle: { color: "white", fontSize: "14px" },
};
export const searchResultList = {
  selectedFontStyle: {
    color: "white",
    fontSize: "14px",
  },
  titleFontStyle: { fontSize: "15px" },
  selectedStyle: { backgroundColor: "black" },
  listItemStyle: { padding: "20 0px 1px 20px", border: "0" },
};
export const sideBarCardStyle = { height: "100%", minHeight: "80vh" };
export const gaugeStyle = { height: "140px", marginBottom: "1em" };
export const sideBarTitleStyle = {
  marginBottom: "5px",
};
