import React from "react";
import { Button, Row, Col, Card, Tabs } from "antd";
import {
  AreaChartOutlined,
  FileTextTwoTone,
  CheckCircleTwoTone,
  DownloadOutlined,
  FileImageOutlined,
  FileTextOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { flatten, isEmpty, capitalize } from "lodash";

// CONFIGS
import LaunchCampaignConfig from "./components/LaunchCampaignConfig";

// CONSTANTS
import { stateList } from "../../../core/utils/constants/constants";

// COMPONENTS
import { CampaignOrderQueueTable } from "./components/CampaignOrderQueueTable";
import ApproveCampaignButton from "./components/ApproveCampaignButton";
import CampaignStatus from "./components/CampaignStatus";
import Loader from "../../shared/Loader";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import CampaignHistories from "./components/CampaignHistories";
import { ReviewBasics, ReviewCreatives } from "../CampaignLauncher/components";
import LauncherMapSimple from "../../shared/maps/LauncherMapSimple";
import { GeoTargetsTable } from "../Campaigns/geotargetmanager/geoTargetManagementComponents/GeoTargetsTable";
import CampaignTrackings from "./components/CampaignTrackings";

// UTILS
import { kmToFt } from "../../../core/utils/campaigns";
import { multiImageZip } from "../../../core/utils/dataloading/multiImageZip";

const styles = {
  container: {
    border: "0.5px solid #e8e8e8",
    padding: "0.5px",
    display: "relative",
  },
  card: {
    marginBottom: "20px",
  },
  cardBody: {
    padding: 0,
  },
  map: {
    position: "relative",
    height: "300px",
    width: "100%",
  },
};

const { TabPane } = Tabs;
const { card, cardBody } = styles;
const campaignTypes = {
  DISPLAY: 1,
};

const CampaignOrderQueueView = ({
  campaignOrders,
  config,
  createMessageLoading,
  createMessageRemarks,
  createNotification,
  currentUser,
  handleFilterChange,
  handlePaginationChange,
  handleSelectCampaignOrder,
  handleSubmitCampaignOrder,
  handleSubmitCampaignOrderReview,
  handleTabChange,
  isVisibleContent,
  launchCampaignDSP,
  liquidMPublishers,
  loadingCampaignOrders,
  loadingCampaignOrdersReady,
  loadingSelectedCampaignOrder,
  pagination,
  searchParams,
  selectedCampaignOrder,
  setSelectedCampaignOrder,
  tabletMode,
  updateCampaignOrderReview,
  updateCampaignOrderStatus,
  updateCampaignOrderWithoutMessage,
  updatePastCampaignOrderReview,
  handleLaunchCampaignOrderManually,
  campaignHistories,
  addCreativeNotes,
  formattedTargets,
  launchCampaignDSPLoading,
  dspStatus,
  formattedWalkIns,
  adUnitBannerIds,
  setAdUnitBannerIds,
  tableKey,
}) => {
  const {
    category,
    name: campaignName,
    tags,
    startDate,
    endDate,
    budget,
    adUnits,
    isRegulated,
    geoTargets,
    attributionTargets,
    cpm,
    targetingCriteria,
    awarenessCampaign,
    transactionsConfig,
    description,
    type,
    advertiser,
    brands,
  } = selectedCampaignOrder || {};

  const handleDownloadAdUnits = () => {
    // Check if adUnits exist and contain banners
    if (
      !adUnits ||
      !adUnits.length ||
      !adUnits.some(item => item.banners.length > 0)
    ) {
      return; // Exit function if adUnits are empty or contain no banners
    }

    // Filter adUnits to include only selected banners
    const selectedAdUnitBanners = adUnits.map(adUnit => ({
      ...adUnit,
      banners: adUnit.banners.filter(banner =>
        adUnitBannerIds.includes(banner.id)
      ),
    }));

    // Call multiImageZip function with appropriate adUnits
    multiImageZip(
      campaignName,
      !adUnitBannerIds.length ? adUnits : selectedAdUnitBanners
    );
  };

  return (
    <>
      <PageHeaderLayout
        title={
          <Col>
            <h3>
              <AreaChartOutlined
                style={{
                  marginRight: "10px",
                  color: "lightgrey",
                  fontSize: 20,
                }}
              />
              {selectedCampaignOrder
                ? selectedCampaignOrder.name
                : "Campaign Order Queue"}
            </h3>
          </Col>
        }
        centerContent={
          <Row>
            <Button
              onClick={() => {
                handleTabChange("PENDING");
              }}
              type={
                ["PENDING", "LIVE_PENDING"].includes(searchParams.tab)
                  ? "primary"
                  : ""
              }
              icon={<FileTextTwoTone />}
            >
              PENDING
            </Button>
            <Button
              onClick={() => {
                handleTabChange("READY");
              }}
              type={searchParams.tab === "READY" ? "primary" : ""}
              icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            >
              READY
            </Button>
          </Row>
        }
        rightContent={<div />}
      />
      <PageContentLayout>
        {tabletMode && (
          <Row>
            <Col span={24}>
              {(loadingCampaignOrders || loadingCampaignOrdersReady) && (
                <Loader />
              )}

              <div style={styles.container}>
                <CampaignOrderQueueTable
                  campaignOrders={campaignOrders}
                  pagination={pagination}
                  selectedCampaignOrder={selectedCampaignOrder}
                  handlePaginationChange={handlePaginationChange}
                  handleSelectCampaignOrder={handleSelectCampaignOrder}
                  handleFilterChange={handleFilterChange}
                  tab={searchParams.tab}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {!tabletMode && (
            <Col span={8}>
              {(loadingCampaignOrders || loadingCampaignOrdersReady) && (
                <Loader />
              )}

              <div style={styles.container}>
                <CampaignOrderQueueTable
                  campaignOrders={campaignOrders}
                  pagination={pagination}
                  selectedCampaignOrder={selectedCampaignOrder}
                  setSelectedCampaignOrder={setSelectedCampaignOrder}
                  handlePaginationChange={handlePaginationChange}
                  handleSelectCampaignOrder={handleSelectCampaignOrder}
                  handleFilterChange={handleFilterChange}
                  tab={searchParams.tab}
                />
              </div>
            </Col>
          )}

          <Col span={tabletMode ? 24 : 16}>
            {loadingSelectedCampaignOrder && <Loader />}

            <div style={styles.container}>
              {isVisibleContent() && (
                <>
                  <Card bordered={false}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ApproveCampaignButton
                        updateCampaignOrderStatus={updateCampaignOrderStatus}
                        name={selectedCampaignOrder.name}
                        id={selectedCampaignOrder.id}
                        checkStatus={selectedCampaignOrder.status}
                      />
                    </div>
                    <Tabs type="card" defaultActiveKey="overview">
                      {["APPROVED", "LIVE", "LIVE_APPROVED"].includes(
                        selectedCampaignOrder.status
                      ) && (
                        <>
                          <TabPane tab={<span>Status</span>} key="status">
                            {/* STATUS */}
                            <CampaignStatus
                              isProcessor={config.isProcessor}
                              isAdmin={config.isAdmin}
                              selectedCampaignOrder={selectedCampaignOrder}
                              createMessageRemarks={createMessageRemarks}
                              loadingCreateMessage={createMessageLoading}
                              updateCampaignOrderReview={
                                updateCampaignOrderReview
                              }
                              updatePastCampaignOrderReview={
                                updatePastCampaignOrderReview
                              }
                              createNotification={createNotification}
                              updateCampaignOrderWithoutMessage={
                                updateCampaignOrderWithoutMessage
                              }
                              loggedInId={currentUser.id}
                              isCampaignDetails={false}
                              handleSubmitCampaignOrderReview={
                                handleSubmitCampaignOrderReview
                              }
                              handleLaunchCampaignOrderManually={
                                handleLaunchCampaignOrderManually
                              }
                              dspStatus={dspStatus}
                            />
                          </TabPane>
                        </>
                      )}
                      <TabPane tab={<span>Overview</span>} key="overview">
                        {/* BASICS */}
                        <Card
                          style={card}
                          title={
                            <Col>
                              <span>
                                <FileTextOutlined /> Basics
                              </span>
                            </Col>
                          }
                        >
                          <ReviewBasics
                            brands={brands}
                            campaignName={campaignName}
                            startDate={startDate}
                            endDate={endDate}
                            category={category}
                            tags={tags}
                            isRegulated={isRegulated}
                            budget={budget}
                            cpm={cpm}
                            targetingCriteria={targetingCriteria}
                            awarenessCampaign={awarenessCampaign}
                            cart={transactionsConfig && transactionsConfig.cart}
                            transactionTarget={
                              transactionsConfig && transactionsConfig.appId
                            }
                            traffId={
                              transactionsConfig && transactionsConfig.traffId
                            }
                            gtmAccess={
                              transactionsConfig && transactionsConfig.gtmAccess
                            }
                            gtmStatus={
                              transactionsConfig && transactionsConfig.gtmStatus
                            }
                            description={description}
                            type={campaignTypes[type]}
                            name={advertiser ? advertiser.name : ""}
                          />
                        </Card>

                        {/* CREATIVES */}
                        <Card
                          style={card}
                          title={
                            <div style={{ position: "relative" }}>
                              <Col>
                                <span>
                                  <FileImageOutlined /> Creatives
                                </span>
                              </Col>
                              <Button
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: -4,
                                }}
                                onClick={() => handleDownloadAdUnits()}
                                disabled={
                                  !adUnits.some(item => item.banners.length > 0)
                                }
                              >
                                <DownloadOutlined />
                                {!adUnitBannerIds.length ||
                                adUnitBannerIds.length ===
                                  adUnits.flatMap(adUnit => adUnit.banners)
                                    .length
                                  ? "Download All Creatives"
                                  : `Download ${
                                      adUnitBannerIds.length
                                    } Creative${
                                      adUnitBannerIds.length > 1 ? "s" : ""
                                    }`}
                              </Button>
                            </div>
                          }
                        >
                          <ReviewCreatives
                            adUnits={adUnits}
                            isAdmin={config.isAdmin}
                            status={selectedCampaignOrder.status}
                            addCreativeNotes={addCreativeNotes}
                            startDate={startDate}
                            endDate={endDate}
                            adUnitBannerIds={adUnitBannerIds}
                            setAdUnitBannerIds={setAdUnitBannerIds}
                            tableKey={tableKey}
                          />
                        </Card>

                        {/* WALK INS */}
                        <Card
                          style={card}
                          title={
                            <div style={{ position: "relative" }}>
                              <Col>
                                <span>
                                  <GlobalOutlined /> Walk In Locations
                                </span>
                              </Col>
                            </div>
                          }
                          bodyStyle={cardBody}
                        >
                          {attributionTargets &&
                            attributionTargets.length !== 0 && (
                              <React.Fragment>
                                <LauncherMapSimple
                                  geoTargets={
                                    attributionTargets && [
                                      attributionTargets[0],
                                    ]
                                  }
                                  zoom={5}
                                  styles={styles}
                                />
                                <GeoTargetsTable
                                  geoTargets={formattedWalkIns}
                                  type={"Geo"}
                                  showRowStatus={true}
                                />
                              </React.Fragment>
                            )}
                        </Card>

                        {/* GEOTARGETS */}
                        <Card
                          style={card}
                          title={
                            <div style={{ position: "relative" }}>
                              <Col>
                                <span>
                                  <GlobalOutlined /> Geo Targets
                                </span>
                              </Col>
                              <Button
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: -4,
                                }}
                                disabled={
                                  !geoTargets || geoTargets.length === 0
                                }
                              >
                                <CSVLink
                                  filename={`${campaignName}-Geo.csv`}
                                  style={{ textDecoration: "none" }}
                                  data={flatten(
                                    geoTargets.map((geoTarget, i) => {
                                      const output = [];
                                      geoTarget.circles.forEach(circle => {
                                        let state = "";
                                        let getCode = "";
                                        if (
                                          circle.location &&
                                          circle.location.state
                                        ) {
                                          state = capitalize(
                                            circle.location.state
                                          );

                                          const getState = stateList.filter(
                                            val => {
                                              return (
                                                state === val.State && val.Code
                                              );
                                            }
                                          );

                                          getCode =
                                            getState &&
                                            getState.length &&
                                            getState[0].Code;
                                        }

                                        output.push({
                                          Name:
                                            circle.location &&
                                            circle.location.name,
                                          Type: "CIRCLE",
                                          Address:
                                            circle.location &&
                                            circle.location.formattedAddress,
                                          Street:
                                            circle.location &&
                                            circle.location.street,
                                          City:
                                            circle.location &&
                                            circle.location.city,
                                          County:
                                            circle.location &&
                                            circle.location.county,
                                          State: circle.location && getCode,
                                          Country:
                                            circle.location &&
                                            circle.location.country,
                                          Zip:
                                            circle.location &&
                                            circle.location.zip,
                                          Latitude: circle.coordinate.lat,
                                          Longitude: circle.coordinate.lng,
                                          Radius: Math.round(
                                            kmToFt(circle.radius)
                                          ),
                                        });
                                      });

                                      geoTarget.polygons.forEach(polygon => {
                                        if (!isEmpty(polygon.coordinates)) {
                                          output.push({
                                            GeoTarget: i,
                                            Type: "POLYGON",
                                            Latitude:
                                              polygon.coordinates[0].lat,
                                            Longitude:
                                              polygon.coordinates[0].lng,
                                          });
                                          polygon.coordinates.forEach(
                                            (coordinate, i) => {
                                              if (i > 0)
                                                output.push({
                                                  Latitude:
                                                    polygon.coordinates[i].lat,
                                                  Longitude:
                                                    polygon.coordinates[i].lng,
                                                });
                                            }
                                          );
                                        }
                                      });
                                      return output;
                                    })
                                  )}
                                >
                                  <DownloadOutlined /> Download CSV
                                </CSVLink>
                              </Button>
                            </div>
                          }
                          bodyStyle={cardBody}
                        >
                          {geoTargets && geoTargets.length !== 0 && (
                            <React.Fragment>
                              <LauncherMapSimple
                                geoTargets={geoTargets && [geoTargets[0]]}
                                zoom={5}
                                styles={styles}
                              />
                              <GeoTargetsTable
                                geoTargets={formattedTargets}
                                type={"Geo"}
                                showRowStatus={true}
                                hideRowSelection={true}
                              />
                            </React.Fragment>
                          )}
                        </Card>

                        {/* TARGETING CRITERIA */}
                        {/* GEOTARGETS */}
                        <Card
                          style={card}
                          title={
                            <span>
                              <FileTextOutlined /> Targeting Criteria
                            </span>
                          }
                        >
                          <p>{targetingCriteria || ""}</p>
                        </Card>

                        {/* TRACKING */}
                        <Card
                          style={card}
                          title={
                            <div style={{ position: "relative" }}>
                              <Col>
                                <span>
                                  <FileImageOutlined /> Tracking
                                </span>
                              </Col>
                            </div>
                          }
                        >
                          <CampaignTrackings
                            selectedCampaignOrder={selectedCampaignOrder}
                          />
                        </Card>

                        {/* STATUS */}
                        {["PENDING", "LIVE_PENDING"].includes(
                          selectedCampaignOrder.status
                        ) && (
                          <CampaignStatus
                            isProcessor={config.isProcessor}
                            isAdmin={config.isAdmin}
                            selectedCampaignOrder={selectedCampaignOrder}
                            createMessageRemarks={createMessageRemarks}
                            loadingCreateMessage={createMessageLoading}
                            updateCampaignOrderReview={
                              updateCampaignOrderReview
                            }
                            updatePastCampaignOrderReview={
                              updatePastCampaignOrderReview
                            }
                            createNotification={createNotification}
                            updateCampaignOrderWithoutMessage={
                              updateCampaignOrderWithoutMessage
                            }
                            loggedInId={currentUser.id}
                            isCampaignDetails={false}
                            handleSubmitCampaignOrderReview={
                              handleSubmitCampaignOrderReview
                            }
                            handleLaunchCampaignOrderManually={
                              handleLaunchCampaignOrderManually
                            }
                          />
                        )}
                      </TabPane>
                      {(selectedCampaignOrder.status === "APPROVED" ||
                        (["LIVE", "LIVE_APPROVED"].includes(
                          selectedCampaignOrder.status
                        ) &&
                          selectedCampaignOrder.dspConfig &&
                          !selectedCampaignOrder.dspConfig.overrideManual)) && (
                        <TabPane tab={<span>DSP Actions</span>} key="launcher">
                          <LaunchCampaignConfig
                            publishers={
                              liquidMPublishers &&
                              liquidMPublishers.liquidMPublishers
                            }
                            selectedCampaignOrder={selectedCampaignOrder}
                            updateCampaignOrderStatus={
                              updateCampaignOrderStatus
                            }
                            launchCampaignDSP={launchCampaignDSP}
                            handleSubmitCampaignOrder={
                              handleSubmitCampaignOrder
                            }
                            launchCampaignDSPLoading={launchCampaignDSPLoading}
                            dspStatus={dspStatus}
                          ></LaunchCampaignConfig>
                        </TabPane>
                      )}
                      {campaignHistories && campaignHistories.length && (
                        <TabPane tab={<span>History</span>} key="history">
                          <CampaignHistories histories={campaignHistories} />
                        </TabPane>
                      )}
                    </Tabs>
                  </Card>
                </>
              )}
            </div>
          </Col>
        </Row>
      </PageContentLayout>
    </>
  );
};

export default CampaignOrderQueueView;
