import React from "react";

// Ant Design
import { Tabs, Breadcrumb } from "antd";

// Demograph Components
import CategoryList from "./CategoryList";
import SubCategoryList from "./SubCategoryList";
import GenericMetric from "./Metrics/GenericMetric";

import { useAudienceBuilderContext } from "../context/AudienceBuilderContext";

const MobileViewCategoryList = props => {
  const {
    categories,
    subCategories,
    selectedCategory,
    onCategoryClick,
    onSubCategoryClick,
    titleFontStyle,
    listItemStyle,
    selectedStyle,
    selectedFontStyle,
    updateAudienceMetrics,
    fetchAudienceCount,
    removeAudienceMetric,
    loading,
    metric,
  } = props;

  const { activeTabKey, setActiveTabKey } = useAudienceBuilderContext();

  const getBreadCrumbs = () => {
    let breadCrumbs = ["Category"];
    switch (activeTabKey) {
      case "1":
        breadCrumbs = ["Category"];
        break;
      case "2":
        breadCrumbs = ["Category", "Sub Category"];
        break;
      case "3":
        breadCrumbs = ["Category", "Sub Category", "Datapoint Entry"];
        break;
      default:
        break;
    }

    return breadCrumbs.map((breadCrumb, index) => {
      return (
        <Breadcrumb.Item
          key={`${index + 1}`}
          onClick={() => {
            setActiveTabKey(`${index + 1}`);
          }}
        >
          {breadCrumb}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <>
      <Breadcrumb>{getBreadCrumbs()}</Breadcrumb>
      <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
        <Tabs.TabPane key="1">
          <CategoryList
            list={categories}
            selectedCategory={selectedCategory}
            onCategoryClick={onCategoryClick}
            titleFontStyle={titleFontStyle}
            listItemStyle={listItemStyle}
            selectedStyle={selectedStyle}
            selectedFontStyle={selectedFontStyle}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2">
          <SubCategoryList
            list={subCategories}
            selectedCategory={selectedCategory}
            onCategoryClick={onSubCategoryClick}
            titleFontStyle={titleFontStyle}
            listItemStyle={listItemStyle}
            selectedStyle={selectedStyle}
            selectedFontStyle={selectedFontStyle}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="3">
          <GenericMetric
            updateAudienceMetrics={updateAudienceMetrics}
            fetchAudienceCount={fetchAudienceCount}
            removeAudienceMetric={removeAudienceMetric}
            loading={loading}
            metric={metric}
          ></GenericMetric>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default MobileViewCategoryList;
