import React, { useState } from "react";
import { Modal, Row, Col } from "antd";
import GlobalButton from "../../../../shared/globalStyling/Button";
import { advertisers } from "../../../../../core/utils/constants/constants";
import TagsManagerView from "../../../Tags/manager/TagsManagerView";

const CreateImpressionsTag = ({
  onSubmit = () => {}, //Function to be called when the modal is submitted
}) => {
  const [visible, setVisible] = useState(false); //Modal Pop up

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col className="ant-col-xs-100 ant-col-md-flex-auto">
          <GlobalButton
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            Create Impression Tag
          </GlobalButton>
        </Col>
      </Row>

      <Modal
        title="Create Impression Tag"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <TagsManagerView
          headerSubmit={false}
          tagsToCreate={"Impression"}
          advertisers={advertisers}
          onSubmit={async value => {
            await onSubmit(value);
            setVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default CreateImpressionsTag;
