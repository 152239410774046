import React, { useEffect, useState } from "react";
import {
  Modal,
  Select,
  Spin,
  Row,
  Typography,
  Col,
  Button,
  Tabs,
  Space,
} from "antd";

import { InfoCircleTwoTone } from "@ant-design/icons";

const { Option } = Select;
const { Title, Text } = Typography;

/**
 *
 * @param {*} modalToggle - to toggle the modal visibility
 * @returns
 */
const SharedOrgsModal = ({
  modalToggle,
  sharedOrgsLoading,
  selectedRows,
  onCancel,
  onSubmit,
  orgs,
}) => {
  const [selectedItems, setSelectedItems] = useState(selectedRows);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [tabPosition, setTabPosition] = useState(0);

  const onDeselect = (selectedOrg, index) => {
    let copySelectedItems = [...selectedItems];
    let selectedObject = {
      ...copySelectedItems[index].orgs.find(o => o.id === selectedOrg.value),
      isRemoved: true,
    };

    copySelectedItems[index].orgs = copySelectedItems[index].orgs.filter(
      item => item.id !== selectedOrg.value
    );

    copySelectedItems[index].orgs.push(selectedObject);

    setSelectedItems(copySelectedItems);
  };

  const onSelect = (selectedOrg, index) => {
    let copySelectedItems = [...selectedItems];
    copySelectedItems[index].orgs = [
      ...copySelectedItems[index].orgs,
      {
        id: selectedOrg.key,
        name: selectedOrg.children,
      },
    ];
    setSelectedItems(copySelectedItems);
  };

  const onSelectOrg = selectedOrg => {
    setSelectedOrgs([
      ...selectedOrgs,
      { id: selectedOrg.key, name: selectedOrg.children },
    ]);
  };

  const onDeselectOrg = selectedOrg => {
    setSelectedOrgs(selectedOrgs.filter(org => org.id !== selectedOrg.value));
  };

  useEffect(() => {
    setSelectedItems(selectedRows);
  }, [selectedRows]);

  return (
    <Modal
      title={`Share Display Campaign`}
      visible={modalToggle}
      onCancel={onCancel}
      width={650}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          {"Cancel"}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (selectedOrgs.length && tabPosition === "batchSelection") {
              let copySelectedItems = [...selectedRows];

              copySelectedItems.map(org => {
                org.orgs = [...selectedOrgs];
                return org;
              });
              setSelectedItems(copySelectedItems);
            }

            onSubmit(selectedRows);
          }}
        >
          {"Update"}
        </Button>,
      ]}
    >
      {sharedOrgsLoading ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        <React.Fragment>
          <Tabs
            onChange={activeKey => {
              setTabPosition(activeKey);
            }}
          >
            <Tabs.TabPane tab="Multiple Selection" key="multipleSelection">
              {selectedItems.map((organization, index) => {
                return (
                  <Row key={organization.id} style={{ marginBottom: `10px` }}>
                    <Col xs={24}>
                      <Title level={5}>
                        Share {organization.name}'s Campaign with:
                      </Title>
                      <Select
                        showSearch
                        key={record => record.id}
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please Select Organization"
                        value={organization.orgs
                          .filter(o => !o.isRemoved)
                          .map(org => org.id)}
                        onSelect={(_, selectedOrg) => {
                          onSelect(selectedOrg, index);
                        }}
                        onDeselect={(_, selectedOrg) => {
                          onDeselect(selectedOrg, index);
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {orgs && orgs.length
                          ? orgs.map(org => {
                              return (
                                <Option key={org.id} value={org.id}>
                                  {org.name}
                                </Option>
                              );
                            })
                          : []}
                      </Select>
                    </Col>
                  </Row>
                );
              })}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Batch Selection" key="batchSelection">
              <React.Fragment>
                <Row>
                  <Space direction="vertical">
                    <Text>
                      <InfoCircleTwoTone /> Use this to add new organizations to
                      multiple campaigns at once. Please note that
                      addition/removal of orgs set from the Multiple Selection
                      tab will not reflect/be saved here.
                    </Text>

                    <Title level={5}>
                      You've chosen to share the following{" "}
                      {selectedItems.length > 1 ? "campaigns" : "campaign"}:
                    </Title>
                    {selectedItems.map((organization, index) => {
                      return (
                        <Text key={organization.id}>{organization.name}</Text>
                      );
                    })}
                  </Space>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  <Title level={5}>
                    Below you can select the orgs to share these campaigns with:
                  </Title>
                  <Select
                    showSearch
                    key={record => record.id}
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please Select Organization"
                    value={
                      selectedOrgs.length
                        ? selectedOrgs
                            .filter(o => !o.isRemoved)
                            .map(org => org.id)
                        : []
                    }
                    onSelect={(_, selectedOrg) => {
                      onSelectOrg(selectedOrg);
                    }}
                    onDeselect={(_, selectedOrg) => {
                      onDeselectOrg(selectedOrg);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {Array.isArray(orgs) &&
                      orgs.map(org => {
                        return (
                          <Option key={org.id} value={org.id}>
                            {org.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Row>
              </React.Fragment>
            </Tabs.TabPane>
          </Tabs>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default SharedOrgsModal;
