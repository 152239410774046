import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { useQuery, useMutation } from "@apollo/react-hooks";
import OverlaySpinner from "../../../platform/shared/OverlaySpinner";
import AdvertiserMainController from "./AdvertiserMainController";
import compose from "lodash/flowRight";
import TAG_ORGS from "../../GraphQl/Queries/TAG_ORGS";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
// import ADVERTISERS from "../../GraphQl/Queries/ADVERTISERS";
import ADVERTISER_CONNECTION from "../../GraphQl/Queries/ADVERTISER_CONNECTION";
import CREATE_ADVERTISERS from "../../GraphQl/Mutations/CREATE_ADVERTISERS";
import GET_ADMIN_IDS from "../../GraphQl/Queries/GET_ADMIN_IDS";
import { cloneDeep } from "lodash";

const AdvertiserMainLoader = props => {
  const { children, currentUser, match, location, history } = props;
  const currentOrgId = currentUser.role.org.id;
  const pageNumber = match.params.pageNumber;

  const params = new URLSearchParams(location.search);
  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;
  const advertiser = params.get("advertiser");

  if (isNaN(pageNumber) || Number(pageNumber) < 1)
    location.search
      ? history.push(`/advertiser/main/1${location.search}`)
      : history.push("/advertiser/main/1");

  const search = params.get("search");
  const where = {
    AND: [
      {
        impressionsTag: {
          id_not: null,
        },
      },
    ],
    OR: [
      {
        sharedOrgs_some: {
          org: {
            id: currentOrgId,
          },
        },
      },
      {
        sharedOrgs_some: {
          org: {
            parentOrg_some: { id: currentOrgId },
          },
        },
      },
    ],
  };

  let whereFilter = cloneDeep(where);
  if (search) {
    whereFilter["AND"].push({ name_contains: search });
  }

  if (advertiser) {
    whereFilter["AND"].push({
      impressionsTag: {
        orgs_some: {
          id: advertiser,
        },
      },
    });
  }

  // fetch admin ids
  const { data: adminIds, loading: adminLoading } = useQuery(GET_ADMIN_IDS);

  //Tags option for Advertiser Creation
  const { data: eventTags, loading: eventTagsLoading } = useQuery(TAG_ORGS, {
    variables: {
      orgId: currentOrgId,
    },
  });

  // Advertiser Table Data
  const { data, loading, refetch } = useQuery(ADVERTISER_CONNECTION, {
    variables: {
      //first: pageSize,
      //skip: (Number(pageNumber) - 1) * pageSize,
      where: whereFilter,
      orderBy: "name_ASC",
    },
    fetchPolicy: "no-cache",
  });

  const [createAdvertiser] = useMutation(CREATE_ADVERTISERS);

  if (adminLoading || eventTagsLoading || !data || loading)
    return <OverlaySpinner />;

  const { mediajelAdminId, mediajelId } = adminIds.getAdminIds || {};
  // const total = data.full.aggregate.count;
  return (
    <AdvertiserMainController
      {...props}
      eventsTargets={eventTags.eventsTargets}
      currentOrgId={currentOrgId}
      data={data.advertisersConnection.edges.map(({ node }) => node)}
      refetch={refetch}
      createAdvertiser={createAdvertiser}
      mediajelAdminId={mediajelAdminId}
      mediajelId={mediajelId}
      pageSize={pageSize}
      current={Number(pageNumber)}
      // total={total}
    >
      {children}
    </AdvertiserMainController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(AdvertiserMainLoader));
