import React from "react";

// Ant Design
import { List, Button, Popconfirm } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

// Utils
import { getColorTag } from "./../utils/getColorRange";

const SelectedMetricList = props => {
  const {
    list,
    loading,
    titleFontStyle,
    selectedCategory,
    selectedFontStyle,
    handleSelectedMetric,
    removeAudienceMetric,
  } = props;

  return (
    <List
      loading={loading}
      size="large"
      dataSource={list}
      renderItem={item => {
        let { name, operator, label, displayValue, value, dataType } = item;

        const finaldisplayValue =
          dataType === "boolean" ? (value ? "Yes" : "No") : displayValue;

        return (
          <List.Item
            onClick={() => {
              if (typeof handleSelectedMetric === "function") {
                handleSelectedMetric(item);
              }
            }}
            style={{
              cursor: "pointer",
              border: "1px solid black",
              marginBottom: "0.7em",
              borderRadius: "6px",
              borderLeft: `5px solid ${getColorTag(operator)}`,
            }}
          >
            <List.Item.Meta
              title={
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    titleFontStyle,
                    ...(selectedCategory && selectedCategory.name === name
                      ? selectedFontStyle
                      : {}),
                  }}
                >
                  <span>{label}</span>
                  {removeAudienceMetric && (
                    <Popconfirm
                      title="Are you sure you want to delete this item?"
                      onConfirm={e => {
                        e.stopPropagation(); // Prevent the onClick of List.Item from being triggered
                        removeAudienceMetric(item);
                      }}
                      onCancel={e => e.stopPropagation()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="text"
                        icon={<CloseCircleOutlined style={{ color: "red" }} />}
                        onClick={e => e.stopPropagation()} // Prevent the onClick of List.Item from being triggered
                      />
                    </Popconfirm>
                  )}
                </span>
              }
              description={`${finaldisplayValue}`}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default SelectedMetricList;
