import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CampaignsMainViewDataLoader from "../../../../core/components/campaigns/campaignsmain";
import CampaignsMainView from "../CampaignsMainView";
import CampaignDetailsDataLoader from "../../../.././core/components/campaigns/campaigndetails";
import CampaignDetailsChartsDataLoader from "../../../../core/components/campaigns/campaigndetails/CampaignDetailsChartsDataLoader";

import CampaignsDetailsView from "../campaigndetails/CampaignsDetailsView";
import AmplifyView from "../campaigndetails/amplify/AmplifyView";
import CampaignLauncherLoader from "../../../../core/components/campaignLauncher";
import CampaignLauncherView from "../../CampaignLauncher/CampaignLauncherView";
import CampaignOrderQueueView from "../../CampaignOrderQueue/CampaignOrderQueueView";
import CampaignOrdersQueueLoader from "../../../../core/components/campaigns/campaignordersqueue/CampaignOrdersQueueLoader";
import CampaignOrderAuditView from "../../CampaignOrderAudit/CampaignOrderAuditView";
import CampaignOrderAuditLoader from "../../../../core/components/campaigns/campaignorderaudit/CampaignOrderAuditLoader";
import GeotargetManagerLoader from "../../../../core/components/campaigns/geotargetmanager/GeotargetManagerLoader";

const CampaignsRoutes = props => {
  const { path } = props.match;

  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${path}/main/:pageNumber?`}
          render={() => {
            return (
              <CampaignsMainViewDataLoader>
                <CampaignsMainView />
              </CampaignsMainViewDataLoader>
            );
          }}
        />
        <Route
          exact
          path={`${path}/details/:id?`}
          render={() => (
            <CampaignDetailsDataLoader>
              <CampaignDetailsChartsDataLoader>
                <CampaignsDetailsView />
              </CampaignDetailsChartsDataLoader>
            </CampaignDetailsDataLoader>
          )}
        />
        <Route
          exact
          path={`${path}/manager/:id?`}
          render={() => (
            <CampaignLauncherLoader>
              <GeotargetManagerLoader>
                <CampaignLauncherView />
              </GeotargetManagerLoader>
            </CampaignLauncherLoader>
          )}
        />
        <Route
          exact
          path={`${path}/boost/:id?`}
          render={() => (
            <CampaignLauncherLoader>
              <AmplifyView />
            </CampaignLauncherLoader>
          )}
        />

        <Route
          exact
          path={`${path}/orderqueue/:id?`}
          render={() => (
            <CampaignOrdersQueueLoader>
              <CampaignOrderQueueView />
            </CampaignOrdersQueueLoader>
          )}
        />
        <Route
          exact
          path={`${path}/audit/:id?`}
          render={() => (
            <CampaignOrderAuditLoader>
              <CampaignOrderAuditView />
            </CampaignOrderAuditLoader>
          )}
        />
        {/* Fallback */}
        <Route
          path={`${path}`}
          render={() => <Redirect to={`${path}/main/1`} />}
        />
      </Switch>
    </div>
  );
};

export default CampaignsRoutes;
