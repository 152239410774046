import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import DetailBar from "./components/DetailBar";
import { Row, Col, Space, Popconfirm, message } from "antd";
import DualAxisChart from "../../../shared/charts/DualAxisChart";
import { LoadingPage } from "../../../shared/LoadingPage";
import { summaryScale } from "../utils/summaryScale";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import TransactionsTable from "./components/TransactionsTable";
import GlobalButton from "../../../shared/globalStyling/Button";
import JSZip from "jszip";
import Papa from "papaparse";
import { cloneDeep } from "lodash";
import { saveAs } from "file-saver";
import { HasAccess } from "@permify/react-role";
import TopCreatives from "./components/TopCreatives";
import TopImpressionDomains from "./components/TopImpressionDomains";
import TopInventoryTypes from "./components/TopInventoryTypes";

const AdvertiserDetailsView = ({
  advertiser,
  reportDataSummary,
  reportDataSummaryLoading,
  changeDatePicker,
  deleteAdvertiser,
  isSelfService,
  topCreatives,
  topImpressions,
  topInventoryTypes,
}) => {
  if (reportDataSummaryLoading) return <LoadingPage />;
  const { byDates, transactions } = reportDataSummary;
  const {
    impressionsTag: { dsp },
  } = advertiser;

  const dropDownOptions = [
    { key: "impressions", display: "Attributed Impressions" },
    { key: "transactions", display: "Transactions" },
    { key: "revenue", display: "Revenue" },
    { key: "customers", display: "Customers" },
    { key: "totalImpressions", display: "Impressions" },
  ];

  const onDeleteAdvertiser = async () => {
    try {
      await deleteAdvertiser();
      message.success("Advertiser successfully deleted");
    } catch (err) {
      message.error(`Error deleting advertiser: ${err.message}`);
    }
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={advertiser.name}
        rightContent={
          <HasAccess permissions={["default", "defaultSSO"]}>
            <Space>
              <GlobalButton
                type="primary"
                onClick={() => {
                  try {
                    const zip = new JSZip();
                    const folder = zip.folder(
                      `Advertiser ${advertiser.name} Transactions Report`
                    );
                    let dupFileData = cloneDeep(transactions);
                    folder.file(
                      `${advertiser.name} Transactions Report.csv`,
                      Papa.unparse(dupFileData)
                    );
                    zip.generateAsync({ type: "blob" }).then(
                      blob => {
                        saveAs(blob, "Advertiser");
                        return true;
                      },
                      function(e) {
                        console.log("error", e);
                        return false;
                      }
                    );
                  } catch (err) {
                    console.log(err);
                  }
                }}
                disabled={transactions.length === 0}
              >
                Export CSV
              </GlobalButton>
              {isSelfService && (
                <Popconfirm
                  title="Are you sure you want to permanently delete this funnel?"
                  onConfirm={onDeleteAdvertiser}
                  okText="Yes"
                  cancelText="No"
                >
                  <GlobalButton type="primary">Delete Funnel</GlobalButton>
                </Popconfirm>
              )}
            </Space>
          </HasAccess>
        }
      />
      <PageContentLayout>
        <HasAccess permissions={["default", "defaultSSO", "advertiserSSO"]}>
          <DetailBar
            {...reportDataSummary.summary}
            startDate={advertiser.startDate}
            endDate={advertiser.endDate}
          />

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <DualAxisChart
                byDate={byDates}
                xAxis="date"
                options={dropDownOptions}
                defaultLeftYValue={dropDownOptions[0]}
                defaultRightYValue={dropDownOptions[1]}
                customScale={summaryScale}
                onDatePickerChange={({ data, dates, rangeKey }) => {
                  changeDatePicker(dates);
                }}
                has6MonDisables={false}
                allTimeDateRange={[
                  moment(advertiser.startDate),
                  moment(advertiser.endDate),
                ]}
              />
            </Col>
            <Col xs={24}>
              <TransactionsTable
                data={transactions ? transactions : []}
                total={0}
                topImpressions={topImpressions}
                dsp={dsp}
              />
            </Col>
            <Col xs={24}>
              <TopCreatives data={topCreatives} />
            </Col>
            <Col xs={24}>
              <TopImpressionDomains data={topImpressions} />
            </Col>
            <Col xs={24}>
              <TopInventoryTypes data={topInventoryTypes} />
            </Col>
          </Row>
        </HasAccess>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(AdvertiserDetailsView);
