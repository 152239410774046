import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Card,
  Col,
  Row,
  Modal,
  message,
  Tabs,
  Tooltip,
  Popconfirm,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { cardMargin, cardBody } from "../../../shared/globalStyling/style";
import LocationListView from "./LocationListView";
import MapDrawing from "../../../shared/maps/MapDrawing";
import CreateAudienceForm from "./CreateAudienceForm";
import LocationAttributionAudiences from "./LocationAttributionAudiences";
import { MapConfig } from "../../../shared/maps/configs/mapConfigs";

const FirstFormPage = ({ form, onChange, getCartProvider }) => {
  return (
    <>
      <Form.Item
        label="Location Name"
        name="name"
        extra="Required name given to this location"
        rules={[
          {
            required: true,
            message: "Please input your Business name!",
          },
        ]}
      >
        <Input
          placeholder="Enter retail store name"
          name="name"
          onChange={({ target }) => {
            onChange([target.name], target.value);
          }}
        />
      </Form.Item>
      <Form.Item
        label="Location Description"
        name="description"
        extra="Optional: Description for this location"
      >
        <Input
          placeholder="Optional description"
          name="description"
          onChange={({ target }) => {
            onChange([target.name], target.value);
          }}
        />
      </Form.Item>
      <Row>
        <Col style={{ padding: 5 }} xs={24} xl={24}>
          <Form.Item
            label="Location Key"
            name="locationKey"
            extra="Optional: Shopping cart URL string that identifies a retail location"
          >
            <Input
              placeholder="Location Key"
              name="locationKey"
              onChange={({ target }) => {
                onChange([target.name], target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Street Address"
        name="formattedAddress"
        extra="Search and then select the address of this location"
        rules={[
          {
            required: true,
            message: "Address is required!",
          },
        ]}
      >
        <input
          type="input"
          placeholder="Address"
          name="formattedAddress"
          id="search-input-tl"
          className="ant-input"
          onFocus={e => {
            e.target.setAttribute("autocomplete", "no");
          }}
        />
      </Form.Item>
      <Row>
        <Col xs={24} xl={9} style={{ padding: 5 }}>
          <Form.Item label="City" name="city">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} xl={9} style={{ padding: 5 }}>
          <Form.Item label="Zip" name="zip">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} xl={6} style={{ padding: 5 }}>
          <Form.Item label="State" name="state">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col style={{ padding: 5 }} xs={24} xl={12}>
          <Form.Item label="Cart" name="cart">
            <Select
              style={{ width: "100%", borderRadius: 0 }}
              name="cart"
              onChange={value => onChange(["cart"], value)}
            >
              {getCartProvider &&
                getCartProvider.map(val => {
                  const { name } = val;
                  return (
                    <Option key={name} value={name}>
                      {name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col style={{ padding: 5 }} xs={24} xl={12}>
          <Form.Item label="Menu Data Key" name="menuDataKey">
            <Input
              placeholder="Menu Data Key"
              name="menuDataKey"
              onChange={({ target }) => {
                onChange([target.name], target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
};

const SecondFormPage = ({
  form,
  onChange,
  selectedGeoTargets,
  initialLocation,
  shouldClearShapes,
  toggleShouldClearShapes,
  changeLocationMap,
  selectedAddress,
  initialPosition,
  setHasOverlay,
  hasOverlay,
}) => {
  return (
    <>
      <Col xs={24} lg={24}>
        <MapDrawing
          inputPlaceholder={"Search Retail Location..."}
          zoom={17}
          shapes={selectedGeoTargets ? selectedGeoTargets : []}
          overlayChanged={val => {
            onChange("inProgressOverlay", val);
            setHasOverlay(val && val.length > 0);
          }}
          initialLocation={initialPosition ? initialPosition : initialLocation}
          shouldClearShapes={shouldClearShapes}
          clearedCallback={toggleShouldClearShapes}
          toolOptions={{
            fillColor: "#BCDCF9",
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: "#57ACF9",
            clickable: true,
            editable: true,
            zIndex: 1,
          }}
          styles={{
            map: {
              height: "500px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            },
            mapContainer: {
              height: "510px",
              width: "100%",
              position: "relative",
            },
          }}
          changeLocation={place => {
            changeLocationMap(place);
          }}
          drawing={true}
          isSearchBarVisible={false}
          mapType={"satellite"}
        />
      </Col>
    </>
  );
};

const LocationTab = ({
  locationList,
  parentState,
  changeLocationMap,
  onChange,
  createLocation,
  deleteLocation,
  editRetailLocation,
  resetParentState,
  updateOrgLocation,
  cartProvider,
  onChangeStateValue,
  selectedLocations,
  onSubmitAudience,
  attributionAudienceName,
  shouldClearShapes,
  toggleShouldClearShapes,
  selectedOrgId,
  attributionAudiences,
  deleteAudience,
  currentuser,
  google,
}) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [formShow, setFormShow] = useState(false);
  const [form] = Form.useForm();
  const [isAudienceModalVisible, setIsAudienceModalVisible] = useState(false);
  const [audienceType, setAudienceType] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [initialPosition, setInitialPosition] = useState(null);
  const [hasOverlay, setHasOverlay] = useState(false);
  let locationIds = selectedLocations.map(loc => loc.id);

  const {
    id,
    name,
    street,
    city,
    zip,
    state,
    description,
    email,
    contact,
    url,
    phone,
    formattedAddress,
    cart,
    menuDataKey,
    lat,
    lng,
    start,
    end,
    attributionWindow,
    locationKey,
  } = parentState || {};

  // const getCartProvider =
  //   cartProvider &&
  //   cartProvider.__type &&
  //   cartProvider.__type.enumValues &&
  //   cartProvider.__type.enumValues;

  useEffect(() => {
    form.setFieldsValue({
      name,
      description,
      street,
      city,
      state,
      zip,
      email,
      contact,
      url,
      formattedAddress,
      cart: cart ? cart : "Select Cart",
      menuDataKey,
      locationKey,
      phone,
      start,
      end,
      attributionWindow,
    });
  }, [
    attributionWindow,
    cart,
    city,
    contact,
    description,
    email,
    end,
    form,
    formattedAddress,
    locationKey,
    menuDataKey,
    name,
    parentState,
    phone,
    start,
    state,
    street,
    url,
    zip,
  ]);

  useEffect(() => {
    searchInputPlacesInit();
  });

  const toggleForm = val => {
    setFormShow(val);
    if (!val) {
      setCurrentStep(1);
    }
  };

  const onCreateNewRetailLocation = values => {
    toggleForm(false);
    createLocation(values);
  };

  const onDeleteRetailLocation = Id => {
    toggleForm(false);
    message.loading("Action in progress..", 0);
    deleteLocation(Id)
      .then(() => {
        message.destroy();
        message.success(`Successfully deleted retail Location`);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: Cannot delete retail Location`);
      });
  };

  const onEditRetailLocation = data => {
    toggleForm(false);
    resetParentState();
    setTimeout(() => {
      editRetailLocation(data);
      toggleForm(true);
    }, 100);
  };

  const onCloseForm = () => {
    toggleForm(false);
    // resetParentState();
  };

  const onCreateLocation = () => {
    toggleForm(true);
    resetParentState();
  };

  const onUpdateOrgLocation = values => {
    toggleForm(false);
    updateOrgLocation(values)
      .then(() => {
        message.destroy();
        message.success(`Successfully updated retail Location`);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: Cannot update retail Location`);
      });
  };

  const onFinish = values => {
    if (lat === null || lng === null || !hasOverlay) {
      message.error(
        `Please search for a location and draw an overlay on the map before submitting.`
      );
      return false;
    }
    id === "" ? onCreateNewRetailLocation(values) : onUpdateOrgLocation(values);
  };

  const handleAttributionAudience = async () => {
    setIsAudienceModalVisible(false);
    onSubmitAudience(audienceType);
  };

  const onCloseAudienceForm = () => {
    setIsAudienceModalVisible(false);
  };

  const searchInputPlacesInit = () => {
    const input = document.getElementById("search-input-tl");
    if (input) {
      input.addEventListener("keydown", e => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      });

      var options = {
        componentRestrictions: MapConfig.componentRestrictions,
      };

      const searchBox = new google.maps.places.Autocomplete(input, options);

      searchBox.addListener("place_changed", () => {
        const place = searchBox.getPlace();

        if (!place) {
          message.error("Error: Place not found!");
          return;
        }

        if (!place.geometry || !place.geometry.location) {
          message.error("Error: Place not found!");
          return;
        }

        const location = place.geometry.location;
        updateFormFields(place);
        setSelectedAddress(place);
        onChange(["lat"], location.lat());
        onChange(["lng"], location.lng());
        setInitialPosition({
          latitude: location.lat(),
          longitude: location.lng(),
        });
      });
    }
  };

  const updateFormFields = place => {
    let street = "";
    let city = "";
    let state = "";
    let zip = "";
    let country = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          street = `${component.long_name} ${street}`;
          break;
        case "route":
          street += component.short_name;
          break;
        case "postal_code":
          zip = component.long_name;
          break;
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.long_name;
          break;
        case "country":
          country = component.short_name;
          break;
        default:
          console.warn(`Unknown component type: ${componentType}`);
          break;
      }
    }

    form.setFieldsValue({
      formattedAddress: place.formatted_address,
    });

    // Convert state to uppercase and replace spaces with underscores to match the accepted values
    const formattedState = state.replace(/\s+/g, "_").toUpperCase();
    const formattedCountry = country === "US" ? "USA" : country;

    onChange(["street"], street);
    onChange(["city"], city);
    onChange(["state"], formattedState);
    onChange(["zip"], zip);
    onChange(["country"], formattedCountry);
    onChange(["formattedAddress"], place.formatted_address);
  };

  return (
    <>
      <Tabs type="card" defaultActiveKey="location">
        <Tabs.TabPane
          tab={
            <Tooltip title="Have the ability to add specific locations to target for your advertising or marketing efforts. This includes creating custom audiences based on these locations, and managing your location data through the import and export of CSV files. You can also download a sample template as a guide. Additionally, you have the ability to delete any location from your targeting locations list as per your preference.">
              <span>Addresses</span>
            </Tooltip>
          }
          key="location"
        >
          {formShow && (
            <Modal
              visible={formShow}
              title={
                currentStep === 1 ? "CREATE LOCATION" : "LOCATION BOUNDARY AREA"
              }
              onOk={form.submit}
              onCancel={onCloseForm}
              footer={null}
              bodyStyle={currentStep === 2 ? { padding: 0 } : {}}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  name,
                  description,
                  street,
                  city,
                  state,
                  zip,
                  email,
                  contact,
                  url,
                  cart: cart ? cart : "Select Cart",
                  menuDataKey,
                  phone,
                  locationKey,
                }}
              >
                {currentStep === 1 ? (
                  <FirstFormPage form={form} onChange={onChange} />
                ) : (
                  <SecondFormPage
                    form={form}
                    onChange={onChange}
                    selectedAddress={selectedAddress}
                    shouldClearShapes={shouldClearShapes}
                    toggleShouldClearShapes={toggleShouldClearShapes}
                    changeLocationMap={changeLocationMap}
                    initialPosition={initialPosition}
                    setHasOverlay={setHasOverlay}
                    hasOverlay={hasOverlay}
                  />
                )}
                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {currentStep > 1 && (
                      <Button
                        onClick={() => setCurrentStep(currentStep - 1)}
                        style={{ marginRight: "10px", marginBottom: "10px" }}
                      >
                        Back
                      </Button>
                    )}
                    {currentStep < 2 && (
                      <Button
                        onClick={() => setCurrentStep(currentStep + 1)}
                        disabled={!name || !street}
                      >
                        Next
                      </Button>
                    )}
                    {currentStep === 2 && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={lat === null || !hasOverlay}
                        style={{ marginRight: "10px", marginBottom: "10px" }}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          )}
          <Row gutter={[18, 18]}>
            <Col xs={24} lg={24}>
              <Card style={cardMargin} bodyStyle={cardBody} title="LOCATIONS">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Popconfirm
                    title="Are you sure to delete this location?"
                    onConfirm={() => deleteLocation(locationIds)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      disabled={
                        selectedLocations && selectedLocations.length === 0
                      }
                    >
                      Delete Location
                    </Button>
                  </Popconfirm>
                  <div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => onCreateLocation()}
                      style={{ marginRight: "10px" }}
                    >
                      Create Location
                    </Button>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      disabled={
                        selectedLocations && selectedLocations.length === 0
                      }
                      onClick={() => {
                        setAudienceType("ATTRIBUTION");
                        setIsAudienceModalVisible(true);
                      }}
                    >
                      Create Attribution Audiences
                    </Button>
                  </div>
                </div>
                <LocationListView
                  locationList={locationList}
                  deleteLocation={onDeleteRetailLocation}
                  editRetailLocation={onEditRetailLocation}
                  onChangeStateValue={onChangeStateValue}
                  selectedOrgId={selectedOrgId}
                  attributionAudiences={attributionAudiences}
                  history={history}
                />
              </Card>
            </Col>
          </Row>
          {isAudienceModalVisible && (
            <CreateAudienceForm
              attributionAudienceName={attributionAudienceName}
              isFormOpen={isAudienceModalVisible}
              closeForm={onCloseAudienceForm}
              onChangeStateValue={onChangeStateValue}
              submitForm={handleAttributionAudience}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Tooltip title="The ability to to create an audience from a targeting location">
              <span>Audiences</span>
            </Tooltip>
          }
          key="audience"
        >
          <LocationAttributionAudiences
            attributionAudiences={attributionAudiences}
            currentuser={currentuser}
            deleteAudience={deleteAudience}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default LocationTab;
