import React, { useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Popconfirm,
  message,
  Tooltip,
  Card,
  Select,
  InputNumber,
  Spin,
} from "antd";

// Locals
import { permissionsFromRoles } from "../../../../core/utils/dataManipulating/organization";
import SingleImageUploader from "../../../shared/images/SingleImageUploader";
import PermissionToggler from "./PermissionToggler";
import ConfigurationToggler from "./ConfigurationToggler";
import DataConfig from "./DataConfig";
import { errorType } from "../../../../core/utils/constants/constants";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MenuItemTextColor } from "../../../shared/globalStyling/styledText";
import { isEmpty } from "lodash";
import {
  orgLevelMenuItems,
  defaultCPM,
} from "../../../../core/utils/constants/constants";
const { Option } = Select;

const OrganizationDetails = ({
  createOrg,
  updateOrg,
  deleteOrg,
  disableOrg,
  enableOrg,
  onChange,
  onSwitchChange,
  selectedOrg: {
    name,
    logo: _logo,
    signInLogo: _signInLogo,
    description,
    domain,
    level,
    cpm,
    id,
    features,
    parentOrg,
    config,
    dataConfig,
    platformDomain,
    tagDomain,
  },
  currentOrg,
  currentRole,
  organizations,
  preview,
  currentuser,
  setDisplayedOrganization,
  refetchOrganizations,
  dataDealIds,
}) => {
  const tempConfig = {
    priceVisible: false,
    isDemo: false,
    isAdmin: false,
    isPartner: false,
    isTrafficker: false,
    exportData: false,
    isSelfService: false,
    hasInsights: false,
    providersVisible: false,
    isProcessor: false,
    canResetPasswords: false,
    campaignSummary: false,
    isPacing: false,
    pastData: false,
    segment: false,
    technicalSupport: false,
    articles: false,
    amplify: false,
    configurator: false,
    billing: false,
    sitesAppsTransactions: false,
  };
  const [error, setError] = useState("");
  const nameInput = useRef(null);
  const domainInput = useRef(null);
  const platformDomainInput = useRef(null);

  const [loading, setLoading] = useState(false);

  // Check if the only parent is MediaJelAdmin so that some config will be shown.
  const isMJParent = parentOrg.length <= 1 ? true : false;

  const { isPreviewOrg } = currentuser;
  const isMediaJelAdmin = currentRole.org.name === "MediaJelAdmin";

  const isDisabled = currentOrg.name === name ? true : false;
  const orgIsEnabled = Object.keys(features).includes("ENABLED");

  // Features
  const permissionsRoles =
    currentOrg.id === id
      ? currentOrg.features
      : permissionsFromRoles([
          organizations
            .find(org => org.id === parentOrg[parentOrg.length - 1].id)
            .roles.find(role => role.isMaster),
        ]);

  // Configuration
  const permissionsConfig =
    currentOrg.id === id
      ? currentOrg.config
      : !organizations.find(
          org => org.id === parentOrg[parentOrg.length - 1].id
        ).config
      ? tempConfig
      : organizations.find(org => org.id === parentOrg[parentOrg.length - 1].id)
          .config;

  // Data Config for current Org
  // const isTrafficker = currentOrg.config
  //   ? currentOrg.config.isTrafficker
  //   : false;

  const isAdmin = currentuser.permission
    ? currentuser.permission.isAdmin
    : false;

  const isChannelPartner = currentOrg.level === "CHANNEL_PARTNER";

  const isProcessor = currentOrg.config ? currentOrg.config.isProcessor : false;

  const isSelfService = currentOrg.config
    ? currentOrg.config.isSelfService
    : false;

  const isTrafficker = currentuser.permission
    ? currentuser.permission.isTrafficker
    : false;

  const checkRules = () => {
    try {
      if (name.trim() === "" || !name) {
        message.error("Please enter a organization name");
        return false;
      }
      const urlPattern = /^(https?:\/\/|\/)/i;

      if (urlPattern.test(platformDomain)) {
        setError(
          "Please enter a domain without 'https://', 'http://', or '/'."
        );
        return false;
      } else {
        setError("");
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  /**
   * Create Organization function
   * handles creation of new organization and UI feedback
   * @param {*} values
   * @returns null
   */
  const handleSubmit = async values => {
    // e.preventDefault();
    setLoading(true);
    if (!checkRules()) {
      setLoading(false);
      return;
    }
    // If the selected customer already has an ID we know they are in the DB
    message.loading("Action in progress..", 0);
    if (id) {
      updateOrg()
        .then(() => {
          message.destroy();
          message.success(`Organization ${name} successfully updated`);
          setLoading(false);
        })
        .catch(err => {
          message.destroy();
          if (err === "feature in empty") {
            message.error(
              `Please select at least one feature to update the organization!`
            );
          } else if (err === "This name already exists") {
            message.error(
              `Error: Org ${name} already exists, please use a different name`
            );
          } else {
            message.error(`Error: ${name} not updated`);
          }
          setLoading(false);
        });
    } else {
      createOrg()
        .then(async () => {
          message.destroy();
          message.success(`Organization ${name} successfully created`);
          await refetchOrganizations({
            first: 300,
            skip: 0,
          });
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          message.destroy();
          if (err === errorType.nameDuplicate) {
            message.error(`Error: '${name}' Organization name already exists!`);
            // Focus org name input
            nameInput.current.focus();
          } else if (err === errorType.domainDuplicate) {
            message.error(
              `Error: '${domain}' Domain already used by other organization!`
            );
            // Focus domain input
            domainInput.current.focus();
          } else if (err === "feature in empty") {
            message.error(
              `Please select at least one feature to create an organization!`
            );
          } else {
            message.error(`Error: ${name} not created`);
          }
          setLoading(false);
        });
    }
  };

  const onClickDelete = e => {
    e.stopPropagation();

    setLoading(true);
    message.loading("Action in progress..", 0);
    deleteOrg()
      .then(() => {
        message.destroy();
        message.success(`Organization ${name} successfully deleted`);
        setLoading(false);

        // Remove the deleted org from the org tree
        setDisplayedOrganization(
          !isEmpty(organizations) && organizations.filter(org => org.id !== id)
        );
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${name} not deleted`);
        setLoading(false);
      });
  };

  const onClickDisable = async e => {
    e.stopPropagation();

    message.loading("Action in progress", 0);
    try {
      await disableOrg();
      // message.destroy();
      await refetchOrganizations({
        first: 0,
        skip: 0,
      });
      message.destroy();
      message.success(`Organization ${name} successfully disabled`);
    } catch (err) {
      message.destroy();
      message.error(`Error: ${name} not disabled`);
    }
  };

  const onClickEnabled = async () => {
    message.loading("Action in progress", 0);
    try {
      await enableOrg();
      // message.destroy();
      await refetchOrganizations({
        first: 0,
        skip: 0,
      });
      message.destroy();
      message.success(`Organization ${name} successfully enabled`);
    } catch (err) {
      message.destroy();
      message.error(`Error: ${name} not enabled`);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card
        headStyle={{
          border: "0.5px solid #e8e8e8",
          background: "#FAFAFA",
        }}
        title="Details"
        extra={
          <Row layout="vertical" type="flex" justify="end" gutter={10}>
            {id ? (
              <React.Fragment>
                {isAdmin &&
                  (orgIsEnabled ? (
                    <Col>
                      <Popconfirm
                        title="Disable this organization?"
                        onConfirm={e => onClickDisable(e)}
                        okText="Yes"
                        cancelText="No"
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        disabled={isDisabled || loading}
                      >
                        <Button
                          size="small"
                          type="danger"
                          ghost={true}
                          disabled={isDisabled || loading}
                        >
                          Disable
                        </Button>
                      </Popconfirm>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        size="small"
                        type="primary"
                        disabled={isDisabled || loading}
                        style={{
                          borderColor: "#52c41a",
                          background: "#52c41a",
                        }}
                        onClick={onClickEnabled}
                      >
                        Enable
                      </Button>
                    </Col>
                  ))}
                {isAdmin && (
                  <Col>
                    <Popconfirm
                      title="This is permanent. Delete this organization?"
                      onConfirm={e => onClickDelete(e)}
                      okText="Yes"
                      cancelText="No"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      disabled={
                        isDisabled ||
                        loading ||
                        dataDealIds.some(deal => deal.org.id === id)
                      }
                    >
                      <Tooltip
                        title={
                          dataDealIds.some(deal => deal.org.id === id)
                            ? "A Deal ID is linked with this organization. Remove the Deal ID first before deleting the organization."
                            : ""
                        }
                      >
                        <Button
                          size="small"
                          type="danger"
                          ghost={true}
                          disabled={
                            isDisabled ||
                            loading ||
                            dataDealIds.some(deal => deal.org.id === id)
                          }
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                )}
                <Col>
                  {currentOrg.id !== id && name !== "" ? (
                    <Tooltip
                      title={
                        isPreviewOrg
                          ? "You can't preview another org while already in preview mode."
                          : "Assume Role"
                      }
                    >
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => preview()}
                        disabled={loading || isPreviewOrg}
                      >
                        Preview Advertiser
                      </Button>
                    </Tooltip>
                  ) : null}
                </Col>
                {isAdmin && (
                  <>
                    <Col>
                      <Button
                        size="small"
                        type="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        Update
                      </Button>
                    </Col>
                  </>
                )}
              </React.Fragment>
            ) : null}
          </Row>
        }
      >
        <Form layout="vertical">
          <Row type="flex" justify="space-around">
            <Col span={10} xs={22} xl={10}>
              <Form.Item label="Organization Name">
                <Input
                  disabled={isDisabled}
                  placeholder="organization name"
                  name="name"
                  value={name}
                  onChange={({ target }) => {
                    onChange([target.name], target.value);
                  }}
                  ref={nameInput}
                />
              </Form.Item>
              <Form.Item label="Description">
                <Input
                  placeholder="optional description"
                  name="description"
                  value={description}
                  onChange={({ target }) => {
                    onChange([target.name], target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={5} xs={22} sm={11} xl={4}>
              <Form.Item label="Logo">
                <SingleImageUploader
                  onChange={onChange}
                  image={_logo}
                  statekey="logo"
                />
              </Form.Item>
            </Col>
            <Col span={5} xs={22} sm={11} xl={4}>
              {isMediaJelAdmin ? (
                <Form.Item
                  label="Sign in logo"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <SingleImageUploader
                    onChange={onChange}
                    image={_signInLogo}
                    statekey="signInLogo"
                  />
                </Form.Item>
              ) : null}
            </Col>
          </Row>

          <Row type="flex" justify="space-around">
            <Col span={10} xs={22} xl={10}>
              <Form.Item label="Advertiser Domain">
                <Input
                  name="domain"
                  value={domain}
                  onChange={({ target }) => {
                    onChange([target.name], target.value);
                  }}
                  ref={domainInput}
                />
              </Form.Item>
            </Col>
            <Col span={10} xs={22} xl={10}>
              <Form.Item label="Org Level">
                <Select
                  defaultValue={"STANDARD"}
                  label="Org Level"
                  value={level}
                  onSelect={value => {
                    onChange("level", value);
                  }}
                >
                  {orgLevelMenuItems.map(option => {
                    return (
                      <Option key={option.value} value={option.value}>
                        <MenuItemTextColor text={option.name} />
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {level === "CHANNEL_PARTNER" && (
            <Row type="flex" justify="space-around">
              <Col span={10} xs={22} xl={10}>
                <Form.Item
                  label="Platform Domain"
                  validateStatus={error ? "error" : ""}
                  help={error}
                >
                  <Tooltip title="Please enter your domain without 'https://', 'http://', or '/'">
                    <Input
                      name="platformDomain"
                      value={platformDomain}
                      onChange={({ target }) => {
                        onChange([target.name], target.value);
                      }}
                      ref={platformDomainInput}
                      placeholder="domain.com"
                    />
                  </Tooltip>
                </Form.Item>
              </Col>
              <Col span={10} xs={22} xl={10}>
                <Form.Item label="Tag Domain">
                  <Input
                    name="tagDomain"
                    value={tagDomain}
                    onChange={({ target }) => {
                      onChange([target.name], target.value);
                    }}
                    ref={domainInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {isChannelPartner && (
            <Row type="flex" justify="left">
              <Col span={10} xs={22} xl={10}>
                <Form.Item label="CPM" style={{ paddingLeft: "40px" }}>
                  <InputNumber
                    value={parseFloat(cpm)}
                    disabled={isDisabled}
                    onChange={target => {
                      onChange("cpm", parseFloat(target));
                    }}
                    min={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {isMediaJelAdmin && (
            <Row type="flex" justify="left">
              <Col span={10} xs={22} xl={10}>
                <Form.Item label="CPM" style={{ paddingLeft: "40px" }}>
                  <InputNumber
                    value={parseFloat(cpm || defaultCPM.cpm)}
                    onChange={target => {
                      onChange("cpm", parseFloat(target));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row type="flex" justify="center">
            <Col span={22}>
              <PermissionToggler
                loggedInPermissions={permissionsRoles}
                currentPermissions={features}
                disabled={currentOrg.id === id}
                onSwitchChange={onSwitchChange}
              />
            </Col>
          </Row>

          {true ? (
            <Row type="flex" justify="center">
              <Col span={22}>
                <ConfigurationToggler
                  isAdmin={isMediaJelAdmin}
                  isMJParent={isMJParent}
                  loggedInPermissions={permissionsConfig}
                  config={!config ? {} : config}
                  disabled={currentOrg.id === id}
                  onSwitchChange={onSwitchChange}
                  isSelfService={isSelfService}
                  isProcessor={isProcessor}
                  orgTab={true}
                />
              </Col>
            </Row>
          ) : null}
          {isTrafficker ? (
            <Row type="flex" justify="center">
              <Col span={22}>
                <DataConfig
                  loggedInPermissions={dataConfig}
                  dataConfig={!dataConfig ? {} : dataConfig}
                  disabled={currentOrg.id === id}
                  onSwitchChange={onSwitchChange}
                  onChange={onChange}
                  hideFields={[
                    "tradeDeskIdentifier",
                    "currency",
                    "storageBucket",
                    "googleCustomerId",
                    "seo",
                    "semrushEnable",
                    "semrushConfig",
                    "attributionWindow",
                    "menuId",
                    "dspAdvertiserId",
                    "bing",
                    "googleAnalytics",
                    "tagProcessingConfig",
                    "paidSearchDataConfig",
                  ]}
                />
              </Col>
            </Row>
          ) : null}

          <Row
            type="flex"
            justify="end"
            gutter={10}
            style={{ marginRight: "100px" }}
          >
            {isAdmin &&
              (id ? (
                <React.Fragment>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      Update
                    </Button>
                  </Col>
                </React.Fragment>
              ) : (
                <Col>
                  <Button type="primary" onClick={handleSubmit}>
                    Create
                  </Button>
                </Col>
              ))}
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default OrganizationDetails;
