import gql from "graphql-tag";
const AUTHENTICATED_USER = gql`
  mutation authenticatedUser {
    authenticatedUser {
      id
      username
      campaignTableDisplayColumns
      config {
        id
        priceVisible
        providersVisible
        isPartner
        isTrafficker
        isSelfService
        hasInsights
        isAdmin
        isDemo
        exportData
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
        embeddableConfig {
          type
          ssoToken
          widgetConfig {
            transactionChart
            transactionTable
          }
          campaignOrder {
            id
            name
          }
        }
      }
      email
      cognitoUserId
      roles {
        roleItems {
          feature
          actions
        }
        org {
          id
          name
          cpm
          chatWootId
          level
          config {
            priceVisible
            providersVisible
            isPartner
            isTrafficker
            isSelfService
            hasInsights
            isAdmin
            isDemo
            exportData
            isProcessor
            canResetPasswords
            campaignSummary
            isPacing
            pastData
            segment
            technicalSupport
            articles
            amplify
            configurator
            billing
            sitesAppsTransactions
          }
          paymentConfig {
            id
            providerId
            paymentProvider
          }
          dataConfig {
            reTargeting
            appIds
            tradeDeskIdentifier
            storageBucket
            googleCustomerId
            roasFormat
            defaultDisplayAttributionWindow
            defaultPaidSearchAttributionWindow
            defaultSEOAttributionWindow
            currency {
              currency
              conversionValue
            }
          }
          platformDomain
          tagDomain
          parentOrg {
            id
            name
          }
          logo {
            id
            key
          }
        }
      }
      avatar {
        key
      }
      notifications {
        id
        message
        type
        featureID
      }
      ssoToken
    }
  }
`;
export default AUTHENTICATED_USER;
