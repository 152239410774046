/** @jsx jsx */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Input,
  Modal,
  Typography,
  Switch,
  Divider,
} from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
  // ShoppingCartOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";

import StepsCard from "./components/StepsCard";
import NavigationButtons from "./components/NavigationButtons";
import LauncherMap from "../../shared/maps/LauncherMap";
import { GeoTargetManager } from "../Campaigns/geotargetmanager/GeoTargetManager";
import GeotargetManagerController from "../../../core/components/campaigns/geotargetmanager/GeotargetManagerController";
import AudienceCard from "./components/AudienceCard";
import LocationsDropdown from "../../shared/maps/LocationsDropdown";
import useLocationsDropdown from "../../shared/maps/hooks/useLocationsDropdown";
import { css, jsx } from "@emotion/react";
import SelectTags from "./components/SelectTags";
import AttributionRetailIdDocs from "./components/docs";
import SelectTagSignUps from "./components/SelectTagSignUps";
import SelectTagsPageView from "./components/SelectTagsPageView";
import CartSelect from "../../shared/tags/cart/CartSelect";

const responsiveMenu = css`
@media only screen and (max-width: 1920px) {
  .responsive-menu {
    display: block;
  }
  .responsive-menu .span-mobile{
    width: 80% !important;
  }
  .card-border{
    border: 0px !important;
  }
  @media only screen and (max-width: 768px) {
    .responsive-menu {
      display: block;
    }
    .responsive-menu .span-mobile {
      width: 100% !important;
      margin-bottom: 25px;
    }
    .mobile-nav{
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-bottom: 25px;
    }
}
`;

const Frag = React.Fragment;

const { Title, Text } = Typography;

const BASKETITEMS_CART = [
  "dutchie-iframe",
  "dutchiejs", // TODO: Look into this later, there must be a better way to do this, perhaps this BASKEITEMS_CART mapping is incorrect, Take a look at GQL schema
  "shopify",
  "liquidm",
  "jane",
  "lightspeed",
  "leafly",
  "dutchie-subdomain",
  "woocommerce",
  "olla",
  "ecwid",
  "greenrush",
  "tymber",
  "meadow",
  "grassdoor",
  "dutchie",
  "buddi",
  "shopifyuniversal",
];

const styles = {
  map: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  mapCard: {
    padding: 0,
  },
  input: {
    position: "relative",
    height: "100%",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #1AA89D",
  },
  outerContainer: {
    height: "80vh",
  },
  innerContainer: {
    padding: 4,
    backgroundColor: "#fff",
  },
  tableHeaderRow: {
    marginBottom: "20px",
  },
  col: {
    minWidth: "140px",
    padding: "10px",
  },
  colTwo: {
    padding: "10px",
  },
  inputNumber: {
    width: "100%",
    minWidth: "50px",
  },
  rowOne: {
    paddingTop: "15px",
  },
  select: {
    width: "50%",
    minWidth: "50px",
  },
  inputOne: {
    width: "50%",
    minWidth: "50px",
  },
  div: {
    cursor: "pointer",
  },
  colThree: {
    marginTop: "15px",
  },
  title: {
    color: "#595959",
    margin: 0,
    fontWeight: 500,
    fontSize: "1.15em",
  },
  divider: { height: "6em" },
};

const tooltipText = (
  <p>
    1) Search for an address that you would like to target.
    <br />
    2) Select the radius around the location from the drop down.
    <br />
    3) Click add place.
    <br />
    You may add as many places as you need to target for your camapign.
    <br />
    You may add custom polygons or circles by clicking the corresponding icon at
    the top of the screen.
    <br />
    When you have added all of your targets click add GeoTarget to add them to
    the list.
  </p>
);

const CampaignLauncherViewAttribution = props => {
  const {
    onStepChange,
    handleUpdateCampaignOrder,
    onChange,
    campaignOrder,
    currentStep,
    tablet,
    attributionTargets,
    updateCampaignOrderWithAttributionLocations,
    isLive,
    isEdit,
    transactionTarget,
    traffId,
    locationId,
    audiences,
    updateFromExistingAudience,
    changeValue,
    mapOpen,
    isModalVisible,
    audienceGeoTargetsToAdd,
    attributionAudiences,
    cart,
    awarenessCampaign,
    tracksBasketItems,
    signUpCampaign,
    pageViewCampaign,
    pageViewAppId,
    onSubmitLoad,
    cloneAttributionTargets,
    isCloningClicked,
    getSubOrgsTagsData,
    appId,
    currentUser,
    cartProvidersData,
    eventTagerts,
  } = props;

  const attrData =
    isCloningClicked === true ? cloneAttributionTargets : attributionTargets;

  const walkInAttributions = attrData.filter(
    attr => attr.status !== "PENDING_DELETE"
  );

  const { isTrafficker } =
    currentUser && currentUser.permission ? currentUser.permission : {};
  const [currentLocationId, setCurrentLocationId] = useLocationsDropdown(
    "",
    attrData
  );
  const [basketItemsToggle, setBasketItemsToggle] = useState(
    tracksBasketItems ? true : false
  );
  const [basketItemsChecked, setBasketItemsChecked] = useState(
    tracksBasketItems ? true : false
  );

  let currentTransactionTarget = !isEmpty(transactionTarget)
    ? transactionTarget
    : !isEmpty(campaignOrder)
    ? campaignOrder.transactionTarget
    : null;

  //If no cart remove app id if exisiting
  if (!cart) currentTransactionTarget = null;

  const handleOk = () => {
    updateFromExistingAudience(audienceGeoTargetsToAdd);
    changeValue("isModalVisible", false);
  };

  const handleCancel = () => {
    changeValue("isModalVisible", false);
  };

  const steps = [
    {
      title: "Awareness Campaign",
      descriptionTitle: [""],
      description: [awarenessCampaign === true ? "Checked" : "Unchecked"],
      complete: awarenessCampaign === true,
    },
    {
      title: "Sign Up Campaign",
      descriptionTitle: [""],
      description: [signUpCampaign === true ? "Checked" : "Unchecked"],
      complete: signUpCampaign === true,
    },
    {
      title: "Transactions Configuration",
      descriptionTitle: ["E-commerce Platform:", "App Id:"],
      description: [
        `${cart ? cart : "None"}`,
        `${currentTransactionTarget ? currentTransactionTarget : "None"}`,
      ],
      complete: !isEmpty(currentTransactionTarget) && !isEmpty(cart),
    },
    {
      title: "Walk In Locations",
      descriptionTitle: ["Attribution Targets Added:"],
      description: [`${attrData ? attrData.length : 0}`],
      complete: !isEmpty(attrData),
    },
    {
      title: "Page View Configuration",
      descriptionTitle: ["App Id:"],
      description: [`${pageViewAppId ? pageViewAppId : "None"}`],
      complete: !isEmpty(pageViewAppId) && pageViewCampaign === true,
    },
  ];

  useEffect(() => {
    if (
      campaignOrder &&
      campaignOrder.transactionsConfig &&
      campaignOrder.transactionsConfig.cart &&
      BASKETITEMS_CART.includes(
        campaignOrder.transactionsConfig.cart.toLowerCase()
      )
    ) {
      setBasketItemsToggle(true);
    }
  }, [campaignOrder]);

  const handleCartSupport = val => {
    if (val !== null) {
      if (BASKETITEMS_CART.includes(val.toLowerCase())) {
        setBasketItemsToggle(true);
        return;
      }
    }
    setBasketItemsToggle(false);
  };

  const handleSelectCart = val => {
    if (!val) {
      onChange("cart", null);
      onChange("generatedTag", null);

      onChange("transactionTarget", null);
      onChange("segmentId", null);
      onChange("segmentName", null);
      onChange("tagName", null);
      onChange("appId", null);
    } else {
      const item = eventTagerts.find(eTarget => eTarget.cart.cart === val);
      handleCartSupport(val);
      onChange("cart", val);
      if (item) {
        const updatedScriptTag = item.cart.generatedTag.replace(
          /appId=[^&"]*/,
          `appId=${transactionTarget}`
        );
        onChange("generatedTag", updatedScriptTag);
      }
    }
  };

  const getCartDefaultValue = !isEmpty(
    campaignOrder &&
      campaignOrder.transactionsConfig &&
      campaignOrder.transactionsConfig.cart
  )
    ? campaignOrder.transactionsConfig.cart
    : "BUDDI";

  //Null Value is equal to no cart
  const getCartValue = cart ? cart : null;

  return (
    <Frag>
      <Row
        gutter={{ xl: 30, lg: 10, md: 10, sm: 10, xs: 10 }}
        css={responsiveMenu}
      >
        {tablet && (
          <Col span={12} xs={24} className="mobile-nav">
            <StepsCard stepsToComplete={steps} />
          </Col>
        )}
        <Col xs={24} md={24} lg={18} className="mobile-nav">
          <Row>
            <Col xs={24}>
              <Card
                title={
                  <Col xs={24}>
                    <LineChartOutlined />
                    <span> KPI Events Configuration </span>
                  </Col>
                }
                extra={
                  <span>
                    <Tooltip
                      title={
                        "Necessary for e-commerce attribution - after properly placing the tracker code provided - enter your application ID"
                      }
                      arrowPointAtCenter={true}
                      placement="leftBottom"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <Row type="flex">
                  <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                    <Row>
                      <Title level={4} style={styles.title}>
                        Awareness Campaign
                      </Title>
                    </Row>
                    <Row>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={
                          awarenessCampaign ? awarenessCampaign : false
                        }
                        onChange={val => {
                          onChange("awarenessCampaign", val);
                        }}
                        style={{ marginRight: "15px", marginTop: "15px" }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row type="flex">
                  <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                    <Row>
                      <Title level={4} style={styles.title}>
                        E-commerce Platform
                        <span>
                          <Tooltip
                            title={
                              <div>
                                <Row>
                                  Enter your app id. <br /> Note: All app ids
                                  that are added will not be saved in the
                                  database.
                                </Row>
                                <Row>(APP ID - CART - ORG)</Row>
                              </div>
                            }
                            arrowPointAtCenter={true}
                            placement="leftBottom"
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      </Title>
                    </Row>
                    <Row>
                      <Text
                        type="secondary"
                        style={{ marginTop: "10px", color: "#bababa" }}
                      >
                        Set Transaction Cart and Application ID.
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ marginTop: "10px", color: "#bababa" }}>
                        (APP ID - CART - ORG)
                      </Text>
                    </Row>
                    <Input.Group compact style={{ margin: "10px 0px" }}>
                      <SelectTags
                        transactionTarget={currentTransactionTarget}
                        getSubOrgsTagsData={getSubOrgsTagsData}
                        onChange={onChange}
                        //disabled={cart === null || cart === "NOCART"}
                        selectedCart={cart}
                        isTrafficker={isTrafficker}
                      />
                      <CartSelect
                        type="launcher"
                        cartValue={
                          getCartValue === "SHOPIFY"
                            ? "SHOPIFYLEGACY"
                            : getCartValue
                        }
                        cartDefaultValue={
                          getCartDefaultValue === "SHOPIFY"
                            ? "SHOPIFYLEGACY"
                            : getCartDefaultValue
                        }
                        onChange={val => {
                          handleSelectCart(val);
                        }}
                        cartProvidersData={cartProvidersData}
                      />
                    </Input.Group>
                  </Col>
                  <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                    <Row>
                      <Title level={4} style={styles.title}>
                        Retail ID
                        <span>
                          <AttributionRetailIdDocs />
                        </span>
                      </Title>
                    </Row>
                    <Row>
                      <Text
                        type="secondary"
                        style={{ marginTop: "10px", color: "#bababa" }}
                      >
                        Specify a specific retail location. (Optional).
                      </Text>
                    </Row>
                    <Input
                      value={traffId}
                      onChange={e => {
                        onChange("traffId", e.target.value);
                      }}
                      placeholder="Enter Transaction Affilate ID"
                      defaultValue={
                        !isEmpty(campaignOrder)
                          ? campaignOrder &&
                            campaignOrder.transactionsConfig &&
                            campaignOrder.transactionsConfig.traffId &&
                            campaignOrder.transactionsConfig.traffId
                          : null
                      }
                      style={{ margin: "10px 0 10px 0" }}
                    />
                    <br />
                  </Col>
                  <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                    <Row>
                      <Title level={4} style={styles.title}>
                        Basket Items
                      </Title>
                    </Row>
                    <Row>
                      <Switch
                        checked={basketItemsToggle ? basketItemsChecked : false}
                        disabled={!basketItemsToggle}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={val => {
                          setBasketItemsChecked(val);
                          onChange("tracksBasketItems", val);
                        }}
                        style={{ marginRight: "15px", marginTop: "15px" }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* <Row>
                    <Col xs={24} md={24} lg={12} style={styles.colTwo}> */}
                {/* <Row>
                        <Title level={4} style={styles.title}>
                          GTM Access
                        </Title>
                      </Row> */}
                {/* <Row>
                        <Text
                          type="secondary"
                          style={{ marginTop: "10px", color: "#bababa" }}
                        >
                          Set GTM Access
                        </Text>
                      </Row> */}
                {/* <Row align="middle" style={{ margin: "10px 0px" }}>
                        {gtmStatus !== "no-gtm" && (
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={gtmAccess ? gtmAccess : false}
                            onChange={val => {
                              onChange("gtmAccess", val);
                            }}
                            style={{ marginRight: "15px" }}
                          />
                        )}
                        <Select
                          style={{ width: "245px", marginTop: "7px" }}
                          defaultValue={
                            !isEmpty(gtmStatus) ? gtmStatus : "not-enabled"
                          }
                          onChange={val => {
                            onChange("gtmStatus", val);
                            if (val === "no-gtm") {
                              onChange("gtmAccess", false);
                            }
                          }}
                        >
                          <Option value="not-enabled">
                            The brand will not be enabling GTM Access
                          </Option>
                          <Option value="not-granted">
                            GTM Access has not yet been granted
                          </Option>
                          <Option value="no-gtm">
                            The brand does not use GTM
                          </Option>
                        </Select>
                      </Row> */}
                {/* </Col>
                  </Row> */}
                <Divider />
                <Row type="flex">
                  <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                    <Row>
                      <Title level={4} style={styles.title}>
                        Sign Up Tracking
                      </Title>
                    </Row>
                    <Row>
                      <Text
                        type="secondary"
                        style={{ marginTop: "10px", color: "#bababa" }}
                      >
                        Set Sign Up App ID for Sign Ups.
                      </Text>
                    </Row>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={signUpCampaign ? signUpCampaign : false}
                      onChange={val => {
                        onChange("signUpCampaign", val);
                      }}
                      style={{ marginRight: "15px" }}
                    />
                    <SelectTagSignUps
                      signUp={
                        !isEmpty(campaignOrder)
                          ? campaignOrder &&
                            campaignOrder.signUpConfig &&
                            campaignOrder.signUpConfig.appId &&
                            campaignOrder.signUpConfig.appId
                          : ""
                      }
                      getSubOrgsTagsData={getSubOrgsTagsData}
                      onChange={onChange}
                      disabled={signUpCampaign === false}
                    />
                    <br />
                  </Col>

                  <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                    <Row>
                      <Title level={4} style={styles.title}>
                        Location ID
                      </Title>
                    </Row>
                    <Row>
                      <Text
                        type="secondary"
                        style={{ marginTop: "10px", color: "#bababa" }}
                      >
                        Specify a specific location. (Optional)
                      </Text>
                    </Row>

                    <Input
                      value={locationId}
                      onChange={e => {
                        onChange("locationId", e.target.value);
                      }}
                      placeholder="Location ID"
                      defaultValue={
                        !isEmpty(campaignOrder)
                          ? campaignOrder &&
                            campaignOrder.signUpConfig &&
                            campaignOrder.signUpConfig.locationId &&
                            campaignOrder.signUpConfig.locationId
                          : ""
                      }
                      style={{ width: "245px", margin: "10px 0 10px 0" }}
                    />
                    <br />
                  </Col>
                </Row>
                {isTrafficker && (
                  <>
                    <Divider />
                    <Row type="flex">
                      <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                        <Row>
                          <Title level={4} style={styles.title}>
                            Page View Tracking
                          </Title>
                        </Row>
                        <Row>
                          <Text
                            type="secondary"
                            style={{ marginTop: "10px", color: "#bababa" }}
                          >
                            Set Page View app id for Page View attribution.
                          </Text>
                        </Row>
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          style={{ marginRight: "15px" }}
                          defaultChecked={
                            pageViewCampaign ? pageViewCampaign : false
                          }
                          onChange={val => {
                            onChange("pageViewCampaign", val);
                          }}
                        />
                        <SelectTagsPageView
                          pageViewTag={
                            !isEmpty(campaignOrder)
                              ? campaignOrder &&
                                campaignOrder.pageViewConfig &&
                                campaignOrder.pageViewConfig.appId &&
                                campaignOrder.pageViewConfig.appId
                              : ""
                          }
                          getSubOrgsTagsData={getSubOrgsTagsData}
                          onChange={onChange}
                          disabled={pageViewCampaign === false}
                        />
                      </Col>
                      <Col xs={24} md={24} lg={12} style={styles.colTwo}>
                        <Row>
                          <Title level={4} style={styles.title}>
                            Location ID
                          </Title>
                        </Row>
                        <Row>
                          <Text
                            type="secondary"
                            style={{ marginTop: "10px", color: "#bababa" }}
                          >
                            Specify a specific location. (Optional)
                          </Text>
                        </Row>

                        <Input
                          placeholder="Location ID"
                          style={{ width: "245px", margin: "10px 0 10px 0" }}
                          defaultValue={
                            !isEmpty(campaignOrder)
                              ? campaignOrder &&
                                campaignOrder.pageViewConfig &&
                                campaignOrder.pageViewConfig.locationId &&
                                campaignOrder.pageViewConfig.locationId
                              : ""
                          }
                          onChange={e => {
                            onChange("pageViewLocationId", e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            </Col>
          </Row>

          {/* <Row style={styles.rowOne}>
            <Col xs={24}>
              <AudienceCard
                changeValue={changeValue}
                attributionAudiences={attributionAudiences}
                audiences={audiences}
                selectedTab="attribution"
                iconTypeCard="environment"
                titleTextCard="Attribution Audiences"
              />
            </Col>
          </Row> */}

          <Row style={styles.rowOne} css="card-border">
            <Col xs={24}>
              <Card
                bordered={false}
                title={
                  <Col>
                    <EnvironmentOutlined />
                    <span> Walk In Locations</span>
                  </Col>
                }
                bodyStyle={styles.mapCard}
                extra={
                  <Tooltip
                    title={tooltipText}
                    arrowPointAtCenter={true}
                    placement="leftBottom"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                }
              >
                <AudienceCard
                  changeValue={changeValue}
                  attributionAudiences={attributionAudiences}
                  audiences={audiences}
                  selectedTab="attribution"
                  iconTypeCard="environment"
                  title="Attribution Audiences"
                />
                {tablet && (
                  <div
                    style={{
                      margin: "12px",
                      textAlign: "center",
                    }}
                  >
                    <LocationsDropdown
                      currentLocationId={currentLocationId}
                      locations={walkInAttributions}
                      setCurrentLocationId={setCurrentLocationId}
                      title={"View Location(s)"}
                    />
                  </div>
                )}
                {!tablet && (
                  <div
                    style={{
                      margin: "12px",
                      textAlign: "end",
                    }}
                  >
                    <LocationsDropdown
                      currentLocationId={currentLocationId}
                      locations={walkInAttributions}
                      setCurrentLocationId={setCurrentLocationId}
                      title={"View Location(s)"}
                    />
                  </div>
                )}
                {mapOpen && (
                  <LauncherMap
                    zoom={5}
                    styles={styles}
                    addGeoTarget={(item, isAttribution) => {
                      props.addTargetFromMapClicked(item, isAttribution);
                    }}
                    geoTargets={walkInAttributions}
                    currentLocationId={currentLocationId}
                    disabledDrawingManagerTool={!isTrafficker}
                  />
                )}
              </Card>
            </Col>
          </Row>
          {mapOpen && (
            <Row style={styles.rowOne}>
              <Col xs={24}>
                <GeoTargetManager
                  {...props}
                  updateCampaignOrderWithLocations={
                    updateCampaignOrderWithAttributionLocations
                  }
                  locationTargets={walkInAttributions}
                  type="Attribution"
                  isTrafficker={isTrafficker}
                />
              </Col>
            </Row>
          )}
          {tablet && (
            <Row>
              <Col style={styles.col} span={12} xs={24} className="mobile-nav">
                <NavigationButtons
                  disabled={isEmpty(attrData)}
                  onStepChange={onStepChange}
                  handleUpdateCampaignOrder={handleUpdateCampaignOrder}
                  currentStep={currentStep}
                  isEdit={isEdit}
                  onChange={onChange}
                  onSubmitLoad={onSubmitLoad}
                />
              </Col>
            </Row>
          )}
        </Col>
        {!tablet && (
          <Col xs={6}>
            <div>
              <StepsCard stepsToComplete={steps} />
              <NavigationButtons
                disabled={
                  isEmpty(cart) &&
                  !appId &&
                  isEmpty(attrData) &&
                  awarenessCampaign === false &&
                  signUpCampaign === false &&
                  pageViewCampaign === false &&
                  !isEmpty(pageViewAppId)
                }
                onStepChange={onStepChange}
                handleUpdateCampaignOrder={handleUpdateCampaignOrder}
                currentStep={currentStep}
                isLive={isLive}
                isEdit={isEdit}
                onChange={onChange}
                onSubmitLoad={onSubmitLoad}
              />
            </div>
          </Col>
        )}
      </Row>

      <Modal
        title="Add attribution locations"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>
          Are you sure you want to add {audienceGeoTargetsToAdd.length}{" "}
          attribution locations to this campaign order?{" "}
        </p>
      </Modal>
    </Frag>
  );
};

const CampaignLauncherViewAttributionWithController = props => {
  return (
    <GeotargetManagerController
      {...props}
      updateCampaignOrderWithLocations={
        props.updateCampaignOrderWithAttributionLocations
      }
      targets={
        props.isCloningClicked === true
          ? props.cloneGeoTargetData.concat(props.attributionTargets)
          : props.attributionTargets
      }
      targetType={"Attribution"}
    >
      <CampaignLauncherViewAttribution />
    </GeotargetManagerController>
  );
};

export default CampaignLauncherViewAttributionWithController;
