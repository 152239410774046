import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";
import ORG_DETAILS_UPDATE from "../../GraphQl/Mutations/ORG_DETAILS_UPDATE";
import GET_ORG_THEME_CONFIG from "../../GraphQl/Queries/GET_ORG_THEME_CONFIG";
import GET_ORG_TABLE_CONFIG from "../../GraphQl/Queries/GET_ORG_TABLE_CONFIG";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import GET_ORG from "../../GraphQl/Queries/GET_ORG";
import { LoadingPage } from "../../../platform/shared/LoadingPage";
const SettingLoader = ({ ...props }) => {
  const currentOrgId = props.currentUser.role.org.id;

  const { data: selectedOrg, loading: loadingSelectedOrg } = useQuery(GET_ORG, {
    variables: {
      where: {
        id: currentOrgId,
      },
    },
  });

  const {
    data: orgTableConfig,
    loading: orgTableConfigLoading,
    error: orgTableConfigError,
    refetch: orgTableConfigRefetch,
  } = useQuery(GET_ORG_TABLE_CONFIG, {
    variables: { id: currentOrgId },
  });

  const {
    data: themeConfig,
    loading: themeConfigLoading,
    error: themeConfigError,
  } = useQuery(GET_ORG_THEME_CONFIG, {
    variables: { id: currentOrgId },
  });

  const [updateOrg] = useMutation(ORG_DETAILS_UPDATE);

  if (loadingSelectedOrg) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    selectedOrg: selectedOrg.org,
    orgTableConfig,
    themeConfig,
    orgTableConfigLoading,
    themeConfigLoading,
    orgTableConfigError,
    themeConfigError,
    updateOrg,
    orgTableConfigRefetch,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(SettingLoader));
