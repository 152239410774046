import { message } from "antd";
import React from "react";

const EditorialMainController = ({
  data,
  eventsTargets,
  isSelfService,
  orgsSharing,
  createEditorial,
  refetch,
  updateEditorialSharing,
  updateEditorialMeasurements,
  allEditorials,
  ...props
}) => {
  const onCreateEditorial = async values => {
    message.loading("Creating Editorial...", 10);
    try {
      await createEditorial({
        variables: {
          clicksTag: values.clicksTag,
          transactionsTag: values.transactionsTag,
          name: values.name,
          domain: values.domain,
          url: values.url,
          trackMesurements: values.trackMesurements,
          startDate: values.startDate,
        },
      });
      await refetch();
      message.destroy();
      message.success("Editorial Created", 0.5);
    } catch (error) {
      message.destroy();
      message.error("Error Creating Editorial");
    }
  };

  const onShareEditorial = async values => {
    message.loading("Sharing Editorial(s)...", 10);
    for (const key of Object.keys(values)) {
      const { editorialId, sharedOrgs } = values[key];
      const toDelete = [];
      const toAdd = [];

      //Initial Editorial Data
      const editorialData = data.find(editorial => {
        return editorial.id === editorialId;
      });

      const exisingSharedOrgs = [];
      editorialData.sharedOrgs.forEach(shared => {
        if (
          !shared.isAdmin &&
          !sharedOrgs.find(o => o === shared.org.id) &&
          orgsSharing.find(o => o.id === shared.org.id)
        ) {
          toDelete.push({ id: shared.id });
        } else if (!shared.isAdmin) exisingSharedOrgs.push(shared.org.id);
      });

      sharedOrgs.forEach(shared => {
        const existingShared = exisingSharedOrgs.find(o => o === shared);
        if (!existingShared) {
          toAdd.push({
            isAdmin: false,
            isOwner: true,
            org: { connect: { id: shared } },
          });
        }
      });

      await updateEditorialSharing({
        variables: {
          id: editorialId,
          sharedOrgs: {
            create: toAdd,
            delete: toDelete,
          },
        },
      });
      await refetch();
    }
    message.destroy();
    message.success("Editorial(s) Shared", 0.5);
  };

  let publishers = [];
  if (allEditorials) {
    allEditorials.forEach(editorial => {
      if (editorial.transactionsTag) {
        editorial.transactionsTag.orgs.forEach(org => {
          if (!publishers.find(p => p.id === org.id)) {
            publishers.push(org);
          }
        });
      }
    });
  }
  publishers = publishers.sort((a, b) => a.name.localeCompare(b.name));

  const groupedDataParents = [];
  const groupedDataChild = [];
  //Segregate the data into parent and child publishers
  data.forEach(editorial => {
    // Having no optional url means that the editorial is a parent publisher
    if (!editorial.url) {
      groupedDataParents.push(editorial);
    } else {
      groupedDataChild.push(editorial);
    }
  });

  //Assign child publishers to its proper parent.
  groupedDataChild.forEach(editorial => {
    //To be considered a parent the child publisher should have the same clicksTag and transactionsTag
    const parent = groupedDataParents.find(
      g =>
        g.clicksTag.id === editorial.clicksTag.id &&
        g.transactionsTag.id === editorial.transactionsTag.id
    );
    if (parent) {
      if (!parent.children) parent.children = [];
      parent.children.push(editorial);
    } else {
      //If there is no parent, then the child publisher is a parent
      groupedDataParents.push(editorial);
    }
  });

  // Share Editorial filter Logic
  const getFilteredOrgsRow = (row, orgs) => {
    let filteredRow;
    let filteredSelection;

    // get clickEventsOrg and transactionsEventsOrg
    const clickEventsOrg = row.sharedOrgs.find(
      org => org.isClickEvents === true && org.isTransactionsEvents === false
    );
    const transactionsEventsOrg = row.sharedOrgs.find(
      org => org.isTransactionsEvents === true && org.isClickEvents === false
    );

    // filter orgs based on clickEventsOrg
    if (clickEventsOrg) {
      filteredRow = clickEventsOrg.org.id;
      filteredSelection = orgs.filter(org =>
        org.parentOrg.some(parent => parent.id === filteredRow)
      );
      const filteredRowOrg = orgs.find(org => org.id === filteredRow);
      if (filteredRowOrg) {
        filteredSelection.push(filteredRowOrg);
      }
    } else {
      filteredSelection = orgs;
    }

    // add transactionsEventsOrg to filteredSelection if not already present
    if (transactionsEventsOrg) {
      const additionalOrgId = transactionsEventsOrg.org.id;
      if (!filteredSelection.some(org => org.id === additionalOrgId)) {
        const additionalOrg = orgs.find(org => org.id === additionalOrgId);
        if (additionalOrg) {
          filteredSelection.push(additionalOrg);
        }
      }
    }
    return filteredSelection;
  };
  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        data: groupedDataParents,
        tags: eventsTargets,
        onCreateEditorial,
        onShareEditorial,
        isSelfService,
        orgsSharing,
        publishers,
        getFilteredOrgsRow,
      })}
    </React.Fragment>
  );
};

export default EditorialMainController;
