import React from "react";
import { useQuery, useLazyQuery, useMutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import EVENTS_TARGET from "../../../GraphQl/Queries/EVENTS_TARGET";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import ErrorPage from "../../../../platform/shared/ErrorPage";
import GET_BASIC_TAG_ACTIVITY from "../../../GraphQl/Queries/GET_BASIC_TAG_ACTIVITY";
import CREATE_TEST_SIGNAL_LOGS from "../../../GraphQl/Mutations/CREATE_TEST_SIGNAL_LOGS";
import moment from "moment";
import DELETE_EVENTS_TARGET from "../../../GraphQl/Mutations/DELETE_EVENTS_TARGET";
import DISABLE_ENDABLE_TAG from "../../../GraphQl/Mutations/DISABLE_ENABLE_TAG";
import REGENERATE_TAG from "../../../GraphQl/Mutations/REGENERATE_TAG";

const TagsDetailsLoader = props => {
  const { match, history, userPermission } = props;
  const id = match.params.id;

  const { data, loading, error, refetch } = useQuery(EVENTS_TARGET, {
    variables: {
      where: {
        id,
      },
    },
  });

  const [regenerateTag] = useMutation(REGENERATE_TAG, {
    onCompleted: () => refetch(),
  });

  const [createLogs] = useMutation(CREATE_TEST_SIGNAL_LOGS, {
    onCompleted: () => refetch(),
  });
  const [getBasicTagActivity, { loading: tagActivityLoading }] = useLazyQuery(
    GET_BASIC_TAG_ACTIVITY,
    {
      onCompleted: data => {
        const { getBasicTagActivity } = data;
        createLogs({
          variables: {
            id,
            createLogs: {
              testDate: moment().format(),
              pageViews: getBasicTagActivity.pageViews,
              signups: getBasicTagActivity.signups,
              transactions: getBasicTagActivity.transactions,
            },
          },
        });
      },
    }
  );
  const [deleteTag] = useMutation(DELETE_EVENTS_TARGET, {
    variables: {
      id,
    },
    onCompleted: () => {
      history.push("/tags/main");
    },
  });

  const [disableTag, { loading: disableEnableLoading }] = useMutation(
    DISABLE_ENDABLE_TAG,
    {
      onCompleted: () => refetch(),
    }
  );

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    data,
    getBasicTagActivity,
    tagActivityLoading,
    deleteTag,
    disableTag,
    disableEnableLoading,
    regenerateTag,
    userPermission,
  });
};

export default withRouter(TagsDetailsLoader);
