import React from "react";
import { useMutation, useQuery, graphql, useLazyQuery } from "react-apollo";
import compose from "lodash/flowRight";
import { withRouter } from "react-router-dom";
import EditorialDetailsController from "./EditorialDetailsController";
import EDITORIAL from "../../../GraphQl/Queries/EDITORIAL";
import EDITORIAL_TRANSACTIONS_CONNECTIONS from "../../../GraphQl/Queries/EDITORIAL_TRANSACTIONS_CONNECTIONS";
import EDITORIAL_TRANSACTIONS from "../../../GraphQl/Queries/EDITORIAL_TRANSACTIONS";
import { Auth } from "aws-amplify";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import EDITORIAL_DOMAIN from "../../../GraphQl/Queries/EDITORIAL_DOMAIN";
import EDITORIAL_DELETE from "../../../GraphQl/Mutations/EDITORIAL_DELETE";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import EDITORIAL_PAUSE_PLAY from "../../../GraphQl/Mutations/EDITORIAL_PAUSE_PLAY";

const EditorialDetailsLoader = props => {
  const { children, match, location, currentUser } = props;
  const { params } = match;
  const { id, pageNumber } = params;
  const urlParams = new URLSearchParams(location.search);
  const currentOrgId = currentUser.role.org.id;

  const config = props.currentUser.permission;
  const userPermission = determineUserAccess(config);

  //Maximum displayed transactions per page
  const pageSize = urlParams.get("pageSize")
    ? parseInt(urlParams.get("pageSize"))
    : 10;
  //Filter for attribution method
  const method = urlParams.get("method") ? urlParams.get("method") : "All";
  //Filter for page views count
  const pageViews = urlParams.get("pageViews")
    ? urlParams.get("pageViews")
    : "All";
  const startDate = urlParams.get("startDate")
    ? urlParams.get("startDate")
    : null;
  const endDate = urlParams.get("endDate") ? urlParams.get("endDate") : null;
  const url = urlParams.get("url") ? urlParams.get("url") : null;

  let transactionWhereClause = {
    editorial: {
      id,
    },
  };
  if (method !== "All") {
    transactionWhereClause = {
      ...transactionWhereClause,
      attributionMethod: method,
    };
  }
  if (pageViews !== "All") {
    transactionWhereClause = {
      ...transactionWhereClause,
      clicks_gte: Number(pageViews),
    };
  }
  if (startDate && endDate) {
    transactionWhereClause = {
      ...transactionWhereClause,
      orderTime_gte: startDate,
      orderTime_lte: endDate,
    };
  }

  if (url) {
    transactionWhereClause = {
      ...transactionWhereClause,
      clicksList_some: {
        pageURL_contains: url,
      },
    };
  }
  //By Date and Summary Data
  const { data, loading, error } = useQuery(EDITORIAL, {
    variables: { id },
  });

  const [
    getEditorialDomain,
    { data: domainData, loading: domainDataLoading },
  ] = useLazyQuery(EDITORIAL_DOMAIN);

  //Paginated Transactions for table
  const {
    data: transactions,
    loading: transactionLoading,
    error: transactionsError,
  } = useQuery(EDITORIAL_TRANSACTIONS_CONNECTIONS, {
    variables: {
      first: pageSize,
      skip: (Number(pageNumber) - 1) * pageSize,
      where: transactionWhereClause,
      orderBy: `orderTime_ASC`,
    },
  });

  //Delete Editorial
  const [deleteEditorial] = useMutation(EDITORIAL_DELETE, {
    variables: { id },
  });

  //Pause Play Editorial
  const [pausePlayEditorial] = useMutation(EDITORIAL_PAUSE_PLAY, {
    variables: {
      id,
    },
  });

  //All transactions records for reporting
  const {
    data: allTransactions,
    loading: allTransactionLoading,
    error: allTransactionsError,
  } = useQuery(EDITORIAL_TRANSACTIONS, {
    variables: {
      where: transactionWhereClause,
      orderBy: `orderTime_ASC`,
    },
  });

  if (
    error &&
    error.graphQLErrors[0].extensions &&
    error.graphQLErrors[0].extensions.code === "401"
  ) {
    //Force signout if user has no access
    Auth.signOut();
    window.location.reload();
  }

  return (
    <EditorialDetailsController
      data={data}
      transactions={transactions}
      loading={loading}
      error={error}
      transactionsError={transactionsError}
      transactionLoading={transactionLoading}
      allTransactions={allTransactions}
      allTransactionLoading={allTransactionLoading}
      allTransactionsError={allTransactionsError}
      getEditorialDomain={getEditorialDomain}
      domainData={domainData}
      domainDataLoading={domainDataLoading}
      currentOrgId={currentOrgId}
      deleteEditorial={deleteEditorial}
      userPermission={userPermission}
      pausePlayEditorial={pausePlayEditorial}
    >
      {children}
    </EditorialDetailsController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(EditorialDetailsLoader));
