import React from "react";
import { Storage } from "aws-amplify";
import { Spin, Image } from "antd";

class S3Image extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ready: false,
      url: "",
    };
  }

  componentDidMount = () => {
    const { imgKey } = this.props;
    if (imgKey)
      Storage.get(imgKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => console.log(err));
    else
      this.setState({
        ready: true,
      });
  };

  componentDidUpdate(prevProps) {
    // If props are updated, such as from new data getting added, will recalculate
    if (this.props.imgKey !== prevProps.imgKey) {
      this.setState({
        ready: false,
      });
      Storage.get(this.props.imgKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => console.log(err));
    }
  }

  render() {
    if (!this.state.ready) {
      return <Spin size="small" />;
    }

    return <Image src={this.state.url} alt="preview" height={40} width={40} />;
  }
}

export default S3Image;
