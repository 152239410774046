import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Divider, Row, Col, Card } from "antd";
import { mapValues, groupBy, omit, isEmpty } from "lodash";

import FilterBar from "./FilterBar";
import BasketItemsTable from "./BasketItemsTable";
import DetailsBar from "./DetailsBar";
import BasketItemsBarChart from "./BasketItemsBarChart";
import Charts from "./charts/Charts";
import { colors } from "../../../../../../../core/utils/constants/listOfColors";

const aggregateData = data =>
  data && data.length
    ? mapValues(groupBy(data, "ORDER_ID"), list =>
        list.map(obj => omit(obj, "ORDER_ID"))
      )
    : {};

const sortData = data =>
  data && data.length
    ? data.sort(
        (a, b) => moment.unix(a.DATE).unix() - moment.unix(b.DATE).unix()
      )
    : [];

const BasketItemsLayout = withRouter(
  ({
    chartData,
    location,
    permission,
    reportName,
    searchTerm,
    setSearchTerm,
    setAttributionWindow = () => {}, // Default to no-op function
    campaignOrderId,
    chartsLoading,
  }) => {
    const params = new URLSearchParams(location.search);
    const search = params.get("search") || "";
    const orderId = params.get("orderId") || "";
    const dropdownFilter = params.get("df");
    const [currentPage, setCurrentPage] = useState(1);
    const [orderIdVal, setOrderIdVal] = useState(orderId);

    const sortedData = useMemo(
      () => (chartData && chartData.data ? sortData(chartData.data) : []),
      [chartData]
    );

    const filterByOrderId = useMemo(() => {
      const lowerCaseOrderId = orderId.toLowerCase();
      return obj => obj.ORDER_ID.toLowerCase().includes(lowerCaseOrderId);
    }, [orderId]);

    const filterBySearchTerm = useMemo(() => {
      const searchStrings = search
        .split(",")
        .map(str => str.trim().toLowerCase());
      return obj =>
        searchStrings.some(searchStr =>
          obj.NAME.toLowerCase().includes(searchStr)
        );
    }, [search]);

    const daysMapping = {
      "Last 7 Days": 7,
      "Last 15 Days": 15,
      "Last 30 Days": 30,
      "Last 60 Days": 60,
      "Last 90 Days": 90,
    };

    const filterDataByDate = (data, days) => {
      const endDate = moment();
      const startDate = moment().subtract(days, "days");
      return data.filter(obj =>
        moment.unix(obj.DATE).isBetween(startDate, endDate, null, "[]")
      );
    };

    const getFilteredData = data => {
      let filteredData = data;
      if (orderId) filteredData = filteredData.filter(filterByOrderId);
      if (search) {
        filteredData = filteredData.filter(filterBySearchTerm);
        if (setSearchTerm) setSearchTerm(search);
      }
      if (dropdownFilter && dropdownFilter !== "All Time") {
        const days = daysMapping[dropdownFilter];
        if (days) {
          setAttributionWindow(days);
          filteredData = filterDataByDate(filteredData, days);
        }
      }
      return filteredData;
    };

    const filteredData = useMemo(() => getFilteredData(sortedData), [
      getFilteredData,
      sortedData,
    ]);

    const aggregatedData = useMemo(() => aggregateData(filteredData), [
      filteredData,
    ]);

    const [tableData, setTableData] = useState([]);
    const [exportData, setExportData] = useState([]);

    const sortedByName = useMemo(
      () =>
        filteredData.length
          ? filteredData.sort((a, b) => a.NAME.localeCompare(b.NAME))
          : [],
      [filteredData]
    );

    const filteredByName = useMemo(
      () =>
        sortedByName.filter(
          item =>
            item.ORDER_ID.toLowerCase().includes(search.toLowerCase().trim()) ||
            item.NAME.toLowerCase().includes(search.toLowerCase().trim())
        ),
      [sortedByName, search]
    );

    useEffect(() => {
      const revenueTableAggregatedData = isEmpty(filteredByName)
        ? {}
        : groupBy(filteredByName, "NAME");

      const updatedTableData = [];
      let updatedTotalRevenue = 0;
      let updatedTotalQuantity = 0;

      Object.keys(revenueTableAggregatedData).forEach(key => {
        const byNameDataArray = revenueTableAggregatedData[key];
        let revenue = 0;
        let quantity = 0;

        byNameDataArray.forEach(item => {
          revenue += item.PRICE * item.QUANTITY;
          quantity += item.QUANTITY;
        });

        updatedTableData.push({
          NAME: key,
          REVENUE: revenue,
          QUANTITY: quantity,
        });

        updatedTotalRevenue += revenue;
        updatedTotalQuantity += quantity;
      });

      const updatedExportData = [
        ...updatedTableData,
        {
          NAME: "TOTAL",
          REVENUE: updatedTotalRevenue,
          QUANTITY: updatedTotalQuantity,
        },
      ];

      setTableData(prev =>
        JSON.stringify(prev) !== JSON.stringify(updatedTableData)
          ? updatedTableData
          : prev
      );
      setExportData(prev =>
        JSON.stringify(prev) !== JSON.stringify(updatedExportData)
          ? updatedExportData
          : prev
      );
    }, [filteredByName]);

    const colorValuePairs = useMemo(
      () => tableData.map((el, index) => ({ ...el, color: colors[index] })),
      [tableData]
    );

    return (
      <Card>
        <Row>
          <Col span={24}>
            <FilterBar
              data={exportData}
              setOrderIdVal={setOrderIdVal}
              params={params}
              dropdownFilter={dropdownFilter}
              permission={permission}
              setSearchTerm={setSearchTerm}
              setAttributionWindow={setAttributionWindow}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={{ span: 18, order: 1, push: 6 }}>
            <DetailsBar
              data={filteredData}
              aggregatedData={aggregatedData}
              chartsLoading={chartsLoading}
            />
          </Col>
          <Col id="basket-items-lg-table" xs={24} lg={{ span: 6 }}>
            <BasketItemsTable
              orderIdVal={orderIdVal}
              setOrderIdVal={setOrderIdVal}
              aggregatedData={aggregatedData}
              reportName={reportName}
              campaignOrderId={campaignOrderId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchTerm={searchTerm}
            />
          </Col>
          <Col xs={24} lg={{ span: 18, push: 6, order: 2 }}>
            <BasketItemsBarChart data={filteredData} />
          </Col>
          <Col xs={24} lg={{ span: 18, push: 6, order: 3 }}>
            <Charts chartData={colorValuePairs} chartsLoading={false} />
          </Col>
        </Row>
      </Card>
    );
  }
);

export default BasketItemsLayout;
