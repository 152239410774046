import React from "react";
import moment from "moment";
import * as XLSX from "xlsx";
import { Row, Col, Result, Card, Space, Typography, Tabs } from "antd";
import { AreaChartOutlined, DownloadOutlined } from "@ant-design/icons";
import { PageContentLayout, PageHeaderLayout } from "../../../../shared/layout";
import PacingReport from "./PacingReport";
import { cloneDeep } from "lodash";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import Button from "../../../../shared/globalStyling/Button";

import { Link } from "react-router-dom";
import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber3SigFig,
} from "../../../../../core/utils/campaigns";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { isEmpty } from "lodash";
import AdvertiserFilter from "../../../../shared/AdvertiserFilter";
import JSZip from "jszip";
import PctDiff from "../../RollUp/components/PctDiff";
import { orgLevelCustomization } from "../../../../../core/permissions/orgLevelCustomization";
import DualAxisChart from "../../../../shared/charts/DualAxisChart";

const { Text } = Typography;

const PaidSearchAnalyticsView = ({
  history,
  location,
  data,
  diffPcts,
  error,
  loading,
  orgConfig,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  defaultSummaryDates,
  pacingReport,
  generatePacingReport,
  pacingGoogleReport,
  pacingBingReport,
  pacingAllCampaignsData,
  pacingSingleCampaignsData,
  getPacing,
  getPacingGoogle,
  getPacingBing,
  getPacingAllCampaigns,
  getSingleCampaigns,
  pacingLoading,
  pacingGoogleLoading,
  pacingBingLoading,
  pacingAllCampaignsLoading,
  pacingSingleCampaignsLoading,
  currentUser,
}) => {
  document.title = "Roll Up - Paid Search Summary";
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;
  const refreshPage = () => {
    history.push("/");
    history.goBack();
  };
  const isGoogleAds = (orgConfig && orgConfig.processWithGoogleAds) || false;
  const byDates = !data || isEmpty(data.byDate) ? [] : data.byDate;

  const ExportDataButton = () => {
    const exportFile = fileExtension => {
      try {
        const zip = new JSZip();
        const folder = zip.folder("Paid Search Analytics");
        let fileName = "paid-search-analytics";
        let dupFileData =
          isEmpty(data) && isEmpty(data.byDate) ? [] : cloneDeep(data.byDate);
        dupFileData = dupFileData.map(item => {
          item.date = moment(item.xAxis).format("YYYY-MM-DD");
          return item;
        });
        const newData = dupFileData.map(
          ({ __typename, xAxis, ...rest }) => rest
        );

        if (fileExtension === "csv") {
          folder.file(
            `${fileName}-${moment.utc().format("MMMM DD YYYY")}.csv`,
            Papa.unparse(newData)
          );
        } else if (fileExtension === "xlsx") {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const excelData = new Blob([excelBuffer], { type: fileType });

          folder.file(
            `${fileName}-${moment.utc().format("MMMM DD YYYY")}.xlsx`,
            excelData
          );
        }

        zip.generateAsync({ type: "blob" }).then(
          blob => {
            saveAs(blob, "paid-search-analytics.zip");
            return true;
          },
          function(e) {
            console.log("error", e);
            return false;
          }
        );
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <PageHeaderLayout
        title={" "}
        rightContent={
          <Space className="ant-space-md-horizontal ant-space-xs-vertical">
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => exportFile("csv")}
              disabled={isEmpty(data)}
            >
              <DownloadOutlined />
              {`Export CSV`}
            </Button>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => exportFile("xlsx")}
              disabled={isEmpty(data)}
            >
              <DownloadOutlined />
              {`Export Excel`}
            </Button>
          </Space>
        }
      />
    );
  };

  // Set Document Title

  if (!orgConfig) {
    return (
      <>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button onClick={() => refreshPage()} type="primary">
              Reload
            </Button>
          }
        />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Link to="/home">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </>
    );
  }

  const updatedAt = () => {
    if (data && data.updatedAt) {
      return `Updated ${moment(
        data.updatedAt,
        moment.DATETIME_LOCAL_MS
      ).calendar()}`;
    }
    return "--";
  };

  const dropDownOptions = [
    { key: "clicks", display: "Clicks" },
    { key: "impressions", display: "Impressions" },
    { key: "conversions", display: "Conversions" },
    { key: "cpc", display: "CPC" },
    { key: "cpm", display: "CPM" },
    { key: "ctr", display: "CTR" },
    { key: "roas", display: "ROAS" },
    { key: "spend", display: "Spend" },
    { key: "revenue", display: "Revenue" },
  ];

  console.log(byDates);
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Paid Search Performance"}
        subtitle={updatedAt()}
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Col style={{ display: "flex" }} span={24}>
            <>
              <Button onClick={refreshPage}>
                <ButtonText text="Update" />
              </Button>
            </>
          </Col>
        }
      />
      <PageContentLayout>
        <Row>
          <Col xs={24} md={8} xl={5}>
            <Text>Filter by Advertiser</Text>
            <AdvertiserFilter isSinglePage={true} isDemo={orgConfig.isDemo} />
          </Col>
        </Row>
        <Row wrap={false} style={{ marginTop: "32px" }}>
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="Impressions">
                  <Typography>
                    {data && data.impressions
                      ? formatNumber(data.impressions)
                      : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.impressions} />}
                </Card>
              </Col>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="Clicks">
                  <Typography>
                    {data && data.clicks ? formatNumber(data.clicks) : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.clicks} />}
                </Card>
              </Col>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="CTR">
                  <Typography>
                    {data && data.ctr
                      ? `${formatNumber3SigFig(data.ctr)}%`
                      : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.ctr} />}
                </Card>
              </Col>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="CPC">
                  <Typography>
                    {data && data.cpc
                      ? `${formatNumber3SigFig(data.cpc)}%`
                      : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.cpc} />}
                </Card>
              </Col>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="ROAS">
                  <Typography>
                    {data && data.roas ? `${formatNumber(data.roas)}%` : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.roas} />}
                </Card>
              </Col>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="Revenue">
                  <Typography>
                    {data && data.revenue
                      ? formatCurrency2SigFig(data.revenue, currencyFormat)
                      : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.revenue} />}
                </Card>
              </Col>
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card loading={loading} title="Spend">
                  <Typography>
                    {data && data.cost
                      ? formatCurrency2SigFig(data.cost, currencyFormat)
                      : "N/A"}
                  </Typography>
                  {diffPcts && <PctDiff value={diffPcts.data.cost} />}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageContentLayout>
      <PageContentLayout>
        <Space size={16} style={{ width: "100%" }} direction="vertical">
          <Card loading={loading}>
            <Tabs
              onChange={key => {
                if (key === "pacing") getPacing();
              }}
            >
              <Tabs.TabPane tab="Paid Search Summary" key="summary">
                {orgConfig.exportData && <ExportDataButton />}
                <DualAxisChart
                  byDate={byDates}
                  xAxis="xAxis"
                  options={dropDownOptions}
                  onDatePickerChange={({ data, dates, rangeKey }) => {
                    setBySummaryRangeDate(dates, rangeKey);
                  }}
                  hasTooltip
                  tooltip={
                    isGoogleAds ? (
                      <span>
                        Data is processed daily and is not in real time. In
                        addition, revenue and transactions are subject to a
                        default 30 day conversion window. Minor discrepancies
                        between this dashboard and the Google Interface are to
                        be expected. You can learn more about conversion windows
                        here.
                        <br />
                        <br />
                        <a href="https://support.google.com/google-ads/answer/3123169?hl=en">
                          https://support.google.com/google-ads/answer/3123169?hl=en
                        </a>
                      </span>
                    ) : (
                      <span>
                        Data is processed daily and is not in real time. Google
                        analytics uses data sampling which is designed to speed
                        up reporting. In addition, revenue and transactions are
                        subject to a default 30 day conversion window. Minor
                        discrepancies between this dashboard and the Google
                        Interface are to be expected.
                        <br />
                        <br />
                        Data Sampling - Links to
                        <br />
                        <a href="https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article">
                          https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article
                        </a>
                        <br />
                        <br />
                        Conversion windows - Links to
                        <br />
                        <a href="https://support.google.com/google-ads/answer/3123169?hl=en">
                          https://support.google.com/google-ads/answer/3123169?hl=en
                        </a>
                      </span>
                    )
                  }
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Pacing Report" key="pacing">
                <PacingReport
                  orgConfig={orgConfig}
                  location={location}
                  pacingReport={pacingReport}
                  generateReport={generatePacingReport}
                  history={history}
                  pacingGoogleReport={pacingGoogleReport}
                  pacingBingReport={pacingBingReport}
                  pacingAllCampaignsData={pacingAllCampaignsData}
                  pacingSingleCampaignsData={pacingSingleCampaignsData}
                  getPacing={getPacing}
                  getPacingGoogle={getPacingGoogle}
                  getPacingBing={getPacingBing}
                  getPacingAllCampaigns={getPacingAllCampaigns}
                  getSingleCampaigns={getSingleCampaigns}
                  pacingLoading={pacingLoading}
                  pacingGoogleLoading={pacingGoogleLoading}
                  pacingBingLoading={pacingBingLoading}
                  pacingAllCampaignsLoading={pacingAllCampaignsLoading}
                  pacingSingleCampaignsLoading={pacingSingleCampaignsLoading}
                  currencyFormat={currencyFormat}
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Space>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default PaidSearchAnalyticsView;
