//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { Avatar, Timeline, Row, Col, Tooltip, Card } from "antd";
import { getMobileOperatingSystem, determineAdServer } from "./utils";

import {
  DesktopOutlined,
  TabletOutlined,
  MobileOutlined,
  // AppleOutlined,
  // AndroidOutlined,
  ArrowUpOutlined,
  CloudOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

const { Item } = Timeline;

const ImpressionSubTable = ({ impressions, record, topImpressions, dsp }) => {
  const [impressionsData, setImpressionsData] = useState([]);
  useEffect(() => {
    const sortedImpressions =
      !isEmpty(impressions) &&
      impressions.sort((a, b) => {
        if (
          moment(a.IMPRESSION_TIME, moment.DATETIME_LOCAL_MS).isBefore(
            new Date(b.IMPRESSION_TIME)
          )
        )
          return -1;
        return 1;
      });

    setImpressionsData(sortedImpressions);
  }, [impressions]);

  const getUrlByName = name => {
    const entry = topImpressions.find(item => item.name === name);
    return entry ? entry.url : "";
  };

  const makeTimeLine = impressionsData.map((impression, i) => {
    const {
      IMPRESSION_TIME,
      IMPRESSION_USERAGENT,
      IMPRESSION_PAGE_URL,
      PAGE_URLHOST,
      IMPRESSION_REFERRER,
      //IMPRESSION_PUBLISHERNAME,
      IMPRESSION_APPNAME,
      IMPRESSION_APPSTORE_ID,
      IMPRESSION_SITE_ID,
      IMPRESSION_SITENAME,
    } = impression;
    let APPSITENAME = "";
    //let APPSITELINK = "";
    //let APPSITENAMETOOLTIP = "";
    let APPLICATION_PLATFORM = "";
    let OS = "";

    OS = getMobileOperatingSystem(IMPRESSION_USERAGENT);

    // let PUBLISHER =
    //   IMPRESSION_PUBLISHERNAME && IMPRESSION_PUBLISHERNAME !== "N/A"
    //     ? `Published by - ${decodeURI(IMPRESSION_PUBLISHERNAME).replace(
    //         /[+_]/g,
    //         " "
    //       )}`
    //     : "";

    //First case checked - no pub, site or app macros resolve at all - TTD, CENTRO and LiquidM legacy tags revert to page url defaults
    // if (
    //   (!IMPRESSION_APPSTORE_ID || IMPRESSION_APPSTORE_ID === "N/A") &&
    //   (!IMPRESSION_APPNAME || IMPRESSION_APPNAME === "N/A") &&
    //   (!IMPRESSION_SITE_ID || IMPRESSION_SITE_ID === "N/A") &&
    //   (!IMPRESSION_SITENAME || IMPRESSION_SITENAME === "N/A")
    // ) {
    //   //No new macros resolve - we process with out of the box page view information - often only have ad server level information
    //   APPSITENAME = PAGE_URLHOST ? `${decodeURIComponent(PAGE_URLHOST)}` : "";
    //   APPSITELINK = IMPRESSION_REFERRER
    //     ? `${decodeURIComponent(IMPRESSION_REFERRER).substring(0, 60)}`
    //     : "";
    //   APPSITENAMETOOLTIP = IMPRESSION_PAGE_URL
    //     ? decodeURIComponent(IMPRESSION_PAGE_URL).substring(0, 60)
    //     : "";
    //   //Chacks the available page and referrer information to determine application platform
    //   APPLICATION_PLATFORM = determineAdServer(PAGE_URLHOST);
    //   //Even if we determine the page url to be an ad server - the referrer may still give better resolution
    //   if (IMPRESSION_REFERRER && APPLICATION_PLATFORM !== "Application") {
    //     APPLICATION_PLATFORM = determineAdServer(IMPRESSION_REFERRER);
    //   }
    // } else if (IMPRESSION_APPSTORE_ID && IMPRESSION_APPNAME) {
    //   //Highly confident we have a mobile application based on new macros
    //   APPSITENAME = `${decodeURIComponent(IMPRESSION_APPNAME)
    //     .replace(/[+_]/g, " ")
    //     .split(":")
    //     .pop()}`;
    //   APPSITELINK = `${decodeURIComponent(IMPRESSION_APPSTORE_ID)}`;
    //   APPLICATION_PLATFORM = "Application";
    // } else if (
    //   IMPRESSION_SITENAME &&
    //   IMPRESSION_SITE_ID &&
    //   !IMPRESSION_SITE_ID
    // ) {
    //   //No url from IMPRESSION_SITE_ID suggests mobile application
    //   APPSITENAME = `${decodeURIComponent(IMPRESSION_SITENAME)
    //     .replace(/[+_]/g, " ")
    //     .split(":")
    //     .pop()}`;

    //   APPSITELINK = IMPRESSION_REFERRER
    //     ? `Referred by ${IMPRESSION_REFERRER.substring(0, 30)}`
    //     : "";
    //   APPLICATION_PLATFORM = "Application";
    // } else if (IMPRESSION_SITENAME && IMPRESSION_APPNAME) {
    //   //suggests website - better resolution than page level information
    //   const TEMP_SITENAME = decodeURIComponent(IMPRESSION_SITENAME)
    //     .replace(/[+_]/g, " ")
    //     .split(":")
    //     .pop();

    //   APPSITENAME = `${TEMP_SITENAME}`;
    //   APPSITELINK = IMPRESSION_REFERRER
    //     ? IMPRESSION_REFERRER
    //     : "https://" + TEMP_SITENAME.trim();
    //   APPLICATION_PLATFORM = "Website";
    // } else if (IMPRESSION_PAGE_URL && PAGE_URLHOST) {
    //   APPSITENAME = PAGE_URLHOST ? `${decodeURIComponent(PAGE_URLHOST)}` : "";

    //   APPLICATION_PLATFORM = "Application";
    //   APPSITELINK = IMPRESSION_PAGE_URL;
    // }

    // let appTypeIcon = () => {
    //   if (IMPRESSION_APPSTORE_ID && IMPRESSION_APPNAME) {
    //     if (IMPRESSION_APPSTORE_ID.includes("itunes")) {
    //       return <AppleOutlined />;
    //     } else if (IMPRESSION_APPSTORE_ID.includes("google")) {
    //       return <AndroidOutlined />;
    //     } else {
    //       return <MobileOutlined />;
    //     }
    //   } else if (
    //     IMPRESSION_SITENAME &&
    //     IMPRESSION_SITE_ID &&
    //     !IMPRESSION_SITE_ID
    //   ) {
    //     return <MobileOutlined />;
    //   } else if (APPLICATION_PLATFORM.includes("Ad Server")) {
    //     return <MobileOutlined />;
    //   } else if (APPLICATION_PLATFORM.includes("Application")) {
    //     if (
    //       PAGE_URLHOST
    //         ? PAGE_URLHOST.includes("itunes")
    //         : null || IMPRESSION_REFERRER
    //         ? IMPRESSION_REFERRER.includes("itunes")
    //         : null
    //     ) {
    //       return <AppleOutlined />;
    //     } else if (
    //       PAGE_URLHOST
    //         ? PAGE_URLHOST.includes("play.google")
    //         : null || IMPRESSION_REFERRER
    //         ? IMPRESSION_REFERRER.includes("play.google")
    //         : null
    //     ) {
    //       return <AndroidOutlined />;
    //     } else return <MobileOutlined />;
    //   }

    //   return <DesktopOutlined />;
    // };

    switch (dsp) {
      case "BKO":
      case "GOOGLEANALYTICS":
      case "Smart":
        if (
          (!IMPRESSION_APPSTORE_ID || IMPRESSION_APPSTORE_ID === "N/A") &&
          (!IMPRESSION_APPNAME ||
            IMPRESSION_APPNAME === "N/A" ||
            IMPRESSION_APPNAME === "{APPNAME}") &&
          (!IMPRESSION_SITE_ID ||
            IMPRESSION_SITE_ID === "N/A" ||
            IMPRESSION_SITE_ID === "{APPNAME}") &&
          (!IMPRESSION_SITENAME || IMPRESSION_SITENAME === "N/A")
        ) {
          APPSITENAME = PAGE_URLHOST
            ? decodeURIComponent(PAGE_URLHOST)
            : "Unknown Domain";
          //Chacks the available page and referrer information to determine application platform
          APPLICATION_PLATFORM = determineAdServer(PAGE_URLHOST);
          //Even if we determine the page url to be an ad server - the referrer may still give better resolution
          if (IMPRESSION_REFERRER && APPLICATION_PLATFORM !== "Application") {
            APPLICATION_PLATFORM = determineAdServer(IMPRESSION_REFERRER);
          }
        } else if (
          IMPRESSION_SITENAME &&
          (!IMPRESSION_APPNAME || !IMPRESSION_APPSTORE_ID)
        ) {
          APPSITENAME = decodeURIComponent(IMPRESSION_SITENAME)
            .replace(/[+_]/g, " ")
            .split(":")
            .pop();
          APPLICATION_PLATFORM = "Website";
        } else if (
          !IMPRESSION_SITENAME &&
          (IMPRESSION_APPNAME || IMPRESSION_APPSTORE_ID)
        ) {
          const appName = IMPRESSION_APPNAME || IMPRESSION_APPSTORE_ID;
          APPSITENAME = decodeURIComponent(appName)
            .replace(/[+_]/g, " ")
            .split(":")
            .pop();
          APPLICATION_PLATFORM = "Application";
        } else if (IMPRESSION_SITENAME && IMPRESSION_APPNAME) {
          APPSITENAME = decodeURIComponent(IMPRESSION_SITENAME)
            .replace(/[+_]/g, " ")
            .split(":")
            .pop();
          APPLICATION_PLATFORM = "Website";
        }
        break;
      // Fallback into LIQUIDM DSP
      default:
        if (
          (!IMPRESSION_APPSTORE_ID || IMPRESSION_APPSTORE_ID === "N/A") &&
          (!IMPRESSION_APPNAME || IMPRESSION_APPNAME === "N/A") &&
          (!IMPRESSION_SITE_ID || IMPRESSION_SITE_ID === "N/A") &&
          (!IMPRESSION_SITENAME || IMPRESSION_SITENAME === "N/A")
        ) {
          APPSITENAME = decodeURIComponent(PAGE_URLHOST);
          //Chacks the available page and referrer information to determine application platform
          APPLICATION_PLATFORM = determineAdServer(PAGE_URLHOST);
          //Even if we determine the page url to be an ad server - the referrer may still give better resolution
          if (IMPRESSION_REFERRER && APPLICATION_PLATFORM !== "Application") {
            APPLICATION_PLATFORM = determineAdServer(IMPRESSION_REFERRER);
          }
        } else if (IMPRESSION_APPSTORE_ID && IMPRESSION_APPNAME) {
          //Highly confident we have a mobile application based on new macros
          APPSITENAME = decodeURIComponent(IMPRESSION_APPNAME)
            .replace(/[+_]/g, " ")
            .split(":")
            .pop();
          APPLICATION_PLATFORM = "Application";
        } else if (
          IMPRESSION_SITENAME &&
          IMPRESSION_SITE_ID &&
          !IMPRESSION_SITE_ID
        ) {
          //No url from IMPRESSION_SITE_ID suggests mobile application
          APPSITENAME = decodeURIComponent(IMPRESSION_SITENAME)
            .replace(/[+_]/g, " ")
            .split(":")
            .pop();
          APPLICATION_PLATFORM = "Application";
        } else if (IMPRESSION_SITENAME && IMPRESSION_APPNAME) {
          //suggests website - better resolution than page level information
          APPSITENAME = decodeURIComponent(IMPRESSION_SITENAME)
            .replace(/[+_]/g, " ")
            .split(":")
            .pop();
          APPLICATION_PLATFORM = "Website";
        } else if (IMPRESSION_PAGE_URL && PAGE_URLHOST) {
          APPSITENAME = decodeURIComponent(PAGE_URLHOST);
          APPLICATION_PLATFORM = "Application";
        }
    }

    let icon = () => {
      if (
        OS === "Unknown" ||
        OS === "Chromebook Computer" ||
        OS === "Windows Computer" ||
        OS === "Computer"
      ) {
        return <DesktopOutlined />;
      }

      if (OS === "iPhone" || OS === "Android" || OS === "Windows Phone") {
        return <MobileOutlined />;
      }

      if (OS === "iPad") {
        return <TabletOutlined />;
      }
    };

    return (
      <Item key={i} dot={<ArrowUpOutlined style={{ fontSize: 16 }} />}>
        <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Col span={1}></Col>
          <Col span={4}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`${moment(IMPRESSION_TIME, moment.DATETIME_LOCAL_MS).format(
                "MM/DD/YY - HH:mm"
              )}`}
            </div>
          </Col>
          <Col span={4}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              Impression
            </div>
          </Col>
          <Col span={2}>
            <Avatar
              shape="square"
              size={50}
              icon={<CloudOutlined />}
              style={{ marginTop: "-16px" }}
            />
          </Col>
          <Col span={3}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {icon()}
              <Tooltip title={IMPRESSION_USERAGENT ? IMPRESSION_USERAGENT : ""}>
                {`  ${OS}`}
              </Tooltip>
            </div>
          </Col>
          <Col span={3}>{APPLICATION_PLATFORM}</Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
                display: "inline",
              }}
            >
              <Avatar
                shape="square"
                size={50}
                icon={<CloudOutlined />}
                src={getUrlByName(APPSITENAME)}
                style={{ marginRight: "16px" }}
              />
              <Tooltip
                title={APPSITENAME ? APPSITENAME : ""}
              >{`${APPSITENAME}`}</Tooltip>
            </div>
          </Col>
          <Col span={1}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <a
                href={`https://${APPSITENAME}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {APPLICATION_PLATFORM === "Website" &&
                APPSITENAME !== "Unknown Domain"
                  ? `Link`
                  : ``}
              </a>
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Card
        title="CUSTOMER JOURNEY"
        bodyStyle={{ padding: "0" }}
        headStyle={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Row
          style={{
            fontWeight: "bold",
            backgroundColor: "#FAFAFA",
            borderBottom: "1px solid #ddd",
            paddingLeft: "50px",
          }}
        >
          <Col style={{ padding: "10px" }} span={1}></Col>
          <Col style={{ padding: "10px" }} span={4}>
            Time
          </Col>
          <Col style={{ padding: "10px" }} span={4}>
            Event
          </Col>
          <Col style={{ padding: "10px" }} span={2}>
            Ad Unit
          </Col>
          <Col style={{ padding: "10px" }} span={3}>
            Device Type
          </Col>
          <Col style={{ padding: "10px" }} span={3}>
            Inventory
          </Col>
          <Col style={{ padding: "10px" }} span={5}>
            Impression Domain
          </Col>
          <Col style={{ padding: "10px" }} span={1}></Col>
        </Row>
        <br />
        <Timeline
          mode={"left"}
          reverse={true}
          style={{ padding: "10px", paddingLeft: "40px" }}
        >
          {makeTimeLine}
          <Item dot={<ShoppingCartOutlined style={{ fontSize: 16 }} />}>
            <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Col span={1}></Col>
              <Col span={4}>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "105%",
                    fontWeight: 700,
                    color: "#636363",
                  }}
                >
                  {`${moment(
                    record.transaction,
                    moment.DATETIME_LOCAL_MS
                  ).format("MM/DD/YY - HH:mm")}`}
                </div>
              </Col>
              <Col span={4}>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "105%",
                    fontWeight: 700,
                    color: "#636363",
                  }}
                >
                  Order Placed
                </div>
              </Col>
              <Col span={2}></Col>
              <Col span={3}></Col>
              <Col span={3}></Col>
              <Col span={6}></Col>
              <Col span={1}></Col>
            </Row>
          </Item>
        </Timeline>
      </Card>
    </div>
  );
};

export default ImpressionSubTable;
