import React, { createContext, useContext, useEffect, useState } from "react";
import { formatWholeNumber } from "../../../../utils/campaigns";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import moment from "moment";

const SEOConversionsContext = createContext();

export const SEOConversionsProvider = ({ reportKey, children }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();
  const [startDateEnDate, setStartDateEnDate] = useState([]);

  const [conversionsSummary, setConversionsSummary] = useState([]);
  const [conversionsByDate, setConversionsByDate] = useState([]);
  const [conversionsBySource, setConversionsBySource] = useState([]);

  const { data: signedURL } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  useEffect(() => {
    if (signedURL && !reportData) {
      const s3Url = new URL(signedURL.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            const json = await response.json();
            setReportData(json);
          } catch (err) {
            console.error(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [reportData, signedURL]);

  useEffect(() => {
    if (reportData) {
      const { totals, byDate, bySource } = reportData;

      const endDate =
        byDate.length > 0
          ? moment(
              byDate.reduce((acc, curr) => {
                const currDate = moment(curr.date, "YYYY-MM-DD");
                return currDate.isAfter(acc) ? currDate : acc;
              }, moment(byDate[0].date, "YYYY-MM-DD"))
            )
          : moment();

      setConversionsSummary(generateConversionsSummary(totals));
      setConversionsByDate(
        byDate
          .filter(d =>
            moment(d.date, "YYYY-MM-DD").isAfter(
              endDate.clone().subtract(30, "days")
            )
          )
          .sort((a, b) =>
            moment(a.date, "YYYY-MM-DD").diff(moment(b.date, "YYYY-MM-DD"))
          )
      );
      setConversionsBySource(bySource);

      setStartDateEnDate([endDate.clone().subtract(29, "days"), endDate]);
      setReportLoading(false);
    }
  }, [reportData]);

  const generateConversionsSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Total Conversions",
          value: formatWholeNumber(totals.conversions),
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Total Users",
          value: formatWholeNumber(totals.totalUsers),
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "User Engagement",
          value: totals.userEngagement,
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const secondsToFormatted = seconds => {
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const time = moment.utc(remainingSeconds * 1000).format("HH:mm:ss");
    return days > 0 ? `${days} day(s) ${time}` : time;
  };

  const onDateChange = dates => {
    if (!reportData) return;

    const { byDate } = reportData;
    const [startDate, endDate] = dates.map(d => moment(d));

    let totalsFiltered = {
      conversions: 0,
      totalUsers: 0,
      userEngagementSeconds: 0,
      userEngagement: "00:00:00",
    };
    let bySourceFiltered = [];
    const byDateFiltered = byDate.filter(d => {
      const date = moment(d.date, "YYYY-MM-DD");
      return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
    });

    byDateFiltered.forEach(c => {
      totalsFiltered.conversions += Number(c.conversions);
      totalsFiltered.totalUsers += Number(c.totalUsers);
      totalsFiltered.userEngagementSeconds += Number(c.userEngagementSeconds);

      c.source.forEach(sm => {
        const sourceExists = bySourceFiltered.findIndex(
          s => s.trafficSource === sm.trafficSource
        );
        if (sourceExists === -1) {
          bySourceFiltered.push({
            trafficSource: sm.trafficSource,
            conversions: Number(sm.conversions),
            totalUsers: Number(sm.totalUsers),
            newUsers: Number(sm.newUsers),
            userEngagementSeconds: Number(c.userEngagementSeconds),
            userEngagement: secondsToFormatted(Number(c.userEngagementSeconds)),
          });
        } else {
          bySourceFiltered[sourceExists].conversions += Number(sm.conversions);
          bySourceFiltered[sourceExists].totalUsers += Number(sm.totalUsers);
          bySourceFiltered[sourceExists].newUsers += Number(sm.newUsers);
          bySourceFiltered[sourceExists].userEngagementSeconds += Number(
            c.userEngagementSeconds
          );
          bySourceFiltered[sourceExists].userEngagement = secondsToFormatted(
            Number(bySourceFiltered[sourceExists].userEngagementSeconds)
          );
        }
      });
    });
    totalsFiltered.userEngagement = secondsToFormatted(
      totalsFiltered.userEngagementSeconds
    );

    setConversionsByDate(byDateFiltered);
    setConversionsBySource(bySourceFiltered);
    setConversionsSummary(generateConversionsSummary(totalsFiltered));
    setStartDateEnDate(dates);
    setReportLoading(false);
  };

  return (
    <SEOConversionsContext.Provider
      value={{
        conversionsSummary,
        conversionsByDate,
        reportLoading,
        conversionsBySource,
        secondsToFormatted,
        reportError,
        startDateEnDate,
        onDateChange,
      }}
    >
      {children}
    </SEOConversionsContext.Provider>
  );
};

export const useSEOConversionsContext = () => {
  const context = useContext(SEOConversionsContext);
  if (!context) {
    throw new Error(
      "useSEOConversionsContext must be used within a SEOConversionsProvider"
    );
  }
  return context;
};
